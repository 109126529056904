import LoadingButton from '@mui/lab/LoadingButton'
import { Box } from '@mui/material'
import { FC, memo, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { MessageText } from 'src/components/message-text/MessageText'
import { AppMainForm } from 'src/facade/forms/appFormConverter'
import { Attribute, AttributeType, ChildForm } from 'src/facade/forms/formsService.types'
import { AppTask } from 'src/features/tasks/tasksApi'
import { MainFormAccordion } from 'src/forms/_shared/main-form/MainFormAccordion'

export interface MainFormProps {
	initialMainForm: MainFormForm
	message?: string
	isUpdating?: boolean
	disabled?: boolean
	task?: AppTask
	onSubmit(form: MainFormForm): Promise<void> | void
	onClearMessage?(): void
}

export interface MainFormForm extends AppMainForm {
	attributes: AppAttribute[]
	children: AppChildForm[]
}

export interface AppChildForm extends ChildForm {
	attributes: AppAttribute[]
	tagCode?: string
}

export type AppAttribute = Attribute &
	(
		| {
				attribute_type: Exclude<AttributeType, 'Date' | 'Text' | 'Media' | 'Signature' | 'User' | 'Attachment'>
				value?: string | null
		  }
		| {
				attribute_type: AttributeType.Date
				value?: Date
		  }
		| {
				attribute_type: AttributeType.Text
				value?: string | null
		  }
		| {
				attribute_type: AttributeType.Media
				value?: File[]
		  }
		| {
				attribute_type: AttributeType.Signature
				value?: File[]
		  }
		| {
				attribute_type: AttributeType.Attachment
				value?: File[]
		  }
		| {
				attribute_type: AttributeType.User
				value?: string[]
		  }
	)

export const MainForm: FC<MainFormProps> = memo(
	({ initialMainForm, message, onSubmit, onClearMessage, isUpdating, disabled, task }) => {
		const methods = useForm<MainFormForm>({ defaultValues: initialMainForm })
		const { watch, reset, handleSubmit, formState } = methods

		useEffect(() => {
			reset(initialMainForm)
		}, [initialMainForm, reset])

		const form = watch()

		const writeableChildForms: string[] = (task?.metadata?.write || '').split(',').filter(Boolean)
		const canEditAttribute = (formElement: AppChildForm): boolean =>
			writeableChildForms.includes(formElement.attribute_name) || !task
		const isDisabledChildForm = (childForm: AppChildForm): boolean => (disabled ? true : !canEditAttribute(childForm))

		const isDisabledMainForm: boolean = disabled ? true : !!task

		if (!form.id) {
			return null
		}

		const getOnlyDirtyFields = (submittedForm: MainFormForm): MainFormForm => {
			return {
				...submittedForm,
				attributes: submittedForm.attributes.filter((_, index) => formState.dirtyFields?.attributes?.[index]),
				children: submittedForm.children
					.map((childForm, childFormIndex) => ({
						...childForm,
						attributes: childForm.attributes.filter(
							(_, attrIndex) => formState.dirtyFields?.children?.[childFormIndex]?.attributes?.[attrIndex],
						),
					}))
					.filter((_, childFormIndex) => formState.dirtyFields?.children?.[childFormIndex]),
			}
		}

		return (
			<Box my={2}>
				<FormProvider {...methods}>
					<form
						noValidate
						onSubmit={(event) => {
							onClearMessage?.()
							handleSubmit(async (submittedForm) => {
								await onSubmit(isUpdating ? getOnlyDirtyFields(submittedForm) : submittedForm)
							})(event)
						}}
					>
						<Box>
							<MainFormAccordion form={form} disabled={isDisabledMainForm} shouldOmitDocumentNumber={!task} />
							{form.children.map((childForm, index) => (
								<MainFormAccordion
									key={`${childForm.attribute_name}-${childForm.id}`}
									form={childForm}
									formIndex={index}
									disabled={isDisabledChildForm(childForm)}
								/>
							))}
						</Box>
						<Box my={2}>
							<LoadingButton
								variant="contained"
								color="primary"
								type="submit"
								loading={formState.isSubmitting}
								loadingIndicator={isUpdating ? 'Updating...' : 'Creating...'}
								sx={() => ({ minWidth: 240 })}
								disabled={disabled ? true : isUpdating ? !formState.isDirty : false}
							>
								{isUpdating ? 'Update' : 'Create'}
							</LoadingButton>
							<MessageText mt={2} errorMessage={message} />
						</Box>
					</form>
				</FormProvider>
			</Box>
		)
	},
)

MainForm.displayName = 'MainForm'
