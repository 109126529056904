import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { MessagePanel } from 'src/components/message-panel/MessagePanel'
import { Layout } from 'src/components/Molecules/Layout'
import { FormTemplate } from 'src/facade/forms/formsService'
import { AttributeDependsOn } from 'src/facade/forms/formsService.types'
import { useFetchFormTemplatesQuery } from 'src/features/form-templates/formTemplatesApi'
import { useFetchFormQuery } from 'src/features/forms/formsApi'
import { updateForm } from 'src/features/forms/updateForm'
import { tasksApi } from 'src/features/tasks/tasksApi'
import { createAppAttribute } from 'src/forms/_shared/createAppAttribute'
import { MainForm, MainFormForm } from 'src/forms/_shared/main-form/MainForm'
import { sortForm } from 'src/forms/_shared/sortForm'
import browserHistory from 'src/services/history'
import { useAppDispatch, useAppSelector } from 'src/utils/reduxUtils'

export interface FormCreateForm {
	formTemplate: FormTemplate | null
}

export const CreateForm: FC = () => {
	const {
		contract: { contract },
	} = useAppSelector((state) => state.users)

	const { control, watch } = useForm<FormCreateForm>({})

	const { formTemplate } = watch()

	const { data: templates, error, isLoading: isLoadingTemplates } = useFetchFormTemplatesQuery(contract)

	if (error) {
		return <MessagePanel my={3} errorMessage="Failed to load forms." />
	}

	return (
		<Layout>
			<Box my={3}>
				<Box sx={(theme) => ({ maxWidth: theme.breakpoints.values.md })}>
					<Controller
						name="formTemplate"
						control={control}
						defaultValue={null}
						render={({ field }) => (
							<Autocomplete
								{...field}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								onChange={(e, data) => field.onChange(data)}
								disablePortal
								loading={isLoadingTemplates}
								id="form-search"
								options={templates || []}
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										InputLabelProps={{ shrink: true }}
										label="Select form"
										size="medium"
										margin="normal"
									/>
								)}
								getOptionLabel={(template) => template.class_name}
								renderOption={(params, option) => (
									<Box
										{...(params as any)}
										sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start!important' }}
									>
										<Typography display="block">{option.class_name}</Typography>
										<Typography variant="caption" display="block">
											{option.class_code}
										</Typography>
									</Box>
								)}
							/>
						)}
					/>
					{formTemplate?.id && <MainFormWrapper id={formTemplate.id} />}
				</Box>
			</Box>
		</Layout>
	)
}

interface MainFormWrapperProps {
	id: string
}

const MainFormWrapper: FC<MainFormWrapperProps> = ({ id }) => {
	const dispatch = useAppDispatch()
	const {
		contract: { contract },
	} = useAppSelector((state) => state.users)

	const { data: mainForm, isFetching } = useFetchFormQuery({ id, contract })

	const [message, setMessage] = useState<string | undefined>()

	const initialMainForm: MainFormForm | null = useMemo(
		() =>
			mainForm
				? sortForm({
						...mainForm,
						attributes: mainForm.attributes.map((attr) => createAppAttribute(attr)),
						children: mainForm.children
							.filter((childForm) => childForm.depends_on === AttributeDependsOn.PreRequisite)
							.map((childForm) => ({
								...childForm,
								attributes: childForm.attributes.map((attr) => createAppAttribute(attr)),
							})),
				  })
				: null,
		[mainForm],
	)

	const handleSubmit = async (submittedForm: MainFormForm) => {
		try {
			await updateForm(submittedForm, contract)
			dispatch(tasksApi.util.invalidateTags(['Tasks']))
			toast.success('Successfully created form.')
			browserHistory.push('/forms')
		} catch (e) {
			console.error(e)
			setMessage('Failed to create form.')
		}
	}

	if (isFetching) {
		return (
			<Box my={2}>
				<SpinnerComponent size={42} />
			</Box>
		)
	}

	if (!initialMainForm) {
		return null
	}

	return (
		<MainForm
			initialMainForm={initialMainForm}
			message={message}
			onSubmit={handleSubmit}
			onClearMessage={() => setMessage(undefined)}
		/>
	)
}
