import { useEffect } from 'react'
import { TaskStatus } from 'src/facade/tasks/tasksService.types'
import { useLocalStorageState } from 'src/utils/useLocalStorageState'
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params'
import { TASK_STATUS_LABELS } from './_shared/taskStatusLabels'

const CustomFilterParam = withDefault(ArrayParam, [])
type UseFormsSelectedFilters = [string[], (filters: string[]) => void]
let didInit = false

export function useFormsSelectedFilters(): UseFormsSelectedFilters {
	const [localFilters, setLocalFilters] = useLocalStorageState<string[]>('filters', [])
	const [queryParamFilters, setQueryParamFilters] = useQueryParam('selected_filters', CustomFilterParam)

	useEffect(() => {
		if (!didInit) {
			didInit = true
			if (localFilters.length > 0) {
				let filterList = localFilters.map((item) => {
					return String(item)
				})
				setLocalFilters(filterList)
			} else if (queryParamFilters.length > 0) {
				setQueryParamFilters(localFilters)
			} else {
				const filters = [TaskStatus.InProgress, TaskStatus.Pending, TaskStatus.AwaitingAcceptance].map(
					(taskStatus) => TASK_STATUS_LABELS[taskStatus],
				)
				setLocalFilters(filters)
				setQueryParamFilters(filters)
			}
		}
	}, [])

	const setFormsSelectedFilters = (filters: string[]) => {
		setLocalFilters(filters)
		setQueryParamFilters(filters)
	}
	return [localFilters, setFormsSelectedFilters]
}
