import { Box, BoxProps, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { FC } from 'react'

export interface MessagePanelProps extends BoxProps {
	errorMessage?: string
}

export const MessagePanel: FC<MessagePanelProps> = ({ errorMessage, ...boxProps }) => {
	if (!errorMessage) {
		return null
	}

	return (
		<Box
			py={3}
			{...boxProps}
			sx={{ ...boxProps.sx, display: 'flex', justifyContent: 'center', background: red[50], borderRadius: 2 }}
		>
			<Typography variant="h5">{errorMessage}</Typography>
		</Box>
	)
}
