export const GET_LIST_OF_GROUPS = 'groupsManagement/GET_LIST_OF_GROUPS'
export const SET_LIST_OF_GROUPS = 'groupsManagement/SET_LIST_OF_GROUPS'
export const HANDLE_LIST_OF_GROUPS_IS_LOADING = 'groupsManagement/HANDLE_LIST_OF_GROUPS_IS_LOADING'
export const HANDLE_PAGINATION_FOR_GROUPS = 'groupsManagement/HANDLE_PAGINATION_FOR_GROUPS'

export const SEARCH_FOR_GROUP = 'groupsManagement/SEARCH_FOR_GROUP'
export const SEARCH_FOR_USERS_IN_GROUP = 'groupsManagement/SEARCH_FOR_USERS_IN_GROUP'
export const HANDLE_CHECKED_USER = 'groupsManagement/HANDLE_CHECKED_USER'
export const CHECK_DUPLICATES_IN_USERS = 'groupsManagement/CHECK_DUPLICATES_IN_USERS'

export const DELETE_GROUP = 'groupsManagement/DELETE_GROUP'
export const IS_DELETING_GROUP = 'groupsManagement/IS_DELETING_GROUP'

export const HANDLE_NEW_GROUP_POPUP = 'groupsManagement/HANDLE_NEW_GROUP_POPUP'
export const CREATE_GROUP = 'groupsManagement/CREATE_GROUP'
export const HANDLE_NEW_GROUP_IS_CREATING = 'groupsManagement/HANDLE_NEW_GROUP_IS_CREATING'

export const HANDLE_EDIT_POPUP = 'groupsManagement/HANDLE_EDIT_POPUP'
export const GET_USERS_FOR_GROUP = 'groupsManagement/GET_USERS_FOR_GROUP'
export const SET_USERS_FOR_GROUP = 'groupsManagement/SET_USERS_FOR_GROUP'
export const HANDLE_USERS_LOADING = 'groupsManagement/HANDLE_USERS_LOADING'

export const REMOVE_USERS_IN_GROUP = 'groupsManagement/REMOVE_USERS_IN_GROUP'
export const HANDLE_IS_REMOVING = 'groupsManagement/HANDLE_IS_REMOVING'

export const ADD_NEW_USERS_TO_GROUP = 'groupsManagement/ADD_NEW_USERS_TO_GROUP'
export const HANDLE_IS_ADDING_NEW_USER_TO_GROUP = 'groupsManagement/HANDLE_IS_ADDING_NEW_USER_TO_GROUP'

export const GET_PRIVILEGES_TYPES = 'groupsManagement/GET_PRIVILEGES_TYPES'
export const SET_PRIVILEGES_TYPES = 'groupsManagement/SET_PRIVILEGES_TYPES'
export const SET_PRIVILEGES_HIERARCHY = 'groupsManagement/SET_PRIVILEGES_HIERARCHY'
export const SET_PRIVILEGES_TYPES_LOADING = 'groupsManagement/SET_PRIVILEGES_TYPES_LOADING'

export const SET_PRIVILEGES_LIST_LOADING = 'groupsManagement/SET_PRIVILEGES_LIST_LOADING'

export const GET_PRIVILEGES_SOURCE = 'groupsManagement/GET_PRIVILEGES_SOURCE'
export const SET_PRIVILEGES_SOURCE = 'groupsManagement/SET_PRIVILEGES_SOURCE'
export const HANDLE_IS_LOADING_PRIVILEGES_SOURCE = 'groupsManagement/HANDLE_IS_LOADING_PRIVILEGES_SOURCE'

export const HANDLE_OPEN_PRIVILEGE_SOURCE = 'groupsManagement/HANDLE_OPEN_PRIVILEGE_SOURCE'

export const SAVE_PRIVILEGE = 'groupsManagement/SAVE_PRIVILEGE'
export const HANDLE_IS_SAVING_PRIVILEGES = 'groupsManagement/HANDLE_IS_SAVING_PRIVILEGES'

export const REMOVE_PRIVILEGE = 'groupsManagement/REMOVE_PRIVILEGE'
export const HANDLE_IS_REMOVING_PRIVILEGES = 'groupsManagement/HANDLE_IS_REMOVING_PRIVILEGES'

export const CREATE_PRIVILEGE_PARENT = 'groupsManagement/CREATE_PRIVILEGE_PARENT'
export const CREATE_NEW_PRIVILEGE = 'groupsManagement/CREATE_NEW_PRIVILEGE'
export const HANDLE_CHANGE_CHILD = 'groupsManagement/HANDLE_CHANGE_CHILD'
export const ASSIGN_CHILD = 'groupsManagement/ASSIGN_CHILD'

export const CLEAR_PRIVILEGES = 'groupsManagement/CLEAR_PRIVILEGES'

export const REMOVE_NEW_PRIVILEGE = 'groupsManagement/REMOVE_NEW_PRIVILEGE'
export const HANDLE_OPEN_NEW_PRIVILEGE = 'groupsManagement/HANDLE_OPEN_NEW_PRIVILEGE'
