import { ICellEditorParams } from 'ag-grid-community'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import styled from 'styled-components'

export interface AsyncAutocompleteEditorProps extends AsyncProps<any>, ICellEditorParams {}

const Wrapper = styled.div<{ width?: number; height: number }>`
	background: inherit;
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	display: flex;
	align-items: center;
`

export const AsyncAutocompleteEditor = forwardRef<any, AsyncAutocompleteEditorProps>((props, ref) => {
	const [value, setValue] = useState<any>(props.value)
	const [inputValue, setInputValue] = useState<string>('')
	const { isMulti, loadOptions } = props as any
	const refInput = useRef<any>(null)

	useEffect(() => {
		setTimeout(() => refInput.current?.focus())
	}, [])

	/* Component Editor Lifecycle methods */
	useImperativeHandle(ref, () => {
		return {
			getValue() {
				return value
			},
			// Gets called once before editing starts, to give editor a chance to
			// cancel the editing before it even starts.
			isCancelBeforeStart() {
				return false
			},
			// Gets called once when editing is finished (eg if enter is pressed).
			// If you return true, then the result of the edit will be ignored.
			isCancelAfterEnd() {
				return false
			},
			isPopup() {
				return true
			},
		}
	})

	const handleInputChange = (newValue: string) => {
		const iv = newValue.replace(/\W/g, '')
		setInputValue(iv)
		return iv
	}

	return (
		<Wrapper width={(props.column as any).actualWidth} height={props.eGridCell.offsetHeight}>
			<AsyncSelect
				defaultOptions
				isMulti={isMulti}
				loadOptions={loadOptions}
				ref={refInput}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus
				styles={{
					container: (prev) =>
						({
							...prev,
							width: (props.column as any).actualWidth,
						}) as any,
					control: (prev) =>
						({
							...prev,
							borderRadius: 0,
						}) as any,
				}}
				value={value}
				getOptionLabel={(item) => item}
				getOptionValue={(item) => item}
				onChange={(item) => {
					setValue(item)
				}}
				onInputChange={handleInputChange}
				inputValue={inputValue}
				fullWidth
				defaultMenuIsOpen
				closeMenuOnSelect={false}
			/>
		</Wrapper>
	)
})

AsyncAutocompleteEditor.displayName = 'AsyncAutocompleteEditor'
