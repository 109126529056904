import { axiosInstance } from 'src/services/axiosInstance'
import { apiUrl } from 'src/services/constants'
import { SearchTasksResponse } from './workflowService.types'

export const searchTasks = async (query: string, contract: string): Promise<SearchTasksResponse> => {
	const { data } = await axiosInstance.get<SearchTasksResponse>(
		`${apiUrl}/workflow/tasks/search/?contract=${contract}&document_number=${query}`,
	)

	return data
}
