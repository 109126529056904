import { action } from 'typesafe-actions'
import {
	GET_DOCUMENT_ATTRIBUTES,
	SET_DOCUMENT_ATTRIBUTES,
	GET_TECHNICAL_LIFECYCLES_ATTRIBUTES,
	SET_TECHNICAL_LIFECYCLES_ATTRIBUTES,
} from './constants'
import { IDocumentAttributes } from '../../models/attributes/attributes'

export const getAttributesForDocument = () => action(GET_DOCUMENT_ATTRIBUTES)
export const setAttributesForDocument = (attributes: IDocumentAttributes[]) =>
	action(SET_DOCUMENT_ATTRIBUTES, attributes)

export const getAttributesForTechnicalLifecycle = () => action(GET_TECHNICAL_LIFECYCLES_ATTRIBUTES)
export const setAttributesForTechnicalLifecycle = (attributes: any[]) =>
	action(SET_TECHNICAL_LIFECYCLES_ATTRIBUTES, attributes)
