import { combineEpics } from 'redux-observable'
import { attributesEpics } from '../../features/attributes'
import { commentsEpics } from '../../features/comments/commentsEpics'
import { deliverablesEpics } from '../../features/deliverables/deliverablesEpics'
import { disciplineOverviewEpics } from '../../features/disciplineOverview'
import { flowForManagerEpics } from '../../features/flowForManager'
import { formsHandlerEpics } from '../../features/formsHandler/epics'
import { formsListEpics } from '../../features/formsList'
import { groupsManagementEpics } from '../../features/groupsManagement'
import { searchAssetsEpics } from '../../features/search'
import { tasksActionsElementEpics } from '../../features/taskActions'
import { taskActionsEpics } from '../../features/taskActions/taskActionsEpics'
import { tasksListEpics } from '../../features/tasksList'
import { tidpOverviewEpics } from '../../features/tidpOverview'
import { usersEpics } from '../../features/users'
import { usersManagementEpics } from '../../features/usersManagement'

export default combineEpics(
	flowForManagerEpics,
	tidpOverviewEpics,
	disciplineOverviewEpics,
	usersEpics,
	commentsEpics,
	attributesEpics,
	searchAssetsEpics,
	tasksListEpics,
	tasksActionsElementEpics,
	usersManagementEpics,
	groupsManagementEpics,
	formsListEpics,
	formsHandlerEpics,
	deliverablesEpics,
	taskActionsEpics,
)
