/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { msalInstance } from 'src/utils/msalInstance'
import styled from 'styled-components'
import cancel from '../../../assets/icons/cancel.svg'
import searchIcon from '../../../assets/icons/searchIcon24px.svg'
import logoImage from '../../../assets/logo.svg'
import { searchAssets, setAssetsResults } from '../../../features/search/actions'
import { setContract } from '../../../features/users/actions'
import { Contract } from '../../../features/users/reducer'
import browserHistory from '../../../services/history'
import { colors } from '../../../theme/colors'
import { useAppDispatch, useAppSelector, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Image from '../../Atoms/Image'
import Input from '../../Atoms/Input'
import SpinnerComponent from '../../Atoms/Loader'
import HeaderMenu from '../../Molecules/HeaderMenu'
import SettingsMenu from '../../Molecules/HeaderSettings'
import Notifier from '../../Molecules/Notifier'
import Settings from '../../Molecules/Settings'
import UserInfo from '../../Molecules/UserInfo'

export const headerHeight = '80px'
const MainWrapper = styled.div`
	height: ${headerHeight};
	background-color: #fff;
	align-items: center;
	padding: 0 40px;
	justify-content: space-between;
	display: flex;
	border-bottom: 1px solid #e6ecf7;
`

const Right = styled.div`
	display: flex;
	align-items: center;
`

const Left = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
`

const LogoWrapper = styled.div`
	margin: -10px 10px 0 10px;
	img {
		cursor: pointer;
	}
`
const Results = styled.div`
	position: absolute;
	top: 50px;
	width: 100%;
	background: ${colors.white};
	border: 1px solid ${colors.lightGreyE0};
	max-height: 100px;
	overflow: auto;
	z-index: 10000;
	border-radius: 5px;
	&::-webkit-scrollbar-thumb {
		display: none;
	}
	&::-webkit-scrollbar {
		display: none;
	}
	.oneResult {
		padding: 5px;
		&:hover {
			background: ${colors.lightGreyE0};
		}
	}
`

const Search = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	cursor: pointer;
	border-radius: 5px;
	width: 100%;
	background: ${colors.lightGreyE0};
	input {
		height: 42px;
		background: ${colors.lightGreyE0};
	}
	img {
		width: 16px;
		height: 16px;
		margin: 0 10px 0 5px;
	}
	.cancelImg {
		width: 14px;
		height: 14px;
		margin: 0 5px;
	}
	.spinnerWrapper {
		margin: 0 5px;
	}
`

function Header() {
	const dispatch = useAppDispatch()
	const { contract, contracts, roles, isSearching, assetSearchResults } = useAppSelectorDeprecated((state) => ({
		contract: state.users.contract,
		contracts: state.users.contracts,
		roles: state.authorization.roles,
		isSearching: state.search.isSearching,
		assetSearchResults: state.search.assetSearchResults,
	}))
	const user = useAppSelector((state) => state.profile.user)
	const username = useAppSelector((state) => state.profile.user!.name)

	const [searchingPhrase, setSearchingPhrase] = useState<string | number>('')
	const [searchTask, setSearchTask] = useState<any>(null)
	const [showModal, handleShowModal] = useState(false)

	const search = () => {
		clearTimeout(searchTask)
		handleShowModal(true)
		dispatch(searchAssets(searchingPhrase))
	}
	const cancelSearching = () => {
		clearTimeout(searchTask)
		handleShowModal(false)
		setSearchingPhrase('')
		dispatch(setAssetsResults([]))
	}
	const searchPhrase = (value: string | number) => {
		setSearchingPhrase(value)
		dispatch(setAssetsResults([]))
		if (searchTask) {
			clearTimeout(searchTask)
		}
		setSearchTask(
			setTimeout(() => {
				searchAssets(value)
				handleShowModal(true)
			}, 400),
		)
	}

	const handleContractSwitch = (ctr: Contract) => {
		dispatch(setContract(ctr))

		// FIXME get rid off it - contract can be accessed from emt-viewer itself
		// Set selected contract to local storage to sync with emt-3d viewer
		localStorage.setItem('contract', ctr.contract)
	}

	return (
		<MainWrapper>
			<Left>
				<Link to="/">
					<LogoWrapper>
						<Image imageSrc={logoImage} alt="logo" width="87px" height="32px" />
					</LogoWrapper>
				</Link>
				{roles.length > 0 && <HeaderMenu />}
				{roles.length > 0 && (
					<Search>
						<Input
							className="searchInput"
							placeholder="Tag / Function / Class / Disciplines"
							backgroundColor={colors.white}
							value={searchingPhrase}
							onClick={() => {
								if (assetSearchResults.length > 0) {
									handleShowModal(true)
								}
							}}
							onChange={searchPhrase}
						/>
						{isSearching && (
							<div className="spinnerWrapper">
								<SpinnerComponent size={20} />
							</div>
						)}
						{searchingPhrase && (
							<div onClick={cancelSearching}>
								<img src={cancel} alt="cancel-searching" />
							</div>
						)}

						<img className="searchIcon" src={searchIcon} alt="search" onClick={search} />
						{showModal && searchingPhrase && assetSearchResults.length > 0 && (
							<Results>
								{assetSearchResults.map((item: { displayName: string; link: string }, index) => (
									<div
										onClick={() => {
											browserHistory.push(item.link)
											dispatch(setAssetsResults([]))
										}}
										key={`${item.displayName}_${index}`}
										className="oneResult"
									>
										{item.displayName}
									</div>
								))}
							</Results>
						)}
					</Search>
				)}
			</Left>
			<Right>
				{roles.length > 0 && <Settings />}
				{roles.length > 0 && <Notifier notificationsCounter={0} />}
				<SettingsMenu />
				<UserInfo
					logout={() => {
						msalInstance.logout()
					}}
					onSelectContract={(ctr) => handleContractSwitch(ctr)}
					userName={user ? user.name.substring(0, user.name.indexOf('@')) : ''}
					contract={contract}
					contracts={contracts}
				/>
			</Right>
		</MainWrapper>
	)
}

export default Header
