/* eslint-disable no-nested-ternary */
import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import trash from '../../../assets/icons/delete.svg'
import { downloadGuide, handleSelectedFile, removeGuide, uploadGuide } from '../../../features/formsHandler/actions'
import { IFormDetails } from '../../../models/formsHandler/formsHandler'
import { useAppDispatch, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Input from '../../Atoms/Input'
import Accordion from '../../Molecules/Accordion'
import Button from '../../Molecules/Button'
import LGButton from '../../Molecules/LGButton'
import { AccordionWrapper } from './Attributes'
import { colors } from '../../../theme/colors'

interface IFormDetailsComponent {
	handleFormDetails: (el: { value: any; key: string }) => void
	formDetails: IFormDetails
	isDisabled: boolean
	IsEditForm: boolean
	isCreate: boolean
	isGuide: boolean
}

const ButtonsWrapper = styled.div`
	display: flex;
	button {
		margin-right: 15px;
	}
`
const Error = styled.div`
	position: absolute;
	right: 0;
	color: ${colors.danger100};
	font-size: 12px;
	margin-top: 5px;
`
const InputWrapper = styled.div`
	max-width: 400px;
	position: relative;
`

const trimText = (el: any) => (el === ' ' ? '' : el)

const DetailsComponent = (
	item: { label: string; value: any },
	isDisabled: boolean,
	el: string,
	handleFormDetails: (el: { value: any; key: string }) => void,
	isCreate: boolean,
	isFormNumberOriginal: boolean,
	isCheckingCode: boolean,
) =>
	useMemo(
		() => (
			<div key={`inputWrapper_${el}`} className="inputWrapper">
				<div className="header">{item.label}</div>
				<InputWrapper>
					<Input
						border={
							!isDisabled && isCreate
								? el === 'formNumber'
									? (item.value.length > 0 ? item.value.replace(/\s/g, '') : item.value) === '' ||
									  (!isFormNumberOriginal && !isCheckingCode)
										? '1px solid red'
										: ''
									: (item.value.length > 0 ? item.value.replace(/\s/g, '') : item.value) === ''
									? '1px solid red'
									: ''
								: ''
						}
						isDisabled={isDisabled}
						key={item.label}
						placeholder={item.label}
						value={item.value}
						onChange={(value) => handleFormDetails({ value: trimText(value), key: el })}
					/>
					{isCreate && el === 'formNumber' && !isFormNumberOriginal && item.value.length > 0 && !isCheckingCode && (
						<Error>Code Duplicated</Error>
					)}
				</InputWrapper>
			</div>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[item, isDisabled, isCheckingCode],
	)

const FormDetailsComponent = ({
	isDisabled,
	isGuide,
	formDetails,
	handleFormDetails,
	IsEditForm,
	isCreate,
}: IFormDetailsComponent) => {
	const changeHandler = (event: any) => {
		dispatch(handleSelectedFile(event.target.files))
	}
	const {
		guideInfo,
		isDownloadingGuide,
		isUploadingGuide,
		selectedFile,
		isRemovingGuide,
		isFormNumberOriginal,
		isCheckingCode,
	} = useAppSelectorDeprecated((state) => ({
		guideInfo: state.formsHandler.guideInfo,
		isDownloadingGuide: state.formsHandler.isDownloadingGuide,
		isUploadingGuide: state.formsHandler.isUploadingGuide,
		selectedFile: state.formsHandler.selectedFile,
		isRemovingGuide: state.formsHandler.isRemovingGuide,
		isFormNumberOriginal: state.formsHandler.isFormNumberOriginal,
		isCheckingCode: state.formsHandler.isCheckingCode,
	}))
	const dispatch = useAppDispatch()

	return (
		<Accordion
			isArrow
			isOpen={formDetails.isOpen.value}
			handleOpen={() => handleFormDetails({ value: !formDetails.isOpen.value, key: 'isOpen' })}
			title="Form Details"
			isChecked={
				Object.keys(formDetails)
					// @ts-ignore
					.map((value) => formDetails[value].value)
					.map((value) => (value.length > 0 ? value.replace(/\s/g, '') : value))
					.filter((el) => el === '').length === 0 && (isCreate ? isFormNumberOriginal && !isCheckingCode : true)
			}
		>
			<AccordionWrapper>
				{Object.keys(formDetails)
					.filter((el) => el !== 'isOpen')
					.map((el) => {
						// @ts-ignore
						const item = formDetails[el]
						return DetailsComponent(
							item,
							isDisabled,
							el,
							handleFormDetails,
							isCreate,
							isFormNumberOriginal,
							isCheckingCode,
						)
					})}
				{isGuide && (
					<>
						{IsEditForm && (
							<div key="inputWrapper_guide_upload" className="inputWrapper">
								<div className="header">Upload Guidance Document</div>
								<div className="upload">
									<label className="custom-file-upload">
										<input
											disabled={isDownloadingGuide || isUploadingGuide || isRemovingGuide}
											type="file"
											id="file_upload"
											accept="application/pdf"
											onChange={changeHandler}
										/>
										{!!selectedFile && selectedFile[0]?.name
											? selectedFile[0]?.name
											: guideInfo.name
											? `${guideInfo.name}`
											: 'Select File ...'}
									</label>
									<LGButton
										text="Save"
										onClick={() => dispatch(uploadGuide(selectedFile))}
										height={60}
										isWhiteText
										isLoading={isUploadingGuide}
										disabled={
											!selectedFile ||
											!selectedFile[0]?.name ||
											isDownloadingGuide ||
											isUploadingGuide ||
											isRemovingGuide
										}
										padding="0 15px"
									/>
								</div>
							</div>
						)}

						<ButtonsWrapper>
							{guideInfo.id && (
								<div className="inputWrapper">
									<LGButton
										text="Download Guide"
										onClick={() => dispatch(downloadGuide())}
										height={60}
										isWhiteText
										isLoading={isDownloadingGuide}
										disabled={isDownloadingGuide || isUploadingGuide || isRemovingGuide}
										padding="0 15px"
									/>
								</div>
							)}
							{guideInfo.id && IsEditForm && (
								<div className="inputWrapper">
									<Button
										loaderHeight={15}
										text="Remove Guide"
										onClick={() => dispatch(removeGuide())}
										isError
										height={60}
										imageSrc={trash}
										isLoading={isRemovingGuide}
										isDisabled={isDownloadingGuide || isUploadingGuide || isRemovingGuide}
									/>
								</div>
							)}
						</ButtonsWrapper>
					</>
				)}
			</AccordionWrapper>
		</Accordion>
	)
}

export default memo(FormDetailsComponent)
