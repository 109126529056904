import { action } from 'typesafe-actions'
import {
	GET_LIST_OF_GROUPS,
	SET_LIST_OF_GROUPS,
	HANDLE_LIST_OF_GROUPS_IS_LOADING,
	DELETE_GROUP,
	IS_DELETING_GROUP,
	CREATE_GROUP,
	HANDLE_NEW_GROUP_POPUP,
	HANDLE_NEW_GROUP_IS_CREATING,
	HANDLE_EDIT_POPUP,
	GET_USERS_FOR_GROUP,
	HANDLE_USERS_LOADING,
	SET_USERS_FOR_GROUP,
	REMOVE_USERS_IN_GROUP,
	HANDLE_IS_REMOVING,
	HANDLE_IS_ADDING_NEW_USER_TO_GROUP,
	ADD_NEW_USERS_TO_GROUP,
	HANDLE_PAGINATION_FOR_GROUPS,
	SEARCH_FOR_GROUP,
	SEARCH_FOR_USERS_IN_GROUP,
	HANDLE_CHECKED_USER,
	CHECK_DUPLICATES_IN_USERS,
	GET_PRIVILEGES_TYPES,
	SET_PRIVILEGES_LIST_LOADING,
	SET_PRIVILEGES_TYPES_LOADING,
	SET_PRIVILEGES_TYPES,
	GET_PRIVILEGES_SOURCE,
	SET_PRIVILEGES_SOURCE,
	HANDLE_OPEN_PRIVILEGE_SOURCE,
	HANDLE_IS_LOADING_PRIVILEGES_SOURCE,
	SAVE_PRIVILEGE,
	HANDLE_IS_SAVING_PRIVILEGES,
	HANDLE_IS_REMOVING_PRIVILEGES,
	REMOVE_PRIVILEGE,
	SET_PRIVILEGES_HIERARCHY,
	CREATE_NEW_PRIVILEGE,
	CREATE_PRIVILEGE_PARENT,
	REMOVE_NEW_PRIVILEGE,
	HANDLE_OPEN_NEW_PRIVILEGE,
	HANDLE_CHANGE_CHILD,
	ASSIGN_CHILD,
	CLEAR_PRIVILEGES,
} from './constants'
import { IEditPopup } from '../../models/groupsManagement/groupsManagement'

export const getListOfGroups = (group: { page: number, contract: string }) => action(GET_LIST_OF_GROUPS, group)
export const setListOfGroups = (groups: Array<{ name: string; quantity: number }>) => action(SET_LIST_OF_GROUPS, groups)
export const handleListOfGroupsIsLoading = (isLoading: boolean) => action(HANDLE_LIST_OF_GROUPS_IS_LOADING, isLoading)
export const handlePaginationForGroups = (pagination: { page: number; pageCount: number }) =>
	action(HANDLE_PAGINATION_FOR_GROUPS, pagination)

export const searchForGroup = (group: { page: number; query: string }) => action(SEARCH_FOR_GROUP, group)

export const deleteGroup = (id: string) => action(DELETE_GROUP, id)
export const handleIsDeletingGroup = (isDeleting: boolean) => action(IS_DELETING_GROUP, isDeleting)

export const handleNewGroupPopup = (isOpen: boolean) => action(HANDLE_NEW_GROUP_POPUP, isOpen)
export const handleNewGroupIsCreating = (isCreating: boolean) => action(HANDLE_NEW_GROUP_IS_CREATING, isCreating)
export const createNewGroup = (group: { title: string; project_organisation: boolean }) => action(CREATE_GROUP, group)

export const handleEditGroup = (editPopup: IEditPopup) => action(HANDLE_EDIT_POPUP, editPopup)
export const getUsersForGroup = (tag: string | number) => action(GET_USERS_FOR_GROUP, tag)
export const handleUsersLoading = (isLoading: boolean) => action(HANDLE_USERS_LOADING, isLoading)
export const checkUsersDuplicates = (users: Array<{ value: string; label: string }>) =>
	action(CHECK_DUPLICATES_IN_USERS, users)

export const setUsersForGroup = (users: Array<{ user: string; isSelected: boolean }>) =>
	action(SET_USERS_FOR_GROUP, users)
export const searchForUsersInGroup = (users: { page: number; query: string }) =>
	action(SEARCH_FOR_USERS_IN_GROUP, users)
export const handleCheckedUser = (user: string) => action(HANDLE_CHECKED_USER, user)

export const removeUsersForGroup = (listOfUsers: { groupTag: string | number }) =>
	action(REMOVE_USERS_IN_GROUP, listOfUsers)
export const isRemovingUsersFromGroup = (isRemoving: boolean) => action(HANDLE_IS_REMOVING, isRemoving)

export const addNewUserToGroup = (group: { users: string[]; groupTag: string | number }) =>
	action(ADD_NEW_USERS_TO_GROUP, group)
export const isAddingNewUsersForGroup = (isAdding: boolean) => action(HANDLE_IS_ADDING_NEW_USER_TO_GROUP, isAdding)

export const getPrivilegesTypes = () => action(GET_PRIVILEGES_TYPES)
export const setPrivilegesTypes = (types: string[]) => action(SET_PRIVILEGES_TYPES, types)
export const setPrivilegesHierarchy = (hierarchy: string[]) => action(SET_PRIVILEGES_HIERARCHY, hierarchy)
export const handlePrivilegesTypesLoading = (isLoading: boolean) => action(SET_PRIVILEGES_TYPES_LOADING, isLoading)

export const handlePrivilegesListLoading = (isLoading: boolean) => action(SET_PRIVILEGES_LIST_LOADING, isLoading)

export const getPrivilegesSource = (id: any) => action(GET_PRIVILEGES_SOURCE, id)
export const setPrivilegesSource = (privilege: any) => action(SET_PRIVILEGES_SOURCE, privilege)
export const handleLoadingPrivilegeSource = (isLoading: boolean) =>
	action(HANDLE_IS_LOADING_PRIVILEGES_SOURCE, isLoading)

export const removePrivilege = (privilege: { tag: any; id: any }) => action(REMOVE_PRIVILEGE, privilege)
export const isRemovingPrivileges = (isRemoving: boolean) => action(HANDLE_IS_REMOVING_PRIVILEGES, isRemoving)

export const handleOpenPrivilegeSource = (privilege: { isOpen: any; id: any }) =>
	action(HANDLE_OPEN_PRIVILEGE_SOURCE, privilege)

export const savePrivilege = (privilege: { tag: any }) => action(SAVE_PRIVILEGE, privilege)
export const isSavingPrivileges = (isSaving: boolean) => action(HANDLE_IS_SAVING_PRIVILEGES, isSaving)

export const createPrivilegeParent = (privilege: { name: string }) => action(CREATE_PRIVILEGE_PARENT, privilege)
export const createNewPrivilege = (privilege: { id: string; parentsIds: string[]; isAdditional: boolean }) =>
	action(CREATE_NEW_PRIVILEGE, privilege)

export const clearPrivileges = () => action(CLEAR_PRIVILEGES)

export const handleChangeChild = (privilege: {
	id: string
	parentsIds: string[]
	isType: boolean
	value: any
	parentPrivilege: any
	isAdditional?: boolean
}) => action(HANDLE_CHANGE_CHILD, privilege)
export const assignChild = (privilege: any) => action(ASSIGN_CHILD, privilege)

export const removeNewPrivilege = () => action(REMOVE_NEW_PRIVILEGE)
export const handleOpenNewPrivilege = () => action(HANDLE_OPEN_NEW_PRIVILEGE)
