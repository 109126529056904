/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import SectionHeadline from '../../Atoms/SectionHeadline'
import Headline from '../../Atoms/Headline'
import Paragraph from '../../Atoms/Paragraph'

const SubTitleWrapper = styled.div<{ margin?: string }>`
	margin: ${({ margin }) => margin || '15px 0 50px 0'}; ;
`

function PageTitle({
	title,
	subTitle,
	section,
	margin,
}: {
	title: string
	subTitle: string
	section?: boolean
	margin?: string
}) {
	return (
		<>
			{section ? <SectionHeadline text={title} /> : <Headline title={title} small />}
			{section ? (
				<SubTitleWrapper margin={margin}>
					<SectionHeadline text={subTitle} />
				</SubTitleWrapper>
			) : (
				<SubTitleWrapper margin={margin}>
					<Paragraph text={subTitle} small black62 />
				</SubTitleWrapper>
			)}
		</>
	)
}

export default PageTitle
