/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import { colors } from '../../../theme/colors'

interface IPagination {
	onPageChange: (page: number) => void
	pageCount: number
	initialPage: number
	page: number
}

const PaginationContainer = styled.div`
	width: 100%;
	justify-content: center;
	display: flex;
	padding: 10px 0;
	.pagination > li {
		display: inline-block;
		padding-left: 0;
	}

	.pagination > li {
		list-style: none;
	}

	.pagination > li > a,
	.pagination > li > span {
		position: relative;
		float: left;
		margin: 0 7px;
		border-radius: 5px;
		color: ${colors.black};
		padding: 6px 12px;
		line-height: 1.42857143;
		text-decoration: none;
		background-color: #fff;
	}

	.pagination > li.active > a {
		color: ${colors.main100};
		background-color: ${colors.lightGreyE0};
	}

	.pagination > li > a:hover {
		background-color: ${colors.lightGreyE0};
		color: ${colors.darkGrey46};
	}

	.pagination > li:first-child > a,
	.pagination > li:first-child > span,
	.pagination > li:last-child > a,
	.pagination > li:last-child > span {
		background-color: ${colors.lightGreyE0};
		color: ${colors.black};
	}
`

function Pagination(props: IPagination) {
	const onPageChange = (page: { selected: number }) => {
		props.onPageChange(page.selected)
	}
	return (
		<PaginationContainer>
			<ReactPaginate
				forcePage={props.page}
				previousLabel="<"
				nextLabel=">"
				breakLabel={<div>..</div>}
				pageCount={props.pageCount}
				marginPagesDisplayed={7}
				pageRangeDisplayed={3}
				containerClassName="pagination"
				activeClassName="active"
				onPageChange={onPageChange}
			/>
		</PaginationContainer>
	)
}

export default Pagination
