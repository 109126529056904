import SpinnerComponent from 'src/components/Atoms/Loader'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
`

export function LoadingPage() {
	return (
		<SpinnerContainer>
			<SpinnerComponent size={100} />
		</SpinnerContainer>
	)
}
