import { BrowserCacheLocation, Configuration } from '@azure/msal-browser'
import { getAppUrl } from 'src/utils/getBaseUrl'
import { z } from 'zod'

const AzureADConfig = z.object({
	VITE_MSAL_CLIENT_ID: z.string(),
	VITE_MSAL_TENANT_NAME: z.string(),
	VITE_MSAL_REDIRECT_URL: z.string(),
})

export const env = AzureADConfig.parse({
	VITE_MSAL_CLIENT_ID: import.meta.env.VITE_MSAL_CLIENT_ID,
	VITE_MSAL_TENANT_NAME: import.meta.env.VITE_MSAL_TENANT_NAME,
	VITE_MSAL_REDIRECT_URL: import.meta.env.VITE_MSAL_REDIRECT_URL,
})

const redirectUri: string = import.meta.env.MODE === 'production' ? env.VITE_MSAL_REDIRECT_URL : getAppUrl()

export const getAuthorityPrefix = () =>
	`https://${env.VITE_MSAL_TENANT_NAME}.b2clogin.com/${env.VITE_MSAL_TENANT_NAME}.onmicrosoft.com`

export const DEFAULT_POLICY = 'B2C_1A_SignInLocal'

export const getAimsMsalConfig = (policy: string = DEFAULT_POLICY): Configuration => ({
	auth: {
		clientId: env.VITE_MSAL_CLIENT_ID,
		authority: `${getAuthorityPrefix()}/${policy}`,
		knownAuthorities: [`${env.VITE_MSAL_TENANT_NAME}.b2clogin.com`],
		redirectUri,
		postLogoutRedirectUri: redirectUri,
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage,
	},
})
