import { head, isEmpty } from 'ramda'
import { actionFormService } from 'src/facade/action/form/actionFormService'
import { FormAssetData, FormAttributes, MainFormAttributes } from 'src/facade/action/form/actionFormService.type'
import { formAttributeService } from 'src/facade/form-attribute/formAttributeService'
import { AttributeType, Form } from 'src/facade/forms/formsService.types'
import { formatAttributeValue } from 'src/features/forms/formatAttributeValue'
import { AppAttribute, MainFormForm } from 'src/forms/_shared/main-form/MainForm'

export async function updateForm(form: MainFormForm, contract: string, taskId?: number): Promise<void> {
	const mainFormAttributes: MainFormAttributes = formatAttributes(form.attributes)

	const childFormAttributes: FormAttributes = form.children.reduce(
		(previousForms, currentChildForm) => ({
			...previousForms,
			[currentChildForm.attribute_name]: {
				contract,
				class_code: currentChildForm.form_number,
				attribute: formatAttributes(currentChildForm.attributes),
				...(currentChildForm.tagCode ? { tag_code: currentChildForm.tagCode } : {}),
			},
		}),
		{},
	)

	const actionParams = {
		contract,
		task_name: `${form.form_name} (${form.form_number})`,
		form_data: {
			contract,
			class_code: form.form_number,
			attribute: {
				...mainFormAttributes,
				...childFormAttributes,
			},
			...(form.tagCode ? { tag_code: form.tagCode } : {}),
		},
	}

	const {
		action_data: { asset_data: formAsset },
	} = await (taskId ? actionFormService.update(taskId, actionParams) : actionFormService.create(actionParams))

	await uploadFormFiles(form, formAsset, contract)
}

async function uploadFormFiles(form: MainFormForm, formAsset: FormAssetData, contract: string): Promise<void> {
	const fileAttributes: { form: Form; attr: AppAttribute }[] = [
		...form.attributes.map((attr) => ({ form, attr })),
		...form.children.map((childForm) => childForm.attributes.map((attr) => ({ form: childForm, attr }))).flat(),
	].filter(({ attr }) => {
		return [(AttributeType.Media, AttributeType.Attachment, AttributeType.Signature)].includes(attr.attribute_type)
	})

	const allFormAssets: FormAssetData[] = [
		formAsset,
		...(Object.values(formAsset.attribute).filter(
			(attr) => typeof attr === 'object' && Object.keys(attr).length > 0,
		) as FormAssetData[]),
	]
	const mainFormAsset = allFormAssets?.find((asset) => asset.class_code === form?.form_number)
	await Promise.all(
		fileAttributes
			.map(({ attr, form: f }) => {
				const asset = 'attribute_name' in f ? mainFormAsset?.attribute[f.attribute_name] : mainFormAsset

				if (!asset || typeof asset === 'string') {
					return false
				}

				return formAttributeService.uploadAttachment({
					contract,
					tagCode: asset.tag_code,
					attributeName: attr.attribute_name,
					file: head(attr.value as File[]),
				})
			})
			.filter(Boolean),
	)
}

function formatAttributes(attrs: AppAttribute[]) {
	return attrs
		.filter((attr) => !!attr.value && !isEmpty(attr.value))
		.reduce((previous, current) => ({ ...previous, [current.attribute_name]: formatAttributeValue(current) }), {})
}
