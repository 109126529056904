/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'

const StyledImg = styled.img<{ customRotate?: number; isClickable?: boolean; margin?: string }>`
	transform: ${({ customRotate }) => customRotate && `rotate(${customRotate}deg)`};
	cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
	margin: ${({ margin }) => margin || ''};
	transition: all 150ms ease-in;
`

function Image({
	imageSrc,
	alt,
	width,
	onClick,
	height,
	customRotate,
	isClickable,
	className,
	margin,
}: {
	imageSrc: string
	margin?: string
	className?: string
	alt: string
	width?: string
	onClick?: any
	height?: string
	isClickable?: boolean
	customRotate?: number
}) {
	return (
		<StyledImg
			className={className}
			isClickable={isClickable}
			onClick={onClick}
			src={imageSrc}
			alt={alt}
			width={width && width}
			height={height && height}
			customRotate={customRotate}
			margin={margin}
		/>
	)
}

export default Image
