import { format } from 'date-fns'
import { isEmpty } from 'ramda'
import { AttributeType } from 'src/facade/forms/formsService.types'
import { AppAttribute } from 'src/forms/_shared/main-form/MainForm'

export function formatAttributeValue(attribute: AppAttribute): string | number | undefined | null {
	if (attribute.attribute_type === AttributeType.Date) {
		return attribute.value && format(attribute.value, 'yyyy/MM/dd H:mm:ss')
	}

	if (
		attribute.attribute_type === AttributeType.Signature ||
		attribute.attribute_type === AttributeType.Media ||
		attribute.attribute_type === AttributeType.Attachment ||
		attribute.attribute_type === AttributeType.ReadonlyImage ||
		attribute.attribute_type === AttributeType.MarkupImage
	) {
		return isEmpty(attribute.value) ? 0 : 1
	}

	if (attribute.attribute_type === AttributeType.Text) {
		if (typeof attribute.value === 'string') {
			return attribute.value
		}
		return attribute.value
	}

	if (attribute.attribute_type === AttributeType.User) {
		return attribute.value?.join(',')
	}

	return attribute.value
}
