/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import lodash from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import plus from '../../../assets/icons/plus.svg'
import save from '../../../assets/icons/save.svg'
import {
	addComponent,
	addExistingComponent,
	addNewComponent,
	deleteRowComponent,
	handleAutoFocus,
	handleComponentValue,
	handleEditComponent,
	handleOpenComponents,
	updateExistingComponent,
} from '../../../features/formsHandler/actions'
import { getComponentsList } from '../../../features/formsList/actions'
import { IForm } from '../../../models/formsList/formsList'
import { useAppDispatch, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Accordion from '../../Molecules/Accordion'
import LGButton from '../../Molecules/LGButton'
import SelectOverview from '../../Molecules/SelectOverview'
import { AccordionWrapper } from './Attributes'
import FormTable from './Form'
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { useParams } from 'react-router-dom'
import { ComponentDetails } from 'src/components/Organisms/Forms/ComponentDetails'
import { additionalDependsOn } from '../../../features/formsHandler/utils'
import { colors } from '../../../theme/colors'

const NewComponentHeader = styled.div`
	margin: 20px 0;
	display: flex;
	justify-content: flex-end;

	.right {
		display: flex;

		button {
			margin-left: 15px;
		}
	}
`

const AddNewComponent = styled.div`
	display: flex;

	button {
		margin-left: 20px;
	}
`

const Carousel = styled.div`
	margin: 20px 0;
	display: flex;
	overflow-y: auto;
	.component_element:nth-child(2n) {
		margin: 0 30px;
	}
`
const Line = styled.div`
	margin: 20px 0;
	border-bottom: 1px solid ${colors.greyBD};
`

const pageSize = 20

const focusStart = 'form_components_'

interface IComponents {
	isDisabled: boolean
	handleSavePopup: ({ componentId, component }: { componentId: string | number; component: string }) => void
}

// eslint-disable-next-line react/display-name
const Components = memo(({ isDisabled, handleSavePopup }: IComponents) => {
	const [componentOptionSelected, handleComponentOptionSelected] = useState<any>(null)

	const dispatch = useAppDispatch()
	const {
		sequences,
		focusName,
		FormComponents,
		FormAttributes,
		isOpenComponent,
		isCheckedComponent,
		IsFormsLoading,
		componentsList,
		isComponentsListLoading,
		isOpenComponents,
		isSavingExistingComponent,
	} = useAppSelectorDeprecated((state) => ({
		focusName: state.formsHandler.focusName,
		FormComponents: state.formsHandler.FormComponents,
		FormAttributes: state.formsHandler.FormAttributes,
		isOpenComponent: state.formsHandler.FormComponents.isOpen,
		isCheckedComponent: state.formsHandler.FormComponents.isChecked,
		IsFormsLoading: state.formsHandler.IsFormsLoading,
		sequences: state.formsHandler.sequences,
		isOpenComponents: state.formsHandler.FormComponents.isOpen,
		isSavingExistingComponent: state.formsHandler.isSavingExistingComponent,

		componentsList: state.formsList.componentsList,
		isComponentsListLoading: state.formsList.isComponentsListLoading,
	}))

	const { param1: formId } = useParams<{ param1: string }>()

	const getData = () => {
		dispatch(getComponentsList({ pageSize, page: 0, query: '' }))
	}

	const fetchData = useCallback(getData, [])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const createComponent = () => {
		if (componentOptionSelected.value === 'new') {
			dispatch(addNewComponent())
		} else {
			dispatch(
				addExistingComponent({
					id: componentOptionSelected.value,
					label: componentOptionSelected.label,
					class_name: componentOptionSelected.class_name,
				}),
			)
		}
		handleComponentOptionSelected(null)
	}

	const uniqComponents = lodash.uniqBy(FormComponents.attributes, 'originalId')

	const ComponentsElements = useMemo(
		() =>
			lodash.sortBy(uniqComponents, 'sequence').map((attribute, index) => {
				const handleEditRow = (element: any) => {
					dispatch(
						handleComponentValue({
							componentId: attribute.id,
							keyName: element.keyName,
							value: element.value,
							rowId: element.id,
							focusStart,
						}),
					)
				}

				return (
					<div key={`component_${attribute.is_new}_${attribute.id}`}>
						{!attribute.is_new && (
							<Carousel>
								{lodash
									.sortBy(FormComponents.attributes, 'sequence')
									.filter((el) => el.originalId === attribute.originalId)
									.map((att) => {
										return (
											<ComponentDetails
												key={att.id}
												attribute={att}
												isDisabled={isDisabled}
												focusName={focusName}
												index={index}
												sequences={sequences}
												dependsOnOptions={FormComponents.dependsOnOptions}
												mainForm={FormAttributes}
												uniqComponents={uniqComponents}
												components={FormComponents.attributes}
											/>
										)
									})}
							</Carousel>
						)}
						{!isDisabled && (
							<NewComponentHeader>
								{attribute.is_new && (
									<LGButton
										className="addComponent"
										text="Save New Component"
										icon={save}
										isWhiteText
										margin="0 10px 0 0"
										padding="8px 15px"
										disabled={
											isSavingExistingComponent ||
											attribute.sequences.length === 0 ||
											lodash.uniqBy(attribute.sequences, 'sequence').length !== attribute.sequences.length ||
											attribute.attributes.filter(
												(element) =>
													!element.sequence.value ||
													!element.attribute_name.value ||
													!element.display_name.value ||
													!element.attribute_type.value,
											).length > 0
										}
										onClick={() => handleSavePopup({ componentId: attribute.id, component: 'empty' })}
									/>
								)}
								{!attribute.is_new && (
									<>
										{!attribute.isEditing && (
											<LGButton
												margin="0 10px 0 0"
												className="addComponent"
												text="Edit"
												icon={save}
												isWhiteText
												padding="8px 15px"
												disabled={false}
												onClick={() => dispatch(handleEditComponent({ id: attribute.id, isEditing: true }))}
											/>
										)}
										{attribute.isEditing && (
											<LGButton
												margin="0 10px 0 0"
												className="addComponent"
												text="Save"
												icon={save}
												isWhiteText
												padding="8px 15px"
												disabled={
													isSavingExistingComponent ||
													attribute.sequences.length === 0 ||
													lodash.uniqBy(attribute.sequences, 'sequence').length !== attribute.sequences.length ||
													attribute.attributes.filter(
														(element) =>
															!element.sequence.value ||
															!element.attribute_name.value ||
															!element.display_name.value ||
															!element.attribute_type.value,
													).length > 0 ||
													lodash.isEqual(attribute.attributes, attribute.oldAttributes)
												}
												onClick={() => {
													dispatch(
														updateExistingComponent({
															formId,
															componentId: attribute.id,
															name: attribute.componentName,
														}),
													)
												}}
											/>
										)}
										{attribute.isEditing && (
											<LGButton
												margin="0 10px 0 0"
												className="addComponent"
												text="Save New Component"
												icon={save}
												isWhiteText
												padding="8px 15px"
												disabled={
													isSavingExistingComponent ||
													attribute.sequences.length === 0 ||
													lodash.uniqBy(attribute.sequences, 'sequence').length !== attribute.sequences.length ||
													attribute.attributes.filter(
														(element) =>
															!element.sequence.value ||
															!element.attribute_name.value ||
															!element.display_name.value ||
															!element.attribute_type.value,
													).length > 0
												}
												onClick={() => handleSavePopup({ componentId: attribute.id, component: 'new_component' })}
											/>
										)}
										{attribute.isEditing && (
											<LGButton
												margin="0 10px 0 0"
												className="addComponent"
												text="Cancel Edit"
												isWhiteText={false}
												padding="8px 15px"
												disabled={false}
												onClick={() => dispatch(handleEditComponent({ id: attribute.id, isEditing: false }))}
											/>
										)}
									</>
								)}
							</NewComponentHeader>
						)}
						<FormTable
							focusStart={focusStart}
							focusName={focusName}
							attributesSequence={attribute.sequences}
							showErrors={!isDisabled && (attribute.is_new ? true : attribute.isEditing)}
							onClickDelete={(el) => dispatch(deleteRowComponent({ id: el, componentId: attribute.id }))}
							isDeleteColumn={!isDisabled && (attribute.is_new ? true : attribute.isEditing)}
							isAddRow={!isDisabled && (attribute.is_new ? true : attribute.isEditing)}
							addNewRow={() => dispatch(addComponent({ componentId: attribute.id }))}
							idAddRowDisabled={isDisabled}
							isDisabled={isDisabled || (attribute.is_new ? false : !attribute.isEditing)}
							isLoading={IsFormsLoading}
							dataForForm={attribute.attributes}
							dependsOnOptions={[...attribute.dependsOnOptions, additionalDependsOn]}
							onEditRow={handleEditRow}
						/>
						{FormComponents.attributes.length > 1 &&
							lodash.uniqBy(FormComponents.attributes, 'originalId').length !== index + 1 && <Line />}
					</div>
				)
			}),
		[FormComponents, dispatch, sequences, isDisabled],
	)

	return (
		<Accordion
			isArrow
			isOpen={isOpenComponent}
			handleOpen={() => dispatch(handleOpenComponents(!isOpenComponents))}
			title="Components"
			isChecked={isCheckedComponent}
		>
			<AccordionWrapper>
				{ComponentsElements}
				{!isDisabled && (
					<AddNewComponent>
						<SelectOverview
							maxMenuHeight={100}
							minWidth="300px"
							height="40px"
							autoFocus={focusName === 'select_component_input'}
							menuPlacement="top"
							placeholder="Select Component"
							value={componentOptionSelected}
							onInputChange={(query) => dispatch(getComponentsList({ pageSize, page: 0, query }))}
							onChange={(value: any) => {
								dispatch(handleAutoFocus('select_component_input'))
								handleComponentOptionSelected(value)
							}}
							isLoading={isComponentsListLoading}
							options={[
								{
									value: 'new',
									label: '***New Component***',
								},
								...componentsList.forms
									.map((item: IForm) => ({
										value: item.id,
										label: item.name,
										class_name: item.class_name,
									}))
									.filter(
										(item: any) =>
											FormComponents.attributes.filter((element) => element.id === item.value).length === 0,
									),
							]}
						/>
						<LGButton
							className="addComponent"
							text="New Component"
							isWhiteText
							padding="8px 15px"
							icon={plus}
							disabled={componentOptionSelected === null}
							onClick={createComponent}
						/>
					</AddNewComponent>
				)}
			</AccordionWrapper>
		</Accordion>
	)
})

export default memo(Components)
