import { combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { tasksActionService } from '../../services/tasksActionsService'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { fetchTaskActions, fetchTaskActionsFail, fetchTaskActionsSuccess } from './taskActionsSlice'

export const fetchTaskActionsEpic: AppEpicDeprecated<ReturnType<typeof fetchTaskActions>> = (action$, state$) =>
	action$.pipe(
		filter(fetchTaskActions.match),
		withLatestFrom(state$),
		switchMap(([{ payload: tagCode }]) => {
			return fromPromise(tasksActionService.fetch(tagCode)).pipe(
				switchMap((actions) => {
					return of(fetchTaskActionsSuccess(actions))
				}),
				catchError(() => {
					return of(fetchTaskActionsFail())
				}),
			)
		}),
	)

export const taskActionsEpics = combineEpics(fetchTaskActionsEpic)
