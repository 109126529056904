import { axiosInstance } from 'src/services/axiosInstance'
import { GET_PRIMARY, REACT_ACTIONS, REACT_CREATE_ASSET, REACT_GET_OWNERS } from '../constants'

export const getPrimaryAssets = async (contract: string) => {
	const { data } = await axiosInstance.post(GET_PRIMARY, {
		contract,
	})
	return data
}

export const getOwners = async () => {
	const { data } = await axiosInstance.get(REACT_GET_OWNERS)
	return data
}

export const saveAsset = async (request: {}) => {
	const { data } = await axiosInstance.post(REACT_CREATE_ASSET, request)
	return data
}

export const createNewTidp = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_ACTIONS, body)
	return data
}
