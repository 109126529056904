/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import { FontWeightType } from '../../../typings/styledComponents'

enum FontWeight {
	light = 300,
	regular = 500,
	bold = 700,
}

const StyledSectionHeadline = styled.h1<{
	small?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
}>`
	font-weight: ${({ fontWeight }) => (fontWeight ? FontWeight[fontWeight] : 'bold')};
	font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '96px')};
	line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '112px')};
	${({ small }) =>
		small &&
		css`
			font-weight: 500;
			font-size: 48px;
			line-height: 56px;
		`}
`

function SectionHeadline({
	text,
	small,
	fontWeight,
	fontSize,
	lineHeight,
}: {
	text: string
	small?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
}) {
	return (
		<StyledSectionHeadline small={small} fontSize={fontSize} fontWeight={fontWeight} lineHeight={lineHeight}>
			{text}
		</StyledSectionHeadline>
	)
}
export default SectionHeadline
