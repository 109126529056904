/* eslint-disable react/require-default-props */
import styled, { css } from 'styled-components'
import information from '../../../assets/icons/information.svg'
import { colors } from '../../../theme/colors'
import Image from '../../Atoms/Image'
import SpinnerComponent from '../../Atoms/Loader'
import TextButton from '../../Atoms/TextButton'
import Tooltip from '../Tooltip'

const SpinnerArea = styled.div`
	margin-left: 5px;
`

interface IStyledLGButton {
	width?: number
	minWidth?: string
	height?: number
	isWhiteText?: boolean
	padding?: string
	margin?: string
	hidden?: boolean
	infoIconColor?: string
}

const StyledButton = styled.button<IStyledLGButton>`
	width: ${({ width }) => width && `${width}px`};
	height: ${({ height }) => height && `${height}px`};
	display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
	justify-content: center;
	align-items: center;
	border: ${({ theme }) => `1px solid ${theme.colors.main100}`};
	border-radius: 8px;
	background-color: ${colors.white};
	transition: all 250ms ease-in;
	cursor: pointer;
	outline: none;
	padding: ${({ padding }) => padding || 0};
	margin: ${({ margin }) => margin || 0};
	min-width: ${({ minWidth }) => minWidth && minWidth};
	.icon {
		height: ${({ height }) => height && `${height / 2.5}px`};
	}
	&:disabled {
		cursor: not-allowed;
		border: ${({ theme }) => `1px solid ${theme.colors.greyB0}`};
	}
	.tooltipIcon {
		height: ${({ height }) => height && `${height / 2.5}px`};
		width: ${({ height }) => height && `${height / 2.5}px`};
		margin-left: 7px;
		fill: ${({ infoIconColor }) => infoIconColor || colors.white};
	}
	${({ isWhiteText }) =>
		isWhiteText &&
		css`
			background-color: ${({ theme }) => theme.colors.main100};
			&:disabled {
				background-color: ${({ theme }) => theme.colors.greyB0};
			}
		`}
`

function LGButton({
	text,
	width,
	minWidth,
	height,
	onClick,
	isWhiteText,
	disabled,
	padding,
	margin,
	type,
	hidden,
	isLoading,
	icon,
	tooltip,
	className,
}: {
	text: string
	width?: number
	minWidth?: string
	height?: number
	onClick?: any
	className?: any
	isWhiteText?: boolean
	disabled?: boolean
	padding?: string
	margin?: string
	type?: any
	hidden?: boolean
	isLoading?: boolean
	icon?: any
	tooltip?: string
}) {
	return (
		<StyledButton
			type={type}
			padding={padding}
			width={width}
			minWidth={minWidth}
			height={height}
			onClick={onClick}
			isWhiteText={isWhiteText}
			disabled={disabled}
			hidden={hidden}
			margin={margin}
			className={className}
			infoIconColor={!disabled ? colors.white : colors.main100}
		>
			{icon && <img className="icon" src={icon} alt="icon" />}
			<TextButton text={text} lineHeight={19} fontSize={16} mainColor={!disabled} isWhiteText={isWhiteText} />
			{isLoading && (
				<SpinnerArea>
					<SpinnerComponent color={colors.white} size={16} />
				</SpinnerArea>
			)}
			{tooltip && (
				<Tooltip maxTextWidth="200px" hideArrow placement="right" trigger="hover" tooltip={<>{tooltip}</>}>
					<Image width="20px" height="20px" className="eyeImage" imageSrc={information} alt="eye" />
				</Tooltip>
			)}
		</StyledButton>
	)
}
export default LGButton
