/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'

import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'src/components/Molecules/Layout'
import { getTidpOverview } from 'src/features/tidpOverview/actions'
import { saveTidpDocumentCommentThunk } from 'src/features/tidpOverview/saveTidpDocumentCommentThunk'
import { ITidpOverview } from 'src/models/tidpOverview/tidpOverview'
import styled from 'styled-components'
import { dateFormat } from '../../../assets/dateFormats'
import { attributesActions } from '../../../features/attributes'
import { changeCommentsStatus } from '../../../features/comments/commentsSlice'
import { controlsActions } from '../../../features/controls'
import { disciplineOverviewActions } from '../../../features/disciplineOverview'
import { tasksActionsElementActions } from '../../../features/taskActions'
import { taskActionResolverSelector } from '../../../features/taskActions/taskActionsSlice'
import { usersActions } from '../../../features/users'
import { IReduxState } from '../../../models/redux/redux'
import { DeliverableAction } from '../../../services/deliverablesService'
import { colors } from '../../../theme/colors'
import { useAppDispatch, useAppSelector, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Cell from '../../Atoms/Cell'
import SpinnerComponent from '../../Atoms/Loader'
import { CommentsDrawer, DrawerWrapper } from '../../Molecules/Comments/CommentsDrawer'
import FetchPopup from '../../Molecules/FetchPopup'
import HistoryComponent from '../../Molecules/HistoryComponent'
import LGButton from '../../Molecules/LGButton'
import Overview from '../../Molecules/Overview'
import PageTitle from '../../Molecules/PageTitle'
import RejectPopup from '../../Molecules/RejectPopup'
import SelectOverview from '../../Molecules/SelectOverview'
import {
	OverviewContent,
	OverviewContentWrapper,
	OverviewWrapper,
	SpinnerOverview,
	StyledTd,
} from '../TIDPOverview/tidpOverviewCustomStyles'
import { clickOnAction, namesByActions, tooltipTextByActions } from './actionsHandler'
import { DeliverablesGrid } from './DeliverablesGrid'
import { BottomButtonSection, PageHeader, SelectContainer } from './disciplineOverviewCustomStyles'
import { IDisciplineOverviewComponent } from './disciplineOverviewInterface'

const existingDeliverablePopup = {
	isOpen: false,
	task_id: -1,
	action_name: '',
	tagCode: '',
}

const DisciplineOverviewWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const DeliverablesGridWrapper = styled.div`
	padding-right: 60px;
`

const DisciplineOverview = (props: IDisciplineOverviewComponent) => {
	const [fetchExistingDeliverablesPopup, handleFetchExistingDeliverablesPopup] = useState(existingDeliverablePopup)
	const [popupData, handlePopupData] = useState<{
		isPopupOpen: boolean
		task_id: number
		action_name: string
		actionField: any
	}>({
		isPopupOpen: false,
		task_id: -1,
		action_name: '',
		actionField: null,
	})

	const dispatch = useAppDispatch()
	const [isRejecting, handleIsRejecting] = useState(false)
	const [contractElement, handleContract] = useState('')
	const [isNextRequest, handleIsNextRequest] = useState(false)
	const [match, handleMatch] = useState<any>('')
	const username = useAppSelector((state) => state.profile.user!.name)

	const taskActionResolver = useAppSelector(taskActionResolverSelector)
	const disciplineOwners = props.rowCells.map((discipline) => discipline.nominatedOwners)

	const tidpTagCode = useAppSelectorDeprecated((state) => state.disciplineOverview.tidpTagCode)
	const leadReviewers = props.externalStakeholersValue.map((reviewer) => reviewer.value)
	const reviewers = props.internalStakeholersValue.map((reviewer) => reviewer.value).concat(leadReviewers)

	useEffect(() => {
		if (props.documentAttributes.length === 0) {
			props.getAttributesForDocument()
		}
	}, [props])

	let commentTypes = props.rowCells.map((discipline: any) => {
		return {
			type: discipline.label,
			tagCode: discipline.tagCode,
		}
	})
	commentTypes.unshift({ type: 'General', tagCode: tidpTagCode })

	const getOverview = () => {
		if (isNextRequest && props.match !== match && props.documentAttributes.length > 0) {
			handleMatch(props.match)
			props.getDisciplineOverview(props.match.params.param1)
		}
		if (props.contract && contractElement !== props.contract && props.documentAttributes.length > 0) {
			props.getDisciplineOverview(props.match.params.param1)
			props.handleCreatingNewDeliverables(false)
			handleContract(props.contract)
			props.setNominateOpen(false)
			handleIsRejecting(false)
			handleIsNextRequest(true)
			handlePopupData({ isPopupOpen: false, task_id: -1, action_name: '', actionField: null })
		}
	}
	const fetchOverview = useCallback(getOverview, [props.match, props.contract, props.documentAttributes])
	useEffect(() => {
		fetchOverview()
		if (tidpTagCode) {
			dispatch(getTidpOverview(tidpTagCode))
		}
	}, [fetchOverview, tidpTagCode])

	const getCells = (tabs: Array<{ value: string; name: string }>) => {
		return tabs.map((element) => (
			<tr key={`${element.value}_${element.name}`}>
				<StyledTd large>
					<Cell text={element.name.replace('DIDP ', '')} white />
				</StyledTd>
				<StyledTd large>
					<Cell
						text={
							element.name === 'DIDP Created Date'
								? moment(new Date(element.value)).format(dateFormat.engHours)
								: element.value
						}
					/>
				</StyledTd>
			</tr>
		))
	}

	const changeNavigationTab = (id: number) => {
		props.handleTab(id)
	}

	const disciplineOwner = props.disciplineOwner.filter((item) => item.isOpen)[0]

	const onInputChange = (text: string) => {
		props.getListOfUsersByRole({ role: '', text })
	}

	const isOverview = props.disciplineOwner.filter((item) => item.name === 'Overview' && item.isOpen).length > 0

	const confirmReject = (comment: string) => {
		handleIsRejecting(true)
		popupData.actionField(comment)
		handlePopupData({ isPopupOpen: false, task_id: -1, action_name: '', actionField: null })
	}

	const onCancel = () => {
		handleIsRejecting(false)
		handlePopupData({ isPopupOpen: false, task_id: -1, action_name: '', actionField: null })
	}

	const isSendForAcceptanceDisable = (actionName: string) => {
		if (actionName === 'action_send_didp_for_acceptance') {
			return props.deliverables.body.filter((item: any) => !item.row1 || item.row10 !== 'Done').length > 0
		}
	}

	const cancelFetchingDeliverable = () => {
		handleFetchExistingDeliverablesPopup(existingDeliverablePopup)
	}

	const fetchDeliverable = () => {
		props.handleFetchDeliverableNumbers({
			tagCode: props.match.params.param1,
			action_name: fetchExistingDeliverablesPopup.action_name,
			task_id: fetchExistingDeliverablesPopup.task_id,
		})
		handleFetchExistingDeliverablesPopup(existingDeliverablePopup)
	}

	return (
		<Layout goBackUrl={`/overview/${props.tidp?.tag_code}`}>
			{props.isLoading || props.didpStatus === '' ? (
				<SpinnerOverview>
					<SpinnerComponent size={100} />
				</SpinnerOverview>
			) : (
				<OverviewWrapper>
					<FetchPopup
						isPopupOpen={fetchExistingDeliverablesPopup.isOpen}
						onConfirm={fetchDeliverable}
						onReject={cancelFetchingDeliverable}
					/>
					<RejectPopup
						isPopupOpen={popupData.isPopupOpen}
						isLoading={isRejecting}
						onConfirm={confirmReject}
						onReject={onCancel}
					/>
					<PageHeader>
						<div>
							<PageTitle title="Discipline Overview" subTitle="" />
						</div>
						<div>
							<HistoryComponent
								handleIsOpenHistory={props.handleHistorySidebar}
								isOpenHistory={!props.isHistorySidebarOpen}
								historyElements={props.disciplineHistory}
							/>
						</div>
					</PageHeader>

					<div className="overviewNav">
						{props.disciplineOwner.map((element) => (
							<div
								onClick={() => changeNavigationTab(element.id)}
								key={element.name}
								style={{ borderTop: element.isOpen ? `2px solid ${colors.main100}` : '' }}
							>
								{element.name}
							</div>
						))}
					</div>
					<OverviewContentWrapper>
						<DisciplineOverviewWrapper>
							<OverviewContent>
								<Overview title="Disciplines Details">
									<table>
										<tbody>{getCells(disciplineOwner.tabs)}</tbody>
									</table>
									<BottomButtonSection>
										{taskActionResolver
											.getAll()
											.filter(
												({ actionName }) =>
													actionName !== DeliverableAction.CREATE_EXISTING &&
													actionName !== DeliverableAction.CREATE &&
													actionName !== DeliverableAction.REMOVE &&
													actionName !== DeliverableAction.UPDATE,
											)
											.map((task) => (
												<LGButton
													padding="0 20px"
													key={`${task.actionName}_${task.taskId}`}
													onClick={() => {
														clickOnAction(
															{ task_id: task.taskId, action_name: task.actionName },
															props,
															handlePopupData,
															{
																onInputChange,
																handleFetchExistingDeliverablesPopup,
															},
														)
													}}
													disabled={
														props.isSavingNewDeliverable ||
														props.isLoadingSelectedDeliverable ||
														props.isActionProcessing ||
														isSendForAcceptanceDisable(task.actionName)
													}
													tooltip={tooltipTextByActions(task.actionName)}
													text={namesByActions(task.actionName)}
													height={36}
													isWhiteText
												/>
											))}
									</BottomButtonSection>

									{props.isNominatedOpen && (
										<SelectContainer>
											<SelectOverview
												isDisabled={props.isNominating}
												closeMenuOnSelect={false}
												isMulti
												onChange={props.setNominateSelected}
												value={props.nominateSelected}
												options={props.users.filter(
													(item: { value: string }) =>
														item.value !== username &&
														props.nominatedDidpOwner.filter((element) => element.toLowerCase() === item.value)
															.length === 0,
												)}
												isSearchable
												onInputChange={onInputChange}
											/>
										</SelectContainer>
									)}
								</Overview>
							</OverviewContent>

							{isOverview && (
								<DeliverablesGridWrapper>
									<DeliverablesGrid />
								</DeliverablesGridWrapper>
							)}
						</DisciplineOverviewWrapper>
						{props.tidp && (
							<DrawerWrapper>
								<CommentsDrawer
									onStatusChange={(value: { tagCode: any; value: any }) =>
										props.changeCommentStatus({
											tagCode: value.tagCode,
											status: value.value,
											contract: props.contract.contract,
											action: () =>
												disciplineOverviewActions.updateDisciplineComment({
													value: value.tagCode,
													status: value.value,
												}),
										})
									}
									reviewers={reviewers}
									owners={props.tidp.attribute['TIDP Owner'].concat(...disciplineOwners)}
									comments={props.tidpComments}
									tagCode={props.tidp.tag_code}
									saveNewComment={async (form) =>
										await dispatch(
											saveTidpDocumentCommentThunk({
												...form,
												selections: {
													didp_tag_code: props.match.params.param1,
													...(form.deliverableTagCode ? { deliverable_tag_code: form.deliverableTagCode } : {}),
												},
											}),
										).unwrap()
									}
									saveReply={props.saveReply}
									commentTypes={commentTypes}
								/>
							</DrawerWrapper>
						)}
					</OverviewContentWrapper>
				</OverviewWrapper>
			)}
		</Layout>
	)
}

export default connect(
	(state: IReduxState) => ({
		disciplineOwner: state.disciplineOverview.disciplineOwner,
		documentAttributes: state.attributes.documentAttributes,
		rowCells: state.tidpOverview.rowCells,
		deliverables: state.disciplineOverview.deliverables,
		isCreatingNewDeliverable: state.disciplineOverview.isCreatingNewDeliverable,
		tidpComments: state.tidpOverview.tidpComments,
		comments: state.disciplineOverview.comments,
		isSavingNewDeliverable: state.disciplineOverview.isSavingNewDeliverable,
		nominateSelected: state.disciplineOverview.nominateSelected,
		isNominatedOpen: state.disciplineOverview.isNominatedOpen,
		isNominating: state.disciplineOverview.isNominating,
		isRenamingDeliverables: state.disciplineOverview.isRenamingDeliverables,
		users: state.users.users,
		isLoading: state.controls.isLoading,
		roles: state.authorization.roles,
		nominatedDidpOwner: state.disciplineOverview.nominatedDidpOwner,
		didpStatus: state.disciplineOverview.didpStatus,
		disciplineHistory: state.disciplineOverview.disciplineHistory,
		isInforming: state.disciplineOverview.isInforming,
		isLinking: state.disciplineOverview.isLinking,
		isActionProcessing: state.disciplineOverview.isActionProcessing,
		isOpenDeliverablePopup: state.disciplineOverview.isOpenDeliverablePopup,
		isLoadingDeliverable: state.disciplineOverview.isLoadingDeliverable,
		isChangingDeliverable: state.disciplineOverview.isChangingDeliverable,
		oneDeliverableTasks: state.disciplineOverview.oneDeliverableTasks,
		isLoadingDeliverables: state.disciplineOverview.isLoadingDeliverables,
		contract: state.users.contract,
		isHistorySidebarOpen: state.controls.isHistorySidebarOpen,
		// tasksActions: state.tasksActions.tasksActions,
		// isLoadingTasksActions: state.tasksActions.isLoadingTasksActions,
		isLoadingSelectedDeliverable: state.disciplineOverview.isLoadingSelectedDeliverable,
		tidp: state.tidpOverview.tidp,
		externalStakeholersValue: state.tidpOverview.externalStakeholersValue,
		internalStakeholersValue: state.tidpOverview.internalStakeholersValue,
	}),
	{
		getAttributesForDocument: attributesActions.getAttributesForDocument,
		getDisciplineOverview: disciplineOverviewActions.getDisciplineOverview,
		handleTab: disciplineOverviewActions.handleTab,
		renameDeliverables: disciplineOverviewActions.renameDeliverables,
		handleCreatingNewDeliverables: disciplineOverviewActions.handleCreatingNewDeliverables,
		assignValueToDeliverablesTabs: disciplineOverviewActions.assignValueToDeliverablesTabs,
		saveCreatingNewDeliverables: disciplineOverviewActions.saveCreatingNewDeliverables,
		handleNewCommentText: disciplineOverviewActions.handleNewCommentText,
		startReply: disciplineOverviewActions.startReply,
		saveReply: disciplineOverviewActions.saveReply,
		handleReplyComment: disciplineOverviewActions.handleReplyComment,
		handleStartNewComment: disciplineOverviewActions.handleStartNewComment,
		createNewDeliverable: disciplineOverviewActions.createNewDeliverable,
		setNominateSelected: disciplineOverviewActions.setNominateSelected,
		nominateAdditionalDidpOwner: disciplineOverviewActions.nominateAdditionalDidpOwner,
		setNominateOpen: disciplineOverviewActions.setNominateOpen,
		informTIDPOwner: disciplineOverviewActions.informTIDPOwner,
		getListOfUsersByRole: usersActions.getListOfUsersByRole,
		handleAcceptOwnership: disciplineOverviewActions.handleAcceptOwnership,
		handleDIDPAcceptance: disciplineOverviewActions.handleDIDPAcceptance,
		handleDIDPReopening: disciplineOverviewActions.handleDIDPReopening,
		handleDIDPRemoving: disciplineOverviewActions.handleDIDPRemoving,
		handleSelectedDeliverables: disciplineOverviewActions.handleSelectedDeliverables,
		getDisciplineHistory: disciplineOverviewActions.getDisciplineHistory,
		handleFetchDeliverableNumbers: disciplineOverviewActions.handleFetchDeliverableNumbers,
		changeCommentStatus: changeCommentsStatus,
		handleHistorySidebar: controlsActions.handleHistorySidebar,
		unlinkDeliverable: disciplineOverviewActions.unlinkDeliverable,
		handleDeliverablePopup: disciplineOverviewActions.handleDeliverablePopup,
		getOneDeliverable: disciplineOverviewActions.getOneDeliverable,
		setOneDeliverable: disciplineOverviewActions.setOneDeliverable,
		getTaskActions: tasksActionsElementActions.getTaskActions,
	},
)(DisciplineOverview)
