/* eslint-disable react/no-children-prop */
import React from 'react'
import LGButton from '../LGButton'
import Popup from '../../Organisms/Popup'
import styled from 'styled-components'

interface IFetchPopup {
	isPopupOpen: any
	onConfirm: () => void
	onReject: () => void
}

const FetchPopupContainer = styled.div`
	max-width: 450px;
	.header {
		font-weight: 700;
		font-size: 14px;
		padding: 12px;
	}
	.buttonWrapper {
		display: flex;
		width: 100%;
		justify-content: space-around;
	}
`

function FetchPopup(props: IFetchPopup) {
	return (
		props.isPopupOpen && (
			<Popup
				onClick={props.onReject}
				isCloseButton
				children={
					<FetchPopupContainer>
						<div className="header">
							By fetching deliverable numbers, the document metadata listed below will be locked and synced with
							Assetwise. Please double check your document title.
						</div>
						<div className="buttonWrapper">
							<LGButton onClick={props.onConfirm} text="Proceed" isWhiteText padding="8px 12px" />
							<LGButton onClick={props.onReject} text="Cancel" isWhiteText={false} padding="8px 12px" />
						</div>
					</FetchPopupContainer>
				}
			/>
		)
	)
}

export default FetchPopup
