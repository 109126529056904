/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */
import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'src/components/Molecules/Layout'
import styled from 'styled-components'
import cancel from '../../../assets/icons/errorCancel.svg'
import { flowForManagerActions } from '../../../features/flowForManager'
import { usersActions } from '../../../features/users'
import { IPrimaryAssets, ITabsFroManagement, IWorkPackage } from '../../../models/florForManager/flowForManager'
import { IReduxState } from '../../../models/redux/redux'
import browserHistory from '../../../services/history'
import SpinnerComponent from '../../Atoms/Loader'
import Accordion from '../../Molecules/Accordion'
import Button from '../../Molecules/Button'
import LGButton from '../../Molecules/LGButton'
import PageTitle from '../../Molecules/PageTitle'
import SelectList from '../../Molecules/SelectList'
import { SpinnerOverview } from '../TIDPOverview/tidpOverviewCustomStyles'

const MainWrapper = styled.div``

const SelectWrapper = styled.div`
	margin-bottom: 25px;
`

const PageTitleWrapper = styled.div`
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
`

interface ICreateNewTidp {
	primaryAssets: IPrimaryAssets[]
	workPackage: IWorkPackage[]
	primaryAssetsSelected: any
	workPackageSelected: any
	contractData: any
	selectedOwner: any
	users: any
	technicalLifeCycleElements: any
	owners: any
	technicalLifeCycle: any
	getPrimaryAssets: typeof flowForManagerActions.getPrimaryAssets
	setPrimaryAssetsSelected: typeof flowForManagerActions.setPrimaryAssetsSelected
	setWorkPackageSelected: typeof flowForManagerActions.setWorkPackageSelected
	seTidrSubmissionDate: typeof flowForManagerActions.seTidrSubmissionDate
	setStageCommencementDate: typeof flowForManagerActions.setStageCommencementDate
	setFinalSubmissionDate: typeof flowForManagerActions.setFinalSubmissionDate
	setL3CertificationDate: typeof flowForManagerActions.setL3CertificationDate
	getWorkPackage: typeof flowForManagerActions.getWorkPackage
	getContractData: typeof flowForManagerActions.getContractData
	setTechnicalLifecycleStage: typeof flowForManagerActions.setTechnicalLifecycleStage
	getOwners: typeof flowForManagerActions.getOwners
	setOwnerSelected: typeof flowForManagerActions.setOwnerSelected
	confirmManagerForm: typeof flowForManagerActions.confirmManagerForm
	l3CertificationDate: any
	finalSubmissionDate: any
	tabsForManagement: ITabsFroManagement[]
	idrSubmissionDate: any
	stageCommencementDate: any
	clearForm: typeof flowForManagerActions.clearForm
	manageTab: typeof flowForManagerActions.manageTab
	getListOfUsersByRole: typeof usersActions.getListOfUsersByRole
	changeTextOfSearch: typeof usersActions.changeTextOfSearch
	userText: string
	isLoading: boolean
	technicalLifecycleAttributes: any[]
}

function CreateNewTidp(props: ICreateNewTidp) {
	const [calendarsData, handleCalendarsData] = useState<any>([])
	const getData = () => {
		props.clearForm()
		props.getPrimaryAssets()
		props.changeTextOfSearch('')
		onUsersChange('')
	}

	const fetchData = useCallback(getData, [])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const select1Examples = [
		{
			id: 131,
			label: 'Primary Asset',
			options: props.primaryAssets,
			value: props.primaryAssetsSelected,
			handleValue: props.setPrimaryAssetsSelected,
		},
		{
			id: 134,
			label: 'Technical lifecycle Stage',
			options:
				props.technicalLifecycleAttributes?.filter(
					(item: { attribute_name: string }) => item.attribute_name === 'Technical Lifecycle Stage',
				).length > 0
					? props.technicalLifecycleAttributes
							?.filter((item: { attribute_name: string }) => item.attribute_name === 'Technical Lifecycle Stage')[0]
							.predetermined_list.map((item: string) => ({ value: item, label: item }))
							.concat({ value: 'Other', label: 'Other' })
					: [{ value: 'Other', label: 'Other' }],
			value: props.technicalLifeCycle,
			handleValue: props.setTechnicalLifecycleStage,
		},
		{
			id: 133,
			label: 'Work Package',
			options: props.workPackage,
			value: props.workPackageSelected,
			handleValue: props.setWorkPackageSelected,
			info: 'Work packages from Assetwise, related to your primary asset and technical lifecycle stage.',
		},
	]

	const select4Examples = [
		{
			id: 301,
			label: 'TIDP Owner',
			value: props.selectedOwner,
			options: props.owners,
			handleValue: props.setOwnerSelected,
			info: 'Begin typing or select the user from the dropdown list.',
		},
	]

	const listOfSelects1 = select1Examples.map((item) => (
		<SelectWrapper key={item.id}>
			<SelectList
				tooltipInfo={item.info}
				value={Object.keys(item.value).length > 0 ? item.value : []}
				onChange={item.handleValue}
				options={item.options}
				label={item.label}
			/>
		</SelectWrapper>
	))

	const onUsersChange = (text: string) => {
		props.changeTextOfSearch(text)
		props.getListOfUsersByRole({ role: '', text })
	}

	const listOfSelects4 = select4Examples.map((item) => (
		<SelectWrapper key={item.id}>
			<SelectList
				tooltipInfo={item.info}
				inputValue={props.userText}
				isMulti
				onInputChange={onUsersChange}
				value={Object.keys(item.value).length > 0 ? item.value : []}
				options={props.users}
				label={item.label}
				onChange={item.handleValue}
			/>
		</SelectWrapper>
	))

	const resetSteps = () => {
		props.clearForm()
		browserHistory.push('/tasks-list')
	}

	const saveData = () => {
		props.confirmManagerForm(calendarsData)
	}

	const calendarsItems = props.technicalLifecycleAttributes.filter(
		(item: { attribute_type: string; contexts: string | string[] }) =>
			item.attribute_type === 'Date' && Array.isArray(item.contexts)
				? !lodash.includes(item.contexts, props.technicalLifeCycle.value)
				: item.contexts === props.technicalLifeCycle.value,
	)

	const confirmStep = (stepId: number) => {
		if (stepId === 2) {
			props.getContractData()
		}
		if (stepId === 3) {
			const calendars: any[] = []
			calendarsItems.forEach((item: { attribute_name: string }) => {
				calendars.push({
					name: item.attribute_name,
					value: props.workPackageSelected[item.attribute_name]
						? new Date(props.workPackageSelected[item.attribute_name])
						: null,
				})
			})
			handleCalendarsData(calendars)
		}
		props.manageTab(
			props.tabsForManagement.map((el) => ({
				id: el.id,
				isVisible: el.id === stepId ? true : el.isVisible,
				isOpen: el.id === stepId,
				isChecked: el.id === stepId,
			})),
		)
	}
	const goToPrevStep = (stepId: number) => {
		props.manageTab(
			props.tabsForManagement.map((el) => ({
				id: el.id,
				isVisible: el.id === stepId ? true : el.isVisible,
				isOpen: el.id === stepId,
				isChecked: el.id === stepId,
			})),
		)
	}

	const isNextButtonDisabled = (stepId: number) => {
		switch (stepId) {
			case 1:
				return !(
					Object.keys(props.primaryAssetsSelected).length > 0 &&
					Object.keys(props.workPackageSelected).length > 0 &&
					Object.keys(props.technicalLifeCycle).length > 0
				)
			case 4:
				return !(Object.keys(props.selectedOwner).length > 0)
		}
	}

	calendarsData.sort((a: any, b: any) => {
		return b.name.replace('T-', '') - a.name.replace('T-', '')
	})

	const LabelWrapper = styled.div`
		margin: 15px 0;
	`
	const ButtonsArea = styled.div`
		display: flex;
		button:first-child {
			margin-right: 20px;
		}
	`

	return (
		<Layout>
			{props.isLoading ? (
				<SpinnerOverview>
					<SpinnerComponent size={100} />
				</SpinnerOverview>
			) : (
				<MainWrapper>
					<PageTitleWrapper>
						<div>
							<PageTitle
								margin="5px 0 30px 0"
								title="Create New TIDP"
								subTitle="Get started by selecting key data relating to your TIDP"
							/>
						</div>
						<div>
							<Button imageSrc={cancel} text="Cancel" isError onClick={resetSteps} />
						</div>
					</PageTitleWrapper>

					{props.tabsForManagement[0].isVisible && (
						<Accordion
							isArrow={false}
							isOpen={props.tabsForManagement[0].isOpen}
							title="Asset and Workpackage"
							isChecked={props.tabsForManagement[0].isVisible && !props.tabsForManagement[0].isOpen}
						>
							{listOfSelects1}
							<LGButton
								onClick={() => confirmStep(2)}
								text="Next"
								height={48}
								width={195}
								disabled={isNextButtonDisabled(1)}
							/>
						</Accordion>
					)}

					{props.tabsForManagement[1].isVisible && (
						<Accordion
							isArrow={false}
							isOpen={props.tabsForManagement[1].isOpen}
							title="TIDP Owner"
							isChecked={props.tabsForManagement[1].isVisible && !props.tabsForManagement[1].isOpen}
						>
							{listOfSelects4}
							<ButtonsArea>
								<LGButton onClick={() => goToPrevStep(1)} text="Previous step" height={48} width={195} />
								<LGButton
									onClick={saveData}
									text="Create TIDP"
									height={48}
									width={195}
									isWhiteText
									disabled={isNextButtonDisabled(2)}
								/>
							</ButtonsArea>
						</Accordion>
					)}
				</MainWrapper>
			)}
		</Layout>
	)
}

export default connect(
	(state: IReduxState) => ({
		primaryAssets: state.flowForManager.primaryAssets,
		workPackage: state.flowForManager.workPackage,
		primaryAssetsSelected: state.flowForManager.primaryAssetsSelected,
		workPackageSelected: state.flowForManager.workPackageSelected,
		contractData: state.flowForManager.contractData,
		technicalLifeCycle: state.flowForManager.technicalLifeCycle,
		owners: state.flowForManager.owners,
		selectedOwner: state.flowForManager.selectedOwner,
		l3CertificationDate: state.flowForManager.l3CertificationDate,
		finalSubmissionDate: state.flowForManager.finalSubmissionDate,
		idrSubmissionDate: state.flowForManager.idrSubmissionDate,
		stageCommencementDate: state.flowForManager.stageCommencementDate,
		tabsForManagement: state.flowForManager.tabsForManagement,
		technicalLifeCycleElements: state.flowForManager.technicalLifeCycleElements,
		users: state.users.users,
		userText: state.users.userText,
		isLoading: state.controls.isLoading,
		technicalLifecycleAttributes: state.attributes.technicalLifecycleAttributes,
	}),
	{
		getPrimaryAssets: flowForManagerActions.getPrimaryAssets,
		setPrimaryAssetsSelected: flowForManagerActions.setPrimaryAssetsSelected,
		setWorkPackageSelected: flowForManagerActions.setWorkPackageSelected,
		getWorkPackage: flowForManagerActions.getWorkPackage,
		getContractData: flowForManagerActions.getContractData,
		setTechnicalLifecycleStage: flowForManagerActions.setTechnicalLifecycleStage,
		getOwners: flowForManagerActions.getOwners,
		setOwnerSelected: flowForManagerActions.setOwnerSelected,
		confirmManagerForm: flowForManagerActions.confirmManagerForm,
		seTidrSubmissionDate: flowForManagerActions.seTidrSubmissionDate,
		setStageCommencementDate: flowForManagerActions.setStageCommencementDate,
		setFinalSubmissionDate: flowForManagerActions.setFinalSubmissionDate,
		setL3CertificationDate: flowForManagerActions.setL3CertificationDate,
		manageTab: flowForManagerActions.manageTab,
		clearForm: flowForManagerActions.clearForm,
		getListOfUsersByRole: usersActions.getListOfUsersByRole,
		changeTextOfSearch: usersActions.changeTextOfSearch,
	},
)(CreateNewTidp)
