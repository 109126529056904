import { format, sub } from 'date-fns'
import { AppTask } from 'src/features/tasks/tasksApi'
import { axiosInstance } from '../../services/axiosInstance'

export const tasksService = {
	async getActiveTasks(): Promise<AppTask[]> {
		const { data } = await axiosInstance.get('/workflow/tasks/?workflow_type=Create_Form')

		return [...data.current, ...data.previous]
	},

	async getAllTasks(): Promise<AppTask[]> {
		let pageNo = 1
		let tasks: AppTask[] = []
		let tasksResponse: any

		do {
			tasksResponse = (
				await axiosInstance.get(
					`/workflow/tasks/search/?contract=${localStorage.getItem('contract')}&page_size=200&from=${format(
						sub(new Date(), { days: 90 }),
						'yyyy-MM-dd',
					)}&page=${pageNo++}`,
				)
			).data
			tasksResponse.results.forEach((task: any) => {
				task.status = String(task.status)
			});

			tasks = tasks.concat(tasksResponse.results)
		} while (tasksResponse?.next !== null)
		return tasks
	},

	action(body: any) {
		return axiosInstance.post('/workflow/actions/', body)
	},
}
