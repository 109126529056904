/* eslint-disable @typescript-eslint/no-unused-expressions */
import moment from 'moment'
import { toast } from 'react-toastify'
import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { getTidpOverview } from 'src/features/tidpOverview/actions'
import * as disciplineOverviewService from 'src/services/disciplineOverview'
import { getAssetByTagCode, GetHistory, PostQuery, saveAsset } from 'src/services/disciplineOverview'
import { saveNewAssetComment } from 'src/services/tidpOverview'
import { isOfType } from 'typesafe-actions'
import { dateFormat } from '../../assets/dateFormats'
import browserHistory from '../../services/history'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import { controlsActions } from '../controls'
import { fetchDeliverables } from '../deliverables/deliverablesSlice'
import { tasksActionsElementActions } from '../taskActions'
import { fetchTaskActions } from '../taskActions/taskActionsSlice'
import { usersActions } from '../users'
import {
	getDisciplineHistory,
	getDisciplineOverview,
	getOneDeliverable,
	handleAcceptOwnership,
	handleDIDPAcceptance,
	handleDIDPReopening,
	handleDIDPRemoving,
	handleFetchDeliverableNumbers,
	informTIDPOwner,
	nominateAdditionalDidpOwner,
	saveReply,
} from './actions'
import { disciplineOverviewActions, disciplineOverviewConstans } from './index'

export const getDisciplineOverviewEpic: AppEpicDeprecated<ReturnType<typeof getDisciplineOverview>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.GET_DISCIPLINE_OVERVIEW)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(controlsActions.handleIsLoading(true)),
				fromPromise(getAssetByTagCode(action.payload, state.users.contract.contract, 'TIDP')).pipe(
					switchMap((response) => {
						const didpTagCode = response.result[0].tag_code
						const attributes = response.result[0].attribute
						const tidpDetails = response.result[0].relatedWith
							.filter((relation: any) => relation.class_name === 'TIDP')
							.map((el: any) => {
								const targetTime = new Date(el['TIDP Created Date'])
								const tzDifference = targetTime.getTimezoneOffset()
								const offsetTime = new Date(targetTime.getTime() - tzDifference * 60 * 1000)
								const tidpOwner = el['TIDP Owner']
								const primaryAssetName = el.relatedWith.filter((item: any) => !!item['Asset Name'])
								const tidp = {
									'TIDP Title': el['TIDP Title'],
									'TIDP Created By': el['TIDP Created By'],
									'TIDP Created Date': moment(new Date(offsetTime)).format(dateFormat.engHours),
									'TIDP Number': el['TIDP Number'],
									'TIDP Owner': tidpOwner ? tidpOwner.map((item: string) => item.substring(0, item.indexOf('@'))) : [],
									'Asset Name': primaryAssetName.length > 0 ? primaryAssetName[0]['Asset Name'] : '',
									'TIDP Stage': el['TIDP Stage'],
									'TIDP Status': el['TIDP Status'],
									'External Stakeholders': el['External Stakeholders'],
									'Internal Stakeholders': el['Internal Stakeholders'],
								}
								return tidp
							})[0]
						const tidpTagCode = response.result[0].relatedWith.filter(
							(relation: any) => relation.class_name === 'TIDP',
						)[0].tag_code
						const comments =
							response.result[0] && response.result[0].relatedTo && response.result[0].relatedTo.length > 0
								? response.result[0].relatedTo
										.filter((relation: any) => relation.class_name === 'Comment')
										.sort((one: any, two: any) =>
											new Date(one['Recorded At']) < new Date(two['Recorded At']) ? 1 : -1,
										)
								: []
						const replies = response.result[0].relatedTo
							? response.result[0].relatedTo.filter((relation: any) => relation.class_name === 'Comment')
							: []

						const commentsData = comments.map((comment: any) => {
							return {
								comment: comment.Message,
								recordBy: comment['Recorded By'],
								recordAt: comment['Recorded At'],
								status: comment.Status,
								reply: comment.Response,
								tagCode: comment.tag_code,
								code: comment.Code,
								replies:
									comment.relatedTo && comment.relatedTo.length > 0
										? comment.relatedTo
												.map((item: any) => ({
													comment: item.Message,
													recordAt: item['Recorded At'],
													recordBy: item['Recorded By'].substring(0, item['Recorded By'].indexOf('@')),
													tagCode: item.tag_code,
													code: item.Code,
												}))
												.sort((one: any, two: any) =>
													new Date(one['Recorded At']) < new Date(two['Recorded At']) ? 1 : -1,
												)
										: [],
							}
						})
						let didpOwner: any = []
						const repliesData = replies.map((comment: any) => {
							return {
								comment: comment.Message,
								recordBy: comment['Recorded By'],
								recordAt: comment['Recorded At'],
								reply: comment.Response,
								tagCode: comment.tag_code,
								status: comment.Status,
								code: comment.Code,
								replies:
									!!comment.relatedTo && comment.relatedTo.length > 0
										? comment.relatedTo
												.map((item: any) => ({
													comment: item.Message,
													recordAt: item['Recorded At'],
													recordBy: item['Recorded By'].substring(0, item['Recorded By'].indexOf('@')),
													tagCode: item.tag_code,
													code: item.Code,
												}))
												.sort((one: any, two: any) =>
													new Date(one['Recorded At']) < new Date(two['Recorded At']) ? 1 : -1,
												)
										: [],
							}
						})

						const nominatedOwners = Object.keys(attributes)
							.map((attributeKey: any) => attributeKey === 'Nominated Owners' && attributes[attributeKey])
							.filter((owner: any) => owner)[0]
						const didpStatus = Object.keys(attributes)
							.filter((attribute: any) => attribute === 'DIDP Status')
							.map((attributeKey: any) => {
								return attributes[attributeKey]
							})[0]
						const detailsData = [
							{
								id: 1,
								isOpen: true,
								name: 'Overview',
								tabs: Object.keys(attributes)
									.filter((attribute: any) => attribute !== 'Nominated Owners')
									.map((attributeKey: any) => {
										if (attributeKey === 'DIDP Owner') {
											didpOwner = Array.isArray(attributes[attributeKey]) ? attributes[attributeKey] : []
											return {
												name: attributeKey,
												value: Array.isArray(attributes[attributeKey])
													? attributes[attributeKey].map((item: string) => item.substring(0, item.indexOf('@')))
													: [],
											}
										}
										if (attributeKey === 'DIDP Created By') {
											return {
												name: attributeKey,
												value: attributes[attributeKey].substring(0, attributes[attributeKey].indexOf('@')),
											}
										}

										if (attributeKey === 'DIDP Name') {
											return {
												name: attributeKey,
												value: attributes[attributeKey],
											}
										}

										return {
											name: attributeKey,
											value: attributes[attributeKey],
										}
									})
									.sort((one: any, two: any) => (one.name > two.name ? 1 : -1)),
							},
							{
								id: 5,
								isOpen: false,
								name: 'TIDP Details',
								tabs:
									Object.keys(tidpDetails).length > 0
										? Object.keys(tidpDetails).map((k) => {
												let key = k
												if (k === 'External Stakeholders') {
													key = 'Reviewers'
												}
												if (k === 'Internal Stakeholders') {
													key = 'SCS Lead Reviewer'
												}
												return {
													name: key,
													value: tidpDetails[k],
												}
										  })
										: [],
							},
						]

						return concat(
							of(fetchDeliverables(action.payload as string)),
							of(disciplineOverviewActions.getDisciplineHistory(action.payload)),
							of(disciplineOverviewActions.assignDidpOwner(didpOwner)),
							of(disciplineOverviewActions.assignNominatedDidpOwner(nominatedOwners)),
							of(disciplineOverviewActions.setDisciplineDidpTagCode(didpTagCode)),
							of(disciplineOverviewActions.setDisciplineTidpTagCode(tidpTagCode)),
							of(disciplineOverviewActions.setDisciplineData([detailsData, commentsData, repliesData, didpStatus])),
							of(controlsActions.handleIsLoading(false)),
							of(tasksActionsElementActions.getTaskActions({ tagCode: didpTagCode })), // TBR
							of(fetchTaskActions(didpTagCode)),
						)
					}),
					catchError((err) => {
						return concat(
							of(controlsActions.handleIsLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.getDisciplineOverview(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const getOneDeliverableEpic: AppEpicDeprecated<ReturnType<typeof getOneDeliverable>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.GET_ONE_DELIVERABLE)),
		withLatestFrom(state$),
		switchMap(([action]) => {
			return concat(
				of(disciplineOverviewActions.handleIsDeliverableLoading(true)),
				of(disciplineOverviewActions.setOneDeliverable([])),
				of(disciplineOverviewActions.handleDeliverablePopup(true)),
				fromPromise(disciplineOverviewService.getOneDeliverable(action.payload)).pipe(
					switchMap((response) => {
						const deliverable = response.results.map((item: any) => ({
							createdBy: item.created_by,
							createdDate: item.created_date,
							displayName: item.display_name,
							message: item.message,
							status: item.status,
						}))
						return concat(
							of(disciplineOverviewActions.handleIsDeliverableLoading(false)),
							of(disciplineOverviewActions.setOneDeliverable(deliverable)),
						)
					}),
					catchError((err) => {
						toast.error('Could not get deliverable.')
						return concat(
							of(disciplineOverviewActions.handleIsDeliverableLoading(false)),
							of(disciplineOverviewActions.handleDeliverablePopup(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.getOneDeliverable(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const saveNewReplyEpic: AppEpicDeprecated<ReturnType<typeof saveReply>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.SAVE_REPLY)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const username = (state as any).profile!.user.name
			const data = {
				contract: state.users.contract.contract,
				class_code: 'Comment',
				attribute: {
					Message: action.payload[0],
					...(action.payload[3] ? { Code: action.payload[3] } : {}),
					'Recorded At': moment(new Date()).zone('+00:00').format(dateFormat.apiFormat),
					'Recorded By': username,
				},
				relations: [{ tag_code: action.payload[1] }],
			}

			return concat(
				of(disciplineOverviewActions.isSavingNewComment(true)),
				fromPromise(saveNewAssetComment(data)).pipe(
					switchMap(() => {
						toast.info('Reply added.')
						return concat(
							of(disciplineOverviewActions.isSavingNewComment(false)),
							of(getTidpOverview(action.payload[2], true)),
							// of(disciplineOverviewActions.getDisciplineOverview(action.payload[2])),
						)
					}),
					catchError((err) => {
						if (err.status === 500) {
							return concat(
								of(disciplineOverviewActions.isSavingNewComment(false)),
								of(disciplineOverviewActions.getDisciplineOverview(action.payload[2])),
							)
						}
						return concat(
							of(disciplineOverviewActions.isSavingNewComment(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.saveReply(
										action.payload[0],
										action.payload[1],
										action.payload[2],
										action.payload[3],
									),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const nominateAdditionalDidpOwnerEpic: AppEpicDeprecated<ReturnType<typeof nominateAdditionalDidpOwner>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.NOMINATE_ADDITIONAL_DIDP_OWNER)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const { tagCode } = action.payload
			const prevNominated = state.disciplineOverview.nominatedDidpOwner
				? state.disciplineOverview.nominatedDidpOwner
				: []
			const disciplineNew = {
				action_name: action.payload.action_name,
				task_id: action.payload.task_id,
				action_input_parameters: {
					contract: state.users.contract.contract,
					'Nominated Owners': [
						...prevNominated,
						...state.disciplineOverview.nominateSelected.map((item: { label: string }) => item.label),
					],
					tag_code: tagCode,
				},
			}

			return concat(
				of(disciplineOverviewActions.setIsNominating(true)),
				of(disciplineOverviewActions.handleActionIsProcessing(true)),
				fromPromise(PostQuery(disciplineNew)).pipe(
					switchMap(() => {
						toast.success('Nominate Additional Owner Successful')
						return concat(
							of(disciplineOverviewActions.getDisciplineOverview(tagCode)),
							of(disciplineOverviewActions.setNominateSelected([])),
							of(disciplineOverviewActions.setNominateOpen(false)),
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(usersActions.changeTextOfSearch('')),
							of(disciplineOverviewActions.setIsNominating(false)),
						)
					}),
					catchError((err) => {
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(disciplineOverviewActions.setIsNominating(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.nominateAdditionalDidpOwner(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const informTidpOwnerEpic: AppEpicDeprecated<ReturnType<typeof informTIDPOwner>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.INFORM_TIDP_OWNER)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const { tagCode } = action.payload

			const discipline = {
				action_name: action.payload.action_name,
				task_id: action.payload.task_id,
				action_input_parameters: {
					contract: state.users.contract.contract,
					tag_code: tagCode,
				},
			}
			return concat(
				of(disciplineOverviewActions.handleActionIsProcessing(true)),
				of(disciplineOverviewActions.setIsInformingTIDP(true)),
				fromPromise(PostQuery(discipline)).pipe(
					switchMap(() => {
						toast.success('Send to TIDP Owner for Acceptance Successful')
						browserHistory.push('/tasks-list')
						return concat(
							of(disciplineOverviewActions.getDisciplineOverview(action.payload.tagCode)),
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
						)
					}),
					catchError((err) => {
						browserHistory.push('/tasks-list')
						return concat(
							of(disciplineOverviewActions.getDisciplineOverview(action.payload.tagCode)),
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.informTIDPOwner(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const handleAcceptOwnershipEpic: AppEpicDeprecated<ReturnType<typeof handleAcceptOwnership>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.HANDLE_ACCEPT_OWNERSHIP)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const { tagCode } = action.payload
			const discipline = {
				action_name: action.payload.action_name,
				task_id: action.payload.task_id,
				action_input_parameters: {
					contract: state.users.contract.contract,
					tag_code: tagCode,
				},
			}
			return concat(
				of(disciplineOverviewActions.handleActionIsProcessing(true)),
				fromPromise(PostQuery(discipline)).pipe(
					switchMap(() => {
						action.payload.isAccepted ? toast.success('Ownership Accepted') : toast.error('Ownership Rejected')
						if (!action.payload.isAccepted) {
							if (action.payload.comment && action.payload.task_id) {
								return concat(
									of(disciplineOverviewActions.saveNewComment(action.payload.comment, action.payload.tagCode, true)),
									of(disciplineOverviewActions.handleActionIsProcessing(false)),
								)
							}
						}
						return action.payload.isAccepted
							? concat(
									of(disciplineOverviewActions.getDisciplineOverview(action.payload.tagCode)),
									of(disciplineOverviewActions.handleActionIsProcessing(false)),
							  )
							: of(disciplineOverviewActions.handleActionIsProcessing(false))
					}),
					catchError((err) => {
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.handleAcceptOwnership(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const handleDIDPAcceptanceEpic: AppEpicDeprecated<ReturnType<typeof handleDIDPAcceptance>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.HANDLE_DIDP_ACCEPTANCE)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const { tagCode } = action.payload
			const discipline = {
				action_name: action.payload.action_name,
				task_id: action.payload.task_id,
				action_input_parameters: {
					contract: state.users.contract.contract,
					tag_code: tagCode,
				},
			}
			action.payload.isAccepted ? toast.info('Discipline Accepting') : toast.info('Discipline  Rejecting')
			return concat(
				of(disciplineOverviewActions.handleActionIsProcessing(true)),
				fromPromise(PostQuery(discipline)).pipe(
					switchMap(() => {
						action.payload.isAccepted ? toast.success('Discipline Accepted') : toast.error('Discipline Rejected')
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							action.payload.isAccepted
								? of()
								: of(disciplineOverviewActions.saveNewComment(action.payload.comment, action.payload.tagCode, true)),
							of(disciplineOverviewActions.getDisciplineOverview(action.payload.tagCode)),
						)
					}),
					catchError((err: any) => {
						action.payload.isAccepted
							? toast.error(err?.response?.data?.Error || 'Unknown error occured.')
							: toast.error('Could Not Reject Discipline')
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.handleDIDPAcceptance(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const handleDIDPReopeningEpic: AppEpicDeprecated<ReturnType<typeof handleDIDPReopening>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.HANDLE_DIDP_REOPENING)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const { tagCode } = action.payload
			const discipline = {
				action_name: action.payload.action_name,
				task_id: action.payload.task_id,
				action_input_parameters: {
					contract: state.users.contract.contract,
					tag_code: tagCode,
				},
			}
			toast.info('Discipline is being reopened')
			return concat(
				of(disciplineOverviewActions.handleActionIsProcessing(true)),
				fromPromise(PostQuery(discipline)).pipe(
					switchMap(() => {
						toast.success('Discipline reopened')
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(disciplineOverviewActions.getDisciplineOverview(action.payload.tagCode)),
						)
					}),
					catchError((err) => {
						toast.error('Could not reopen discipline')
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.handleDIDPReopening(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const handleDIDPRemovingEpic: AppEpicDeprecated<ReturnType<typeof handleDIDPRemoving>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.HANDLE_DIDP_REMOVING)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const { tagCode } = action.payload
			const discipline = {
				action_name: action.payload.action_name,
				task_id: action.payload.task_id,
				action_input_parameters: {
					contract: state.users.contract.contract,
					tag_code: tagCode,
				},
			}
			toast.info('Discipline is being removed')
			return concat(
				of(disciplineOverviewActions.handleActionIsProcessing(true)),
				fromPromise(PostQuery(discipline)).pipe(
					switchMap(() => {
						toast.success('Discipline removed')

						browserHistory.push(`/overview/${state.disciplineOverview.tidpTagCode}`)

						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(disciplineOverviewActions.getDisciplineOverview(action.payload.tagCode)),
						)
					}),
					catchError((err) => {
						toast.error(err?.response?.data?.Error ?? 'Could not remove discipline')
						browserHistory.push(`/overview/${state.disciplineOverview.tidpTagCode}`)
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.handleDIDPRemoving(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const handleFetchDeliverableNumbersEpic: AppEpicDeprecated<ReturnType<typeof handleFetchDeliverableNumbers>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.HANDLE_FETCH_DELIVERABLE_NUMBERS)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const { tagCode } = action.payload
			const discipline = {
				action_name: action.payload.action_name,
				task_id: action.payload.task_id,
				action_input_parameters: {
					contract: state.users.contract.contract,
					tag_code: tagCode,
				},
			}
			return concat(
				of(disciplineOverviewActions.handleActionIsProcessing(true)),
				fromPromise(PostQuery(discipline)).pipe(
					switchMap(() => {
						toast.success('Request submitted successfully')
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(disciplineOverviewActions.getDisciplineOverview(action.payload.tagCode)),
						)
					}),
					catchError((err) => {
						if (err.status !== 401) {
							toast.error('Error fetching some deliverables')
						}
						return concat(
							of(disciplineOverviewActions.handleActionIsProcessing(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: disciplineOverviewActions.handleFetchDeliverableNumbers(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const getDisciplineOverviewHistoryEpic: AppEpicDeprecated<ReturnType<typeof getDisciplineHistory>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(isOfType(disciplineOverviewConstans.GET_DISCIPLINE_OVERVIEW_HISTORY)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return fromPromise(GetHistory(state.users.contract.contract, action.payload)).pipe(
				switchMap((response) => {
					const historyElements = response?.results
						.map((item: { executed_by: string; execution_time: Date; action: string }) => ({
							name: item.executed_by.substring(0, item.executed_by.indexOf('@')),
							date: item.execution_time,
							history: item.action,
						}))
						.sort((one: any, two: any) => (new Date(one.date) < new Date(two.date) ? 1 : -1))
					return of(disciplineOverviewActions.setDiciplineHistory(historyElements))
				}),
				catchError((err) => {
					return of(
						authorizationActions.recallApi({
							errorCode: err.status,
							callback: disciplineOverviewActions.getDisciplineHistory(action.payload),
						}),
					)
				}),
			)
		}),
	)

export default combineEpics(
	getDisciplineOverviewEpic,
	saveNewReplyEpic,
	nominateAdditionalDidpOwnerEpic,
	informTidpOwnerEpic,
	handleAcceptOwnershipEpic,
	handleDIDPAcceptanceEpic,
	handleDIDPReopeningEpic,
	handleDIDPRemovingEpic,
	getDisciplineOverviewHistoryEpic,
	handleFetchDeliverableNumbersEpic,
	getOneDeliverableEpic,
)
