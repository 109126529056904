import { Action, ActionResponse } from 'src/facade/action/actionService.types'
import { ActionDidpUpdateDisciplinesParameters } from 'src/facade/action/didp/actionDidpService.type'
import { FormActionData } from 'src/facade/action/form/actionFormService.type'
import { axiosInstance } from 'src/services/axiosInstance'

export const actionDidpService = {
	async updateDisciplines(
		taskId: number,
		params: ActionDidpUpdateDisciplinesParameters,
	): Promise<ActionResponse<FormActionData>> {
		const { data } = await axiosInstance.post('/workflow/actions/', {
			action_name: Action.DidpUpdateDisciplines,
			task_id: taskId,
			action_input_parameters: params,
		})
		return data
	},
}
