/* eslint-disable no-case-declarations */
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import {
	CHANGE_PAGE,
	CLEAR_USER_DETAILS,
	HANDLE_FILTERS_ITEMS,
	HANDLE_IS_ADDING_NEW_USER,
	HANDLE_IS_DELETING,
	HANDLE_IS_EDITING,
	HANDLE_IS_FILTERING,
	HANDLE_POPUP_DATA,
	HANDLE_RESETTING_PASSWORD,
	SET_AVAILABLE_ROLES_LIST,
	SET_IS_LOADING_LIST,
	SET_IS_USER_DETAILS_LOADING,
	SET_LIST_OF_USERS,
	SET_TOTAL_COUNTER,
	SET_USER_DETAILS,
} from './constants'

export type UsersManagementAction = ActionType<typeof actions>

const initialUsersManagement: IUsersManagement = {
	usersList: [],
	isLoadingUsersList: false,
	isResettingPassword: { id: '', isResetting: false },
	oneUserDetails: {},
	isLoadingUserDetails: false,
	filtering: false,
	totalPageCounter: 0,
	isEditing: { isEditing: false, id: '' },
	isDeleting: { isDeleting: false, id: '' },
	isAddingNewUser: false,
	availableRolesList: [],
	popupData: { isOpen: false, isNew: false },
	pagination: {
		pageSize: 15,
		pageNumber: 0,
	},
	filters: {
		isActive: null,
		isSSO: null,
		email: '',
		groups: '',
	},
}

const userManagementReducer = (state: IUsersManagement = initialUsersManagement, action: UsersManagementAction) => {
	switch (action.type) {
		case SET_TOTAL_COUNTER:
			return { ...state, totalPageCounter: action.payload }
		case HANDLE_IS_ADDING_NEW_USER:
			return { ...state, isAddingNewUser: action.payload }
		case HANDLE_POPUP_DATA:
			return { ...state, popupData: action.payload }
		case HANDLE_IS_EDITING:
			return { ...state, isEditing: action.payload }
		case HANDLE_IS_DELETING:
			return { ...state, isDeleting: action.payload }
		case SET_LIST_OF_USERS:
			return { ...state, usersList: action.payload }
		case HANDLE_RESETTING_PASSWORD:
			return { ...state, isResettingPassword: action.payload }
		case SET_IS_LOADING_LIST:
			return { ...state, isLoadingUsersList: action.payload }
		case SET_USER_DETAILS:
			return { ...state, oneUserDetails: action.payload }
		case SET_IS_USER_DETAILS_LOADING:
			return { ...state, isLoadingUserDetails: action.payload }
		case CLEAR_USER_DETAILS:
			return { ...state, oneUserDetails: {} }
		case HANDLE_IS_FILTERING:
			return { ...state, filtering: action.payload }
		case SET_AVAILABLE_ROLES_LIST:
			return { ...state, availableRolesList: action.payload }
		case CHANGE_PAGE:
			return { ...state, pagination: { pageNumber: action.payload.pageNumber, pageSize: action.payload.pageSize } }
		case HANDLE_FILTERS_ITEMS:
			const { filters } = state
			const filter = {}
			Object.keys(state.filters).forEach((element) => {
				if (element === action.payload.objectName) {
					// @ts-ignore
					filter[element] = action.payload.value
					// @ts-ignore
					filter.last = action.payload.objectName
				} else if (action.payload.objectName !== '') {
					// @ts-ignore
					filter[element] = filters[element]
				}
			})
			return {
				...state,
				filters: Object.keys(filter).length > 0 ? filter : filters,
				pagination: Object.keys(filter).length > 0 ? initialUsersManagement.pagination : state.pagination,
			}
		default:
			return state
	}
}

export default userManagementReducer
