import moment from 'moment'
import { toast } from 'react-toastify'
import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import * as flowForManagerService from 'src/services/flowForManager'
import { isOfType } from 'typesafe-actions'
import { dateFormat } from '../../assets/dateFormats'
import browserHistory from '../../services/history'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { attributesActions } from '../attributes'
import { authorizationActions } from '../authorization'
import { controlsActions } from '../controls'
import {
	confirmManagerForm,
	getContractData,
	getOwners,
	getPrimaryAssets,
	getWorkPackage,
	setPrimaryAssetsSelected,
	setTechnicalLifecycleStage,
} from './actions'
import { flowForManagerActions, flowForManagerConstans } from './index'

export const getPrimaryAssetsEpic: AppEpicDeprecated<ReturnType<typeof getPrimaryAssets>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(flowForManagerConstans.GET_PRIMARY_ASSETS)),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			return fromPromise(flowForManagerService.getPrimaryAssets(state.users.contract.contract)).pipe(
				switchMap((response) => {
					return concat(
						of(
							flowForManagerActions.setPrimaryAssets(
								response.result
									.map((primaryAsset: any) => {
										return {
											label: primaryAsset.attribute['Asset Name'],
											value: primaryAsset.attribute['Unique Asset ID'],
											tag_code: primaryAsset.tag_code,
											attribute: primaryAsset.attribute,
											class_code: primaryAsset.class_code,
											relations: primaryAsset.relations,
										}
									})
									.sort((one: any, two: any) => (one.label > two.label ? 1 : -1)),
							),
						),
						of(attributesActions.getAttributesForTechnicalLifecycle()),
					)
				}),
				catchError((err: any) => {
					return of(
						authorizationActions.recallApi({
							errorCode: err.status,
							callback: flowForManagerActions.getPrimaryAssets(),
						}),
					)
				}),
			)
		}),
	)

export const getSectorCodesEpic: AppEpicDeprecated<ReturnType<typeof getContractData>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(flowForManagerConstans.GET_CONTRACT_DATA)),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			const attributes = state.flowForManager.primaryAssets.filter(
				(res: { value: string }) => res.value === state.flowForManager.primaryAssetsSelected.value,
			)[0]?.attribute
			const data = {
				sector_code: attributes.Sector,
				contract_code: attributes['Contract Code'],
				area: attributes.Area,
				section_code: attributes['Section Code'],
				lot_code: attributes['Lot Code'],
			}
			return of(flowForManagerActions.setContractData(data))
		}),
	)

export const setPrimaryAssetsEpic: AppEpicDeprecated<ReturnType<typeof setPrimaryAssetsSelected>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(flowForManagerConstans.SET_PRIMARY_ASSET_SELECTED)),
		withLatestFrom(state$),
		switchMap(() => {
			return concat(of(flowForManagerActions.setWorkPackageSelected({})), of(flowForManagerActions.getWorkPackage()))
		}),
	)

export const setTechnicalLifecycleEpic: AppEpicDeprecated<ReturnType<typeof setTechnicalLifecycleStage>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(isOfType(flowForManagerConstans.SET_TECHNICAL_LIFECYCLE)),
		withLatestFrom(state$),
		switchMap(() => {
			return concat(of(flowForManagerActions.setWorkPackageSelected({})), of(flowForManagerActions.getWorkPackage()))
		}),
	)

export const getWorkPackageEpic: AppEpicDeprecated<ReturnType<typeof getWorkPackage>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(flowForManagerConstans.GET_WORK_PACKAGE)),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			if (
				Object.keys(state.flowForManager.technicalLifeCycle).length === 0 ||
				Object.keys(state.flowForManager.primaryAssetsSelected).length === 0
			) {
				return of(flowForManagerActions.setWorkPackage([]))
			}

			const workPackagesResults =
				state.flowForManager.technicalLifeCycle.value === 'Other'
					? state.flowForManager.primaryAssetsSelected.relations.filter(
							(item: any) => item.Stage == null || (item.Stage !== 'Detailed Design' && item.Stage !== 'Construction'),
					  )
					: state.flowForManager.primaryAssetsSelected.relations.filter((item: any) =>
							state.flowForManager.technicalLifeCycle.value.includes(item.Stage),
					  )

			return of(
				flowForManagerActions.setWorkPackage(
					workPackagesResults
						.map((workPackage: any) => ({
							...workPackage,
							label: `${workPackage['Work Package Name']} (${workPackage['Work Package Number']})`,
							value: workPackage['Work Package Number'],
							tag_code: workPackage.tag_code,
						}))
						.sort((one: any, two: any) => (one.label > two.label ? 1 : -1)),
				),
			)
		}),
	)

export const saveFormEpic: AppEpicDeprecated<ReturnType<typeof confirmManagerForm>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(flowForManagerConstans.CONFIRM_FLOW_FOR_MANAGER)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const calendarsData = {}
			action.payload.forEach((item: { name: string; value: any }) => {
				// @ts-ignore
				calendarsData[item.name] = moment(new Date(item.value)).zone('+00:00').format(dateFormat.apiFormat)
			})
			const stageCode = state.flowForManager.technicalLifeCycle.value
			const newTidp = {
				action_name: 'action_create_tidp',
				task_id: -1,
				action_input_parameters: {
					contract: state.users.contract.contract,
					TL: {
						stage_value: state.flowForManager.technicalLifeCycle.value,
						stage: state.flowForManager.technicalLifeCycle.label,
						code: stageCode.substring(stageCode.indexOf('(') + 1, stageCode.indexOf(')')),
						calendars: {
							...calendarsData,
						},
					},
					PRIMARY_ASSET: {
						tag_code: state.flowForManager.primaryAssetsSelected.tag_code,
						class_code: state.flowForManager.primaryAssetsSelected.class_code,
					},
					WORK_PACKAGE: {
						label: state.flowForManager.workPackageSelected.label,
						tag_code: state.flowForManager.workPackageSelected.tag_code,
					},
					TIDP: {
						owners: state.flowForManager.selectedOwner.map((item: { label: string }) => item.label),
					},
				},
			}
			return concat(
				of(controlsActions.handleIsLoading(true)),
				fromPromise(flowForManagerService.createNewTidp(newTidp)).pipe(
					switchMap(() => {
						toast.success('TIDP created successfully')
						browserHistory.push('/tasks-list')
						return of(controlsActions.handleIsLoading(false))
					}),
					catchError((err: any) => {
						toast.error('TIDP could not be saved')
						browserHistory.push('/')
						return concat(
							of(controlsActions.handleIsLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: flowForManagerActions.confirmManagerForm(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const getOwnersEpic: AppEpicDeprecated<ReturnType<typeof getOwners>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(flowForManagerConstans.GET_OWNERS)),
		withLatestFrom(state$),
		switchMap(() => {
			return fromPromise(flowForManagerService.getOwners()).pipe(
				switchMap((response) => {
					return of(
						flowForManagerActions.setOwners(
							response.map((element: { key: string; value: string }) => ({
								label: element.value,
								value: element.key,
							})),
						),
					)
				}),
				catchError((err: any) => {
					return of(
						authorizationActions.recallApi({
							errorCode: err.status,
							callback: flowForManagerActions.getOwners(),
						}),
					)
				}),
			)
		}),
	)

export default combineEpics(
	getPrimaryAssetsEpic,
	setTechnicalLifecycleEpic,
	getWorkPackageEpic,
	getSectorCodesEpic,
	setPrimaryAssetsEpic,
	getOwnersEpic,
	saveFormEpic,
)
