import { axiosInstance } from 'src/services/axiosInstance'
import { REACT_FORMS_COMPONENT_LIST, REACT_FORMS_LIST, REMOVE_FORM } from '../constants'

export const getFormsList = async (body: { page: number; pageSize: number; contract: string; query: string }) => {
	const { data } = await axiosInstance.get(
		`${REACT_FORMS_LIST}?contract=${body.contract}&page_size=${body.pageSize}&page=${body.page}${body.query.length >= 3 ? `&class_name=${body.query}` : ''
		}`,
	)
	return data
}
export const getComponentsList = async (body: { page: number; pageSize: number; contract: string; query: string }) => {
	const { data } = await axiosInstance.get(
		`${REACT_FORMS_COMPONENT_LIST}?contract=${body.contract}&page_size=${body.pageSize}&page=${body.page}${body.query.length >= 3 ? `&class_name=${body.query}` : ''
		}`,
	)
	return data
}
export const removeForm = async (body: { id: any; name: any; contract: any }) => {
	const { data } = await axiosInstance.post(REMOVE_FORM, body)
	return data
}
