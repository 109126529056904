/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { IReduxState } from '../../../models/redux/redux'
import { colors } from '../../../theme/colors'
import cancel from '../../../assets/icons/cancel.svg'
import { controlsActions } from '../../../features/controls'
import { dateFormat } from '../../../assets/dateFormats'
import moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useAppDispatch, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import { handleHistorySidebar } from '../../../features/controls/actions'

const MainWrapper = styled.div`
	height: 100%;
	width: 240px;
	padding: 36px 20px;
	background-color: ${({ theme }) => theme.colors.main2};
	border-right: ${({ theme }) => `1px solid ${theme.colors.main10}`};
	a {
		color: ${colors.black}
	  text-decoration: none;
	}
`

const MainHeader = styled.div`
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 20px;
	justify-content: space-between;
	img {
		width: 14px;
		height: 14px;
	}
`

const HistoryElement = styled.div`
	margin-top: 12px;
	display: flex;
	align-items: center;
`
const HistoryElementWrapper = styled.div<{ width?: string }>`
	width: ${({ width }) => width || '100%'};
`
const PersonData = styled.div`
	font-size: 10px;
	font-weight: 700;
	margin-left: 12px;
`

const HistoryContent = styled.div`
	font-size: 14px;
	margin-left: 40px;
	margin-bottom: 8px;
	word-break: break-word;
`

const Circle = styled.div`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.greyBD};
	color: ${colors.white};
	font-weight: 700;
	border-radius: 50%;
`

const oneHistoryElement = (item: { name: string; date: Date; history: string }, index?: number, width?: string) => (
	<HistoryElementWrapper width={width} key={`${item.name}_${index}`}>
		<HistoryElement>
			<Circle>{item.name.substring(0, 2)}</Circle>
			<PersonData>{item.name}</PersonData>
			<PersonData>{moment(new Date(item.date)).format(dateFormat.engHours)}</PersonData>
		</HistoryElement>
		<HistoryContent>{item.history}</HistoryContent>
	</HistoryElementWrapper>
)
function HistorySidebar(props: RouteComponentProps) {
	const dispatch = useAppDispatch()
	const { isHistorySidebarOpen, tidpOverviewHistory, disciplineHistory } = useAppSelectorDeprecated((state) => ({
		isHistorySidebarOpen: state.controls.isHistorySidebarOpen,
		tidpOverviewHistory: state.tidpOverview.tidpOverviewHistory,
		disciplineHistory: state.disciplineOverview.disciplineHistory,
	}))
	const { location } = props

	return isHistorySidebarOpen ? (
		<MainWrapper>
			<MainHeader>
				<div>History</div>
				<img onClick={() => dispatch(handleHistorySidebar(false))} src={cancel} alt="cancel" />
			</MainHeader>
			<div>
				{location.pathname.includes('/discipline-overview/') &&
					disciplineHistory.map((item: any, index: any) => oneHistoryElement(item, index))}
				{location.pathname.includes('/overview/') &&
					tidpOverviewHistory.map((item: any, index: any) => oneHistoryElement(item, index))}
			</div>
		</MainWrapper>
	) : null
}

export default withRouter(
	connect(
		(state: IReduxState) => ({
			isHistorySidebarOpen: state.controls.isHistorySidebarOpen,
			tidpOverviewHistory: state.tidpOverview.tidpOverviewHistory,
			disciplineHistory: state.disciplineOverview.disciplineHistory,
		}),
		{
			handleHistorySidebar: controlsActions.handleHistorySidebar,
		},
	)(HistorySidebar),
)
