import { Attribute, AttributeType } from 'src/facade/forms/formsService.types'
import { AppAttribute } from 'src/forms/_shared/main-form/MainForm'

export function createAppAttribute(attr: Attribute, value?: any): AppAttribute {
	if (value) {
		if (attr.attribute_type === AttributeType.User) {
			return { ...attr, value: value?.split(',') } as AppAttribute
		}

		if ([AttributeType.Media, AttributeType.Attachment, AttributeType.Signature].includes(attr.attribute_type)) {
			return { ...attr, value: [] } as AppAttribute
		}

		return { ...attr, value } as AppAttribute
	}

	if (attr.attribute_type === AttributeType.User) {
		return { ...attr, value: [] } as AppAttribute
	}

	if (attr.attribute_type === AttributeType.Text) {
		return { ...attr, value: attr.predetermined_list ? null : '' } as AppAttribute
	}

	if (attr.attribute_type === AttributeType.Numeric) {
		return { ...attr, value: '' } as AppAttribute
	}

	if ([AttributeType.Media, AttributeType.Attachment, AttributeType.Signature].includes(attr.attribute_type)) {
		return { ...attr, value: [] } as AppAttribute
	}

	if ([AttributeType.MarkupImage, AttributeType.ReadonlyImage].includes(attr.attribute_type)) {
		return { ...attr, value: null } as AppAttribute
	}

	return { ...attr, value: null } as AppAttribute
}
