/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Layout } from 'src/components/Molecules/Layout'
import styled from 'styled-components'
import cancel from '../../../assets/icons/errorCancel.svg'
import save from '../../../assets/icons/save.svg'
import { Roles } from '../../../features/authorization/reducer'
import {
	cancelFormEdit,
	clearForm,
	getComponentAttributeTypes,
	getForm,
	handleFormDetails,
	handleIsEditForm,
	saveExistingComponentAsNew,
	saveNewComponent,
	updateMainForm,
} from '../../../features/formsHandler/actions'
import { colors } from '../../../theme/colors'
import { useAppDispatch, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Input from '../../Atoms/Input'
import SpinnerComponent from '../../Atoms/Loader'
import { SpinerWrapper } from '../../DeliverablePopup'
import Button from '../../Molecules/Button'
import PageTitle from '../../Molecules/PageTitle'
import AttributesComponent from '../../Organisms/Forms/Attributes'
import Components from '../../Organisms/Forms/Components'
import FormDetailsComponent from '../../Organisms/Forms/formDetails'
import Popup from '../../Organisms/Popup'

const PopupContent = styled.div`
	.header {
		display: flex;
		justify-content: center;
		padding: 15px;
		font-size: 16px;
		font-weight: bold;
	}

	.formName {
		margin: 30px 15px;

		input {
			max-width: 300px;
		}
	}

	.buttons {
		display: flex;

		button {
			margin-left: 15px;
			padding: 10px 12px;
		}
	}
`
const MainWrapper = styled.div``
const PageTitleWrapper = styled.div`
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;

	.buttons {
		display: flex;

		button {
			margin-left: 15px;
			padding: 10px 12px;
		}
	}
`

interface IFormEdit {
	match: {
		params: {
			param1: string
		}
	}
}

const FormEdit = ({ match }: IFormEdit) => {
	const [saveComponentPopup, handleSaveComponentPopup] = useState<{
		isOpen: boolean
		componentId: null | number | string
		component: string
		name: string | number
	}>({
		isOpen: false,
		componentId: null,
		component: '',
		name: '',
	})
	const dispatch = useAppDispatch()
	const {
		FormDetails,
		IsFormsLoading,
		IsEditForm,
		isSavingExistingComponent,
		FormAttributes,
		FormComponents,
		sequences,
		isUpdatingMainForm,
	} = useAppSelectorDeprecated((state) => ({
		FormDetails: state.formsHandler.FormDetails,
		IsFormsLoading: state.formsHandler.IsFormsLoading,
		IsEditForm: state.formsHandler.IsEditForm,
		isSavingExistingComponent: state.formsHandler.isSavingExistingComponent,
		isUpdatingMainForm: state.formsHandler.isUpdatingMainForm,

		FormAttributes: state.formsHandler.FormAttributes,
		FormComponents: state.formsHandler.FormComponents,
		sequences: state.formsHandler.sequences,
	}))

	const roles = useAppSelectorDeprecated((state) => state.authorization.roles)

	const getOverview = () => {
		dispatch(clearForm())
		dispatch(getComponentAttributeTypes())
		dispatch(handleIsEditForm(false))
		dispatch(getForm({ id: match.params.param1, counter: 100 }))
	}
	const fetchOverview = useCallback(getOverview, [match])

	useEffect(() => {
		fetchOverview()
	}, [fetchOverview])

	const cancelForm = () => {
		dispatch(handleIsEditForm(false))
		dispatch(cancelFormEdit())
	}

	const handleOpenPopup = ({ componentId, component }: { componentId: string | number; component: string }) => {
		handleSaveComponentPopup({ componentId, component, isOpen: true, name: '' })
	}

	const attributesCondition =
		FormAttributes.attributes.filter((element) => {
			return (
				element.sequence.value !== '' &&
				element.attribute_name.value !== '' &&
				element.display_name.value !== '' &&
				element.attribute_type.value !== ''
			)
		}).length === FormAttributes.attributes.length

	const sequenceCondition =
		lodash.uniqBy(sequences, 'sequence').filter((seq) => seq.sequence !== '').length === sequences.length

	const isAttributeChange =
		FormAttributes.attributes.filter((item) => {
			const old = FormAttributes.oldAttributes.filter((el) => el.id === item.id)[0]
			return lodash.isEqual(old, item)
		}).length === FormAttributes.attributes.length &&
		FormAttributes.attributes.length === FormAttributes.oldAttributes.length

	const isComponentChange =
		FormComponents.attributes.filter((item) => {
			const oldAttr = FormComponents.oldAttributes.filter((el) => el.id === item.id)
			if (oldAttr.length > 0) {
				return (
					oldAttr[0].sequence !== item.sequence ||
					oldAttr[0].dependsOn !== item.dependsOn ||
					oldAttr[0].contexts !== item.contexts ||
					oldAttr[0].displayName !== item.displayName ||
					oldAttr[0].mandatory !== item.mandatory ||
					oldAttr[0].componentName !== item.componentName ||
					oldAttr[0].display_style !== item.display_style ||
					oldAttr[0].display_group !== item.display_group ||
					oldAttr[0].depends_on_conditional !== item.depends_on_conditional
				)
			}
			return true
		}).length === 0 && FormComponents.attributes.length === FormComponents.oldAttributes.length

	const componentsCondition =
		FormComponents.attributes.filter(
			(att) =>
				// @ts-ignore
				!att.isEditing && !att.is_new && att.componentName !== '' && att.displayName !== '' && att.sequence !== '',
		).length === FormComponents.attributes.length

	return (
		<Layout>
			<MainWrapper>
				{saveComponentPopup.isOpen && (
					<Popup
						onClick={() => handleSaveComponentPopup({ isOpen: false, componentId: null, component: '', name: '' })}
						isCloseButton
						children={
							<PopupContent>
								<div className="header">
									By selecting save, you are creating a new Component. Please name the new Component.
								</div>
								<div className="formName">
									<Input
										// eslint-disable-next-line jsx-a11y/no-autofocus
										autoFocus
										height="40px"
										placeholder="Component name"
										value={saveComponentPopup.name}
										onChange={(value) => handleSaveComponentPopup({ ...saveComponentPopup, name: value })}
									/>
								</div>
								<div className="buttons">
									<Button
										imageSrc={cancel}
										text="Cancel"
										isError
										onClick={() =>
											handleSaveComponentPopup({ isOpen: false, componentId: null, component: '', name: '' })
										}
									/>
									<Button
										color={colors.main100}
										textColor={colors.white}
										isDisabled={saveComponentPopup.name === ''}
										imageSrc={save}
										text="Save Form"
										isError={false}
										onClick={() => {
											if (saveComponentPopup.component === 'empty') {
												dispatch(
													saveNewComponent({
														componentId: saveComponentPopup.componentId,
														name: saveComponentPopup.name,
													}),
												)
											}
											if (saveComponentPopup.component === 'new_component') {
												dispatch(
													saveExistingComponentAsNew({
														componentId: saveComponentPopup.componentId,
														name: saveComponentPopup.name,
													}),
												)
											}
											handleSaveComponentPopup({ isOpen: false, componentId: null, component: '', name: '' })
										}}
									/>
								</div>
							</PopupContent>
						}
					/>
				)}
				<PageTitleWrapper>
					<div>
						<PageTitle margin="5px 0 30px 0" title="Form Overview" subTitle="" />
					</div>
					{!IsFormsLoading && (
						<div className="buttons">
							{IsEditForm && (
								<div>
									<Button imageSrc={cancel} text="Cancel" isError onClick={cancelForm} />
								</div>
							)}
							{roles.includes(Roles['Registers:roles.Registers.WorkflowAdmin']) && (
								<div>
									<Button
										color={colors.main100}
										textColor={colors.white}
										loaderColor={colors.white}
										imageSrc={save}
										text={IsEditForm ? 'Save Form' : 'Edit Form'}
										isError={false}
										isLoading={isUpdatingMainForm}
										isDisabled={
											IsEditForm
												? (FormAttributes.attributes.length === 0 && FormComponents.attributes.length === 0) ||
												  !attributesCondition ||
												  (isAttributeChange && isComponentChange) ||
												  !sequenceCondition ||
												  !componentsCondition ||
												  isUpdatingMainForm
												: isSavingExistingComponent
										}
										onClick={() => {
											if (!IsEditForm) {
												dispatch(handleIsEditForm(true))
											} else {
												dispatch(updateMainForm({ id: match.params.param1 }))
											}
										}}
									/>
								</div>
							)}
						</div>
					)}
				</PageTitleWrapper>
				{IsFormsLoading ? (
					<SpinerWrapper>
						<SpinnerComponent size={100} />
					</SpinerWrapper>
				) : (
					<>
						<FormDetailsComponent
							IsEditForm={IsEditForm}
							isGuide
							isCreate={false}
							formDetails={FormDetails}
							isDisabled
							handleFormDetails={(value) => dispatch(handleFormDetails(value))}
						/>
						<AttributesComponent isDisabled={!IsEditForm || isUpdatingMainForm || isSavingExistingComponent} />
						<Components
							handleSavePopup={handleOpenPopup}
							isDisabled={!IsEditForm || isUpdatingMainForm || isSavingExistingComponent}
						/>
					</>
				)}
			</MainWrapper>
		</Layout>
	)
}
export default FormEdit
