import React from 'react'
import styled from 'styled-components'
import Image from '../../Atoms/Image'
import settingsIcon from '../../../assets/icons/settingsInput.svg'
import arrow from '../../../assets/icons/arrowDown.svg'
import { colors } from '../../../theme/colors'

const Wrapper = styled.div`
	padding: 5px;
	margin: 0 15px;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.lightGreyE0};
	img {
		margin: 0 5px;
	}
`

function Settings() {
	return (
		<Wrapper>
			<Image imageSrc={settingsIcon} alt="notifier-icon" />
			<Image customRotate={180} imageSrc={arrow} alt="notifier-icon" />
		</Wrapper>
	)
}

export default Settings
