import { Box, Typography } from '@mui/material'
import { skipToken } from '@reduxjs/toolkit/query'
import { isEmpty } from 'ramda'
import { FC, Fragment, useCallback, useMemo, useState } from 'react'
import { batch } from 'react-redux'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { MessagePanel } from 'src/components/message-panel/MessagePanel'
import { Layout } from 'src/components/Molecules/Layout'
import { ChildForm } from 'src/facade/forms/formsService.types'
import { TaskStatus } from 'src/facade/tasks/tasksService.types'
import { assetsApi, useFetchAssetsQuery } from 'src/features/assets/assetsApi'
import { useFetchFormQuery } from 'src/features/forms/formsApi'
import { updateForm } from 'src/features/forms/updateForm'
import { tasksApi, useFetchTasksQuery } from 'src/features/tasks/tasksApi'
import { createAppAttribute } from 'src/forms/_shared/createAppAttribute'
import { getDocumentNumber } from 'src/forms/_shared/getDocumentNumber'
import { MainForm, MainFormForm } from 'src/forms/_shared/main-form/MainForm'
import { sortForm } from 'src/forms/_shared/sortForm'
import browserHistory from 'src/services/history'
import { useAppDispatch, useAppSelector } from 'src/utils/reduxUtils'

export interface EditFormParams {
	id: string
}

export const EditForm: FC = () => {
	const dispatch = useAppDispatch()
	const {
		contract: { contract },
	} = useAppSelector((state) => state.users)

	const { id } = useParams<EditFormParams>()

	const [message, setMessage] = useState<string | undefined>()

	const { data: tasks, error: tasksError, isLoading: isLoadingTasks } = useFetchTasksQuery({ activeTasksOnly: false })

	const task = useMemo(() => tasks?.find((t) => t.task_id === +id), [tasks, id])

	const {
		data: mainForm,
		error: formError,
		isLoading: isLoadingForm,
	} = useFetchFormQuery(task ? { id: task.metadata.form_template_id, contract } : skipToken)

	const {
		data: assets,
		error: assetsError,
		isLoading: isLoadingAssets,
	} = useFetchAssetsQuery(
		task
			? {
					contract,
					tagCodes: [
						task.main_asset.tag_code,
						...(Object.values(task.main_asset.attribute).filter(
							(attributeValue) => typeof attributeValue === 'string',
						) as string[]),
					],
			  }
			: skipToken,
	)

	const readonlyChildForms: string[] = (task?.metadata?.read_only || '').split(',').filter(Boolean)
	const writeableChildForms: string[] = (task?.metadata?.write || '').split(',').filter(Boolean)
	const visibleChildForms: string[] = useMemo(
		() => [...writeableChildForms, ...readonlyChildForms],
		[writeableChildForms, readonlyChildForms],
	)

	const onlyVisible = useCallback(
		(childForm: ChildForm) => {
			if (task?.task_name === 'FORM_CONTRIBUTOR') {
				return visibleChildForms.includes(childForm.attribute_name)
			}
			return true
		},
		[task?.task_name, visibleChildForms],
	)

	const mainFormAsset = assets?.find((asset) => asset.class_code === mainForm?.form_number)
	const initialMainForm: MainFormForm | null = useMemo(
		() =>
			mainForm && assets && mainFormAsset
				? sortForm({
						...mainForm,
						tagCode: mainFormAsset.tag_code,
						attributes: mainForm.attributes.map((attr) => {
							return createAppAttribute(attr, mainFormAsset?.attribute?.[attr.attribute_name])
						}),
						children: mainForm.children.filter(onlyVisible).map((childForm) => {
							const assetTagCode = mainFormAsset?.attribute[childForm.attribute_name]
							const childFormAsset = assets.find((asset) => asset.tag_code === assetTagCode)

							return {
								...childForm,
								tagCode: assetTagCode,
								attributes: childForm.attributes.map((attr) => {
									return createAppAttribute(attr, childFormAsset?.attribute?.[attr.attribute_name])
								}),
							}
						}),
				  })
				: null,
		[assets, mainForm, mainFormAsset, onlyVisible],
	)

	if (isLoadingTasks || isLoadingForm || isLoadingAssets) {
		return (
			<Layout>
				<Box my={2} sx={{ display: 'flex', justifyContent: 'center', height: '90%', alignItems: 'center' }}>
					<SpinnerComponent size={64} />
				</Box>
			</Layout>
		)
	}

	if (tasksError || formError || assetsError || !initialMainForm || isEmpty(assets)) {
		return (
			<Layout>
				<MessagePanel my={3} errorMessage="Failed to load form." />
			</Layout>
		)
	}

	const handleSubmit = async (submittedForm: MainFormForm) => {
		try {
			await updateForm(submittedForm, contract, task!.task_id)
			batch(() => {
				dispatch(tasksApi.util.invalidateTags(['Tasks']))
				dispatch(assetsApi.util.invalidateTags(['Assets']))
			})
			toast.success('Successfully updated form.')
			browserHistory.push('/forms')
		} catch (e) {
			console.error(e)
			setMessage('Failed to update form.')
		}
	}

	return (
		<Layout>
			<Box my={3}>
				<Box sx={(theme) => ({ maxWidth: theme.breakpoints.values.md })}>
					{initialMainForm && task && (
						<Fragment>
							<Box my={2}>
								<Typography variant="h5">{getDocumentNumber(task)}</Typography>
							</Box>
							<MainForm
								isUpdating
								initialMainForm={initialMainForm}
								message={message}
								onSubmit={handleSubmit}
								onClearMessage={() => setMessage(undefined)}
								disabled={task.status === TaskStatus.Finished}
								task={task}
							/>
						</Fragment>
					)}
				</Box>
			</Box>
		</Layout>
	)
}
