import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Action } from "src/facade/action/actionService.types";
import { actionTaskService } from "src/facade/action/task/actionTaskService";
import { formsService } from "src/facade/forms/formsService";
import { Form } from "src/facade/forms/formsService.types";
import { AppTask, tasksApi } from "./tasksApi";



async function getFormDetails(templateId: string, contract: string) {
    const formsResponse = await formsService.fetch(templateId, { contract })
    const formList = formsResponse[templateId].forms
    return formList.find(e => e.id === templateId)
}

function isSelfService(form: Form) {
    const selfServiceForm = form.attributes
        .find(e => e.attribute_type === "User") === undefined ? true : false
    return selfServiceForm
}

export const markAsCompleteThunk = createAsyncThunk(
    'task/markAsComplete',
    async ({ templateId, contract, tagCode, tasks }: { templateId: string, contract: string, tagCode: string, tasks: AppTask[] | undefined }, thunkAPI) => {
        const form: Form | undefined = await getFormDetails(templateId, contract)
        const selfServiceForm = form !== undefined ? isSelfService(form) : false
        if (selfServiceForm && tasks !== undefined) {
            try {
                const actions: Partial<Record<Action, (taskId: number) => Promise<any>>> = {
                    [Action.MarkTaskComplete]: (taskId) => actionTaskService.markAsComplete(taskId, contract),
                    [Action.FinishForm]: (taskId) => actionTaskService.finish(taskId, contract),
                    [Action.CloseForm]: (taskId) => actionTaskService.close(taskId, contract),
                }
                const formRelatedTasks = tasks.filter((e) => e.main_asset.tag_code === tagCode)
                const formContribuitorTask = formRelatedTasks.find((e) => e.task_name === "FORM_CONTRIBUTOR")
                const formReviewerTask = formRelatedTasks.find((e) => e.task_name === "FORM_REVIEWER")
                const formOwnerTask = formRelatedTasks.find((e) => e.task_name === "FORM_OWNER")
                if (formContribuitorTask !== undefined) {
                    await actions[Action.MarkTaskComplete]?.(formContribuitorTask.task_id)
                }
                if (formReviewerTask !== undefined) {
                    await actions[Action.FinishForm]?.(formReviewerTask.task_id)
                }
                if (formOwnerTask !== undefined) {
                    await actions[Action.CloseForm]?.(formOwnerTask.task_id)
                }
                thunkAPI.dispatch(tasksApi.util.invalidateTags(['Tasks']))
                toast.success("Succesfully marked as complete")
            }
            catch (e) {
                toast.error('Failed to execute action.')
            }
        } else {
            toast.error("Cannot mark as complete")
        }
    }
)