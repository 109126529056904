import React from 'react'
import styled, { css } from 'styled-components'
import TextLink from '../../Atoms/TextLink'
// import userPhoto from '../../../assets/userExample.jpg';
import Tooltip from '../Tooltip'
import logOutImage from '../../../assets/icons/logout.svg'
import { colors } from '../../../theme/colors'
import { Contract } from '../../../features/users/reducer'

const Circle = styled.div`
	width: 35px;
	cursor: pointer;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.greyBD};
	color: ${colors.white};
	font-weight: 700;
	border-radius: 50%;
`

const MainWrapper = styled.div`
	min-width: 120px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const TextWrapper = styled.div`
	margin-right: 12px;
	display: flex;
	flex-direction: column;
	text-align: right;
`

const TextItemWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	//max-width: 200px;
`
const MenuWrapper = styled.div`
	display: flex;
	padding: 12px;
	cursor: pointer;
	border-radius: 10px;

	&:hover {
		background: rgba(1, 60, 166, 0.05);
	}
	img {
		width: 13px;
		height: 13px;
		margin-left: 12px;
	}
`

const MenuLink = styled.div<{ selected: boolean }>`
	display: flex;
	margin: 8px 0;
	font-weight: normal;
	cursor: pointer;

	${({ selected }) =>
		selected &&
		css`
			font-weight: bold;
			cursor: default;
		`}
`

function UserInfo({
	userName,
	contract,
	contracts,
	logout,
	onSelectContract,
}: {
	userName: string
	contract: Contract
	contracts: Contract[]
	logout: () => void
	onSelectContract(contract: Contract): void
}) {
	const handleContractSelect = (c: Contract) => () => {
		onSelectContract(c)
	}

	return (
		<MainWrapper>
			<TextWrapper>
				<TextItemWrapper>
					<TextLink title={userName} mdBold />
				</TextItemWrapper>
				<TextItemWrapper>
					{contracts.length === 1 ? (
						<Tooltip
							placement="bottom"
							hideArrow
							tooltip={
								<div>
									<TextLink title={contract.contract_name} mdBold black62 />
								</div>
							}
						>
							<TextLink isEllipsis={false} title={contract.contract_name} mdBold black62 />
						</Tooltip>
					) : (
						<Tooltip
							placement="bottom"
							trigger="click"
							tooltip={
								<div>
									{contracts.map((ctr) => (
										<MenuLink
											key={ctr.contract}
											selected={ctr.contract === contract.contract}
											onClick={handleContractSelect(ctr)}
										>
											{ctr.contract_name}
										</MenuLink>
									))}
								</div>
							}
						>
							<TextLink isEllipsis={false} title={contract.contract_name} mdBold black62 />
						</Tooltip>
					)}
				</TextItemWrapper>
			</TextWrapper>
			<Tooltip
				styleForTooltip={{
					top: '5px !important',
					left: '-15px !important',
					padding: '0px !important',
				}}
				trigger="click"
				placement="bottom"
				hideArrow
				tooltip={
					<div>
						<MenuWrapper onClick={logout}>
							Log out
							<img alt="Header" src={logOutImage} />
						</MenuWrapper>
					</div>
				}
			>
				<Circle>{userName.substring(0, 2)}</Circle>
				{/* <UserImage isPointer={true} size={48} src={userPhoto} /> */}
			</Tooltip>
		</MainWrapper>
	)
}

export default UserInfo
