/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import styled from 'styled-components'
import Headline from '../../Atoms/Headline'
import Image from '../../Atoms/Image'
import dropDownIcon from '../../../assets/icons/arrowDown.svg'

const MainWrapper = styled.div`
	margin-bottom: 25px;
`

const TitleWrapper = styled.div`
	margin-bottom: 12px;
	border-radius: 4px;
	padding: 20px 25px 16px 15px;
	background-color: ${({ theme }) => theme.colors.main10};
	display: flex;
	justify-content: space-between;
	.left {
		display: flex;
		align-items: center;
		img {
			cursor: pointer;
			margin-left: 15px;
			width: 15px;
			height: 15px;
		}
		.rotating {
			-webkit-animation: rotation 2s infinite linear;
		}
	}
`

const ContentWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.main5};
	border-radius: 8px;
	padding: 12px;
`

function Overview({
	title,
	children,
	leftIcon,
	onClickIcon,
	leftIconLoading,
}: {
	title: string
	children?: any
	leftIcon?: any
	leftIconLoading?: boolean
	onClickIcon?: () => void
}) {
	const [isCollapse, handleCollapse] = useState(false)
	const handleCollapseState = () => {
		handleCollapse(!isCollapse)
	}
	const onClickLeftIcon = () => {
		if (onClickIcon) {
			onClickIcon()
		}
	}
	return (
		<MainWrapper>
			<TitleWrapper>
				<div className="left">
					<Headline title={title} extraSmall />
					{leftIcon && (
						<img
							className={leftIconLoading ? 'rotating' : ''}
							src={leftIcon}
							onClick={onClickLeftIcon}
							alt="left-icon"
						/>
					)}
				</div>
				<Image
					isClickable
					customRotate={isCollapse ? 0 : 180}
					onClick={handleCollapseState}
					imageSrc={dropDownIcon}
					alt="arrow-up-icon"
				/>
			</TitleWrapper>
			{!isCollapse && <ContentWrapper>{children}</ContentWrapper>}
		</MainWrapper>
	)
}

export default Overview
