import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { IAttributes } from '../../models/attributes/attributes'
import { SET_DOCUMENT_ATTRIBUTES, SET_TECHNICAL_LIFECYCLES_ATTRIBUTES } from './constants'

export type AttributesActions = ActionType<typeof actions>
const initialAuthorizationReducer: IAttributes = {
	documentAttributes: [],
	technicalLifecycleAttributes: [],
}

const authorizationReducer = (state: IAttributes = initialAuthorizationReducer, action: AttributesActions) => {
	switch (action.type) {
		case SET_DOCUMENT_ATTRIBUTES: {
			return { ...state, documentAttributes: action.payload }
		}
		case SET_TECHNICAL_LIFECYCLES_ATTRIBUTES: {
			return { ...state, technicalLifecycleAttributes: action.payload }
		}
		default:
			return state
	}
}

export default authorizationReducer
