/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import styled from 'styled-components'
import Popup from '../Organisms/Popup'
import SpinnerComponent from '../Atoms/Loader'
import { MyColumn, MyTable, StyledTdContainer } from '../Molecules/MyTable'
import { DeliverableRow } from '../Pages/DisciplineOverview/disciplineOverviewInterface'
import { CellProps, Renderer } from 'react-table'
import moment from 'moment'
import { dateFormat } from '../../assets/dateFormats'

interface IDeliverablePopup {
	isPopupOpen: any
	title: string
	isLoading: boolean
	isChanging: boolean
	onClose: () => void
	tasksList: Array<{ createdBy: string; createdDate: Date; displayName: string; message: string; status: string }>
}

export const SpinerWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 30vw;
	min-height: 30vh;
`

export const DeliverablePopupContainer = styled.div`
	padding: 15px;
	.header {
		font-weight: 700;
		font-size: 14px;
		padding: 7px 0;
	}
	.buttonWrapper {
		display: flex;
		width: 100%;
		justify-content: flex-end;
	}
	.tableForDeliverableTasks {
		min-width: 70vw;
		max-height: 50vh;
		overflow: auto;
		&::-webkit-scrollbar-thumb {
			display: none;
		}
		&::-webkit-scrollbar {
			display: none;
		}
	}
`

function DeliverablePopup(props: IDeliverablePopup) {
	const deliverableTasksRows: any = props.tasksList.map((deliverable) => ({
		name: deliverable.displayName,
		createdBy: deliverable.createdBy,
		createdDate: deliverable.createdDate,
		message: deliverable.message,
		status: deliverable.status,
	}))

	const cellRenderer =
		(headerId: string): Renderer<CellProps<DeliverableRow>> =>
		({ value }) => {
			return headerId === 'createdDate' ? (
				<StyledTdContainer center>{moment(value).format(dateFormat.engHours)}</StyledTdContainer>
			) : (
				<StyledTdContainer center>{value}</StyledTdContainer>
			)
		}

	const deliverableTasksColumns: MyColumn<DeliverableRow>[] = [
		{ Header: 'Name', accessor: 'name', Cell: cellRenderer('name') },
		{ Header: 'Created By', accessor: 'createdBy', Cell: cellRenderer('createdBy') },
		{ Header: 'Created By', accessor: 'createdDate', Cell: cellRenderer('createdDate') },
		{ Header: 'Message', accessor: 'message', Cell: cellRenderer('message') },
		{ Header: 'Status', accessor: 'status', Cell: cellRenderer('status') },
	].map((column) => ({ ...column, noTdContainer: true } as MyColumn<DeliverableRow>))

	return (
		props.isPopupOpen && (
			<Popup
				onClick={props.onClose}
				isCloseButton
				children={
					<>
						{props.isLoading ? (
							<SpinerWrapper>
								<SpinnerComponent size={100} />
							</SpinerWrapper>
						) : (
							<DeliverablePopupContainer>
								<div className="header">Tasks list for {props.title}:</div>
								<div className="tableForDeliverableTasks">
									<MyTable columns={deliverableTasksColumns} data={deliverableTasksRows} fullWidth />
								</div>
							</DeliverablePopupContainer>
						)}
					</>
				}
			/>
		)
	)
}

export default DeliverablePopup
