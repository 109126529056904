import { useEffect, useState } from 'react'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { msalInstance } from 'src/utils/msalInstance'
import styled from 'styled-components'

interface RefreshPageProps {
	match: {
		params: {
			param1: string
		}
	}
}

const WrapperRefresh = styled.div<{ margin?: string }>`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

function RefreshPage(props: RefreshPageProps) {
	const [paramValue, setParamValue] = useState('')

	const redirectUrl: string = window.location.href.substring(
		window.location.href.indexOf('/link=') + 6,
		window.location.href.length,
	)

	useEffect(() => {
		if (paramValue !== props.match.params.param1) {
			setParamValue(props.match.params.param1)
			msalInstance.refreshFromRefreshPage(redirectUrl)
		}
	}, [props.match])

	return (
		<WrapperRefresh>
			<SpinnerComponent size={100} />
		</WrapperRefresh>
	)
}

export default RefreshPage
