import { Button, TextField } from '@mui/material'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { colors } from 'src/theme/colors'
import { useAppSelector } from 'src/utils/reduxUtils'
import { LoadingButton } from '@mui/lab'

const TextFieldWrapper = styled.div`
	padding-left: 30px;
	margin-top: 10px;
`

const ButtonsWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: row;
	padding-top: 4px;
	width: 405px;
	justify-content: flex-end;
	margin-left: 65px;
`

interface ReplyInput {
	comment: string
}

interface AddReplyProps {
	saveReply: (text: string, commentId: string, tidpId: string, commentCode: string) => void
	commentTagCode: string
	tagCode: string
	reviewers: Array<string>
	owners: Array<string>
}

export function AddReply(props: AddReplyProps) {
	const { register, handleSubmit, control, formState, watch } = useForm({
		defaultValues: {
			comment: '',
		},
	})
	const { saveReply } = props
	const username = useAppSelector((state) => state.profile.user!.name)
	const { isSavingNewComment } = useAppSelector((state) => state.tidpOverview)

	const onSubmit: SubmitHandler<ReplyInput> = (data) => {
		saveReply(data.comment, props.commentTagCode, props.tagCode, '')
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<TextFieldWrapper>
				<Controller
					control={control}
					name="comment"
					defaultValue=""
					render={(field) => (
						<TextField
							{...field}
							{...register('comment', { required: true })}
							sx={{ width: '470px', backgroundColor: `${colors.lightGreyF10}` }}
							id="comment-text-field"
							label="Type comment *"
							multiline
							rows={5}
							variant="filled"
						/>
					)}
				/>
				<ButtonsWrapper>
					<LoadingButton
						loading={isSavingNewComment}
						variant="outlined"
						sx={{ height: '40px', marginBottom: '10px' }}
						type="submit"
					>
						Submit
					</LoadingButton>
				</ButtonsWrapper>
			</TextFieldWrapper>
		</form>
	)
}
