import { Autocomplete, TextField } from '@mui/material'
import { get } from 'lodash'
import { FC, memo, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AttributeInputProps } from 'src/forms/_shared/attribute/AttributeInput'
import { MainFormForm } from 'src/forms/_shared/main-form/MainForm'

export const AttributeAutocompleteInput: FC<AttributeInputProps> = memo(({ attribute, name, disabled }) => {
	const { control, formState } = useFormContext<MainFormForm>()

	const options = useMemo(
		() => (Array.isArray(attribute.predetermined_list) ? attribute.predetermined_list : null) || null,
		[attribute.predetermined_list],
	)

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			rules={{ required: { value: attribute.mandatory, message: 'Field is required.' } }}
			render={({ field }) => (
				<Autocomplete
					{...field}
					fullWidth
					disablePortal
					defaultValue={null}
					onChange={(e, data) => field.onChange(data)}
					options={options || []}
					disabled={disabled}
					renderInput={(params) => (
						<TextField
							{...params}
							id={attribute.id}
							label={attribute.display_name}
							required={attribute.mandatory}
							InputLabelProps={{ shrink: true }}
							variant="standard"
							size="medium"
							margin="normal"
							error={!!get(formState.errors, name)}
							helperText={get(formState.errors, name)?.message}
						/>
					)}
				/>
			)}
		/>
	)
})

AttributeAutocompleteInput.displayName = 'AttributeAutocompleteInput'
