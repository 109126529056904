/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import {
	ASSIGN_CHILD,
	CHECK_DUPLICATES_IN_USERS,
	CLEAR_PRIVILEGES,
	CREATE_NEW_PRIVILEGE,
	CREATE_PRIVILEGE_PARENT,
	HANDLE_CHECKED_USER,
	HANDLE_EDIT_POPUP,
	HANDLE_IS_ADDING_NEW_USER_TO_GROUP,
	HANDLE_IS_LOADING_PRIVILEGES_SOURCE,
	HANDLE_IS_REMOVING,
	HANDLE_IS_REMOVING_PRIVILEGES,
	HANDLE_IS_SAVING_PRIVILEGES,
	HANDLE_LIST_OF_GROUPS_IS_LOADING,
	HANDLE_NEW_GROUP_IS_CREATING,
	HANDLE_NEW_GROUP_POPUP,
	HANDLE_OPEN_NEW_PRIVILEGE,
	HANDLE_OPEN_PRIVILEGE_SOURCE,
	HANDLE_PAGINATION_FOR_GROUPS,
	HANDLE_USERS_LOADING,
	IS_DELETING_GROUP,
	REMOVE_NEW_PRIVILEGE,
	SEARCH_FOR_USERS_IN_GROUP,
	SET_LIST_OF_GROUPS,
	SET_PRIVILEGES_HIERARCHY,
	SET_PRIVILEGES_LIST_LOADING,
	SET_PRIVILEGES_SOURCE,
	SET_PRIVILEGES_TYPES,
	SET_PRIVILEGES_TYPES_LOADING,
	SET_USERS_FOR_GROUP,
} from './constants'
import { IGroupsManagement } from '../../models/groupsManagement/groupsManagement'
import { getTypeOptions } from '../../components/Pages/GroupsManagement/privilege'

export type GroupsManagementAction = ActionType<typeof actions>

const initialGroupsManagement: IGroupsManagement = {
	groupsList: [],
	usersWithoutDuplicates: [],
	isLoadingGroups: false,
	isDeletingGroup: false,
	newGroupPopup: false,
	newGroupIsCreating: false,
	listOfUsersInGroup: [],
	allUsersInGroup: [],
	isLoadingUsersForGroup: false,
	isAddingNewUsersToGroup: false,
	isRemovingUsersFromGroup: false,
	usersPaginationData: {
		page: 0,
		pageCount: 0,
		query: '',
		selectedElements: 0,
	},
	groupsPagination: {
		page: 0,
		pageCount: 0,
	},
	editPopup: {
		isOpen: false,
		tag: -1,
		name: '',
		project_org: false,
		tabs: [
			{
				name: 'Users',
				isSelected: true,
			},
			{
				name: 'Privileges',
				isSelected: false,
			},
		],
	},
	isPrivilegesListLoading: false,
	privilegesSource: [],
	privilegesTypes: [],
	isPrivilegesTypesLoading: false,
	isLoadingPrivilegesSource: false,
	isSavingPrivileges: false,
	isRemovingPrivileges: false,
	privilegesHierarchy: {},
	newPrivileges: {},
}
const newChild = (
	parentId: string[],
	isAdditional: boolean,
	id: any,
	childrenInParent: number,
	hierarchy: any,
	parent: any,
) => {
	const key = `${id}_${isAdditional ? 'additions' : 'substractions'}_${childrenInParent}`
	return {
		parentsIds: [...parentId, parent.id, key],
		id: key,
		parentPrivilege: parent.value.value,
		type: '',
		typeOptions: getTypeOptions(parent.type.value, hierarchy),
		value: '',
		listOptions: [],
		additions: [],
		substractions: [],
	}
}
const changeChild = (id: string, parentsIds: string[], isAdditional: boolean, element: any, hierarchy: any) => {
	if (element.id === id) {
		return {
			...element,
			additions: isAdditional
				? [...element.additions, newChild(parentsIds, true, id, element.additions.length, hierarchy, element)]
				: element.additions,
			substractions: !isAdditional
				? [...element.substractions, newChild(parentsIds, false, id, element.substractions.length, hierarchy, element)]
				: element.substractions,
		}
	}
	return {
		...element,
		additions:
			parentsIds.filter(() => element.id === id).length > 0
				? [...element.additions, newChild(parentsIds, true, id, element.additions.length, hierarchy, element)]
				: element.additions.map((item: any) => {
						return parentsIds.filter((el) => el === item.id).length === 0
							? item
							: changeChild(id, parentsIds, isAdditional, item, hierarchy)
				  }),
		substractions:
			parentsIds.filter(() => element.id === id).length > 0
				? [...element.substractions, newChild(parentsIds, false, id, element.substractions.length, hierarchy, element)]
				: element.substractions.map((item: any) => {
						return parentsIds.filter((el) => el === item.id).length === 0
							? item
							: changeChild(id, parentsIds, isAdditional, item, hierarchy)
				  }),
	}
}

const groupsManagementReducer = (
	state: IGroupsManagement = initialGroupsManagement,
	action: GroupsManagementAction,
) => {
	switch (action.type) {
		case ASSIGN_CHILD:
			return { ...state, newPrivileges: action.payload }
		case CLEAR_PRIVILEGES:
			return { ...state, newPrivileges: {} }
		case CREATE_PRIVILEGE_PARENT:
			const privilegesParent = {
				name: action.payload.name,
				isOpen: true,
				relatedId: [],
				id: '0_privilege_parent',
				privilege: {
					parentsIds: ['0_privilege_parent'],
					id: '1_privilege',
					type: '',
					typeOptions: state.privilegesTypes.map((value) => ({
						value,
						label: value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' '),
					})),
					value: '',
					listOptions: [],
					additions: [],
					substractions: [],
				},
			}
			return { ...state, newPrivileges: privilegesParent }
		case REMOVE_NEW_PRIVILEGE:
			return { ...state, newPrivileges: {} }
		case HANDLE_OPEN_NEW_PRIVILEGE:
			return {
				...state,
				newPrivileges: {
					...state.newPrivileges,
					isOpen: !state.newPrivileges.isOpen,
				},
			}
		case CREATE_NEW_PRIVILEGE:
			const newPrivileges = {
				...state.newPrivileges,
				privilege: {
					...state.newPrivileges.privilege,
					additions:
						action.payload.parentsIds.filter(() => state.newPrivileges.privilege.id === action.payload.id).length > 0
							? !action.payload.isAdditional
								? state.newPrivileges.privilege.additions
								: [
										...state.newPrivileges.privilege.additions,
										newChild(
											action.payload.parentsIds,
											true,
											action.payload.id,
											state.newPrivileges.privilege.additions.length,
											state.privilegesHierarchy,
											state.newPrivileges.privilege,
										),
								  ]
							: state.newPrivileges.privilege.additions.map((item: any) => {
									return action.payload.parentsIds.filter((el: any) => el === item.id).length === 0
										? item
										: changeChild(
												action.payload.id,
												action.payload.parentsIds,
												action.payload.isAdditional,
												item,
												state.privilegesHierarchy,
										  )
							  }),
					substractions:
						action.payload.parentsIds.filter(() => state.newPrivileges.privilege.id === action.payload.id).length > 0
							? action.payload.isAdditional
								? state.newPrivileges.privilege.substractions
								: [
										...state.newPrivileges.privilege.substractions,
										newChild(
											action.payload.parentsIds,
											false,
											action.payload.id,
											state.newPrivileges.privilege.substractions.length,
											state.privilegesHierarchy,
											state.newPrivileges.privilege,
										),
								  ]
							: state.newPrivileges.privilege.substractions.map((item: any) => {
									return action.payload.parentsIds.filter((el: any) => el === item.id).length === 0
										? item
										: changeChild(
												action.payload.id,
												action.payload.parentsIds,
												action.payload.isAdditional,
												item,
												state.privilegesHierarchy,
										  )
							  }),
				},
			}
			return { ...state, newPrivileges }
		case SET_PRIVILEGES_HIERARCHY:
			return { ...state, privilegesHierarchy: action.payload }
		case HANDLE_IS_REMOVING_PRIVILEGES:
			return { ...state, isRemovingPrivileges: action.payload }
		case HANDLE_IS_SAVING_PRIVILEGES:
			return { ...state, isSavingPrivileges: action.payload }
		case SET_PRIVILEGES_TYPES_LOADING:
			return { ...state, isPrivilegesTypesLoading: action.payload }
		case HANDLE_IS_LOADING_PRIVILEGES_SOURCE:
			return { ...state, isLoadingPrivilegesSource: action.payload }
		case HANDLE_OPEN_PRIVILEGE_SOURCE:
			return {
				...state,
				privilegesSource: state.privilegesSource.map((item) =>
					item.id === action.payload.id ? { ...item, isOpen: action.payload.isOpen } : item,
				),
			}
		case SET_PRIVILEGES_TYPES:
			return { ...state, privilegesTypes: action.payload }
		case SET_PRIVILEGES_LIST_LOADING:
			return { ...state, isPrivilegesListLoading: action.payload }
		case SET_PRIVILEGES_SOURCE:
			return { ...state, privilegesSource: action.payload }
		case HANDLE_LIST_OF_GROUPS_IS_LOADING:
			return { ...state, isLoadingGroups: action.payload }
		case HANDLE_IS_ADDING_NEW_USER_TO_GROUP:
			return { ...state, isAddingNewUsersToGroup: action.payload }
		case SET_LIST_OF_GROUPS:
			return { ...state, groupsList: action.payload }
		case HANDLE_PAGINATION_FOR_GROUPS:
			return { ...state, groupsPagination: action.payload }
		case CHECK_DUPLICATES_IN_USERS:
			const users = action.payload.reduce(
				(unique, item) =>
					// @ts-ignore
					state.allUsersInGroup.find((element) => element.user === item.value) ? unique : [...unique, item],
				[],
			)
			return { ...state, usersWithoutDuplicates: users }
		case SET_USERS_FOR_GROUP:
			return {
				...state,
				listOfUsersInGroup: action.payload.slice(0, 20),
				allUsersInGroup: action.payload,
				usersPaginationData: {
					page: 0,
					pageCount: Math.ceil(action.payload.length / 20),
					query: '',
					selectedElements: 0,
				},
			}
		case SEARCH_FOR_USERS_IN_GROUP:
			const { page } = action.payload
			const search = state.allUsersInGroup.filter(
				(item) => item.user.toLowerCase().indexOf(action.payload.query.toLowerCase()) !== -1,
			)
			return {
				...state,
				usersPaginationData: {
					...state.usersPaginationData,
					page: action.payload.page,
					pageCount: Math.ceil(search.length / 20),
					query: action.payload.query,
				},
				listOfUsersInGroup: search.slice(page * 20, page * 20 + 20),
			}
		case HANDLE_IS_REMOVING:
			return { ...state, isRemovingUsersFromGroup: action.payload }
		case HANDLE_CHECKED_USER:
			const newList = state.allUsersInGroup.map((item) =>
				item.user === action.payload
					? {
							...item,
							isSelected: !item.isSelected,
					  }
					: item,
			)
			return {
				...state,
				usersPaginationData: {
					...state.usersPaginationData,
					selectedElements: newList.filter((item) => item.isSelected).length,
				},
				allUsersInGroup: [...newList],
				listOfUsersInGroup: [
					...state.listOfUsersInGroup.map((item) =>
						item.user === action.payload
							? {
									...item,
									isSelected: !item.isSelected,
							  }
							: item,
					),
				],
			}
		case IS_DELETING_GROUP:
			return { ...state, isDeletingGroup: action.payload }
		case HANDLE_NEW_GROUP_POPUP:
			return { ...state, newGroupPopup: action.payload }
		case HANDLE_NEW_GROUP_IS_CREATING:
			return { ...state, newGroupIsCreating: action.payload }
		case HANDLE_USERS_LOADING:
			return { ...state, isLoadingUsersForGroup: action.payload }
		case HANDLE_EDIT_POPUP:
			if (!action.payload.isOpen) {
				return { ...state, editPopup: initialGroupsManagement.editPopup }
			}
			return { ...state, editPopup: action.payload }
		default:
			return state
	}
}

export default groupsManagementReducer
