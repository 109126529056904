import { createApi } from '@reduxjs/toolkit/query/react'
import { formsService, FormTemplate } from 'src/facade/forms/formsService'
import { axiosBaseQuery } from 'src/utils/axiosBaseQuery'

export const formTemplatesApi = createApi({
	reducerPath: 'formTemplatesApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		fetchFormTemplates: builder.query<FormTemplate[], string>({
			query: (contract) => () => formsService.fetchTemplates(contract),
		}),
	}),
})

export const { useFetchFormTemplatesQuery } = formTemplatesApi
