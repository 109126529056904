import { action } from 'typesafe-actions'
import {
	GET_LIST_OF_FORMS,
	SET_LIST_OF_FORMS,
	HANDLE_LIST_IS_LOADING,
	REMOVE_FORM,
	HANDLE_IS_REMOVING_FORM,
	GET_COMPONENTS_OF_FORMS,
	SET_COMPONENT_OF_FORMS,
	HANDLE_COMPONENTS_LIST_IS_LOADING,
} from './constants'

export const getFormsList = (forms: { page: number; pageSize: number; query: string }) =>
	action(GET_LIST_OF_FORMS, forms)
export const setFormsList = (forms: any) => action(SET_LIST_OF_FORMS, forms)
export const handleIsLoadingListOfForms = (isLoading: boolean) => action(HANDLE_LIST_IS_LOADING, isLoading)

export const getComponentsList = (forms: { page: number; pageSize: number; query: string }) =>
	action(GET_COMPONENTS_OF_FORMS, forms)
export const setComponentsList = (forms: any) => action(SET_COMPONENT_OF_FORMS, forms)
export const handleIsLoadingListOfComponents = (isLoading: boolean) =>
	action(HANDLE_COMPONENTS_LIST_IS_LOADING, isLoading)

export const removeForm = (form: { id: any; name: any }) => action(REMOVE_FORM, form)
export const handleIsRemovingForm = (isRemoving: boolean) => action(HANDLE_IS_REMOVING_FORM, isRemoving)
