import Privilege from './privilege'
import { connect } from 'react-redux'
import { IReduxState } from '../../../models/redux/redux'
import { groupsManagementActions } from '../../../features/groupsManagement'
import Accordion from '../../Molecules/Accordion'
import { editChild } from '../../../features/groupsManagement/epics'
import SpinnerComponent from '../../Atoms/Loader'
import { SpinnerOverview } from './index'
import { Roles } from '../../../features/authorization/reducer'

interface ITest {
	newPrivileges: any
	editPopup: any
	privilegesHierarchy: any
	roles: string[]
	privilegesSource: any
	isPrivilegesTypesLoading: boolean
	isPrivilegesListLoading: boolean
	isLoadingPrivilegesSource: boolean
	isRemovingPrivileges: boolean
	isSavingPrivileges: boolean
	createNewPrivilege: typeof groupsManagementActions.createNewPrivilege
	removeNewPrivilege: typeof groupsManagementActions.removeNewPrivilege
	handleOpenNewPrivilege: typeof groupsManagementActions.handleOpenNewPrivilege
	handleChangeChild: typeof groupsManagementActions.handleChangeChild
	assignChild: typeof groupsManagementActions.assignChild
	removePrivilege: typeof groupsManagementActions.removePrivilege
	handleOpenPrivilegeSource: typeof groupsManagementActions.handleOpenPrivilegeSource
}

const PrivilegeHandler = ({
	privilegesHierarchy,
	assignChild,
	handleChangeChild,
	newPrivileges,
	createNewPrivilege,
	removeNewPrivilege,
	handleOpenNewPrivilege,
	isPrivilegesTypesLoading,
	isPrivilegesListLoading,
	isSavingPrivileges,
	privilegesSource,
	isLoadingPrivilegesSource,
	editPopup,
	removePrivilege,
	handleOpenPrivilegeSource,
	isRemovingPrivileges,
	roles,
}: ITest) => {
	const changeChild = (
		id: string,
		parentsIds: string[],
		isType: boolean,
		value: any,
		parentPrivilege: any,
		isAdditional?: boolean,
	) => {
		const typedPrivileges = {
			...newPrivileges,
			privilege: editChild(id, parentsIds, newPrivileges.privilege, isType, value, false),
		}
		if (value.value.toLowerCase() === 'all') {
			const valuedPrivileges = {
				...typedPrivileges,
				privilege: editChild(id, parentsIds, typedPrivileges.privilege, false, { value: 'All', label: 'All' }, false),
			}
			assignChild(valuedPrivileges)
		} else {
			assignChild(typedPrivileges)
			handleChangeChild({ id, parentsIds, isType, value, parentPrivilege, isAdditional })
		}
	}

	const isRemove = roles.some((role) => role === Roles['AIMS:roles.Sysadmin'])

	return (
		<>
			{isLoadingPrivilegesSource ? (
				<SpinnerOverview>
					<SpinnerComponent size={100} />
				</SpinnerOverview>
			) : (
				privilegesSource.map((item: any) => (
					<Accordion
						key={item.id}
						isArrow
						isOpen={item.isOpen}
						handleOpen={() => handleOpenPrivilegeSource({ id: item.id, isOpen: !item.isOpen })}
						onRemove={() => removePrivilege({ tag: editPopup.tag, id: item.name })}
						title={item.name}
						isRemovingDisabled={isRemovingPrivileges}
						isChecked={false}
						isRemove={isRemove}
					>
						<Privilege
							startLevel={1}
							isPrivilegesTypesLoading={isPrivilegesTypesLoading}
							isPrivilegesListLoading={isPrivilegesListLoading}
							isDisabled
							isSaving={isSavingPrivileges}
							privilegesHierarchy={privilegesHierarchy}
							privilege={item.privilege}
						/>
					</Accordion>
				))
			)}
			{Object.keys(newPrivileges).length > 0 && (
				<Accordion
					isArrow
					isOpen={newPrivileges.isOpen}
					handleOpen={handleOpenNewPrivilege}
					title={newPrivileges.name}
					isChecked={false}
					isRemove={isRemove}
					onRemove={removeNewPrivilege}
				>
					<Privilege
						startLevel={1}
						isPrivilegesTypesLoading={isPrivilegesTypesLoading}
						isPrivilegesListLoading={isPrivilegesListLoading}
						handleChangeChild={changeChild}
						isDisabled={false}
						isSaving={isSavingPrivileges}
						privilegesHierarchy={privilegesHierarchy}
						privilege={newPrivileges.privilege}
						handleAddNew={(id, parentsIds, isAdditional) => createNewPrivilege({ isAdditional, id, parentsIds })}
					/>
				</Accordion>
			)}
		</>
	)
}

export default connect(
	(state: IReduxState) => ({
		newPrivileges: state.groupsManagement.newPrivileges,
		privilegesHierarchy: state.groupsManagement.privilegesHierarchy,
		isPrivilegesListLoading: state.groupsManagement.isPrivilegesListLoading,
		isPrivilegesTypesLoading: state.groupsManagement.isPrivilegesTypesLoading,
		isSavingPrivileges: state.groupsManagement.isSavingPrivileges,
		isLoadingPrivilegesSource: state.groupsManagement.isLoadingPrivilegesSource,
		privilegesSource: state.groupsManagement.privilegesSource,
		isRemovingPrivileges: state.groupsManagement.isRemovingPrivileges,
		roles: state.authorization.roles,
	}),
	{
		createNewPrivilege: groupsManagementActions.createNewPrivilege,
		removeNewPrivilege: groupsManagementActions.removeNewPrivilege,
		handleOpenNewPrivilege: groupsManagementActions.handleOpenNewPrivilege,
		handleChangeChild: groupsManagementActions.handleChangeChild,
		assignChild: groupsManagementActions.assignChild,
		removePrivilege: groupsManagementActions.removePrivilege,
		handleOpenPrivilegeSource: groupsManagementActions.handleOpenPrivilegeSource,
	},
)(PrivilegeHandler)
