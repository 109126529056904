import { range } from 'ramda'
import { axiosInstance } from '../../services/axiosInstance'
import { Asset, AssetSearchResponse, AssetsSearchQuery } from './assetsService.types'

const PAGE_SIZE: number = 500

export const assetsService = {
	async search<CustomAsset extends Asset = Asset>(query: AssetsSearchQuery): Promise<AssetSearchResponse<CustomAsset>> {
		const { data } = await axiosInstance.post<AssetSearchResponse<CustomAsset>>('/assets/search/', {
			...query,
			pageSize: PAGE_SIZE,
			sortKey: 'tag_code',
			page: 1,
		})

		const pages: number = Math.ceil(data.count / PAGE_SIZE)

		if (pages > 1) {
			const responses = await Promise.all(
				range(2, pages + 1).map((pageNumber) =>
					axiosInstance
						.post<AssetSearchResponse<CustomAsset>>('/assets/search/', {
							...query,
							pageSize: PAGE_SIZE,
							sortKey: 'tag_code',
							page: pageNumber,
						})
						// eslint-disable-next-line @typescript-eslint/no-shadow
						.then(({ data }) => data),
				),
			)
			return [data, ...responses].reduce(
				(pV, cV) => ({
					...pV,
					result: pV.result.concat(cV.result),
				}),
				{ count: data.count, result: [] },
			)
		}
		return data
	},
}
