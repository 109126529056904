import { TextField } from '@mui/material'
import { get } from 'lodash'
import { FC, memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AttributeType } from 'src/facade/forms/formsService.types'
import { AttributeAutocompleteInput } from 'src/forms/_shared/attribute/AttributeAutocompleteInput'
import { AttributeInputProps } from 'src/forms/_shared/attribute/AttributeInput'
import { MainFormForm } from 'src/forms/_shared/main-form/MainForm'

export const AttributeTextInput: FC<AttributeInputProps> = memo((props) => {
	const { control, formState } = useFormContext<MainFormForm>()

	if (props.attribute.predetermined_list) {
		return <AttributeAutocompleteInput {...props} />
	}

	const { attribute, name } = props

	return (
		<Controller
			name={name}
			control={control}
			defaultValue=""
			rules={{
				required: { value: attribute.mandatory, message: 'Field is required.' },
				...(props.attribute.attribute_type === AttributeType.Numeric
					? ({
							validate: (value: any) => (isNaN(+value) ? 'Value has to be a number.' : undefined),
					  } as any)
					: {}),
			}}
			render={({ field }) => (
				<TextField
					{...field}
					fullWidth
					required={attribute.mandatory}
					disabled={props.disabled}
					id={attribute.id}
					label={attribute.display_name}
					InputLabelProps={{ shrink: true }}
					inputProps={
						props.attribute.attribute_type === AttributeType.Numeric ? { inputMode: 'numeric', pattern: '[0-9]*' } : {}
					}
					variant="standard"
					size="medium"
					margin="normal"
					error={!!get(formState.errors, name)}
					helperText={get(formState.errors, name)?.message}
				/>
			)}
		/>
	)
})

AttributeTextInput.displayName = 'AttributeTextInput'
