import { ConfirmationDialog } from 'src/components/ConfirmationDialog'
import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import deleteIcon from '../../../assets/icons/delete.svg'
import deleteGrayIcon from '../../../assets/icons/deleteGray.svg'
import { ICellRendererParams, GridApi } from 'ag-grid-community'

const StyledImage = styled.img<{ disabled?: boolean }>`
	width: 16px;
	height: 16px;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export interface ActionRendererProps extends ICellRendererParams {
	removeOptions: { canRemove?: boolean; onRemove?(row: any, gridApi?: GridApi): void; withConfirmation?: boolean }
}

export const ActionRenderer = forwardRef<any, ActionRendererProps>(
	({ removeOptions: { canRemove, onRemove, withConfirmation }, data, api }, ref) => {
		const [isOpen, setIsOpen] = useState(false)

		const handleDialogOpen = () => {
			setIsOpen(true)
		}

		const handleDialogClose = () => {
			setIsOpen(false)
		}
		const handleOnConfirmed = () => {
			onRemove?.(data, api)
		}

		return (
			<div ref={ref}>
				<StyledImage
					disabled={!canRemove}
					onClick={() => {
						if (canRemove) {
							if (withConfirmation) {
								handleDialogOpen()
							} else {
								onRemove?.(data, api)
							}
						}
					}}
					src={canRemove ? deleteIcon : deleteGrayIcon}
					alt="delete"
				/>
				<ConfirmationDialog
					open={isOpen}
					onClose={handleDialogClose}
					onConfirmed={handleOnConfirmed}
					title="Confirm deletion?"
					subtitle="Are you sure you want to delete this?"
				></ConfirmationDialog>
			</div>
		)
	},
)

ActionRenderer.displayName = 'ActionRenderer'
