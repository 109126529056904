import { ICellRendererParams } from 'ag-grid-community'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { isPromise } from 'rxjs/internal-compatibility'
import styled from 'styled-components'
import LGButton from '../LGButton'
import { StatusBarConfig } from './DataGrid'

export interface StatusBarRightParams extends ICellRendererParams, StatusBarConfig {}

const Wrapper = styled.div`
	display: flex;
`

// eslint-disable-next-line react/display-name
export const StatusBarRight = forwardRef<any, StatusBarRightParams>((_, ref) => {
	const [config, setConfig] = useState<StatusBarConfig>({})
	const [saving, setSaving] = useState<boolean>(false)

	useImperativeHandle(ref, () => {
		return {
			setConfig: (c: Partial<StatusBarConfig>) => {
				setConfig(c)
			},
		}
	})

	const handleSave = async () => {
		if (isPromise(config?.onSave)) {
			setSaving(true)
			try {
				await config.onSave()
			} finally {
				setSaving(false)
			}
		} else {
			config?.onSave?.()
		}
	}

	return (
		<Wrapper>
			<LGButton
				isWhiteText
				disabled={config.autoSave || !config.canSave}
				margin="0 10px 10px 0"
				padding="0 20px"
				text="Save"
				height={32}
				isLoading={config.isSaving || saving}
				onClick={handleSave}
			/>
		</Wrapper>
	)
})
