import { createAction, createSlice } from '@reduxjs/toolkit'
import { fetchProfileThunk } from 'src/features/profile/fetchProfileThunk'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { ASSIGN_USERNAME } from './constants'

export type AuthorizationActions = ActionType<typeof actions>

export const Roles = {
	'Documents:assets.Roles.Creator': 'Documents Creator',
	'AIMS:roles.Sysadmin': 'System Admin',
	'Documents:assets.Roles.Admin': 'Documents Admin',
	'Assets:assets.Roles.Admin': 'Assets Admin',
	'Assets:assets.Roles.Creator': 'Assets Creator',
	'Assets:assets.Roles.Approver': 'Assets Approver',
	'Assets:assets.Roles.Viewer': 'Assets Viewer',
	'Registers:roles.Registers.WorkflowManager': 'Registers:roles.Registers.WorkflowManager',
	'Registers:roles.Registers.Viewer': 'Registers:roles.Registers.Viewer',
	'Registers:roles.Registers.Creator': 'Registers:roles.Registers.Creator',
	'Registers:roles.Registers.WorkflowAdmin': 'Registers:roles.Registers.WorkflowAdmin',
	'3D:Viewer': '3D:Viewer',
	'3D:Admin': '3D:Viewer',
	'System:Monitor': 'System:Monitor',
	'Atlas:Admin': 'Atlas:Admin',
	'Atlas:Creator': 'Atlas:Creator',
	'Atlas:Viewer': 'Atlas:Viewer',
}

export interface AuthState {
	authorizationCommunicate: string
	roles: string[]
	username: string
	isSingingIn: boolean
	isLogin: boolean
	callName: { amount: number; name: string }
	error: any | null
}

const initialState: AuthState = {
	authorizationCommunicate: '',
	roles: [],
	username: '',
	isSingingIn: false,
	isLogin: false,
	callName: { amount: 0, name: '' },
	error: null,
}

export const authFailed = createAction<any>('auth/fail')

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(ASSIGN_USERNAME, (state, action: any) => {
				state.username = action.payload
			})

			.addMatcher(fetchProfileThunk.rejected.match, (state, action: any) => {
				state.error = action.payload
			})
			.addMatcher(fetchProfileThunk.fulfilled.match, (state, action: any) => {
				// @ts-ignore
				state.roles = action.payload.profile.roles.map((role: string) => Roles[role])
			})
	},
})

export const authReducer = authSlice.reducer
