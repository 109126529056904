/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import { FontWeightType } from '../../../typings/styledComponents'

enum FontWeight {
	light = 300,
	regular = 500,
	bold = 700,
}

interface IStyledLabel {
	small?: boolean
	error?: boolean
	dropdown?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
}

const StyledLabel = styled.label<IStyledLabel>`
	font-weight: ${({ fontWeight }) => (fontWeight ? FontWeight[fontWeight] : 'bold')};
	font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
	line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '19px')};
	margin-bottom: 16px;

	${({ small }) =>
		small &&
		css`
			font-weight: 500;
		`}

	${({ error }) =>
		error &&
		css`
			color: ${({ theme }) => theme.colors.danger100};
		`}

	${({ dropdown }) =>
		dropdown &&
		css`
			color: ${({ theme }) => theme.colors.main100};
		`}
`

function TextLabel({
	label,
	small,
	error,
	dropdown,
	fontWeight,
	fontSize,
	lineHeight,
	className,
}: {
	label: string
	small?: boolean
	error?: boolean
	dropdown?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
	className?: string
}) {
	return (
		<StyledLabel
			small={small}
			className={className}
			error={error}
			dropdown={dropdown}
			fontSize={fontSize}
			fontWeight={fontWeight}
			lineHeight={lineHeight}
		>
			{label}
		</StyledLabel>
	)
}

export default TextLabel
