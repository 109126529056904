import { createApi } from '@reduxjs/toolkit/query/react'
import { didpAssetsService } from 'src/facade/assets/didp/didpAssetsService'
import { DidpMetadata } from 'src/facade/assets/didp/didpAssetsService.types'
import { axiosBaseQuery } from 'src/utils/axiosBaseQuery'

export const didpApi = createApi({
	reducerPath: 'didpApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		fetchMetadata: builder.query<DidpMetadata, string>({
			query: (contract) => () => didpAssetsService.fetchMetadata(contract),
			keepUnusedDataFor: 0,
		}),
	}),
})

export const { useFetchMetadataQuery: useFetchDidpMetadataQuery } = didpApi
