/* eslint-disable react/no-children-prop */
import React from 'react'
import Popup from '../../Organisms/Popup'
import { Field, Form, Formik } from 'formik'
import LGButton from '../../Molecules/LGButton'
import { groupsManagementActions } from '../../../features/groupsManagement'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

import * as Yup from 'yup'

const PopupContainer = styled.div`
	padding: 15px;
	max-width: 400px;
	.header {
		padding: 10px 0;
		font-weight: bold;
	}
	.checkbox {
		margin-top: 12px;
	}
	.registerInputHeader {
		margin: 7px 0;
		color: ${colors.black50};
		font-size: 15px;
	}
	form {
		color: ${colors.black50};
	}
	.errorMessage {
		padding: 15px 0;
		font-size: 12px;
		color: ${colors.danger100};
	}
	.registerInputContainer {
		display: flex;
		border-radius: 5px;
		padding: 0 10px;
		justify-content: center;
		width: 100%;
		min-width: 300px;
		background: ${colors.lightGreyE0};
		border: none;
		height: 30px;
		div {
			display: flex;
			flex-direction: column;
		}
	}
`

const CreateGroupSchema = Yup.object().shape({
	groupTitle: Yup.string().min(2, 'too short!').max(50, 'too long!').required('required'),
	project_organisation: Yup.bool(),
})

const promptFormErrors = (formErrors: any) => {
	const errorInfo = (
		<div>
			<div>{`${formErrors.groupTitle ? `Group title ${formErrors.groupTitle}` : ''}`}</div>
		</div>
	)
	return errorInfo
}

const NewGroupPopup = ({
	handleNewGroupPopup,
	createNewGroup,
}: {
	handleNewGroupPopup: typeof groupsManagementActions.handleNewGroupPopup
	createNewGroup: typeof groupsManagementActions.createNewGroup
}) => {
	return (
		<Popup
			onClick={() => {
				handleNewGroupPopup(false)
			}}
			isCloseButton
			children={
				<PopupContainer>
					<div className="header">Create New Group:</div>

					<Formik
						initialValues={{
							groupTitle: '',
							project_organisation: false,
						}}
						validationSchema={CreateGroupSchema}
						onSubmit={(values) => {
							createNewGroup({
								title: values.groupTitle,
								project_organisation: values.project_organisation,
							})
						}}
					>
						{({ values, errors, handleChange }) => (
							<Form>
								<div className="registerInputHeader">Group Title</div>
								<Field
									autoComplete="off"
									className="registerInputContainer"
									name="groupTitle"
									id="groupTitle"
									placeholder="Group Title"
									type="text"
									value={values.groupTitle}
									onChange={handleChange}
								/>
								<input
									type="checkbox"
									className="checkbox"
									name="project_organisation"
									id="project_organisation"
									checked={values.project_organisation}
									onChange={handleChange}
								/>
								Add this to Project Organisation
								<div className="errorMessage">{errors ? promptFormErrors(errors) : ''}</div>
								<LGButton
									isLoading={false}
									disabled={values.groupTitle.length < 2 || Object.keys(errors).length > 0}
									minWidth="100%"
									type="submit"
									text="Save New Group"
									padding="10px 25px"
									isWhiteText
								/>
							</Form>
						)}
					</Formik>
				</PopupContainer>
			}
		/>
	)
}

export default NewGroupPopup
