/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import Headline from '../../Atoms/Headline'
import Image from '../../Atoms/Image'
import arrowDownImage from '../../../assets/icons/arrowDown.svg'
import checkIcon from '../../../assets/icons/check24Green.svg'
import deleteIcon from '../../../assets/icons/delete.svg'
import deleteGray from '../../../assets/icons/deleteGray.svg'

const MainWrapper = styled.div`
	margin-bottom: 20px;
`

const HeaderWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.main10};
	border-radius: 4px;
	padding: 20px 25px 20px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.buttons {
		display: flex;
		img {
			cursor: pointer;
		}
		img:nth-child(2n) {
			margin-left: 10px;
		}
	}
`

const HeadlineWrapper = styled.div`
	display: flex;
	align-items: center;
`

const ImageWrapper = styled.div`
	margin-right: 13px;
`

const ContentWrapper = styled.div`
	padding-top: 30px;
`

function Accordion({
	title,
	children,
	isOpen,
	isChecked,
	isArrow,
	isRemove,
	onRemove,
	handleOpen,
	isRemovingDisabled,
}: {
	title: string
	children: any
	isOpen: boolean
	isChecked?: boolean
	handleOpen?: () => void
	isArrow?: boolean
	isRemove?: boolean
	isRemovingDisabled?: boolean
	onRemove?: () => void
}) {
	return (
		<MainWrapper>
			<HeaderWrapper>
				<HeadlineWrapper>
					{isChecked && (
						<ImageWrapper>
							<Image imageSrc={checkIcon} alt="check-icon" />
						</ImageWrapper>
					)}
					<Headline title={title} extraSmall />
				</HeadlineWrapper>
				<div className="buttons">
					{isRemove && (
						<Image
							width="15px"
							height="15px"
							imageSrc={isRemovingDisabled ? deleteGray : deleteIcon}
							alt="delete-icon"
							onClick={() => (isRemovingDisabled ? '' : onRemove ? onRemove() : '')}
						/>
					)}
					{isArrow && (
						<Image
							onClick={handleOpen}
							imageSrc={arrowDownImage}
							alt="arrow-down-icon"
							customRotate={isOpen ? 0 : 180}
						/>
					)}
				</div>
			</HeaderWrapper>
			{isOpen && <ContentWrapper>{children}</ContentWrapper>}
		</MainWrapper>
	)
}

export default Accordion
