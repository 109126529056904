import { IDisciplineOverview } from '../../models/disciplineOverview/disciplineOverview'

export const initialsForDisciplines: IDisciplineOverview = {
	nominateSelected: [],
	disciplineHistory: [],
	didpOwner: [],
	nominatedDidpOwner: [],
	replies: [],
	oneDeliverableTasks: [],
	didpTagCode: '',
	tidpTagCode: '',
	isNominatedOpen: false,
	isRenamingDeliverables: false,
	isLoadingDeliverables: false,
	isActionProcessing: false,
	isInforming: false,
	isLoadingDeliverable: false,
	isLinking: false,
	isNominating: false,
	isOpenDeliverablePopup: false,
	isChangingDeliverable: false,
	isLoadingSelectedDeliverable: false,
	didpStatus: '',
	disciplineActions: [
		{
			name: 'Accept Discipline',
			roles: ['TIDP Owner'],
			states: ['Ready for Acceptance'],
		},
		{
			name: 'Reject Discipline',
			roles: ['TIDP Owner'],
			states: ['Ready for Acceptance'],
		},
		{
			name: 'Accept Ownership',
			roles: ['DIDP Owner'],
			states: ['Created'],
		},
		{
			name: 'Reject Discipline Ownership',
			roles: ['DIDP Owner'],
			states: ['Created'],
		},
		{
			name: 'Nominate Additional Owner',
			roles: ['DIDP Owner'],
			states: ['Created', 'WIP'],
		},
		{
			name: 'Send to TIDP Owner for Acceptance',
			roles: ['DIDP Owner'],
			states: ['WIP'],
		},
	],
	disciplineOwner: [
		{
			id: 1,
			isOpen: true,
			name: 'Overview',
			tabs: [],
		},
		{
			id: 2,
			isOpen: false,
			name: 'Contract Data',
			tabs: [],
		},
		{
			id: 3,
			isOpen: false,
			name: 'Asset & Work Package',
			tabs: [],
		},
		{
			id: 4,
			isOpen: false,
			name: 'Dates',
			tabs: [],
		},
		{
			id: 5,
			isOpen: false,
			name: 'TIDP Details',
			tabs: [],
		},
	],
	deliverables: {
		header: [
			{
				id: 'name',
				name: 'Deliverable title',
				type: 'text',
			},
			{
				id: 'row1',
				name: 'Document Number',
				type: 'text',
			},
			{
				id: 'row2',
				name: 'Document Type',
				options: [],
				type: 'select',
			},
			{
				id: 'row3',
				name: 'HS2 Deliverable',
				type: 'select',
			},
			{
				id: 'row4',
				name: 'HS2 Issue Reason',
				type: 'select',
			},
			{
				id: 'row5',
				name: 'IDR Submission',
				type: 'select',
			},
		],
		body: [],
		newDeliverableFields: {},
	},
	isCreatingNewDeliverable: false,
	newCommentText: '',
	comments: [
		// 	{
		// 	comment: 'string',
		// 	recordBy: 'string',
		// 	recordAt: new Date(),
		// 	status: 'string',
		// 	reply: []
		// }
	],
	isSavingNewDeliverable: false,
	newReply: {
		id: '',
		comment: '',
	},
	isNewComment: false,
}
