import { createApi } from '@reduxjs/toolkit/query/react'
import { head } from 'ramda'
import { appFormConverter, AppMainForm } from 'src/facade/forms/appFormConverter'
import { FetchFormsResponse, formsService } from 'src/facade/forms/formsService'
import { axiosBaseQuery } from 'src/utils/axiosBaseQuery'

export const formsApi = createApi({
	reducerPath: 'formsApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		fetchForm: builder.query<AppMainForm | null, { id: string; contract: string }>({
			query:
				({ id, contract }) =>
					() =>
						formsService.fetch(id, { contract }),
			transformResponse: (response: FetchFormsResponse) => {
				return appFormConverter(head(Object.values(response))?.forms || [])
			},
		}),
	}),
})

export const { useFetchFormQuery } = formsApi
