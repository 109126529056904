import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	TextField,
} from '@mui/material'
import { isEmpty } from 'ramda'
import { FC, Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CellProps } from 'react-table'
import { NumberMask } from 'src/facade/forms/formsService.types'
import { IFormDataObject } from 'src/models/formsHandler/formsHandler'
import { NUMBER_REGEX } from 'src/utils/numberRegex'

const numberValidate = (val: string) => (!val ? true : !val.match(NUMBER_REGEX) ? 'Value has to be a number' : true)

export const NumberMaskRenderer: FC<
	CellProps<IFormDataObject> & {
		headerId: string
		isDisabled: boolean
		onEditRow: (item: { id: string | number; keyName: string; value: any }) => void
	}
> = ({ value, row, headerId, isDisabled, onEditRow }) => {
	const [open, setOpen] = useState(false)

	const { reset, control, handleSubmit, formState } = useForm<NumberMask>({ defaultValues: {} })

	useEffect(() => {
		if (!isEmpty(value?.value) && !!value?.value) {
			reset(value.value)
		}
	}, [reset, value])

	const label =
		!isEmpty(value?.value) && !!value?.value
			? `~NumberValidation(${value?.value.mask}, ${value?.value.rangeLow}, ${value?.value.rangeHigh}, ${
					value?.value.displayMask ? value?.value.displayMask : 'null'
			  }, ${value?.value.enforce ? value?.value.enforce : 'null'})`
			: 'add'

	const onSubmit = (form: NumberMask) => {
		onEditRow({
			id: row.original.id,
			keyName: headerId,
			value: form,
		})
		setOpen(false)
	}

	return (
		<Fragment>
			<Button
				disabled={isDisabled}
				onClick={() => setOpen(true)}
				sx={{ textAlign: 'left', fontSize: 12, lineHeight: 1.2, width: '100%' }}
			>
				{label}
			</Button>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogTitle>Number mask</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Controller
									control={control}
									name="mask"
									defaultValue=""
									rules={{
										required: 'Field is required.',
									}}
									render={({ field }) => (
										<TextField
											{...field}
											margin="none"
											label="Number mask *"
											fullWidth
											variant="standard"
											error={!!formState.errors.mask}
											helperText={formState.errors.mask?.message}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									control={control}
									name="rangeLow"
									defaultValue=""
									rules={{
										required: 'Field is required.',
										validate: numberValidate,
									}}
									render={({ field }) => (
										<TextField
											{...field}
											margin="none"
											label="Valid range low *"
											fullWidth
											variant="standard"
											error={!!formState.errors.rangeLow}
											helperText={formState.errors.rangeLow?.message}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									control={control}
									name="rangeHigh"
									defaultValue=""
									rules={{
										required: 'Field is required.',
										validate: numberValidate,
									}}
									render={({ field }) => (
										<TextField
											{...field}
											margin="none"
											label="Valid range high *"
											fullWidth
											variant="standard"
											error={!!formState.errors.rangeHigh}
											helperText={formState.errors.rangeHigh?.message}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									control={control}
									name="displayMask"
									defaultValue=""
									render={({ field }) => (
										<TextField {...field} margin="none" label="Display mask" fullWidth variant="standard" />
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									control={control}
									name="enforce"
									defaultValue={false}
									render={({ field }) => {
										return <FormControlLabel {...field} checked={field.value} label="Enforce" control={<Checkbox />} />
									}}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpen(false)}>Cancel</Button>
						<Button type="submit">Save</Button>
					</DialogActions>
				</form>
			</Dialog>
		</Fragment>
	)
}
