import { DidpMetadata } from 'src/facade/assets/didp/didpAssetsService.types'
import { AimsModule } from 'src/features/authorization/constants'
import { axiosInstance } from 'src/services/axiosInstance'

export const didpAssetsService = {
	async fetchMetadata(contract: string): Promise<DidpMetadata> {
		const { data } = await axiosInstance.get<DidpMetadata>('/assets/attribute/metadata/', {
			params: {
				class_code: AimsModule.DIDP,
				contract,
			},
		})
		return data
	},
}
