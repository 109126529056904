import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import { getUserByRole } from 'src/services/users'
import { isOfType } from 'typesafe-actions'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import { getListOfUsersByRole } from './actions'
import { usersActions, usersConstans } from './index'

export const getUserByRoleEpic: AppEpicDeprecated<ReturnType<typeof getListOfUsersByRole>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersConstans.GET_USER_BY_ROLE)),
		withLatestFrom(state$),
		mergeMap(([action]) => {
			return concat(
				of(usersActions.handleLoadingUsers(true)),
				of(usersActions.assignListOfUsers([])),
				fromPromise(getUserByRole(action.payload)).pipe(
					switchMap((response) => {
						return concat(
							of(
								usersActions.assignListOfUsers(
									response.results.map((item: { username: string }) => ({
										value: item.username,
										label: item.username,
									})),
								),
							),
							of(usersActions.handleLoadingUsers(false)),
						)
					}),
					catchError((err) => {
						return concat(
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: usersActions.getListOfUsersByRole(action.payload),
								}),
							),
							of(usersActions.handleLoadingUsers(false)),
						)
					}),
				),
			)
		}),
	)

export default combineEpics(getUserByRoleEpic)
