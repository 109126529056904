import { range } from 'ramda'
import { axiosInstance } from '../../services/axiosInstance'
import { SearchUser, UserData, UserDomain } from './usersService.types'

const PAGE_SIZE: number = 500

export const usersService = {
	async fetchUserData(): Promise<UserData> {
		const { data } = await axiosInstance.get('/user/user_data/')
		return data
	},
	async fetchUserDomain(email: string): Promise<UserDomain> {
		const { data } = await axiosInstance.get('/user/user_type/', {
			params: {
				email,
			},
		})
		return data
	},
	async fetch(): Promise<SearchUser[]> {
		const { data } = await axiosInstance.get('/user/search', {
			params: {
				page_size: PAGE_SIZE,
				page: 1,
			},
		})

		const pages: number = Math.ceil(data.count / PAGE_SIZE)

		if (pages > 1) {
			const responses = await Promise.all(
				range(2, pages + 1).map((pageNumber) =>
					axiosInstance
						.get('/user/search', {
							params: {
								page_size: PAGE_SIZE,
								page: pageNumber,
							},
						})
						// eslint-disable-next-line @typescript-eslint/no-shadow
						.then(({ data }) => data),
				),
			)
			return [data, ...responses].reduce((pV, cV) => pV.concat(cV.results), [])
		}
		return data.results
	},
}
