/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react'
import styled, { css } from 'styled-components'
import ListItem from '../ListItem'
import TextLink from '../TextLink'
import Image from '../Image'
import commentsIcon from '../../../assets/icons/comments.svg'
import { colors } from '../../../theme/colors'
import information from '../../../assets/icons/information.svg'
import Tooltip from '../../Molecules/Tooltip'
import SpinnerComponent from '../Loader'
import pendingIcon from '../../../assets/icons/pending.svg'
import errorIcon from '../../../assets/icons/exclamationMark.svg'
import doneIcon from '../../../assets/icons/done.svg'
import LGButton from '../../Molecules/LGButton'

interface IMainWrapper {
	white?: boolean
	grey?: boolean
	header?: boolean
	small?: boolean
	icon?: boolean
	noSetWidth?: boolean
	minWidth?: string
	isError?: boolean
	isEye?: boolean
	hasEndAdornment?: boolean
}

const TooltipText = styled.div<IMainWrapper>`
	padding: 5px;
	display: flex;
	max-width: 200px;
	overflow-wrap: break-word;
	justify-content: center;
	flex-wrap: wrap;
`

const StatusWrapper = styled.div<IMainWrapper>`
	position: absolute;
	display: flex;
	align-items: center;
	right: 0;
	margin: 0 5px;
	button {
		margin: 0;
		margin-left: 5px;
	}
`

const MainWrapper = styled.div<IMainWrapper>`
	position: relative;
	min-width: ${({ header, noSetWidth, minWidth }) => minWidth || (header ? '128px' : noSetWidth ? 'unset' : '240px')};
	border-radius: 8px;
	padding: ${({ header }) => (header ? '8px 5px' : '10px 25px')};
	background-color: ${({ white, theme }) => (white ? colors.white : theme.colors.main5)};
	border: ${({ isError }) => (isError ? `1px solid ${colors.danger50}` : '1px solid transparent')};
	text-align: ${({ header }) => (header ? 'center' : 'left')};
	display: flex;
	align-items: center;
	justify-content: ${({ header, isEye, hasEndAdornment }) =>
		header ? 'center' : isEye || hasEndAdornment ? 'space-between' : ''};
	.eyeImage {
		cursor: pointer;
	}
	${({ small }) =>
		small &&
		css`
			min-width: 120px;
			height: 32px;
			padding: 0 12px;
		`}

	${({ icon }) =>
		icon &&
		css`
			min-width: unset;
			height: 32px;
			padding: 0 10px;
			justify-content: center;
		`}
`

const statusIcons = [
	{
		name: 'In queue',
		icon: pendingIcon,
	},
	{
		name: 'Error',
		icon: errorIcon,
	},
	{
		name: 'Done',
		icon: doneIcon,
	},
	{
		name: 'Empty',
		icon: '',
	},
]

function Cell({
	text,
	white,
	header,
	small,
	icon,
	centered,
	noSetWidth,
	className,
	minWidth,
	isError,
	isEye,
	eyeInfo,
	cellStatus,
	showRefreshStatus,
	onStatusRefresh,
	endAdornment,
}: {
	text: string
	white?: boolean
	header?: boolean
	small?: boolean
	icon?: boolean
	centered?: boolean
	minWidth?: string
	noSetWidth?: boolean
	className?: string
	isError?: boolean
	showRefreshStatus?: boolean
	isEye?: boolean
	eyeInfo?: string
	cellStatus?: string
	onStatusRefresh?: () => void
	endAdornment?: JSX.Element
}) {
	const checkStatus = statusIcons.filter((el) => el.name.toLowerCase() === cellStatus?.toLowerCase())
	return (
		<MainWrapper
			isError={isError}
			minWidth={minWidth}
			white={white}
			header={header}
			small={small}
			icon={icon}
			noSetWidth={noSetWidth}
			className={className}
			isEye={isEye}
			hasEndAdornment={!!endAdornment}
		>
			{header ? (
				icon ? (
					<Image imageSrc={commentsIcon} alt="" />
				) : (
					<TextLink title={text} md />
				)
			) : (
				<ListItem text={text} small={small} centered={centered} />
			)}
			{endAdornment && <div style={{ marginLeft: '4px' }}>{endAdornment}</div>}
			{isEye && eyeInfo && (
				<Tooltip placement="right" hideArrow tooltip={<TooltipText>{eyeInfo}</TooltipText>}>
					<Image width="100%" height="100%" className="eyeImage" imageSrc={information} alt="eye" />
				</Tooltip>
			)}
			{cellStatus && (
				<StatusWrapper>
					{cellStatus.toLowerCase() === 'in progress' && <SpinnerComponent size={15} />}
					{checkStatus.length > 0 && (
						<Image isClickable width="20px" height="20px" imageSrc={checkStatus[0].icon} alt="status_for_deliverable" />
					)}
					{showRefreshStatus &&
						checkStatus.length > 0 &&
						(checkStatus[0].name.toLowerCase() === 'error' || checkStatus[0].name.toLowerCase() === 'empty') && (
							<LGButton onClick={onStatusRefresh} isWhiteText text="Refresh" margin="0" padding="2px" />
						)}
				</StatusWrapper>
			)}
		</MainWrapper>
	)
}

export default Cell
