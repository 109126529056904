/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
import classNames from 'classnames'
import React, { memo, MouseEvent } from 'react'
import { Column, Row, useFlexLayout, useSortBy, UseSortByColumnOptions, useTable } from 'react-table'
import styled, { css } from 'styled-components'
import arrowDownImage from '../../../assets/icons/arrowDown.svg'
import cancel from '../../../assets/icons/cancel.svg'
import deleteIcon from '../../../assets/icons/delete.svg'
import deleteGray from '../../../assets/icons/deleteGray.svg'
import pencil from '../../../assets/icons/pencil.svg'
import pencilGray from '../../../assets/icons/pencilGray.svg'
import { colors } from '../../../theme/colors'
import Image from '../../Atoms/Image'
import SpinnerComponent from '../../Atoms/Loader'

const ImgTd = styled.td`
	display: flex;
	flex-direction: column;
	justify-content: center;
	img:nth-child(2) {
		margin-top: 10px;
	}
`
const DivSpaceForHeaderTrash = styled.div`
	width: 28px;
`
const StyledImage = styled.img`
	width: 12px;
	cursor: pointer;
	height: 12px;
`
const StyledTable = styled.div<{ fullWidth?: boolean; isAdditional?: boolean }>`
	#additional > div {
		background: transparent !important;
	}
	table {
		font-family: ${({ theme }) => theme.fonts.mainFont};
		font-weight: 500;
		vertical-align: middle;
		font-size: 12px;
		border-spacing: 0;

		${({ fullWidth }) =>
			fullWidth &&
			css`
				width: 100%;
			`}

		thead {
			th {
				background: #013ca60d;
				border: none;
			}

			.thContainer {
				background: ${({ theme }) => theme.colors.white};
				position: relative;
				border-radius: 8px;
				padding: 0 24px 0 16px;
				height: 48px;
				margin: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		tbody {
			tr:not(.noResults, #additional) {
				background: white;

				&.hasClickEvent {
					cursor: pointer;
				}

				&:nth-child(even) > td {
					background: rgba(1, 60, 166, 0.02);
				}

				&:hover > td {
					background-color: rgba(1, 60, 166, 0.05);
				}
			}

			td {
				transition: all 50ms ease-in;
				border: none;
				padding: 8px 8px;
				cursor: pointer;
			}
			.noResults {
				td {
					text-align: center;
					padding-top: 24px;
					padding-bottom: 24px;
					cursor: auto;
				}
			}
		}

		.tbodyLoader {
			background: ${colors.lightGreyF10};
			tr {
				background: transparent !important;
			}
		}
	}
`

const StyledArrow = styled.span`
	position: absolute;
	right: 12px;
`
const SpinnerTd = styled.td`
	margin-top: 15px;
	display: flex;
	justify-content: center;
`

export const StyledTdContainer = styled.div<{
	center?: boolean
	background?: string
	padding?: string
	width?: string
}>`
	display: flex;
	align-items: center;
	height: 100%;
	min-height: 28px;
	padding: ${({ padding }) => padding || '8px 24px'};
	width: ${({ width }) => width || '100%'};
	background: ${({ background }) => background || '#013ca60d'};
	border-radius: 8px;
	${({ center }) =>
		center &&
		css`
			justify-content: center;
		`}
`

type RowStatus = 'original' | 'new' | 'modified' | 'deleted' | 'to_add'

export interface RowMetadata {
	status?: RowStatus
}

export type CustomColumn = {
	style?: Partial<{
		background?: any
		center: boolean
	}>
	options?: {
		noHeaderTdContainer?: boolean
		tdCursor?: string
	}
	noTdContainer?: boolean
	additional?: any
}

export type MyColumn<D extends object = {}> = Column<D> & CustomColumn & UseSortByColumnOptions<D>

export interface MyTableProps<TRow extends RowMetadata> {
	columns: MyColumn<TRow>[]
	data: TRow[]
	fullWidth?: boolean
	isDeleteColumn?: boolean
	isEditingColumn?: boolean
	isLoading?: boolean
	isEditAvailable?: boolean
	nonEditableElements?: Array<{ isCancel: boolean; tag_code: string }>
	isDeleteAvailable?: boolean
	onClickDelete?: (row: any) => void
	onClickEdit?: (row: any) => void
	onCancelEdit?: (row: any) => void
	onClickRow?(event: MouseEvent<HTMLTableRowElement>, row: Row<TRow>): void
}

export const MyTable = memo(MyTableFunction) as typeof MyTableFunction

function MyTableFunction<TRow extends object = {}>({
	columns,
	data,
	isLoading,
	onClickRow,
	fullWidth,
	isDeleteAvailable,
	isDeleteColumn,
	onClickDelete,
	isEditingColumn,
	onClickEdit,
	isEditAvailable,
	nonEditableElements,
	onCancelEdit,
}: MyTableProps<TRow>) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			defaultColumn: {
				width: 100,
			},
		},
		useSortBy,
		useFlexLayout,
	)

	const deleteElement = (row: any) => {
		if (isDeleteColumn && onClickDelete && !isDeleteAvailable) {
			onClickDelete(row)
		}
	}

	const editElement = (row: any) => {
		if (isEditingColumn && onClickEdit && !isEditAvailable) {
			onClickEdit(row)
		}
	}

	const cancelEdit = (row: any) => {
		if (isEditingColumn && onCancelEdit && !isEditAvailable) {
			onCancelEdit(row)
		}
	}

	const isEditableElement: any = (row: any) => {
		return nonEditableElements
			? nonEditableElements?.filter((item: any) => {
					return item.tag_code === row.original.tagCode
			  })
			: null
	}

	return (
		<StyledTable fullWidth={fullWidth}>
			<table {...(getTableProps() as any)} cellSpacing="0" cellPadding="0">
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...(headerGroup.getHeaderGroupProps() as any)}>
							{headerGroup.headers.map((column) => {
								const { options } = column as CustomColumn

								const content = (
									<>
										{column.render('Header')}
										{(column as any).isSorted ? (
											<StyledArrow>
												<Image
													imageSrc={arrowDownImage}
													alt="arrow-down-icon"
													customRotate={(column as any).isSortedDesc ? 0 : 180}
												/>
											</StyledArrow>
										) : (
											''
										)}
									</>
								)

								return (
									// tslint:disable-next-line:jsx-key
									<th {...(column.getHeaderProps((column as any).getSortByToggleProps()) as any)}>
										{options && options.noHeaderTdContainer ? (
											<>{content}</>
										) : (
											<div className="thContainer">{content}</div>
										)}
									</th>
								)
							})}
							{isDeleteColumn && (
								<th>
									<DivSpaceForHeaderTrash />
								</th>
							)}
						</tr>
					))}
				</thead>
				{isLoading ? (
					<tbody className="tbodyLoader">
						<tr>
							<SpinnerTd>
								<SpinnerComponent size={100} />
							</SpinnerTd>
						</tr>
					</tbody>
				) : (
					<tbody {...(getTableBodyProps() as any)}>
						{rows.length === 0 && (
							<tr className="noResults">
								<td colSpan={100000}>There are no results</td>
							</tr>
						)}
						{rows.map((row, i) => {
							prepareRow(row)
							return (
								<React.Fragment key={`${row}_${i}`}>
									<tr
										{...(row.getRowProps() as any)}
										onClick={(e) => onClickRow && onClickRow(e, row)}
										className={classNames({ hasClickEvent: !!onClickRow })}
									>
										{row.cells.map((cell: any, index) => {
											const column: CustomColumn = cell.column as CustomColumn
											const { options } = column as CustomColumn
											return (
												<React.Fragment key={`${cell}_${i}_${index}`}>
													<td {...cell.getCellProps()}>
														{column.noTdContainer ? (
															<div style={{ cursor: options && options.tdCursor, height: '100%' }}>
																{cell.render('Cell')}
															</div>
														) : (
															<StyledTdContainer
																center={column.style && column.style.center}
																background={column.style && column.style.background}
															>
																{cell.render('Cell')}
															</StyledTdContainer>
														)}
													</td>
												</React.Fragment>
											)
										})}
										{(isDeleteColumn || isEditingColumn) && (
											<ImgTd>
												{isDeleteColumn && (
													<StyledImage
														onClick={() => deleteElement(row)}
														src={isDeleteAvailable ? deleteGray : deleteIcon}
														alt="delete"
													/>
												)}
												{isEditingColumn && isEditableElement(row) && isEditableElement(row)?.length === 0 && (
													<StyledImage
														onClick={() => editElement(row)}
														src={isEditAvailable ? pencilGray : pencil}
														alt="edit"
													/>
												)}
												{isEditingColumn &&
													isEditableElement(row) &&
													isEditableElement(row)?.length > 0 &&
													isEditableElement(row)[0]?.isCancel && (
														<StyledImage onClick={() => cancelEdit(row)} src={cancel} alt="edit" />
													)}
											</ImgTd>
										)}
									</tr>
									{row.original &&
										// @ts-ignore
										row.original.additionalComponent &&
										// @ts-ignore
										row.original.additionalComponent.filter((el: any) => el.id === row.original.id && el.isOpen)
											.length > 0 && (
											<tr>
												<td id="additional">
													{
														// @ts-ignore
														row.original.additionalComponent.filter(
															// @ts-ignore
															(el: any) => el.id === row.original.id && el.isOpen,
														)[0].component
													}
												</td>
											</tr>
										)}
								</React.Fragment>
							)
						})}
					</tbody>
				)}
			</table>
		</StyledTable>
	)
}
