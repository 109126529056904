import { GridApi } from 'ag-grid-community'
import { range, uniq } from 'ramda'

export function getSelectedRows(api: GridApi): any[] {
	const rowIndexes = uniq(
		(api.getCellRanges() || [])
			.map((cellRange) => {
				if (!cellRange.startRow || !cellRange.endRow) {
					return []
				}
				const startRow = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex)
				const endRow = Math.max(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex)
				return range(startRow, endRow + 1)
			})
			.flat(),
	)
	return rowIndexes.map((rowIndex) => api.getDisplayedRowAtIndex(rowIndex)?.data)
}
