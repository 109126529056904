import { TaskAction } from '../../services/tasksActionsService'
import { AsyncStatus } from '../../models/AsyncStatus'
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../utils/reduxUtils'

export interface TaskActionsState {
	loading: AsyncStatus
	actions: Record<string, any>
}

const initialState: TaskActionsState = {
	loading: 'idle',
	actions: {},
}

export const fetchTaskActions = createAction<string>('taskActions/fetch')
export const fetchTaskActionsSuccess = createAction<TaskAction[]>('taskActions/fetch/success')
export const fetchTaskActionsFail = createAction('taskActions/fetch/fail')

export const taskActionsSlice = createSlice({
	name: 'taskActions',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(fetchTaskActions.match, (state) => {
				state.loading = 'pending'
			})
			.addMatcher(fetchTaskActionsSuccess.match, (state, { payload }) => {
				state.loading = 'success'
				state.actions = {}
				state.actions = payload.reduce((pV, cV) => ({ ...pV, [cV.actionName]: cV }), {})
			})
			.addMatcher(fetchTaskActionsFail.match, (state) => {
				state.loading = 'fail'
				state.actions = {}
			})
	},
})

export interface TaskActionResolver {
	has(actionName: string): boolean
	getAll(): TaskAction[]
	loading: AsyncStatus
}

export const taskActionResolverSelector = createSelector(
	(state: RootState) => state.tasksActions2,
	({ actions, loading }): TaskActionResolver => {
		return {
			has(actionName: string) {
				return !!actions[actionName]
			},
			getAll() {
				return Object.values(actions)
			},
			loading,
		}
	},
)
export const taskActionsReducer = taskActionsSlice.reducer
