import camelcaseKeys from 'camelcase-keys'
import { axiosInstance } from 'src/services/axiosInstance'
import { REACT_ACTIONS } from './constants'

export interface FetchTasksActionsResponse {
	actions: TaskAction[]
}

export interface TaskAction {
	actionName: string
	taskId: number
}

export const tasksActionService = {
	async fetch(tagCode: string): Promise<TaskAction[]> {
		const { data } = await axiosInstance.get(`${REACT_ACTIONS}tag_code/?tag_code=${tagCode}`)
		return camelcaseKeys(data, { deep: true }).actions
	},
}
