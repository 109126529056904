enum StorageKey {
	LastVisitedPathname = 'aims__last-visited-pathname',
}

export const storageService = {
	setLastVisitedPathname(pathname: string): void {
		localStorage.setItem(StorageKey.LastVisitedPathname, pathname)
	},
	getLastVisitedPathname(): string | null {
		return localStorage.getItem(StorageKey.LastVisitedPathname)
	},
	removeLastVisitedPathname(): void {
		localStorage.removeItem(StorageKey.LastVisitedPathname)
	},
}
