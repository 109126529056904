/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useLocalStorage } from '@uidotdev/usehooks'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import { LoadingPage } from 'src/components/LoadingPage'
import { AccessDenied } from 'src/components/Pages/AccessDenied/AccessDenied'
import { fetchProfileThunk } from 'src/features/profile/fetchProfileThunk'
import { CreateForm } from 'src/forms/create-form/CreateForm'
import { EditForm } from 'src/forms/edit-form/EditForm'
import { useAppDispatch, useAppSelector } from 'src/utils/reduxUtils'
import PrivateRoute from './PrivateRoute'
import { LogoutPage } from './components/Molecules/LogoutPage'
import RefreshPage from './components/Molecules/RefreshPage'
import NoRolesUser from './components/Organisms/NoRoles'
import PageNotFound from './components/Organisms/PageNotFound'
import CreateNewTidp from './components/Pages/CreateNewTidp'
import DisciplineOverview from './components/Pages/DisciplineOverview/disciplineOverviewComponent'
import FormEdit from './components/Pages/FormEdit/FormEdit'
import FormCreate from './components/Pages/FormsCreate/FormsCreate'
import FormsList from './components/Pages/FormsList'
import GroupsManagement from './components/Pages/GroupsManagement'
import TIDPOverview from './components/Pages/TIDPOverview'
import TasksList from './components/Pages/TaskList'
import UserManagement from './components/Pages/UserManagement'
import { Roles } from './features/authorization/reducer'
import { controlsActions } from './features/controls'
import { Forms } from './forms/Forms'
import { IReduxState } from './models/redux/redux'
import { HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY, useDefaultModuleRedirect } from './utils/useDefaultModuleRedirect'

interface IWrapper extends RouteComponentProps {
	roles: string[]
	contract: any
	handleHistorySidebar: typeof controlsActions.handleHistorySidebar
}

export const pages = [
	{
		path: '/tasks-list',
		component: TasksList,
	},
	{
		path: '/overview/:param1',
		component: TIDPOverview,
	},
	{
		path: '/discipline-overview/:param1',
		component: DisciplineOverview,
	},
	{
		path: '/create-new-tidp',
		component: CreateNewTidp,
		roles: [Roles['Registers:roles.Registers.WorkflowManager']],
	},
	{
		path: '/user-management',
		component: UserManagement,
		roles: [Roles['AIMS:roles.Sysadmin']],
	},
	{
		path: '/groups-management',
		component: GroupsManagement,
		roles: [
			Roles['Documents:assets.Roles.Admin'],
			Roles['Assets:assets.Roles.Admin'],
			Roles['Registers:roles.Registers.WorkflowManager'],
			Roles['AIMS:roles.Sysadmin'],
		],
	},

	{
		path: '/forms/templates/create',
		component: FormCreate,
		roles: [Roles['Registers:roles.Registers.WorkflowAdmin']],
	},
	{
		path: '/forms/templates/:param1',
		component: FormEdit,
		roles: [Roles['Registers:roles.Registers.WorkflowAdmin'], Roles['Registers:roles.Registers.WorkflowManager']],
	},
	{
		path: '/forms/templates',
		component: FormsList,
		roles: [
			Roles['Registers:roles.Registers.WorkflowAdmin'],
			Roles['Registers:roles.Registers.WorkflowManager'],
			Roles['Assets:assets.Roles.Viewer'],
			Roles['Documents:assets.Roles.Creator'],
			Roles['Assets:assets.Roles.Creator'],
		],
	},
	{
		path: '/forms/create',
		component: CreateForm,
		roles: [
			Roles['Registers:roles.Registers.WorkflowAdmin'],
			Roles['Registers:roles.Registers.WorkflowManager'],
			Roles['Assets:assets.Roles.Viewer'],
			Roles['Documents:assets.Roles.Creator'],
			Roles['Assets:assets.Roles.Creator'],
		],
	},
	{
		path: '/forms/:id',
		component: EditForm,
		roles: [
			Roles['Registers:roles.Registers.WorkflowAdmin'],
			Roles['Registers:roles.Registers.WorkflowManager'],
			Roles['Assets:assets.Roles.Viewer'],
			Roles['Documents:assets.Roles.Creator'],
			Roles['Assets:assets.Roles.Creator'],
		],
	},
	{
		path: '/forms',
		component: Forms,
		roles: [
			Roles['Registers:roles.Registers.WorkflowAdmin'],
			Roles['Registers:roles.Registers.WorkflowManager'],
			Roles['Assets:assets.Roles.Viewer'],
			Roles['Documents:assets.Roles.Creator'],
			Roles['Assets:assets.Roles.Creator'],
		],
	},

	{
		path: '/no-roles',
		component: NoRolesUser,
	},
].map((item, index) => ({ ...item, id: index + 1 }))

let didInit = false

const Wrapper = (props: IWrapper) => {
	const dispatch = useAppDispatch()
	const { inProgress } = useMsal()
	const [hasCheckedDefaultModule] = useLocalStorage(HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY, false)
	const fetching = useAppSelector((state) => state.profile.fetching)
	const profile = useAppSelector((state) => state.profile.profile)

	useDefaultModuleRedirect()

	useEffect(() => {
		if (!didInit) {
			dispatch(fetchProfileThunk())
			didInit = true
		}
	}, [])

	useEffect(() => {
		props.handleHistorySidebar(false)
	}, [props.location])

	const { history } = props

	if (
		fetching === 'pending' ||
		fetching === 'idle' ||
		inProgress !== InteractionStatus.None ||
		(profile && !hasCheckedDefaultModule)
	) {
		return <LoadingPage />
	}

	if (fetching === 'fail') {
		return <AccessDenied />
	}

	return (
		<Switch>
			<PrivateRoute contract={props.contract} history={history} userRoles={props.roles} exact path="/" />
			<PrivateRoute contract={props.contract} history={history} userRoles={props.roles} path="/login" />
			{pages.map((item) => (
				<PrivateRoute
					contract={props.contract}
					history={history}
					key={item.id}
					userRoles={props.roles}
					componentRole={item.roles}
					path={item.path}
					component={item.component}
				/>
			))}
			<Route path="/logout" component={LogoutPage} />
			<Route path="/refresh/link=:param1" component={RefreshPage} />
			<Route component={PageNotFound} />
		</Switch>
	)
}

const AppWrapper = withRouter(Wrapper)
export default connect(
	(state: IReduxState) => ({
		roles: state.authorization.roles,
		contract: state.users.contract,
	}),
	{
		handleHistorySidebar: controlsActions.handleHistorySidebar,
	},
)(AppWrapper)
