/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import { FontWeightType } from '../../../typings/styledComponents'

enum FontWeight {
	light = 300,
	regular = 500,
	bold = 700,
}

interface ITextLink {
	title: string
	lg?: boolean
	md?: boolean
	mdBold?: boolean
	sm?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
	isEllipsis?: boolean
	black62?: boolean
	className?: string
	minWidth?: string
}

interface IStyledLink {
	lg?: boolean
	md?: boolean
	mdBold?: boolean
	sm?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
	black62?: boolean
	isEllipsis?: boolean
	minWidth?: string
}

const StyledLink = styled.span<IStyledLink>`
	font-family: ${({ theme }) => theme.fonts.mainFont};
	font-size: ${({ fontSize }) => `${fontSize}px`};
	line-height: ${({ lineHeight }) => `${lineHeight}px`};
	min-width: ${({ minWidth }) => minWidth};
	font-weight: ${({ fontWeight }) => (fontWeight ? FontWeight[fontWeight] : '500')};
	color: ${({ black62, theme }) => (black62 ? theme.colors.black62 : theme.fonts.mainColor)};
	display: flex;
	align-items: center;

	${({ sm }) =>
		sm &&
		css`
			font-size: 12px;
			line-height: 14px;
		`};

	${({ isEllipsis }) =>
		isEllipsis &&
		css`
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
			width: 100px;
		`};

	${({ mdBold }) =>
		mdBold &&
		css`
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
		`};

	${({ md }) =>
		md &&
		css`
			font-size: 14px;
			line-height: 16px;
		`};

	${({ lg }) =>
		lg &&
		css`
			font-weight: 500;
			font-size: 20px;
			line-height: 23px;
		`};
`

function TextLink({
	title,
	lg,
	md,
	mdBold,
	sm,
	fontWeight,
	fontSize,
	lineHeight,
	black62,
	className,
	isEllipsis,
	minWidth,
}: ITextLink) {
	return (
		<StyledLink
			minWidth={minWidth}
			isEllipsis={isEllipsis}
			lg={lg}
			md={md}
			mdBold={mdBold}
			sm={sm}
			fontWeight={fontWeight}
			fontSize={fontSize}
			lineHeight={lineHeight}
			black62={black62}
			className={className}
		>
			{title}
		</StyledLink>
	)
}

export default TextLink
