import { createApi } from '@reduxjs/toolkit/query/react'
import { AssetSearchResponse } from 'src/facade/assets/assetsService.types'
import { tidpAssetsService } from 'src/facade/assets/tidp/tidpAssetsService'
import { TidpAsset } from 'src/facade/assets/tidp/tidpAssetsService.types'
import { axiosBaseQuery } from 'src/utils/axiosBaseQuery'

export const tidpAssetsApi = createApi({
	reducerPath: 'tidpAssetsApi',
	baseQuery: axiosBaseQuery(),
	tagTypes: ['TidpAssets'],
	endpoints: (builder) => ({
		fetchTidpAssets: builder.query<TidpAsset[] | null, { tagCode: string; contract: string }>({
			query:
				({ tagCode, contract }) =>
				() =>
					tidpAssetsService.search(tagCode, contract),
			transformResponse: (response: AssetSearchResponse<TidpAsset>) => {
				return response.result
			},
			providesTags: ['TidpAssets'],
			keepUnusedDataFor: 0,
		}),
	}),
})

export const { useFetchTidpAssetsQuery } = tidpAssetsApi
