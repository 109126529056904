import { axiosInstance } from 'src/services/axiosInstance'
import {
	REACT_ADD_USER,
	REACT_DELETE_USER,
	REACT_GET_USERS_FOR_USER_MANAGEMENT,
	REACT_GET_USER_BY_USER_ID,
	REACT_RESET_PASSWORD_FOR_USER,
	REACT_UPDATE_USER,
} from '../constants'

export const getUsersForUserManagement = async (
	pageNumber: number,
	pageSize: number,
	email?: string,
	isActive?: boolean,
	groups?: string,
) => {
	const query = `
		?page=${pageNumber}&size=${pageSize}
		${email && email?.length > 0 ? `&email=${email.toLowerCase()}` : ''}
		${groups && groups?.length > 0 ? `&group=${groups.toLowerCase()}` : ''}
		${isActive !== undefined ? `&isActive=${isActive}` : ''}
	`
	const { data } = await axiosInstance.get(`${REACT_GET_USERS_FOR_USER_MANAGEMENT}${query}`)
	return data
}

export const getUserByUserId = async (userId: string) => {
	const { data } = await axiosInstance.get(`${REACT_GET_USER_BY_USER_ID}?userId=${userId}`)
	return data
}

export const updateUser = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_UPDATE_USER, body)
	return data
}

export const deleteUser = async (userId: any) => {
	const { data } = await axiosInstance.get(`${REACT_DELETE_USER}?userId=${userId}`)
	return data
}

export const addUser = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_ADD_USER, body)
	return data
}

export const resetPassword = async (userId: string) => {
	const { data } = await axiosInstance.get(`${REACT_RESET_PASSWORD_FOR_USER}?userId=${userId}`)
	return data
}
