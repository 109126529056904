import { executeAction } from 'src/facade/action/actionService'
import { Action, ActionResponse } from 'src/facade/action/actionService.types'
import { ActionSendTaskParameters } from 'src/facade/action/task/actionTaskService.type'

export const actionTaskService = {
	async send(taskId: number, params: ActionSendTaskParameters): Promise<ActionResponse<void>> {
		return executeAction({
			task_id: taskId,
			action_name: Action.SendTaskPriviliges,
			action_input_parameters: params,
		})
	},
	async recall(taskId: number, contract: string): Promise<ActionResponse<void>> {
		return executeAction({
			task_id: taskId,
			action_name: Action.RecallTaskForm,
			action_input_parameters: {
				contract,
			},
		})
	},
	async resend(taskId: number, contract: string): Promise<ActionResponse<void>> {
		return executeAction({
			task_id: taskId,
			action_name: Action.ResendTask,
			action_input_parameters: {
				contract,
			},
		})
	},
	async markAsComplete(taskId: number, contract: string): Promise<ActionResponse<void>> {
		return executeAction({
			task_id: taskId,
			action_name: Action.MarkTaskComplete,
			action_input_parameters: {
				contract,
			},
		})
	},
	async finish(taskId: number, contract: string): Promise<ActionResponse<void>> {
		return executeAction({
			task_id: taskId,
			action_name: Action.FinishForm,
			action_input_parameters: {
				contract,
			},
		})
	},
	async close(taskId: number, contract: string): Promise<ActionResponse<void>> {
		return executeAction({
			task_id: taskId,
			action_name: Action.CloseForm,
			action_input_parameters: {
				contract,
			},
		})
	},
}
