import { createAction } from '@reduxjs/toolkit'
import { IAttributes, IFormComponents, IInputs } from '../../models/formsHandler/formsHandler'

interface IHandleComponentValue {
	componentId: number | string
	value: any
	rowId: number | string
	keyName: string
	focusStart: string
}

export const clearForm = createAction('formsHandler/clearForm')

export const getComponentAttributeTypes = createAction('formsHandler/getComponentAttributeTypes')
export const setComponentAttributeTypes = createAction<Array<IInputs>>('formsHandler/setComponentAttributeTypes')
export const setAllSequences = createAction<Array<{ id: string; sequence: number }>>('formsHandler/setAllSequences')

export const handleIsEditForm = createAction<boolean>('formsHandler/handleIsEditForm')
export const cancelFormEdit = createAction('formsHandler/cancelFormEdit')

export const handleAutoFocus = createAction<string>('formsHandler/handleAutoFocus')

export const getForm = createAction<{ id: string; counter: number }>('formsHandler/getForm')
export const setForm = createAction<any>('formsHandler/setActiveForms')
export const handleIsGettingForm = createAction<boolean>('formsHandler/handleIsGettingForm')

export const handleFormDetails = createAction<{ value: any; key: string }>('formsHandler/handleFormDetails')
export const setFormDetails = createAction<{ [key: string]: { value: any } }>('formsHandler/setFormDetails')

export const checkFormNumber = createAction<{ value: string }>('formsHandler/checkFormNumber')
export const isCheckingCode = createAction<boolean>('formsHandler/isCheckingCode')
export const setIsFormNumberOriginal = createAction<boolean>('formsHandler/setIsFormNumberOriginal')

export const handleIsOpenAttributes = createAction<boolean>('formsHandler/handleIsOpenAttributes')
export const setAttributes = createAction<IAttributes>('formsHandler/setAttributes')
export const handleAttributeByKey = createAction<{
	id: string | number
	keyName: string
	value: any
	focusStart: string
}>('formsHandler/handleAttributeByKey')
export const addNewAttribute = createAction('formsHandler/addNewAttribute')
export const removeAttribute = createAction<string | number>('formsHandler/removeAttribute')

export const setComponents = createAction<IFormComponents>('formsHandler/setComponents')
export const handleComponentsHeaderValues = createAction<{ keyName: string; value: any; id: number | string }>(
	'formsHandler/handleComponentsHeaderValues',
)
export const handleOpenComponents = createAction<boolean>('formsHandler/handleOpenComponents')
export const handleComponentValue = createAction<IHandleComponentValue>('formsHandler/handleComponentValue')
export const addComponent = createAction<{ componentId: string | number }>('formsHandler/addComponent')
export const deleteRowComponent = createAction<{ id: string | number; componentId: string | number }>(
	'formsHandler/deleteRowComponent',
)
export const addNewComponent = createAction('formsHandler/addNewComponent')
export const removeComponent = createAction<number | string>('formsHandler/removeComponent')
export const handleEditComponent = createAction<{ id: number | string; isEditing: boolean }>(
	'formsHandler/handleEditComponent',
)

export const addExistingComponent = createAction<{ id: number | string; class_name: string; label: string }>(
	'formsHandler/addExistingComponent',
)
export const handleGettingExistingComponent = createAction<boolean>('formsHandler/handleGettingExistingComponent')

export const saveNewComponent = createAction<{ name: string | number; componentId: any }>(
	'formsHandler/saveNewComponent',
)

export const saveExistingComponentAsNew = createAction<{ name: string | number; componentId: any }>(
	'formsHandler/saveExistingComponentAsNew',
)
export const handleIsSavingNewComponent = createAction<boolean>('formsHandler/handleIsSavingNewComponent')

export const updateExistingComponent = createAction<{ name: string | number; componentId: any; formId: string }>(
	'formsHandler/updateExistingComponent',
)
export const handleIsUpdatingExistingComponent = createAction<boolean>('formsHandler/handleIsUpdatingExistingComponent')

export const updateMainForm = createAction<{ id: string }>('formsHandler/updateMainForm')
export const handleIsUpdatingMainForm = createAction<boolean>('formsHandler/handleIsUpdatingMainForm')

export const saveNewForm = createAction('formsHandler/saveNewForm')
export const handleIsSavingNewForm = createAction<boolean>('formsHandler/handleIsSavingNewForm')

export const assignGuideInfo =
	createAction<{ name: string; id: string; main_form_id: string }>('formsHandler/assignGuideInfo')

export const downloadGuide = createAction('formsHandler/downloadGuide')
export const isDownloadingGuide = createAction<boolean>('formsHandler/isDownloadingGuide')

export const uploadGuide = createAction<any>('formsHandler/uploadGuide')
export const isUploadingGuide = createAction<boolean>('formsHandler/isUploadingGuide')
export const handleSelectedFile = createAction<any>('formsHandler/handleSelectedFile')

export const removeGuide = createAction('formsHandler/removeGuide')
export const isRemovingGuide = createAction<boolean>('formsHandler/isRemovingGuide')
