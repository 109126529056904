import { append, without } from 'ramda'
import { AppTask } from 'src/features/tasks/tasksApi'
import { useLocalStorageState } from 'src/utils/useLocalStorageState'

export interface UseFormsExpandedTasks {
	toggle(task: AppTask): void
	includes(tagCode: string): boolean
}

export const useFormsExpandedTasks = (): UseFormsExpandedTasks => {
	const [tagCodes, setTagCodes] = useLocalStorageState<string[]>('forms/expanded-tasks', [])

	return {
		toggle: (task) =>
			setTagCodes((currentTagCodes) =>
				currentTagCodes.includes(task.main_asset.tag_code)
					? without([task.main_asset.tag_code])(currentTagCodes)
					: append(task.main_asset.tag_code)(currentTagCodes),
			),
		includes: (tagCode) => tagCodes.includes(tagCode.replace('_OWNER', '')),
	}
}
