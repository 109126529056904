import React from 'react'
import styled, { css } from 'styled-components'
import TextLink from '../../Atoms/TextLink'
import { colors } from '../../../theme/colors'

const StyledLink = styled.div<{ active: boolean }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	padding: 4px;
	transition: background 0.25s ease-out;
	border-radius: 4px;
	${({ active }) =>
		active &&
		css`
			background-color: ${({ theme }) => theme.colors.main10};
		`}
	&:hover {
		background-color: ${({ theme }) => theme.colors.main10};
	}
`

const StarWrapper = styled.div`
	padding: 4px;
	margin-right: 20px;
	border-radius: 4px;
`

const Circle = styled.div<{ active: boolean }>`
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ active }) => (active ? colors.main100 : colors.greyBD)};
	color: ${colors.white};
	font-weight: 700;
	border-radius: 50%;
	margin: 10px 0;
	padding-top: 2px;
`

function SidebarLink({ title, active, isCollapsed }: { title: string; active: boolean; isCollapsed: boolean }) {
	return (
		<>
			{isCollapsed ? (
				<StyledLink active={active}>
					<StarWrapper>
						<Circle active={active}>{title.substring(0, 1)}</Circle>
					</StarWrapper>
					<TextLink title={title} md />
				</StyledLink>
			) : (
				<Circle active={active}>{title.substring(0, 1)}</Circle>
			)}
		</>
	)
}

export default SidebarLink
