import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { SET_ASSETS_RESULTS, SET_IS_SEARCHING } from './constants'

export type SearchActions = ActionType<typeof actions>

const initialSearchReducer: ISearchResults = {
	assetSearchResults: [],
	isSearching: false,
}

const searchReducer = (state: ISearchResults = initialSearchReducer, action: SearchActions) => {
	switch (action.type) {
		case SET_ASSETS_RESULTS: {
			return { ...state, assetSearchResults: action.payload }
		}
		case SET_IS_SEARCHING: {
			return { ...state, isSearching: action.payload }
		}
		default:
			return state
	}
}

export default searchReducer
