/* eslint-disable no-nested-ternary */
import { toast } from 'react-toastify'
import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import * as groupsManagementService from 'src/services/groupsManagement'
import { isOfType } from 'typesafe-actions'
import { IGroupsList } from '../../models/groupsManagement/groupsManagement'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import {
	addNewUserToGroup,
	createNewGroup,
	deleteGroup,
	getListOfGroups,
	getPrivilegesSource,
	getPrivilegesTypes,
	getUsersForGroup,
	handleChangeChild,
	removePrivilege,
	removeUsersForGroup,
	savePrivilege,
	searchForGroup
} from './actions'
import { groupsManagementActions, groupsManagementConstants } from './index'

export const getGroupsListEpic: AppEpicDeprecated<ReturnType<typeof getListOfGroups>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.GET_LIST_OF_GROUPS)),
		withLatestFrom(state$),
		switchMap(([action]) => {
			return concat(
				of(groupsManagementActions.handleListOfGroupsIsLoading(true)),
				fromPromise(groupsManagementService.getListOfGroups(action.payload.page, action.payload.contract)).pipe( 
					switchMap((response) => { 
						const list = response.available_groups.map((item: any) => ({
							tag: item.id,
							title: item.groupName,
							quantity: item.membersCount,
							project_org: item.project_organisation,
						}))
						return concat(
							of(groupsManagementActions.handleListOfGroupsIsLoading(false)),
							of(groupsManagementActions.setListOfGroups(list)),
							of(
								groupsManagementActions.handlePaginationForGroups({
									page: action.payload.page,
									pageCount: Math.ceil(response.total / 15),
								}),
							),
						)
					}),
					catchError((err) => {
						return concat(
							of(groupsManagementActions.handleListOfGroupsIsLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.getListOfGroups(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const searchForGroupEpic: AppEpicDeprecated<ReturnType<typeof searchForGroup>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.SEARCH_FOR_GROUP)),
		withLatestFrom(state$),
		switchMap(([action]) => {
			return concat(
				of(groupsManagementActions.handleListOfGroupsIsLoading(true)),
				fromPromise(groupsManagementService.searchGroups(action.payload.page, action.payload.query)).pipe(
					switchMap((response) => {
						const list = response.available_groups.map((item: any) => ({
							tag: item.id,
							title: item.groupName,
							quantity: item.membersCount,
							project_org: item.project_organisation,
						}))
						return concat(
							of(groupsManagementActions.handleListOfGroupsIsLoading(false)),
							of(groupsManagementActions.setListOfGroups(list)),
							of(
								groupsManagementActions.handlePaginationForGroups({
									page: action.payload.page,
									pageCount: Math.ceil(response.total / 15),
								}),
							),
						)
					}),
					catchError((err) => {
						return concat(
							of(groupsManagementActions.handleListOfGroupsIsLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.searchForGroup(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const removeGroupEpic: AppEpicDeprecated<ReturnType<typeof deleteGroup>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.DELETE_GROUP)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(groupsManagementActions.handleIsDeletingGroup(true)),
				fromPromise(groupsManagementService.removeGroup(action.payload)).pipe(
					switchMap(() => {
						toast.success('Group deleted')
						return concat(
							of(groupsManagementActions.handleIsDeletingGroup(false)),
							of(
								groupsManagementActions.setListOfGroups(
									state.groupsManagement.groupsList.filter((item: IGroupsList) => item.tag !== action.payload),
								),
							),
						)
					}),
					catchError((err) => {
						return concat(
							of(groupsManagementActions.handleIsDeletingGroup(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.deleteGroup(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const createNewGroupEpic: AppEpicDeprecated<ReturnType<typeof createNewGroup>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.CREATE_GROUP)),
		withLatestFrom(state$),

		switchMap(([action, state]) => {
			const body = {
				...(action.payload.project_organisation
					? {
						groupName: action.payload.title,
						project_organisation: action.payload.project_organisation,
						contractId: state.users.contract.contract,
						contractName: state.users.contract.contract_name,
					}
					: {
						groupName: action.payload.title,
						contractId: state.users.contract.contract,
						contractName: state.users.contract.contract_name,
					}),
			}
			return concat(
				of(groupsManagementActions.handleNewGroupIsCreating(true)),
				of(groupsManagementActions.handleNewGroupPopup(false)),
				fromPromise(groupsManagementService.createNewGroup(body)).pipe(
					switchMap((response) => {
						toast.success('Group created. ')
						const newGroup = {
							tag: response['Group created:'],
							title: action.payload.title,
							quantity: 0,
						}
						return concat(
							of(groupsManagementActions.handleNewGroupIsCreating(false)),
							of(groupsManagementActions.setListOfGroups([newGroup, ...state.groupsManagement.groupsList])),
						)
					}),
					catchError((err) => {
						return concat(
							of(groupsManagementActions.handleNewGroupIsCreating(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,

									callback: groupsManagementActions.createNewGroup(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const getUsersForGroupEpic: AppEpicDeprecated<ReturnType<typeof getUsersForGroup>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.GET_USERS_FOR_GROUP)),
		withLatestFrom(state$),
		switchMap(([action]) => {
			return concat(
				of(groupsManagementActions.handleUsersLoading(true)),
				fromPromise(groupsManagementService.getUsersForGroup(action.payload)).pipe(
					switchMap((response) => {
						return concat(
							of(
								groupsManagementActions.setUsersForGroup(
									response.group_members.map((user: string) => ({
										user,
										isSelected: false,
									})),
								),
							),
							of(groupsManagementActions.handleUsersLoading(false)),
						)
					}),
					catchError((err) => {
						return concat(
							of(groupsManagementActions.handleUsersLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.getUsersForGroup(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const removeUsersFromGroupEpic: AppEpicDeprecated<ReturnType<typeof removeUsersForGroup>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.REMOVE_USERS_IN_GROUP)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const users = state.groupsManagement.allUsersInGroup
				.filter((item: { isSelected: boolean }) => item.isSelected)
				.map((item: { user: string }) => item.user)
			const notSelectedUsers = state.groupsManagement.allUsersInGroup.filter(
				(item: { isSelected: boolean }) => !item.isSelected,
			)
			return concat(
				of(groupsManagementActions.isRemovingUsersFromGroup(true)),
				fromPromise(groupsManagementService.removeUsersFromGroup(action.payload.groupTag, users)).pipe(
					switchMap(() => {
						toast.success('Users removed.')
						const list = state.groupsManagement.groupsList.map((item: any) =>
							action.payload.groupTag === item.tag ? { ...item, quantity: item.quantity - users.length } : item,
						)

						return concat(
							of(groupsManagementActions.setListOfGroups(list)),
							of(groupsManagementActions.setUsersForGroup(notSelectedUsers)),
							of(groupsManagementActions.isRemovingUsersFromGroup(false)),
						)
					}),
					catchError((err) => {
						return concat(
							of(groupsManagementActions.isRemovingUsersFromGroup(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.removeUsersForGroup(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const addNewUsersToGroupEpic: AppEpicDeprecated<ReturnType<typeof addNewUserToGroup>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.ADD_NEW_USERS_TO_GROUP)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(groupsManagementActions.isAddingNewUsersForGroup(true)),
				fromPromise(groupsManagementService.addNewUserToGroup(action.payload.groupTag, action.payload.users)).pipe(
					switchMap(() => {
						toast.success('Users Created')
						const list = state.groupsManagement.groupsList.map((item: any) =>
							action.payload.groupTag === item.tag
								? { ...item, quantity: item.quantity + action.payload.users.length }
								: item,
						)
						const listOfUsers: any = [
							...action.payload.users.map((i: string) => ({ user: i, isSelected: false })),
							...state.groupsManagement.listOfUsersInGroup,
						]
						return concat(
							of(groupsManagementActions.setListOfGroups(list)),
							of(groupsManagementActions.setUsersForGroup(listOfUsers)),
							of(groupsManagementActions.isAddingNewUsersForGroup(false)),
						)
					}),
					catchError((err) => {
						toast.error(err?.response?.data.Error || 'Could not assign users to this group.')
						return concat(
							of(groupsManagementActions.isAddingNewUsersForGroup(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.addNewUserToGroup(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const getPrivilegesTypesEpic: AppEpicDeprecated<ReturnType<typeof getPrivilegesTypes>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.GET_PRIVILEGES_TYPES)),
		withLatestFrom(state$),
		switchMap(() => {
			return concat(
				of(groupsManagementActions.handlePrivilegesTypesLoading(true)),
				fromPromise(groupsManagementService.getPrivilegesTypes()).pipe(
					switchMap((response) => {
						return concat(
							of(groupsManagementActions.setPrivilegesTypes(response.types)),
							of(groupsManagementActions.setPrivilegesHierarchy(response.hierarchy)),
							of(groupsManagementActions.handlePrivilegesTypesLoading(false)),
						)
					}),
					catchError((err) => {
						toast.error('Could not assign users to this group.')
						return concat(
							of(groupsManagementActions.handlePrivilegesTypesLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.getPrivilegesTypes(),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const getPrivilegesSourceEpic: AppEpicDeprecated<ReturnType<typeof getPrivilegesSource>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.GET_PRIVILEGES_SOURCE)),
		withLatestFrom(state$),
		switchMap(([action]) => {
			return concat(
				of(groupsManagementActions.handleLoadingPrivilegeSource(true)),
				fromPromise(groupsManagementService.getPrivilegesSource(action.payload)).pipe(
					switchMap((response) => {
						return concat(
							of(
								groupsManagementActions.setPrivilegesSource(
									response.Privileges.map((i: any) => ({ ...i, isOpen: false, id: `priv_${i.name}` })),
								),
							),
							of(groupsManagementActions.handleLoadingPrivilegeSource(false)),
						)
					}),
					catchError((err) => {
						toast.error('Could not get list of privileges')
						return concat(
							of(groupsManagementActions.handleLoadingPrivilegeSource(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.getPrivilegesSource(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const editChild = (
	id: string,
	parentsIds: string[],
	element: any,
	isType: boolean,
	value: any,
	changeOptions: boolean,
) => {
	if (element.id === id) {
		if (changeOptions) {
			return {
				...element,
				listOptions: value,
			}
		}
		return {
			...element,
			type: isType ? value : element.type,
			value: isType ? '' : value,
			additions: [],
			substractions: [],
		}
	}
	return {
		...element,
		additions:
			parentsIds.filter((i) => element.id === i).length > 0 ||
				element.additions.filter((el: any) => el.id === id).length > 0
				? element.additions.map((item: any) => editChild(id, item.parentsIds, item, isType, value, changeOptions))
				: element.additions,
		substractions:
			parentsIds.filter((i) => element.id === i).length > 0 ||
				element.substractions.filter((el: any) => el.id === id).length > 0
				? element.substractions.map((item: any) => editChild(id, item.parentsIds, item, isType, value, changeOptions))
				: element.substractions,
	}
}

export const handleChangeChildEpic: AppEpicDeprecated<ReturnType<typeof handleChangeChild>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.HANDLE_CHANGE_CHILD)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			if (action.payload.isType) {
				return concat(
					of(groupsManagementActions.handlePrivilegesListLoading(true)),
					fromPromise(
						groupsManagementService.getPrivilegesList(
                            state.users.contract.contract,
							action.payload.value.value,
							action.payload.parentPrivilege === 'All'
								? false
								: action.payload.isAdditional !== undefined && !action.payload.isAdditional
									? action.payload.parentPrivilege
									: false,
						),
					).pipe(
						switchMap((response) => {
							const valueOptions = response.Privileges.map((item: string) => ({ value: item, label: item }))
							const privilegesWithListOptions = {
								...state.groupsManagement.newPrivileges,
								privilege: editChild(
									action.payload.id,
									action.payload.parentsIds,
									state.groupsManagement.newPrivileges.privilege,
									false,
									valueOptions,
									true,
								),
							}
							return concat(
								of(groupsManagementActions.assignChild(privilegesWithListOptions)),
								of(groupsManagementActions.handlePrivilegesListLoading(false)),
							)
						}),
						catchError((err) => {
							toast.error('Could not assign users to this group.')
							return concat(
								of(groupsManagementActions.handlePrivilegesListLoading(false)),
								of(
									authorizationActions.recallApi({
										errorCode: err.status,
										callback: groupsManagementActions.handleChangeChild(action.payload),
									}),
								),
							)
						}),
					),
				)
			}
			const newPrivileges = {
				...state.groupsManagement.newPrivileges,
				privilege: editChild(
					action.payload.id,
					action.payload.parentsIds,
					state.groupsManagement.newPrivileges.privilege,
					action.payload.isType,
					action.payload.value,
					false,
				),
			}
			return concat(of(groupsManagementActions.assignChild(newPrivileges)))
		}),
	)

const assignPrivileges = (isAdd: boolean, priv: any) => {
	return priv[isAdd ? 'additions' : 'substractions'].map((el: any) => {
		return {
			type: el.type.value,
			value: el.value.value,
			additions: priv.additions.length > 0 ? assignPrivileges(true, el) : [],
			substractions: priv.substractions.length > 0 ? assignPrivileges(false, el) : [],
		}
	})
}

export const savePrivilegeEpic: AppEpicDeprecated<ReturnType<typeof savePrivilege>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.SAVE_PRIVILEGE)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const body = {
				privileges: [
					{
						name: state.groupsManagement.newPrivileges.name,
						related_groups: [action.payload.tag],
						privilege: {
							type: state.groupsManagement.newPrivileges.privilege.type.value,
							value: state.groupsManagement.newPrivileges.privilege.value.value,
							additions:
								state.groupsManagement.newPrivileges.privilege.additions.length > 0
									? assignPrivileges(true, state.groupsManagement.newPrivileges.privilege)
									: [],
							substractions:
								state.groupsManagement.newPrivileges.privilege.substractions.length > 0
									? assignPrivileges(false, state.groupsManagement.newPrivileges.privilege)
									: [],
						},
					},
				],
			}
			return concat(
				of(groupsManagementActions.isSavingPrivileges(true)),
				fromPromise(groupsManagementService.saveNewPrivilege(body)).pipe(
					switchMap(() => {
						toast.success(`Privilege ${state.groupsManagement.newPrivileges.name} created.`)
						return concat(
							of(groupsManagementActions.assignChild({})),
							of(groupsManagementActions.getPrivilegesSource(action.payload.tag)),
							of(groupsManagementActions.isSavingPrivileges(false)),
						)
					}),
					catchError((err) => {
						toast.error('Could not save list of privileges')
						return concat(
							of(groupsManagementActions.isSavingPrivileges(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.savePrivilege(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const removePrivilegeEpic: AppEpicDeprecated<ReturnType<typeof removePrivilege>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(groupsManagementConstants.REMOVE_PRIVILEGE)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const body = {
				name: action.payload.id,
				group_id: action.payload.tag,
			}
			return concat(
				of(groupsManagementActions.isRemovingPrivileges(true)),
				fromPromise(groupsManagementService.removeNewPrivilege(body)).pipe(
					switchMap(() => {
						return concat(
							of(
								groupsManagementActions.setPrivilegesSource([
									...state.groupsManagement.privilegesSource.filter((item: any) => item.name !== action.payload.id),
								]),
							),
							of(groupsManagementActions.isRemovingPrivileges(false)),
						)
					}),
					catchError((err) => {
						toast.error('Could not remove privilege')
						return concat(
							of(groupsManagementActions.isRemovingPrivileges(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: groupsManagementActions.removePrivilege(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export default combineEpics(
	getGroupsListEpic,
	removeGroupEpic,
	createNewGroupEpic,
	getUsersForGroupEpic,
	removeUsersFromGroupEpic,
	addNewUsersToGroupEpic,
	searchForGroupEpic,
	getPrivilegesTypesEpic,
	getPrivilegesSourceEpic,
	savePrivilegeEpic,
	removePrivilegeEpic,
	handleChangeChildEpic,
)
