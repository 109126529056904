export function getBaseUrl(): string {
	if (import.meta.env.MODE === 'development') {
		return (import.meta.env.VITE_API as string).replace('/aimsapi', '')
	}

	return window.location.href.substring(0, window.location.href.indexOf('app')) as string
}

export function getAppUrl(): string {
	return `${window.location.origin}/app/`
}

export function getBaseUrlOrLocalhost(): string {
	return window.location.href.substring(0, window.location.href.indexOf('app')) as string
}
