import { ThemeProvider as MuiThemeProvider, ThemeOptions, createTheme } from '@mui/material'
import { ErrorBoundary, init } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ConnectedRouter } from 'connected-react-router'
import queryString from 'query-string'
import { FC } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { MsalWrapper } from 'src/utils/MsalWrapper'
import { ThemeProvider } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import AppWrapper from './AppWrapper'
import store from './appConfig/store'
import browserHistory from './services/history'
import GlobalStyle from './theme/GlobalStyle'
import theme from './theme/mainTheme'
import { getEnvironment } from './utils/getEnvironment'

init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],
	enabled: import.meta.env.MODE === 'production',
	environment: getEnvironment(),
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
})

const themeOptions: ThemeOptions = {
	components: {
		MuiToggleButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					textTransform: 'none',
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				underline: { '&&&&:after': { transition: 'none' } },
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#013CA6',
		},
	},
	unstable_strictMode: import.meta.env.MODE !== 'production',
}

const muiTheme = createTheme(themeOptions)

const App: FC = () => {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<ErrorBoundary fallback={<p>An error has occurred</p>}>
				<MsalWrapper>
					<MuiThemeProvider theme={muiTheme}>
						<Provider store={store}>
							<ConnectedRouter history={browserHistory}>
								<QueryParamProvider
									adapter={ReactRouter5Adapter}
									options={{
										searchStringToObject: queryString.parse,
										objectToSearchString: queryString.stringify,
									}}
								>
									<AppWrapper />
									<ToastContainer />
								</QueryParamProvider>
							</ConnectedRouter>
						</Provider>
					</MuiThemeProvider>
				</MsalWrapper>
			</ErrorBoundary>
		</ThemeProvider>
	)
}

export default App
