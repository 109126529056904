import styled from 'styled-components'

interface FormattedCommentProps {
	comment: string
}

export const BulletList = styled.ul`
	list-style-type: disc;
	margin-left: 10px;
	padding-left: 20px;
`

export const BulletListItem = styled.li`
	margin-bottom: 5px;
	margin-top: 5px;
`

export const FormattedComment = (props: FormattedCommentProps) => {
	const commentLines = props.comment.split('\n')
	const formattedComment = []
	let currentListItems = []

	for (const line of commentLines) {
		if (line.trim().startsWith('•') || line.trim().startsWith('·')) {
			currentListItems.push(line.trim().slice(1))
		} else if (line.trim() === '') {
			formattedComment.push(<br key={formattedComment.length} />)
		} else {
			if (currentListItems.length > 0) {
				formattedComment.push(
					<BulletList key={formattedComment.length}>
						{currentListItems.map((item, index) => (
							<BulletListItem key={index}>{item}</BulletListItem>
						))}
					</BulletList>,
				)
				currentListItems = []
			}
			formattedComment.push(<div key={formattedComment.length}>{line}</div>)
		}
	}

	if (currentListItems.length > 0) {
		formattedComment.push(
			<BulletList key={formattedComment.length}>
				{currentListItems.map((item, index) => (
					<BulletListItem key={index}>{item}</BulletListItem>
				))}
			</BulletList>,
		)
	}

	return <div>{formattedComment}</div>
}
