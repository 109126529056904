import { axiosInstance } from 'src/services/axiosInstance'
import {
	GET_HISTORY,
	REACT_ACTIONS,
	REACT_CREATE_ASSET,
	REACT_GET_DELIVERABLES,
	REACT_GET_ONE_DELIVERABLE,
	REACT_SEARCH
} from '../constants'

export const PostQuery = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_ACTIONS, body)
	return data
}

export const GetHistory = async (contract: string, tagCode: string | number) => {
	const { data } = await axiosInstance.get(`${GET_HISTORY}?contract=${contract}&tag_code=${tagCode}`)
	return data
}

export const getAssetByTagCode = async (tagCode: string | number, contract: string, moduleName: string) => {
	const { data } = await axiosInstance.post(REACT_SEARCH, {
		contract: contract,
		tag_code: { fieldValue: [tagCode] },
		relationsLevel: 2,
		module_name: moduleName,
	})
	return data
}

export const saveAsset = async (request: {}) => {
	const { data } = await axiosInstance.post(REACT_CREATE_ASSET, request)
	return data
}

export const getDeliverables = async (item: { tag: string | number; contract: string }) => {
	const { data } = await axiosInstance.get(`${REACT_GET_DELIVERABLES}?contract=${item.contract}&tag_code=${item.tag}`)
	return data
}

export const getOneDeliverable = async (tag: string) => {
	const { data } = await axiosInstance.get(`${REACT_GET_ONE_DELIVERABLE}${tag}`)
	return data
}
