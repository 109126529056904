import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import RemoveIcon from '@mui/icons-material/Remove'
import {
	Autocomplete,
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Paper,
	TextField,
	Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { equals, uniqBy } from 'ramda'
import { FC, Fragment } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { DisplayGroupDefault, DisplayGroupValue, IFormComponentsAttributes } from 'src/models/formsHandler/formsHandler'
import { resolveValueFunction } from 'src/utils/resolveValueFunction'

export interface DisplayGroupDialogProps {
	open: boolean
	components: IFormComponentsAttributes[]
	dependsOnOptions: {
		value: string | number
		label: string
		category: string
	}[]
	onClose(): void
	onChange(displayGroup: DisplayGroupValue | DisplayGroupDefault | null): void
	defaultValue?: DisplayGroupValue | DisplayGroupDefault | null
}

export type DisplayGroupForm = {
	value_function: DisplayGroupValue | null
	default: DisplayGroupDefault | null
}

export const DisplayGroupDialog: FC<DisplayGroupDialogProps> = ({
	open,
	defaultValue,
	onChange,
	onClose,
	dependsOnOptions,
	components,
}) => {
	const { control, watch, handleSubmit, formState, reset } = useForm<DisplayGroupForm>({
		defaultValues: {
			value_function: {
				...(defaultValue && defaultValue.type === 'value_function'
					? defaultValue
					: {
							type: 'value_function',
							value: { params: [{}] },
					  }),
			},
			default: {
				...(defaultValue && defaultValue.type === 'default'
					? defaultValue
					: {
							type: 'default',
					  }),
			},
		},
	})

	const {
		fields: functionValueParams,
		append,
		remove,
	} = useFieldArray({
		control,
		name: 'value_function.value.params',
	})

	const currentForm: 'default' | 'value_function' | null = !!watch('default.value.formula')
		? 'default'
		: !!watch('value_function.value.params.0.attributeName') ||
		  !!watch('value_function.value.params.0.optional') ||
		  !!watch('value_function.value.formula')
		? 'value_function'
		: null

	const uniqDependsOnOptions = uniqBy((option: any) => option.value, dependsOnOptions) || []

	const onSubmit = (form: DisplayGroupForm) => {
		onChange(currentForm === 'default' ? form.default : currentForm === 'value_function' ? form.value_function : null)
		onClose()
	}

	const existingDisplayGroups = uniqBy(
		(displayGroup) => JSON.stringify(displayGroup),
		components.map((component) => component.display_group).filter((displayGroup) => !!displayGroup),
	)

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle>Display group</DialogTitle>
				<DialogContent>
					{existingDisplayGroups.length > 0 && (
						<Paper variant="outlined" sx={{ mb: 3 }}>
							<Box sx={{ p: 1 }}>
								<Box sx={{ mb: 1 }}>
									<Typography>Existing display groups:</Typography>
								</Box>
								<Box sx={{ display: 'flex', mx: -0.5 }}>
									{existingDisplayGroups.map((displayGroup) => {
										const isSelected =
											currentForm === 'default'
												? equals(watch('default'), displayGroup as DisplayGroupDefault)
												: equals(watch('value_function'), displayGroup as DisplayGroupValue)
										return (
											displayGroup && (
												<Box sx={{ m: 0.5 }}>
													<Chip
														variant={isSelected ? 'filled' : 'outlined'}
														color="primary"
														disabled={isSelected}
														label={
															displayGroup.type === 'value_function'
																? resolveValueFunction(displayGroup as DisplayGroupValue)
																: displayGroup.value.formula
														}
														onClick={() => {
															reset(
																displayGroup.type === 'value_function'
																	? { value_function: displayGroup }
																	: {
																			default: displayGroup,
																			value_function: {
																				type: 'value_function',
																				value: { params: [{}] },
																			},
																	  },
															)
														}}
													/>
												</Box>
											)
										)
									})}
								</Box>
							</Box>
						</Paper>
					)}

					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Controller
								name="default.value.formula"
								control={control}
								defaultValue={undefined}
								rules={{
									validate: () => {
										if (currentForm === 'default' && !watch('default.value.formula')) return 'Required.'
										return true
									},
								}}
								render={({ field }) => (
									<TextField
										{...field}
										fullWidth
										margin="none"
										label="Default value"
										variant="standard"
										disabled={currentForm === 'value_function'}
										InputProps={{
											endAdornment: (
												<IconButton
													size="small"
													sx={{ visibility: field.value ? 'visible' : 'hidden' }}
													onClick={() => field.onChange('')}
												>
													<ClearIcon />
												</IconButton>
											),
										}}
										error={!!formState.errors.default?.value?.formula}
										helperText={formState.errors.default?.value?.formula?.message}
									/>
								)}
							/>
						</Grid>
					</Grid>

					<Divider sx={{ my: 3 }}>OR</Divider>

					<Box>
						<Typography>Parameters:</Typography>
					</Box>
					{functionValueParams.map((param, index) => {
						return (
							<Box
								key={`value_function.value.params.${index}.${param.attributeName}`}
								sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
							>
								<Box sx={{ mr: 1 }}>
									<Typography sx={{ fontWeight: 500 }}>{`{${index}}`} - </Typography>
								</Box>
								<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
									<Controller
										name={`value_function.value.params.${index}.attributeName`}
										control={control}
										defaultValue={undefined}
										render={({ field }) => (
											<Autocomplete
												{...field}
												options={uniqDependsOnOptions}
												groupBy={(option) => option.category}
												renderInput={(params) => (
													<TextField {...params} margin="none" label="" fullWidth variant="standard" />
												)}
												disabled={currentForm === 'default'}
												value={uniqDependsOnOptions.find(({ value }) => value === field.value) || null}
												onChange={(e, option) => {
													field.onChange(option?.value as string)
												}}
											/>
										)}
									/>
								</Box>
								{watch('value_function.value.params').length === index + 1 ? (
									<IconButton
										disabled={currentForm === 'default'}
										onClick={() => append({ attributeName: '', optional: false })}
									>
										<AddIcon />
									</IconButton>
								) : (
									<IconButton
										disabled={currentForm === 'default'}
										onClick={() => {
											remove(index)
										}}
									>
										<RemoveIcon />
									</IconButton>
								)}
							</Box>
						)
					})}

					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Controller
								name="value_function.value.formula"
								control={control}
								defaultValue={undefined}
								rules={{
									validate: () => {
										if (currentForm === 'value_function' && !watch('value_function.value.formula')) return 'Required.'
										return undefined
									},
								}}
								render={({ field }) => (
									<TextField
										{...field}
										fullWidth
										margin="none"
										label="Formula eg. {0} - {1}"
										variant="standard"
										disabled={currentForm === 'default'}
										error={!!formState.errors.value_function?.value?.formula}
										helperText={formState.errors.value_function?.value?.formula?.message}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Cancel</Button>
					<Button type="submit">Save</Button>
				</DialogActions>
			</form>
		</Dialog>
	)
}
