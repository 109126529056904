/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'

const StyledParagraph = styled.p<{ small?: boolean; fontSize?: number; lineHeight?: number; black62?: boolean }>`
	font-weight: normal;
	font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '20px')};
	line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '150%')};

	${({ small }) =>
		small &&
		css`
			font-size: 15px;
		`}

	${({ black62 }) =>
		black62 &&
		css`
			color: ${({ theme }) => theme.colors.black62};
		`}
`

function Paragraph({
	text,
	small,
	fontSize,
	lineHeight,
	black62,
}: {
	text: string
	small?: boolean
	fontSize?: number
	lineHeight?: number
	black62?: boolean
}) {
	return (
		<StyledParagraph small={small} fontSize={fontSize} lineHeight={lineHeight} black62={black62}>
			{text}
		</StyledParagraph>
	)
}

export default Paragraph
