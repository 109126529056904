import { useQueryParam, withDefault, StringParam } from 'use-query-params'
import { useEffect } from 'react'
import { useLocalStorageState } from 'src/utils/useLocalStorageState'

const CustomStringParam = withDefault(StringParam, 'USER_FORMS')

export type SelectedState = 'ALL_FORMS' | 'USER_FORMS'
type UseFormsSelectedState = [SelectedState, (state: SelectedState) => void]
let didInit = false

export function useFormsSelectedState(): UseFormsSelectedState {
	const [localState, setLocalState] = useLocalStorageState<SelectedState>('selectedForm', 'USER_FORMS')
	const [queryParamState, setQueryParamState] = useQueryParam('selected_state', CustomStringParam)

	useEffect(() => {
		if (!didInit) {
			didInit = true
			setLocalState(localState)
			setQueryParamState(localState)
		}
	}, [])

	const setFormsSelectedState = (state: SelectedState) => {
		setLocalState(state)
		setQueryParamState(state)
	}
	return [localState, setFormsSelectedState]
}
