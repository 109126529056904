/* eslint-disable react/require-default-props */

import SelectList from '../../Molecules/SelectList'
import { colors } from '../../../theme/colors'
import styled from 'styled-components'
import Button from '../../Molecules/Button'

const PrivilegesHandlerWrapper = styled.div`
	.selectHeader {
		margin: 12px 0;
		font-weight: bold;
		display: flex;
	}

	.headerSelects {
		margin-left: 40px;
	}

	.headerMain {
		font-weight: bold;
		font-size: 18px;
	}
`

interface IPrivilege {
	privilege: any
	startLevel: number
	privilegesHierarchy: any
	isDisabled: boolean
	isSaving: boolean
	isAdditional?: boolean
	isPrivilegesListLoading: boolean
	isPrivilegesTypesLoading: boolean
	handleAddNew?: (id: string, parentsIds: string[], isAdditional: boolean) => void
	handleChangeChild?: (
		id: string,
		parentsIds: string[],
		isType: boolean,
		value: any,
		parentPrivilege: any,
		isAdditional?: boolean,
	) => void
}

export const getTypeOptions = (privilege: any, privilegesHierarchy: any) => {
	if (!!privilege && !!privilegesHierarchy) {
		return privilegesHierarchy[privilege].map((value: any) => ({
			value,
			label: value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' '),
		}))
	}
	return []
}

const Privilege = ({
	privilege,
	privilegesHierarchy,
	handleAddNew,
	isDisabled,
	isSaving,
	handleChangeChild,
	isPrivilegesTypesLoading,
	isPrivilegesListLoading,
	startLevel,
	isAdditional,
}: IPrivilege) => {
	const canAddNewPrivilege = getTypeOptions(privilege.type.value, privilegesHierarchy).length > 0
	const canShow = privilege.type !== '' && privilege.value !== ''
	return (
		<PrivilegesHandlerWrapper>
			<div className="headerSelects">
				<div className="selectHeader">Type</div>
				<SelectList
					menuPlacement="top"
					isLoading={isPrivilegesTypesLoading}
					border={`1px solid ${colors.lightGreyE5}`}
					isMulti={false}
					value={isDisabled ? { value: privilege.type, label: privilege.type } : privilege.type}
					maxMenuHeight={100}
					padding="0 10px"
					options={privilege.typeOptions}
					onChange={(value: any) => {
						if (handleChangeChild) {
							handleChangeChild(
								privilege.id,
								privilege.parentsIds,
								true,
								value,
								privilege.parentPrivilege,
								isAdditional,
							)
						}
					}}
					isDisabled={isDisabled || isSaving}
				/>
				<div className="selectHeader">Privileges</div>
				<SelectList
					menuPlacement="top"
					isLoading={isPrivilegesListLoading}
					border={`1px solid ${colors.lightGreyE5}`}
					isMulti={false}
					value={isDisabled ? { value: privilege.value, label: privilege.value } : privilege.value}
					maxMenuHeight={100}
					padding="0 10px"
					options={privilege.listOptions}
					onChange={(value: any) => {
						if (handleChangeChild) {
							handleChangeChild(
								privilege.id,
								privilege.parentsIds,
								false,
								value,
								privilege.parentPrivilege,
								isAdditional,
							)
						}
					}}
					isDisabled={isDisabled || isSaving}
				/>

				{((isDisabled && privilege.additions.length > 0) || (!isDisabled && canShow && canAddNewPrivilege)) && (
					<div className="selectHeader">Additions (Level: {startLevel})</div>
				)}

				{canShow && canAddNewPrivilege && (
					<Button
						text="Add Additions"
						textColor={colors.white}
						color={colors.main100}
						isDisabled={isSaving}
						onClick={() => {
							if (handleAddNew) {
								handleAddNew(privilege.id, privilege.parentsIds, true)
							}
						}}
					/>
				)}

				{privilege.additions.map((item: any) => {
					return (
						<Privilege
							isAdditional
							startLevel={startLevel + 1}
							isDisabled={isDisabled}
							isSaving={isSaving}
							privilegesHierarchy={privilegesHierarchy}
							handleAddNew={handleAddNew}
							privilege={item}
							key={isDisabled ? Math.random() * (10 - 1) + 1 : item.id}
							handleChangeChild={handleChangeChild}
							isPrivilegesListLoading={isPrivilegesListLoading}
							isPrivilegesTypesLoading={isPrivilegesTypesLoading}
						/>
					)
				})}

				{((isDisabled && privilege.substractions.length > 0) || (!isDisabled && canShow && canAddNewPrivilege)) && (
					<div className="selectHeader">Exclusions (Level: {startLevel})</div>
				)}

				{canShow && canAddNewPrivilege && (
					<Button
						text="Add Exclusions"
						textColor={colors.white}
						color={colors.main100}
						isDisabled={isSaving}
						onClick={() => {
							if (handleAddNew) {
								handleAddNew(privilege.id, privilege.parentsIds, false)
							}
						}}
					/>
				)}

				{privilege.substractions.map((item: any) => {
					return (
						<Privilege
							isAdditional={false}
							startLevel={startLevel + 1}
							isDisabled={isDisabled}
							isSaving={isSaving}
							privilegesHierarchy={privilegesHierarchy}
							handleAddNew={handleAddNew}
							privilege={item}
							key={isDisabled ? Math.random() * (10 - 1) + 1 : item.id}
							handleChangeChild={handleChangeChild}
							isPrivilegesListLoading={isPrivilegesListLoading}
							isPrivilegesTypesLoading={isPrivilegesTypesLoading}
						/>
					)
				})}
			</div>
		</PrivilegesHandlerWrapper>
	)
}

export default Privilege
