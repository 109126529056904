import { createApi } from '@reduxjs/toolkit/query/react'
import { usersService } from 'src/facade/users/usersService'
import { SearchUser } from 'src/facade/users/usersService.types'
import { axiosBaseQuery } from 'src/utils/axiosBaseQuery'

export const usersApi = createApi({
	reducerPath: 'usersApi',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		fetchUsers: builder.query<SearchUser[], void>({
			query: () => () => usersService.fetch(),
		}),
	}),
})

export const { useFetchUsersQuery } = usersApi
