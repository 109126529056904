import { ChildForm, Form, MainForm } from 'src/facade/forms/formsService.types'

export interface AppMainForm extends MainForm {
	children: ChildForm[]
	tagCode?: string
}

export function appFormConverter(forms: Form[]): AppMainForm | null {
	const mainForm = forms.find((form) => form.is_main_form)

	if (!mainForm) {
		return null
	}

	return {
		...mainForm,
		children: forms.filter((f) => !f.is_main_form),
	} as AppMainForm
}
