import { TextField } from '@mui/material'
import {
	EditableCallbackParams,
	GetContextMenuItemsParams,
	GridApi,
	ICellRendererParams,
	ITooltipParams,
	ValueSetterParams,
} from 'ag-grid-community'
import { AgGridColumn } from 'ag-grid-react'
import { equals } from 'ramda'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { batch } from 'react-redux'
import { useParams } from 'react-router'
import { ActionCreators } from 'redux-undo'
import { fromPromise } from 'rxjs/internal-compatibility'
import { map } from 'rxjs/operators'
import CommentsIcon from 'src/assets/icons/commentsBlue.svg'
import { openAddingCommentPanel } from 'src/features/comments/commentsSlice'
import { selectIsUserAnyDidpOwner } from 'src/features/tidpOverview/selectDidpAsset'
import styled from 'styled-components'
import doneIcon from '../../../assets/icons/done.svg'
import errorIcon from '../../../assets/icons/exclamationMark.svg'
import pendingIcon from '../../../assets/icons/pending.svg'
import refreshIcon from '../../../assets/icons/refresh.svg'
import {
	addDeliverable,
	addExistingDeliverable,
	Deliverable,
	deliverablesSelector,
	DeliverableStatus,
	refetchDeliverables,
	removeDeliverable,
	removeDeliverables,
	saveDeliverables,
	syncDeliverables,
} from '../../../features/deliverables/deliverablesSlice'
import {
	getOneDeliverable,
	handleDeliverablePopup,
	setOneDeliverable,
} from '../../../features/disciplineOverview/actions'
import { taskActionResolverSelector } from '../../../features/taskActions/taskActionsSlice'
import { DeliverableAction } from '../../../services/deliverablesService'
import { usersService } from '../../../services/usersService'
import { useAppDispatch, useAppSelector, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Image from '../../Atoms/Image'
import DeliverablePopup from '../../DeliverablePopup'
import { Option } from '../../Molecules/DataGrid/AutocompleteEditor'
import { DataGrid } from '../../Molecules/DataGrid/DataGrid'
import LGButton from '../../Molecules/LGButton'
import Overview from '../../Molecules/Overview'
import { tooltipTextByActions } from './actionsHandler'

export interface DeliverablesGridUrlParams {
	param1: string
}

const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 8px;
`

const LeftButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
`

const Heading = styled.div`
	font-weight: bold;
	font-size: 96px;
	line-height: 112px;
	font-size: 16px;
	line-height: 19px;
`

const StyledGridContainer = styled.div`
	display: flex;
	width: 100%;
	.ag-header-cell,
	.ag-cell {
		padding: 4px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.ag-cell {
		padding-top: 10px;
		display: table-cell;
		word-break: keep-all;
	}
	.ag-header-cell-label {
		white-space: normal;
		line-height: 1.2;
	}
`

const loadUsernames = (query: string): Promise<string[]> => {
	return fromPromise(usersService.fetch(query))
		.pipe(map((users) => users.map((u) => u.username).sort((a, b) => a.localeCompare(b))))
		.toPromise()
}

export const DeliverablesGrid: FC = memo(() => {
	const dispatch = useAppDispatch()

	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	const [newDeliverableCount, setNewDeliverableCount] = useState<number>(1)
	const [existingDeliverableCount, setExistingDeliverableCount] = useState<number>(1)
	const { param1: tidpTagCode } = useParams<DeliverablesGridUrlParams>()
	const { isOpenDeliverablePopup, isChangingDeliverable, isLoadingDeliverable, oneDeliverableTasks } =
		useAppSelectorDeprecated((state) => state.disciplineOverview)
	const { isActionProcessing } = useAppSelectorDeprecated((state) => state.disciplineOverview)
	const {
		index: changesCount,
		past,
		future,
		present: { options, isSaving, loading },
	} = useAppSelector((state) => state.deliverables)

	const taskActionResolver = useAppSelector(taskActionResolverSelector)
	const deliverables = useAppSelector(deliverablesSelector)
	const { tidp } = useAppSelector((state) => state.tidpOverview)
	const isUserAnyDidpOwner = useAppSelector(selectIsUserAnyDidpOwner)
	const username = useAppSelector((state) => state.profile.user!.name)

	const rows: Deliverable[] = useMemo(() => deliverables.map((deliverable) => ({ ...deliverable })), [deliverables])

	const sync = useCallback(() => {
		dispatch(syncDeliverables(rows))
	}, [dispatch, rows])

	const canUpdate: boolean = taskActionResolver.has(DeliverableAction.UPDATE)
	const canCreate: boolean = taskActionResolver.has(DeliverableAction.CREATE)
	const canCreateExisting: boolean = taskActionResolver.has(DeliverableAction.CREATE_EXISTING)
	const canRemove: boolean = taskActionResolver.has(DeliverableAction.REMOVE)
	const highlightedDeliverableTagCode = useAppSelector((state) => state.comments.highlightedDeliverableTagCode)

	const isEditable = useCallback(
		({ data, colDef }: EditableCallbackParams) => {
			if (isActionProcessing) {
				return false
			}
			const deliverable: Deliverable = data

			if (
				(deliverable?.status === 'ORIGINAL' || deliverable?.status === 'MODIFIED') &&
				canUpdate &&
				deliverable?.deliverableStatus !== 'Done' &&
				deliverable?.deliverableStatus !== 'In progress'
			) {
				return (
					[
						'documentType',
						'title',
						'hs2Deliverable',
						'author',
						'GDPR',
						'stage',
						'suitabilityStatus',
						'discipline',
					] as (keyof Deliverable)[]
				).includes(colDef.field as keyof Deliverable)
			}
			if (deliverable?.status === 'CREATED') {
				return deliverable?.type === 'NEW'
					? (
							[
								'documentType',
								'title',
								'hs2Deliverable',
								'author',
								'GDPR',
								'stage',
								'suitabilityStatus',
								'discipline',
							] as (keyof Deliverable)[]
					  ).includes(colDef.field as keyof Deliverable)
					: (['documentNumber', 'revision'] as (keyof Deliverable)[]).includes(colDef.field as keyof Deliverable)
			}
			return false
		},
		[canUpdate, isActionProcessing],
	)

	const handleSave = useCallback(() => {
		dispatch(saveDeliverables({ tidpTagCode, deliverables }))
	}, [dispatch, tidpTagCode, deliverables])

	const closeDeliverablePopup = useCallback(() => {
		batch(() => {
			dispatch(handleDeliverablePopup(false))
			dispatch(setOneDeliverable([]))
		})
	}, [dispatch])

	const rowData = useMemo(() => rows.filter((row) => row.status !== 'REMOVED'), [rows])
	const disciplineTagCode: any = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
	let doneStatusesCount: number = rowData.filter((x) => x.deliverableStatus === 'Done').length
	let errorStatusesCount: number = rowData.filter((x) => x.deliverableStatus === 'Error').length

	const isUserTidpOwner = (tidp?.attribute?.['TIDP Owner'] || []).includes(username)
	const getCustomContextMenuItems = (params: GetContextMenuItemsParams) =>
		tidp && !(isUserAnyDidpOwner || isUserTidpOwner)
			? [
					{
						name: 'Add comment',
						action: function () {
							dispatch(
								openAddingCommentPanel({
									isAddingComment: true,
									disciplineTagCode: disciplineTagCode,
									deliverableTagCode: params.node?.id,
								}),
							)
						},
						icon: `<img alt="comments_icon" src="${CommentsIcon}" width="16px" />`,
					},
			  ]
			: []

	const highlightDeliverable = () => {
		const rowNode = highlightedDeliverableTagCode && gridApi?.getRowNode(highlightedDeliverableTagCode)
		if (rowNode) {
			rowNode.setSelected(true)
			const rowIndex = rowNode !== null && rowNode !== undefined ? rowNode.rowIndex! : 0
			gridApi?.ensureIndexVisible(rowIndex, 'top')
		} else {
			gridApi?.deselectAll()
		}
	}

	useEffect(() => {
		highlightDeliverable()
	}, [highlightedDeliverableTagCode])

	return (
		<Overview
			title="Deliverables"
			leftIconLoading={loading === 'pending'}
			leftIcon={refreshIcon}
			onClickIcon={() => dispatch(refetchDeliverables(tidpTagCode))}
		>
			<Heading>
				Deliverables : {doneStatusesCount} || Errors : {errorStatusesCount}
			</Heading>

			<DeliverablePopup
				title=""
				tasksList={oneDeliverableTasks}
				isPopupOpen={isOpenDeliverablePopup}
				isChanging={isChangingDeliverable}
				isLoading={isLoadingDeliverable}
				onClose={closeDeliverablePopup}
			/>
			<StyledGridContainer>
				<DataGrid
					fullWidth
					immutableData
					suppressReactUi
					onGridReady={(event) => {
						setGridApi(event.api)
					}}
					onRowDataUpdated={highlightDeliverable}
					getCustomContextMenuItems={getCustomContextMenuItems}
					onRemove={(rowsToRemove) => {
						dispatch(removeDeliverables(rowsToRemove))
						handleSave()
					}}
					sync={sync}
					withConfirmation
					loading={loading === 'pending'}
					statusBarConfig={{
						canUndo: past.length > 0,
						onUndo: () => dispatch(ActionCreators.undo()),
						canRedo: future.length > 0,
						onRedo: () => dispatch(ActionCreators.redo()),
						onSave: handleSave,
						isSaving,
						autoSave: true,
					}}
					changesCount={changesCount}
					rowData={rowData}
					getRowNodeId={(data: Deliverable) => data.tagCode ?? data.id}
					frameworkComponents={{
						statusMessagesRenderer: StatusMessagesRenderer,
					}}
				>
					<AgGridColumn
						width={48}
						wrapText={false}
						field="label"
						valueGetter={({ node }) => (node?.rowIndex ?? 0) + 1}
						headerName=""
						suppressMovable
						sortable={false}
						filter={false}
					/>
					<AgGridColumn
						width={240}
						field="documentNumber"
						headerName="Document Number"
						editable={isEditable}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
						cellStyle={{
							'justify-content': 'left',
						}}
					/>
					<AgGridColumn
						width={90}
						field="revision"
						headerName="Revision"
						editable={isEditable}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
					/>
					<AgGridColumn
						width={218}
						field="documentType"
						headerName="Document Type"
						editable={isEditable}
						cellEditor="autocompleteEditor"
						cellEditorParams={{
							options: options.documentType,
						}}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
						valueFormatter={({ value }) =>
							options.documentType?.find((option: Option) => option.value === value)?.label
						}
					/>
					<AgGridColumn
						width={240}
						field="title"
						headerName="Deliverable Title"
						headerTooltip="When creating a new deliverable, this title will be prefixed with 'Document Type' - 'Primary Asset Name' - 'Free Text'. Please enter your Free Text here."
						editable={isEditable}
						cellStyle={{
							'justify-content': 'left',
						}}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
					/>
					<AgGridColumn
						width={60}
						field="hs2Deliverable"
						headerName="HS2 Deliverable"
						editable={isEditable}
						cellEditor="autocompleteEditor"
						cellEditorParams={{
							options: options.hs2Deliverable,
						}}
						valueFormatter={({ value }) =>
							options.hs2Deliverable?.find((option: Option) => option.value === value)?.label
						}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
					/>
					<AgGridColumn
						width={60}
						field="GDPR"
						headerName="GDPR"
						editable={isEditable}
						cellEditor="autocompleteEditor"
						cellEditorParams={{
							options: options.GDPR,
						}}
						valueFormatter={({ value }) => options.GDPR?.find((option: Option) => option.value === value)?.label}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
					/>
					<AgGridColumn
						width={150}
						field="stage"
						headerName="Stage"
						editable={isEditable}
						cellEditor="autocompleteEditor"
						cellEditorParams={{
							options: options.stage,
						}}
						valueFormatter={({ value }) => options.stage?.find((option: Option) => option.value === value)?.label}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
					/>
					<AgGridColumn
						width={150}
						field="author"
						headerName="Author"
						editable={isEditable}
						cellEditor="asyncAutocompleteEditor"
						valueSetter={({ newValue, data }: ValueSetterParams) => {
							if (Array.isArray(newValue)) {
								// eslint-disable-next-line no-param-reassign
								data.author = newValue
								return true
							}
							return false
						}}
						equals={(authors1, authors2) => {
							return equals(new Set(authors1), new Set(authors2))
						}}
						cellEditorParams={{
							isMulti: true,
							loadOptions: loadUsernames,
						}}
						cellStyle={{
							'justify-content': 'left',
						}}
						valueFormatter={({ value }) => (Array.isArray(value) ? value?.join(', ') : '')}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
					/>
					<AgGridColumn
						width={120}
						field="suitabilityStatus"
						headerName="Suitability Status"
						editable={isEditable}
						cellEditor="autocompleteEditor"
						cellEditorParams={{
							options: options.suitabilityStatus,
						}}
						valueFormatter={({ value }) =>
							options.suitabilityStatus?.find((option: Option) => option.value === value)?.label
						}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
					/>
					<AgGridColumn
						width={100}
						field="discipline"
						headerName="Discipline"
						editable={isEditable}
						cellEditor="autocompleteEditor"
						cellEditorParams={{
							options: options.discipline,
						}}
						cellStyle={{
							'justify-content': 'left',
						}}
						valueFormatter={({ value }) => options.discipline?.find((option: Option) => option.value === value)?.label}
						tooltipValueGetter={(p: ITooltipParams) => p.value}
					/>
					<AgGridColumn
						width={100}
						field="deliverableStatus"
						sortable={false}
						pinned={'right'}
						headerName="Deliverable Status"
						cellRenderer="statusMessagesRenderer"
						cellRendererParams={{
							onClick: (deliverable: Deliverable) => dispatch(getOneDeliverable(deliverable.tagCode)),
						}}
					/>
					<AgGridColumn
						width={40}
						pinned={'right'}
						headerName=""
						valueGetter="status"
						suppressFiltersToolPanel
						sortable={false}
						filter={false}
						cellRenderer="actionRenderer"
						cellRendererParams={{
							removeOptions: {
								withConfirmation: true,
								canRemove,
								onRemove: (deliverable: Deliverable) => {
									dispatch(removeDeliverable(deliverable))
								},
							},
						}}
					/>
				</DataGrid>
			</StyledGridContainer>

			<ButtonsWrapper>
				<LeftButtonsWrapper>
					<LGButton
						disabled={!canCreate}
						tooltip={tooltipTextByActions(DeliverableAction.CREATE)}
						padding="0 20px"
						onClick={() => dispatch(addDeliverable(newDeliverableCount))}
						text="Add new deliverable"
						height={36}
						isWhiteText
					/>
					<TextField
						id="new-deliverable-count"
						type="number"
						variant="standard"
						sx={{ marginLeft: 1, marginRight: 3, width: 48 }}
						value={newDeliverableCount}
						onChange={(e) => setNewDeliverableCount(+e.currentTarget.value)}
						inputProps={{ min: '0', step: '1' }}
					/>
					<LGButton
						disabled={!canCreateExisting}
						tooltip={tooltipTextByActions(DeliverableAction.CREATE_EXISTING)}
						padding="0 20px"
						margin="0 0 0 10px"
						onClick={() => dispatch(addExistingDeliverable(existingDeliverableCount))}
						text="Select existing deliverable"
						height={36}
						isWhiteText
					/>
					<TextField
						id="existing-deliverable-count"
						type="number"
						variant="standard"
						sx={{ marginLeft: 1, width: 48 }}
						value={existingDeliverableCount}
						onChange={(e) => setExistingDeliverableCount(+e.currentTarget.value)}
						inputProps={{ min: '0', step: '1' }}
					/>
				</LeftButtonsWrapper>
			</ButtonsWrapper>
		</Overview>
	)
})

DeliverablesGrid.displayName = 'DeliverablesGrid'

interface StatusMessagesRendererParams extends ICellRendererParams {
	onClick(deliverable: Deliverable): void
}

const STATUS_ICON_MAP: Record<DeliverableStatus, string> = {
	'In queue': pendingIcon,
	'In progress': pendingIcon,
	Error: errorIcon,
	Done: doneIcon,
	Empty: '',
}

const StatusMessagesRenderer = ({ value, data, onClick }: StatusMessagesRendererParams) =>
	value !== 'Empty' &&
	!!value && (
		<Image
			onClick={() => onClick(data)}
			isClickable
			width="20px"
			height="20px"
			imageSrc={STATUS_ICON_MAP[value as DeliverableStatus]}
			alt="status_for_deliverable"
		/>
	)
