import ReplyIcon from '@mui/icons-material/Reply'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'
import { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { dateFormat } from 'src/assets/dateFormats'
import { changeCommentsStatus, highlightDeliverable, TidpComment } from 'src/features/comments/commentsSlice'
import { selectIsUserAnyDidpOwner } from 'src/features/tidpOverview/selectDidpAsset'
import { IReply } from 'src/models/disciplineOverview/disciplineOverview'
import { colors } from 'src/theme/colors'
import { useAppDispatch, useAppSelector, useAppSelectorDeprecated } from 'src/utils/reduxUtils'
import styled from 'styled-components'
import { AddReply } from './AddReply'
import { Reply } from './Reply'
import { FormattedComment } from './FormattedComment'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const Circle = styled.div`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.greyBD};
	color: ${colors.white};
	font-weight: 700;
	border-radius: 50%;
`

const CommentHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: '3px';
	margin: 4px;
`

const UserData = styled.div`
	font-size: 13px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`
const UserName = styled.div`
	max-width: 113px;
	font-size: 13px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

const CommentCodeWrapper = styled.div`
	cursor: default;
	font-size: 13px;
	font-weight: 500;
	border-radius: 50%;
	border: 1px solid black;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const CommentContent = styled.div`
	width: 100%;
	padding-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
	padding-bottom: 12px;
	white-space: pre-line;
	line-height: 1.1;
`

const RepliesWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-left: 30px;
	margin-top: 4px;
`

const CommentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	backgroundcolor: ${colors.lightGreyF10};
	margin-bottom: 8px;
`

const StyledOpenLabel = styled.div`
	color: ${colors.main100};
`

const CommentLabel = styled.div`
	display: flex;
	justify-content: flex-end;
	color: ${colors.black25};
	padding: 5px;
`

interface CommentProps {
	comment: TidpComment & { replies: IReply[] }
	saveReply: (text: string, commentId: string, tidpId: string, commentCode: string) => void
	tagCode: string
	reviewers: Array<string>
	owners: Array<string>
}

export const CommentCard = memo(function (props: CommentProps) {
	const dispatch = useAppDispatch()
	const username = useAppSelector((state) => state.profile.user!.name)
	const history = useHistory()
	const [isAddingReply, setIsAddingReply] = useState(false)
	const { contract } = useAppSelectorDeprecated((state) => state.users)
	const { loadingComments } = useAppSelectorDeprecated((state) => state.comments)
	const { tidp } = useAppSelector((state) => state.tidpOverview)

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [openStatusMenu, setOpenStatusMenu] = useState(false)

	const isUserReviewer = (tidp?.attribute?.['External Stakeholders'] || []).includes(username)
	const isUserReviewerCoordinator = (tidp?.attribute?.['Internal Stakeholders'] || []).includes(username)
	const isUserAnyDidpOwner = useAppSelector(selectIsUserAnyDidpOwner)
	const isUserTidpOwner = (tidp?.attribute?.['TIDP Owner'] || []).includes(username)
	const canAddReplyWhenTidpIssuedForReview =
		tidp?.attribute?.['TIDP Status'] === 'Issued for Review' && (isUserReviewer || isUserReviewerCoordinator)
	const canAddReplyWhenTidpWIP = tidp?.attribute?.['TIDP Status'] === 'WIP' && (isUserAnyDidpOwner || isUserTidpOwner)
	const canAddReplyWhenTidpReadyForReview =
		tidp?.attribute?.['TIDP Status'] === 'Ready for Review' && (isUserAnyDidpOwner || isUserTidpOwner)
	const canAddReply =
		props.comment.Status !== 'Closed' &&
		(canAddReplyWhenTidpIssuedForReview || canAddReplyWhenTidpWIP || canAddReplyWhenTidpReadyForReview)
	const commentAuthor = props.comment['Recorded By'].substring(0, props.comment['Recorded By'].indexOf('@'))

	const handleCloseComment = () => {
		handleCloseStatusMenu()
		dispatch(
			changeCommentsStatus({
				status: 'Closed',
				contract: contract.contract,
				tagCode: props.comment.tag_code,
			}),
		)
	}

	const handleLocateComment = () => {
		const path = !props.comment.selections
			? ''
			: 'tidp_tag_code' in props.comment.selections
			? `/overview/${props.comment.selections.tidp_tag_code}`
			: `/discipline-overview/${props.comment.selections.didp_tag_code}`

		if (!location.pathname.includes(path)) {
			history.push(path)
		}

		if (props.comment.selections && 'deliverable_tag_code' in props.comment.selections) {
			dispatch(highlightDeliverable(props.comment.selections.deliverable_tag_code))
		}
	}

	const handleOpenStatusMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenStatusMenu(true)
		setAnchorEl(event.currentTarget)
	}

	const handleCloseStatusMenu = () => {
		setOpenStatusMenu(false)
		setAnchorEl(null)
	}

	return (
		<CommentWrapper>
			<Paper
				elevation={0}
				sx={{ margin: '0px', backgroundColor: `${colors.lightGreyF10}`, borderRadius: '7px', px: 1 }}
			>
				<CommentHeader>
					{props.comment.selections && (
						<IconButton sx={{ height: '30px', width: '30px' }} onClick={handleLocateComment}>
							<VisibilityIcon />
						</IconButton>
					)}
					<span>{props.comment['Comment Number']}</span>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Circle>{props.comment['Recorded By'].substring(0, 2)}</Circle>
						<Tooltip title={commentAuthor} placement="top" arrow>
							<UserName>{commentAuthor}</UserName>
						</Tooltip>
					</Box>

					<UserData>{moment(new Date(props.comment['Recorded At'])).format(dateFormat.engHours)}</UserData>
					<Tooltip title={props.comment.Code} placement="top" arrow>
						<CommentCodeWrapper>{props.comment.Code?.split(/[–-]+/)?.[0]}</CommentCodeWrapper>
					</Tooltip>
					<LoadingButton
						size="small"
						loading={loadingComments.includes(props.comment.tag_code)}
						variant={props.comment.Status === 'Closed' ? 'text' : 'outlined'}
						disabled={props.comment.Status === 'Closed'}
						onClick={handleOpenStatusMenu}
						endIcon={props.comment.Status === 'Closed' ? '' : <KeyboardArrowDownIcon />}
					>
						{props.comment.Status === 'Closed' ? 'Closed' : <StyledOpenLabel>Open</StyledOpenLabel>}
					</LoadingButton>
					<Menu
						id="status-menu"
						anchorEl={anchorEl}
						open={openStatusMenu}
						onClose={handleCloseStatusMenu}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						slotProps={{
							paper: {
								style: {
									height: '40px',
									width: '76px',
									alignContent: 'center',
									alignItems: 'center',
									justifyContent: 'flex-start',
									display: 'flex',
								},
							},
						}}
					>
						<MenuItem
							style={{
								width: '76px',
								padding: 0,
								paddingLeft: '9px',
								paddingTop: '2px',
								paddingBottom: '2px',
								fontSize: '13px',
								fontFamily: 'Roboto',
								fontWeight: 'bold',
							}}
							onClick={handleCloseComment}
						>
							Close
						</MenuItem>
					</Menu>
				</CommentHeader>
				<CommentContent>
					<FormattedComment comment={props.comment.Message} />
				</CommentContent>
				<CommentLabel>{props.comment.commentLabel}</CommentLabel>
			</Paper>
			{props.comment.replies
				.slice()
				.sort((r1, r2) => +new Date(r1.recordAt) - +new Date(r2.recordAt))
				.map((reply) => (
					<Reply key={reply.tagCode} reply={reply} />
				))}
			{!isAddingReply && canAddReply && (
				<RepliesWrapper>
					<Button
						endIcon={<ReplyIcon />}
						sx={{ height: '35px', maxWidth: '125px', color: 'gray', borderRadius: '25px' }}
						onClick={() => setIsAddingReply(true)}
					>
						Reply
					</Button>
				</RepliesWrapper>
			)}
			{isAddingReply && (
				<AddReply
					saveReply={props.saveReply}
					commentTagCode={props.comment.tag_code}
					tagCode={props.tagCode}
					reviewers={props.reviewers}
					owners={props.owners}
				/>
			)}
		</CommentWrapper>
	)
})
