import {
	Autocomplete,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Grid,
	Popper,
	TextField,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { FC, useEffect, useState } from 'react'
import { DependsOnCondition } from 'src/components/Organisms/Forms/DependsOnConditionalRenderer'
import { DEPENDS_ON_OPTIONS } from 'src/components/Organisms/Forms/dependsOnOptions'
import { AttributeDependsOn } from 'src/facade/forms/formsService.types'
import { IFormComponentsAttributes } from 'src/models/formsHandler/formsHandler'

export interface DependsOnConditionalDialogProps {
	open: boolean
	onClose(): void
	onChange(dependsOnValue: DependsOnCondition | null): void
	dependsOnOptions: {
		value: string | number
		label: string
		category: string
		componentId: string
	}[]
	components: IFormComponentsAttributes[]
	defaultValue?: DependsOnCondition
}

export const DependsOnConditionalDialog: FC<DependsOnConditionalDialogProps> = ({
	open,
	defaultValue,
	onChange,
	onClose,
	dependsOnOptions,
	components,
}) => {
	const [dependsOnValue, setDependsOnValue] = useState<DependsOnCondition | null>(null)

	useEffect(() => {
		if (defaultValue) {
			setDependsOnValue({
				argument: defaultValue?.argument,
				argumentComponentId: defaultValue?.argumentComponentId,
				condition: defaultValue?.condition,
				conditionValue: defaultValue?.conditionValue,
			})
		}
	}, [defaultValue])

	const selectedArgument =
		dependsOnOptions
			// eslint-disable-next-line @typescript-eslint/no-shadow
			.find(({ value }) => value === dependsOnValue?.argument) || null

	const selectedCondition =
		Object.entries(DEPENDS_ON_OPTIONS)
			.map(([label, val]) => ({
				label,
				value: val,
			}))
			// eslint-disable-next-line @typescript-eslint/no-shadow
			.find(({ value }) => value === dependsOnValue?.condition) || null

	const componentsWithArgument = components
		.filter((component) =>
			component.attributes.map((attr) => attr.attribute_name.value).includes(dependsOnValue?.argument),
		)
		.slice()
		.sort((comp1, comp2) => comp1.sequence - comp2.sequence)
		.map((component) => ({ value: component.id as string, label: component.displayName }))

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>Depends on conditional</DialogTitle>
			<DialogContent>
				<DialogContentText>
					This attribute will not display until the attribute that it depends on meets the following condition.
				</DialogContentText>
				<DialogContentText>Please enter a condition and a value for the depends on attribute.</DialogContentText>

				<FormControlLabel
					onChange={() => {
						if (dependsOnValue?.condition === AttributeDependsOn.PreRequisite) {
							setDependsOnValue(null)
						} else {
							setDependsOnValue(() => ({
								argument: undefined,
								condition: AttributeDependsOn.PreRequisite,
								conditionValue: undefined,
							}))
						}
					}}
					control={<Checkbox checked={dependsOnValue?.condition === AttributeDependsOn.PreRequisite} />}
					label="Prerequisite"
					sx={{ mt: 1 }}
				/>

				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Autocomplete
							PopperComponent={(props) => (
								<Popper
									{...props}
									sx={{
										'& .MuiAutocomplete-groupLabel': {
											lineHeight: '28px',
											color: grey[900],
											background: grey[200],
										},
									}}
								/>
							)}
							options={dependsOnOptions}
							groupBy={(option) => option.category}
							disabled={dependsOnValue?.condition === AttributeDependsOn.PreRequisite}
							value={selectedArgument}
							renderInput={(params) => (
								<TextField {...params} margin="none" label="Condition argument" fullWidth variant="standard" />
							)}
							onChange={(e, option) => {
								setDependsOnValue((prevVal) => ({
									...prevVal,
									argument: (option?.value as string) || undefined,
								}))
							}}
						/>
						{componentsWithArgument.length > 1 && (
							<Autocomplete
								sx={{ mt: 1 }}
								disabled={dependsOnValue?.condition === AttributeDependsOn.PreRequisite}
								options={componentsWithArgument}
								value={
									componentsWithArgument.find(({ value }) => value === dependsOnValue?.argumentComponentId) || null
								}
								renderInput={(params) => (
									<TextField
										{...params}
										margin="none"
										label="Argument component (optional)"
										fullWidth
										variant="standard"
									/>
								)}
								onChange={(e, option) => {
									setDependsOnValue((prevVal) => ({
										...prevVal,
										argumentComponentId: option?.value,
									}))
								}}
								getOptionLabel={(option) => `${option.label} (${option.value})`}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={4}>
						<Autocomplete
							disabled={dependsOnValue?.condition === AttributeDependsOn.PreRequisite}
							options={Object.entries(DEPENDS_ON_OPTIONS).map(([label, val]) => ({
								label,
								value: val,
							}))}
							value={selectedCondition}
							isOptionEqualToValue={(val1, val2) => val1.value === val2.value}
							renderInput={(params) => (
								<TextField {...params} margin="none" label="Condition operator" fullWidth variant="standard" />
							)}
							onChange={(e, option) => {
								setDependsOnValue((prevVal) => ({
									...prevVal,
									condition: option?.value,
								}))
							}}
						/>
					</Grid>
					{dependsOnValue?.condition &&
						!['populated', 'not_populated', AttributeDependsOn.PreRequisite].includes(
							dependsOnValue?.condition || '',
						) && (
							<Grid item xs={12} sm={4}>
								<TextField
									margin="none"
									label="Condition value"
									variant="standard"
									fullWidth
									value={dependsOnValue?.conditionValue || ''}
									onChange={(event) =>
										setDependsOnValue((prevVal) => ({
											...prevVal,
											conditionValue: event.target.value,
										}))
									}
								/>
							</Grid>
						)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={() => onChange(dependsOnValue)}>Save</Button>
			</DialogActions>
		</Dialog>
	)
}
