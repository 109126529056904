import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export function ConfirmationDialog({
	open,
	onClose,
	onConfirmed,
	title,
	subtitle,
}: {
	open: boolean
	onClose?(): void
	onConfirmed?(): void
	title?: string
	subtitle?: string
}) {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{subtitle}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onClose?.()
					}}
				>
					Cancel
				</Button>
				<Button
					onClick={() => {
						onConfirmed?.()
					}}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	)
}
