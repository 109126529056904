import { createAction, createSlice } from '@reduxjs/toolkit'
import camelcaseKeys from 'camelcase-keys'
import { SaveCommentForm } from 'src/components/Molecules/Comments/AddComment'
import { saveTidpDocumentCommentThunk } from 'src/features/tidpOverview/saveTidpDocumentCommentThunk'
import { Attribute } from '../../services/comments'

interface CommentsState {
	attributes: Record<string, Attribute>
	isChangingComment: boolean
	loadingComments: string[]
	isSaving: boolean
	isAddingComment: boolean
	disciplineTagCode?: string | undefined
	selectedDeliverableData: string | undefined
	highlightDeliverableComment?: string | undefined
	highlightedDeliverableTagCode?: string | undefined
}

const initialState: CommentsState = {
	attributes: {},
	isChangingComment: false,
	loadingComments: [],
	isSaving: false,
	isAddingComment: false,
	selectedDeliverableData: '',
}

export const fetchCommentsAttributes = createAction('comments/fetchAttributes')
export const fetchCommentsAttributesSuccess = createAction<any[]>('comments/fetchAttributes/success')

export const changeCommentsStatus = createAction<{ status: string; tagCode: string; contract: string }>(
	'comments/changeStatus',
)
export const setIsChangingComment = createAction<{ loading: boolean; tagCode?: string }>(
	'comments/setIsChangingComment',
)

export interface TidpComment {
	id: string
	type: string
	disciplineTagCode?: string
	documentTagCode?: string
	comment: string
	recordBy: string
	recordAt: Date
	status: string
	tagCode: string
	code: string
	Code: string
	Status: string
	tag_code: string
	Message: string
	'Recorded By': string
	'Recorded At': string
	'Comment Number': string
	selections?: TidpDocumentCommentSelections
	commentLabel?: string
}

export type TidpDocumentCommentSelections =
	| { tidp_tag_code: string }
	| { didp_tag_code: string }
	| { didp_tag_code: string; deliverable_tag_code: string }

export const openAddingCommentPanel = createAction<{
	isAddingComment: boolean
	disciplineTagCode?: string
	deliverableTagCode?: string
}>('comments/addComment')
export const highlightDeliverable = createAction<string | undefined>('comments/highlightDeliverable')

export const commentsSlice = createSlice({
	name: 'comments',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(highlightDeliverable.match, (state, action) => {
				state.highlightedDeliverableTagCode = action.payload
			})
			.addMatcher(openAddingCommentPanel.match, (state, action) => {
				state.isAddingComment = action.payload.isAddingComment
				state.disciplineTagCode = action.payload.disciplineTagCode
				state.highlightedDeliverableTagCode = action.payload.deliverableTagCode
			})
			.addMatcher(fetchCommentsAttributesSuccess.match, (state, { payload }) => {
				const attrs = payload?.find((el: { class_code: string }) => el.class_code === 'Comment')
				if (attrs) {
					state.attributes = attrs.attributes.reduce(
						(pV: any, cV: any) => ({
							...pV,
							[cV.attribute_name]: camelcaseKeys(cV),
						}),
						{},
					)
				}
			})
			.addMatcher(setIsChangingComment.match, (state, { payload }) => {
				state.isChangingComment = payload.loading
				if (payload.tagCode) {
					if (payload.loading) {
						state.loadingComments.push(payload.tagCode)
					} else {
						state.loadingComments = state.loadingComments.filter((tc) => tc !== payload.tagCode)
					}
				}
			})

			.addMatcher(saveTidpDocumentCommentThunk.rejected.match, (state) => {
				state.isAddingComment = false
				state.highlightedDeliverableTagCode = undefined
			})
			.addMatcher(saveTidpDocumentCommentThunk.fulfilled.match, (state) => {
				state.isAddingComment = false
				state.highlightedDeliverableTagCode = undefined
			})
	},
})

export const commentsReducer = commentsSlice.reducer
