/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

const StyledInput = styled.input<{ backgroundColor: string; border?: string; isDisabled?: boolean; height?: string }>`
	background-color: ${({ backgroundColor }) => backgroundColor};
	border: ${({ border }) => border || 'none'};
	border-radius: 8px;
	min-height: 32px;
	padding: 0 12px;
	font-family: 'Roboto';
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	width: 100%;
	cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'cursor')};
	height: ${({ height }) => height || ''};
	&::placeholder {
		color: ${colors.greyBD};
	}
`

interface IInput {
	value: string | number
	onChange?: (value: string | number) => void
	onClick?: () => void
	backgroundColor?: string
	isDisabled?: boolean
	autoFocus?: boolean
	className?: string
	placeholder?: string
	border?: string
	height?: string
	type?: string
	min?: number
}

function Input({
	min,
	type,
	className,
	value,
	onChange,
	onClick,
	backgroundColor,
	isDisabled,
	autoFocus,
	placeholder,
	height,
	border,
}: IInput) {
	return (
		<StyledInput
			type={type}
			height={height}
			className={className}
			border={border}
			placeholder={placeholder}
			onClick={onClick}
			min={min}
			isDisabled={isDisabled}
			// eslint-disable-next-line jsx-a11y/no-autofocus
			autoFocus={autoFocus}
			backgroundColor={backgroundColor || colors.main5}
			value={value}
			onChange={(text) => onChange && onChange(text.target.value)}
			disabled={isDisabled}
		/>
	)
}

export default Input
