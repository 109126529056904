import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { SET_IS_LOADING_TASK, SET_TASK_ACTION } from './constants'
import { ITasksActions } from '../../models/tasksActions/tasksActions'

export type TasksActions = ActionType<typeof actions>

const initialTasksActionsReducer: ITasksActions = {
	tasksActions: [],
	isLoadingTasksActions: false,
}

const tidpsOverviewReducer = (state: ITasksActions = initialTasksActionsReducer, action: TasksActions) => {
	switch (action.type) {
		case SET_TASK_ACTION: {
			return { ...state, tasksActions: action.payload }
		}
		case SET_IS_LOADING_TASK: {
			return { ...state, isLoadingTasksActions: action.payload }
		}
		default:
			return state
	}
}

export default tidpsOverviewReducer
