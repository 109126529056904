/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../../../theme/colors'

interface ITextArea {
	placeholder: string
	width?: string
	border?: string
	color?: string
	value?: string | number
	defaultValue?: string | number
	minHeight?: string
	height?: string
	onChange?: (e: string) => void
	isDisabled?: boolean
	backgroundColor?: string
	placeholderFontsize?: string
	padding?: string
	autoFocus?: boolean
	onFocus?: () => void
	onBlur?: (e: any) => void
	isCentered?: boolean
}

const replacer = (item: string) => {
	if (item.indexOf('%') !== -1) {
		return `${Number(item.replace(' ', '').replace('!important', '').replace('%', '')) / 2}px`
	}
	return `${Number(item.replace(' ', '').replace('!important', '').replace('px', '')) / 2}px`
}

const StyledTextArea = styled.textarea<{
	height?: string
	isCentered?: boolean
	padding?: string
	backgroundColor?: string
	placeholderFontsize: string
	color: string
	minHeight?: string
	border?: string
	width?: string
}>`
	background: transparent;
	font-family: ${({ theme }) => theme.fonts.mainFont};
	border: ${({ border }) => border || 'none'};
	background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
	width: ${({ width }) => width || '100%'};
	height: ${({ height }) => height || '100%'};
	border-radius: 10px;
	min-height: ${({ minHeight }) => minHeight || '150px'};
	padding: ${({ padding }) => padding || '20px'};

	${({ isCentered, height, placeholderFontsize, padding }) => {
		if (height && placeholderFontsize) {
			return (
				isCentered &&
				height &&
				css`
					text-align: center;

					&::placeholder {
						transform: translateY(
							calc(${replacer(height)} - ${replacer(placeholderFontsize)} - (${padding || '20px'}))
						);
					}
				`
			)
		}
	}}
	&::-webkit-scrollbar-thumb {
		display: none;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	::-webkit-resizer {
		display: none;
	}

	&::placeholder {
		color: ${({ color }) => color || colors.black};
		font-size: ${({ placeholderFontsize }) => placeholderFontsize || '16px'};
	}
`

function TextArea({
	placeholder,
	isCentered,
	onFocus,
	autoFocus,
	height,
	padding,
	color,
	isDisabled,
	backgroundColor,
	placeholderFontsize,
	border,
	onChange,
	value,
	onBlur,
	minHeight,
	width,
	defaultValue,
}: ITextArea) {
	return (
		<StyledTextArea
			defaultValue={defaultValue}
			border={border}
			// eslint-disable-next-line jsx-a11y/no-autofocus
			autoFocus={autoFocus}
			width={width}
			disabled={isDisabled}
			minHeight={minHeight}
			maxLength={500}
			onBlur={onBlur}
			height={height}
			padding={padding}
			value={value}
			onFocus={onFocus}
			isCentered={isCentered}
			placeholderFontsize={placeholderFontsize || '16px'}
			backgroundColor={backgroundColor}
			onChange={(e) => (onChange ? onChange(e.target.value) : null)}
			placeholder={placeholder}
			color={color || colors.blue1}
		/>
	)
}

export default TextArea
