import { axiosInstance } from 'src/services/axiosInstance'
import {
	DOWNLOAD_GUIDE,
	GET_CLASSES,
	REACT_COMPONENT_UPDATE,
	REACT_FORMS_ATTRIBUTES,
	REACT_FORMS_CREATE,
	REACT_FORMS_TYPES,
	REACT_FORMS_UPDATE,
	REMOVE_GUIDE,
	UPLOAD_GUIDE,
} from '../constants'

export const getForm = async (body: { id: any; contract: string; counter?: number }) => {
	const { data } = await axiosInstance.get(
		`${REACT_FORMS_ATTRIBUTES}?contract=${body.contract}&ids=${body.id}&page_size=${body.counter ? body.counter : '100'
		}`,
	)
	return data
}

export const getComponentAttributeTypes = async () => {
	const { data } = await axiosInstance.get(REACT_FORMS_TYPES)
	return data
}

export const getClassMetadata = async (value: string, contract: string) => {
	const { data } = await axiosInstance.get(`${GET_CLASSES}?contract=${contract}&class_code=${value}`)
	return data
}

export const createNewForm = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_FORMS_CREATE, body)
	return data
}

export const updateForm = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_FORMS_UPDATE, body)
	return data
}
export const updateComponent = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_COMPONENT_UPDATE, body)
	return data
}

export const downloadGuide = async (id: any, contract: any) => {
	const { data } = await axiosInstance.get(`${DOWNLOAD_GUIDE}?linked_guidance_file_id=${id}&contract=${contract}`, {
		responseType: 'arraybuffer',
	})
	return data
}

export const uploadGuide = async (body: any) => {
	const { data } = await axiosInstance.post(UPLOAD_GUIDE, body)
	return data
}

export const removeGuide = async (contract: string, id: any) => {
	const { data } = await axiosInstance.get(`${REMOVE_GUIDE}?contract=${contract}&parent_class_id=${id}`)
	return data
}
