import { Action, ActionResponse } from 'src/facade/action/actionService.types'
import { ActionFormInputParameters, FormActionData } from 'src/facade/action/form/actionFormService.type'
import { axiosInstance } from 'src/services/axiosInstance'

export const actionFormService = {
	async create(params: ActionFormInputParameters): Promise<ActionResponse<FormActionData>> {
		const { data } = await axiosInstance.post('/workflow/actions/', {
			action_name: Action.CreateForm,
			task_id: -1,
			action_input_parameters: params,
		})
		return data
	},
	async update(taskId: number, params: ActionFormInputParameters): Promise<ActionResponse<FormActionData>> {
		const { data } = await axiosInstance.post('/workflow/actions/', {
			action_name: Action.FillFormAttributes,
			task_id: taskId,
			action_input_parameters: params,
		})
		return data
	},
	async downloadPdf(tagCode: string, contract: string): Promise<string> {
		const { data } = await axiosInstance.get(
			`assets/form/${contract}/${tagCode}/pdf/`,
			{ responseType: 'arraybuffer' },
		)
		return data
	},
}
