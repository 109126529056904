/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import TextArea from '../../Atoms/TextArea'
import { colors } from '../../../theme/colors'
import LGButton from '../LGButton'
import Popup from '../../Organisms/Popup'
import styled from 'styled-components'

interface IRejectPopup {
	isPopupOpen: any
	isLoading: boolean
	onConfirm: (item: string) => void
	onReject: () => void
}

export const RejectPopupContainer = styled.div`
	padding: 15px;
	.header {
		font-weight: 700;
		font-size: 14px;
		padding: 7px 0;
	}
	.buttonWrapper {
		display: flex;
		width: 100%;
		justify-content: flex-end;
	}
`

function RejectPopup(props: IRejectPopup) {
	const [comment, handleComment] = useState('')

	return (
		props.isPopupOpen && (
			<Popup
				onClick={props.onReject}
				isCloseButton
				children={
					<RejectPopupContainer>
						<div className="header">Rejection reason:</div>
						<TextArea
							border={`1px solid ${colors.greyC4}`}
							placeholder="Rejection reason:"
							value={comment}
							onChange={handleComment}
							width="30vw"
						/>
						<div className="buttonWrapper">
							<LGButton
								isLoading={props.isLoading}
								disabled={comment.length < 1 || props.isLoading}
								onClick={() => props.onConfirm(comment)}
								text="Reject"
								isWhiteText
								padding="8px 12px"
							/>
						</div>
					</RejectPopupContainer>
				}
			/>
		)
	)
}

export default RejectPopup
