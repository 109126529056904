import { action } from 'typesafe-actions'
import {
	CLEAR_USER_DETAILS,
	GET_LIST_OF_USERS,
	GET_ONE_USER,
	SET_IS_USER_DETAILS_LOADING,
	SET_LIST_OF_USERS,
	SET_USER_DETAILS,
	SET_IS_LOADING_LIST,
	RESET_PASSWORD,
	HANDLE_RESETTING_PASSWORD,
	HANDLE_FILTERS_ITEMS,
	HANDLE_IS_FILTERING,
	ADD_NEW_USER,
	HANDLE_IS_ADDING_NEW_USER,
	HANDLE_IS_EDITING,
	EDIT_USER,
	SET_AVAILABLE_ROLES_LIST,
	HANDLE_POPUP_DATA,
	SET_TOTAL_COUNTER,
	CHANGE_PAGE,
	DELETE_USER,
	HANDLE_IS_DELETING,
} from './constants'

export const getListOfUsers = () => action(GET_LIST_OF_USERS)
export const setListOfUsers = (usersList: any) => action(SET_LIST_OF_USERS, usersList)
export const setAvailableRoles = (availableRoles: any) => action(SET_AVAILABLE_ROLES_LIST, availableRoles)
export const setTotalCounter = (totalCounter: any) => action(SET_TOTAL_COUNTER, totalCounter)
export const isLoadingListOfUsers = (isLoading: boolean) => action(SET_IS_LOADING_LIST, isLoading)

export const handlePopupData = (popupData: any) => action(HANDLE_POPUP_DATA, popupData)

export const changePage = (pagination: { pageSize: number; pageNumber: number }) => action(CHANGE_PAGE, pagination)

export const resetPassword = (data: { id: string; email: string }) => action(RESET_PASSWORD, data)
export const handleResettingPassword = (data: { isResetting: boolean; id: string }) =>
	action(HANDLE_RESETTING_PASSWORD, data)

export const getOneUser = (id: string) => action(GET_ONE_USER, id)
export const setOneUserDetails = (user: any) => action(SET_USER_DETAILS, user)
export const isOneUserDetailsLoading = (isLoading: boolean) => action(SET_IS_USER_DETAILS_LOADING, isLoading)
export const clearUserDetails = () => action(CLEAR_USER_DETAILS)

export const handleFilterItems = (object: { value: any; objectName: string }) => action(HANDLE_FILTERS_ITEMS, object)
export const handleIsFiltering = (isFiltering: boolean) => action(HANDLE_IS_FILTERING, isFiltering)

export const deleteUser = (userId: string) => action(DELETE_USER, userId)
export const isDeletingUser = (isDeleting: { isDeleting: boolean; id: string }) =>
	action(HANDLE_IS_DELETING, isDeleting)

export const editUser = (item: { id: string; form: any }) => action(EDIT_USER, item)
export const isEditingUser = (item: { isEditing: boolean; id: string }) => action(HANDLE_IS_EDITING, item)

export const addNewUser = (form: any) => action(ADD_NEW_USER, form)
export const isAddingNewUser = (isAdding: any) => action(HANDLE_IS_ADDING_NEW_USER, isAdding)
