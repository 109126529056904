/* eslint-disable react/require-default-props */

import styled from 'styled-components'
import { colors } from '../../../theme/colors'
import cancel from '../../../assets/icons/cancel.svg'

const PopupWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.black62};
	z-index: 10000;
`

const PopupContent = styled.div`
	position: relative;
	padding: 10px;
	border-radius: 15px;
	background: ${colors.white};
`

const CloseCircle = styled.div`
	position: absolute;
	top: -6px;
	right: -6px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: ${colors.white};
	border: 1px solid ${colors.black62};
	cursor: pointer;
	img {
		width: 10px;
		height: 10px;
	}
`

interface IPopup {
	children: any
	isCloseButton?: boolean
	onClick?: () => void
}

const Popup = ({ children, isCloseButton, onClick }: IPopup) => {
	return (
		<PopupWrapper>
			<PopupContent>
				{isCloseButton && (
					<CloseCircle onClick={onClick}>
						<img src={cancel} alt="cancel_reject_popup" />
					</CloseCircle>
				)}
				{children}
			</PopupContent>
		</PopupWrapper>
	)
}

export default Popup
