import React from 'react'
import styled from 'styled-components'
import Image from '../../Atoms/Image'
import settingsIcon from '../../../assets/icons/settings.svg'
import { colors } from '../../../theme/colors'
import Tooltip from '../Tooltip'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IReduxState } from '../../../models/redux/redux'
import { Roles } from '../../../features/authorization/reducer'

const Wrapper = styled.div<{ isDisplay: boolean }>`
	cursor: pointer;
	position: relative;
	margin: 0 15px;
	padding: 5px;
	border-radius: 50%;
	display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	background: ${colors.lightGreyE0};
	img {
		cursor: pointer;
		width: 24px;
		height: 24px;
	}
`
const NotificationWrapper = styled.div`
	max-height: 20vh;
	overflow: auto;
	&::-webkit-scrollbar-thumb {
		display: none;
	}
	&::-webkit-scrollbar {
		display: none;
	}
	margin: 10px 0;
	div {
		&:nth-child(2n) {
			margin-top: 15px;
		}
		a {
			font-size: 14px;
			font-weight: bold;
			color: black;
		}
	}
	padding: 3px;
	.notification {
		.brElement {
			margin: 10px 0;
			border-bottom: 1px solid ${colors.lightGreyE0};
		}
		&:last-child {
			.brElement {
				margin: 10px 0;
				border-bottom: none;
			}
		}
	}
`

interface ISettingsMenuWrapper extends RouteComponentProps {
	roles: string[]
}

function SettingsMenuWrapper(props: ISettingsMenuWrapper) {
	const canAccessUserManagement = props.roles.some((role) => role === Roles['AIMS:roles.Sysadmin'])
	const canAccessGroupManagement = props.roles.some((role) =>
		[
			Roles['Documents:assets.Roles.Admin'],
			Roles['Assets:assets.Roles.Admin'],
			Roles['Registers:roles.Registers.WorkflowManager'],
			Roles['AIMS:roles.Sysadmin'],
		].includes(role),
	)
	const canAccessSystemMonitor = props.roles.some((role) => role === Roles['System:Monitor'])

	const canAccessAny = canAccessUserManagement || canAccessGroupManagement || canAccessSystemMonitor

	return (
		<Tooltip
			styleForTooltip={{
				top: '15px !important',
			}}
			trigger={canAccessAny ? 'click' : 'none'}
			placement="bottom"
			hideArrow={false}
			tooltip={
				<NotificationWrapper>
					{canAccessUserManagement && (
						<div>
							<Link to="/user-management">User Management</Link>
						</div>
					)}
					{canAccessGroupManagement && (
						<div>
							<Link to="/groups-management">Groups Management</Link>
						</div>
					)}
					<div />
					{canAccessSystemMonitor && (
						<div>
							<a href={`${import.meta.env.VITE_SYSTEM_MONITOR_URL}`}>System</a>
						</div>
					)}
				</NotificationWrapper>
			}
		>
			<Wrapper isDisplay={canAccessAny}>
				<Image imageSrc={settingsIcon} alt="settings-icon" />
			</Wrapper>
		</Tooltip>
	)
}
const SettingsMenu = withRouter(SettingsMenuWrapper)

export default connect((state: IReduxState) => ({
	roles: state.authorization.roles,
}))(SettingsMenu)
