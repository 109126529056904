/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import FilterItem from '../FilterItem'

const Wrapper = styled.div`
	display: flex;
	margin-bottom: 14px;
	justify-content: flex-start;
	flex-wrap: wrap;
`

const StyledFilterItem = styled(FilterItem)`
	margin: 5px 0 5px 10px;
	&:first-child {
		margin-left: 0;
	}
`

function Filtes({
	items,
	selectedFilter,
	onClick,
}: {
	items: Array<{ id: number; title: string; counter: string }>
	selectedFilter?: string
	onClick?: any
}) {
	const listOfFilters = items.map((item, index) => (
		<StyledFilterItem
			key={`${item.id}_${index}`}
			counterText={item.counter.toString()}
			text={item.title}
			counter
			selected={item.title === selectedFilter}
			onClick={onClick}
		/>
	))
	return <Wrapper>{listOfFilters}</Wrapper>
}

export default Filtes
