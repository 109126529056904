/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import TextButton from '../../Atoms/TextButton'
import Counter from '../../Atoms/Counter'

const StyledButton = styled.button<{ selected?: boolean }>`
	border-radius: 8px;
	padding: 7px 12px;
	border: 1px solid ${({ theme }) => theme.colors.main10};
	display: flex;
	align-items: center;
	transition: all 150ms ease-in;
	background-color: ${({ selected, theme }) => (selected ? theme.colors.main10 : '#fff')};

	&:hover {
		background-color: ${({ theme }) => theme.colors.main10};
	}
`

const StyledCounter = styled(Counter)`
	margin-left: 12px;
`

function FilteItem({
	text,
	selected,
	counter,
	className,
	onClick,
	counterText,
}: {
	text: string
	selected?: boolean
	counter?: boolean
	className?: string
	onClick?: any
	counterText?: string
}) {
	return (
		<StyledButton selected={selected} className={className} onClick={() => onClick(text)}>
			<TextButton text={text} md fontWeight={selected ? 'bold' : undefined} />
			{counter && counterText && <StyledCounter text={counterText} />}
		</StyledButton>
	)
}

export default FilteItem
