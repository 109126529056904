import { action } from 'typesafe-actions'
import {
	GET_PRIMARY_ASSETS,
	SET_PRIMARY_ASSETS,
	GET_WORK_PACKAGE,
	SET_WORK_PACKAGE,
	SET_WORK_PACKAGE_SELECTED,
	SET_PRIMARY_ASSET_SELECTED,
	GET_CONTRACT_DATA,
	SET_CONTRACT_DATA,
	SET_TECHNICAL_LIFECYCLE,
	GET_OWNERS,
	SET_OWNERS,
	SET_OWNER_SELECTED,
	CONFIRM_FLOW_FOR_MANAGER,
	SET_IDR_SUBMISSION_DATE,
	SET_COMMENCEMENT_DATE,
	SET_FINAL_SUBMISSION_DATE,
	SET_CERTIFICATION_DATE,
	CLEAR_FORM,
	MANAGE_TAB,
	SET_TECHNICAL_LIFECYCLE_OPTIONS,
} from './constants'

export const getPrimaryAssets = () => action(GET_PRIMARY_ASSETS)
export const setPrimaryAssets = (primaryAssets: any) => action(SET_PRIMARY_ASSETS, primaryAssets)
export const setPrimaryAssetsSelected = (primaryAssetsSelected: any) =>
	action(SET_PRIMARY_ASSET_SELECTED, primaryAssetsSelected)

export const getWorkPackage = () => action(GET_WORK_PACKAGE)
export const setWorkPackage = (workPackage: any) => action(SET_WORK_PACKAGE, workPackage)
export const setWorkPackageSelected = (workPackageSelected: any) =>
	action(SET_WORK_PACKAGE_SELECTED, workPackageSelected)

export const getOwners = () => action(GET_OWNERS)
export const setOwners = (owners: any) => action(SET_OWNERS, owners)
export const setOwnerSelected = (owners: any) => action(SET_OWNER_SELECTED, owners)

export const getContractData = () => action(GET_CONTRACT_DATA)
export const setContractData = (contractData: any) => action(SET_CONTRACT_DATA, contractData)

export const setTechnicalLifecycleStage = (technicalStage: any) => action(SET_TECHNICAL_LIFECYCLE, technicalStage)
export const setTechnicalElements = (technicalStage: any) => action(SET_TECHNICAL_LIFECYCLE_OPTIONS, technicalStage)

export const seTidrSubmissionDate = (date: any) => action(SET_IDR_SUBMISSION_DATE, date)
export const setStageCommencementDate = (date: any) => action(SET_COMMENCEMENT_DATE, date)
export const setFinalSubmissionDate = (date: any) => action(SET_FINAL_SUBMISSION_DATE, date)
export const setL3CertificationDate = (date: any) => action(SET_CERTIFICATION_DATE, date)

export const confirmManagerForm = (calendarsData: any) => action(CONFIRM_FLOW_FOR_MANAGER, calendarsData)
export const clearForm = () => action(CLEAR_FORM)

export const manageTab = (
	tabSelected: Array<{ id: number; isVisible: boolean; isOpen: boolean; isChecked: boolean }>,
) => action(MANAGE_TAB, tabSelected)
