export interface ActionRequest<ActionInputParams> {
	task_id: number
	action_name: Action
	action_input_parameters: ActionInputParams
}

export interface ActionResponse<ActionData> {
	action_result: string
	action_data: ActionData
	functions_result: FunctionsResult
}

export interface FunctionsResult {}

export enum Action {
	ExportForm = 'action_export_form_as_pdf',
	MarkTaskComplete = 'action_mark_filling_task_as_complete',
	ResendTask = 'action_resend_form_task',
	CloseForm = 'action_mark_form_as_complete',
	FinishForm = 'action_finish_form_task',
	RecallTaskForm = 'action_recall_form_task',
	SendTaskPriviliges = 'action_send_form_task',
	FillFormAttributes = 'action_fill_form_attributes',
	CreateForm = 'action_create_form',

	DidpUpdateDisciplines = 'action_update_disciplines',
}
