import lodash from 'lodash'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import browserHistory from 'src/services/history'
import styled from 'styled-components'
import arrow from '../../../assets/icons/arrowDown.svg'
import { Roles } from '../../../features/authorization/reducer'
import { IReduxState } from '../../../models/redux/redux'
import { colors } from '../../../theme/colors'
import Image from '../../Atoms/Image'
import TextLink from '../../Atoms/TextLink'
import Tooltip from '../Tooltip'
import { nanoid } from '@reduxjs/toolkit'
import { MODULE_URLS } from 'src/utils/moduleUrls'

const MainWrapper = styled.div`
	display: flex;
	margin: 0 20px;
`
const MenuWrapper = styled.div`
	padding: 15px;
`
const MenuElements = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	.text {
		color: ${colors.black};
		font-weight: 700;
		margin: 0 10px;
		max-width: 70px;
	}
`
const Circle = styled.div`
	width: 25px;
	height: 25px;
	background: ${colors.lightGreyE0};
	border-radius: 50%;
`

const StyledLink = styled.div`
	padding: 5px 0;
	cursor: pointer;
`

interface IHeaderMenuWrapper extends RouteComponentProps {
	roles: string[]
}

function HeaderMenuWrapper(props: IHeaderMenuWrapper) {
	const link = `${
		import.meta.env.VITE_LINK !== ''
			? import.meta.env.VITE_LINK
			: window.location.href.substring(0, window.location.href.indexOf('/app'))
	}`

	const uniqueLinks = lodash
		.uniq(props.roles.filter((item) => item).map((item) => item.substring(0, item.indexOf(' '))))
		.filter(Boolean)

	const exampleLinks = uniqueLinks
		.filter((item) => item !== 'System')
		.map((item) => ({
			id: nanoid(),
			title: item,
			show: true,
			active: false,
			link: item === 'Documents' ? MODULE_URLS.Documents : `${link}/?module=${item}`,
		}))
	const atlasLinks = [Roles['Atlas:Admin'], Roles['Atlas:Creator'], Roles['Atlas:Viewer']].some((role) =>
		props.roles.find((r) => r === role),
	)
		? [
				{
					id: nanoid(),
					title: 'Atlas',
					show: true,
					active: false,
					link: MODULE_URLS.Atlas,
				},
		  ]
		: []

	const viewer3dLinks =
		props.roles.filter((role) => role === Roles['3D:Viewer'] || role === Roles['3D:Admin']).length > 0
			? [
					{
						id: nanoid(),
						title: '3D Viewer',
						show: true,
						active: false,
						link: '/app/emt-3d',
					},
			  ]
			: []

	const allLinks = [...exampleLinks, ...viewer3dLinks, ...atlasLinks]

	const redirect = (item: any) => {
		window.location.replace(item)
	}

	const listOfLinks = allLinks
		.filter((item) => item.show)
		.map((item) =>
			item.link ? (
				<StyledLink key={item.id} onClick={() => redirect(item.link)}>
					<TextLink title={item.title} mdBold black62={item.active} />
				</StyledLink>
			) : (
				<StyledLink key={item.id}>
					<TextLink title={item.title} mdBold black62={item.active} />
				</StyledLink>
			),
		)
	return (
		<MainWrapper>
			<Tooltip
				styleForTooltip={{
					top: '15px !important',
					left: '15px !important',
					padding: '0px !important',
				}}
				trigger="click"
				placement="bottom"
				hideArrow={false}
				tooltip={
					<MenuWrapper>
						{listOfLinks}
						<StyledLink
							onClick={() => {
								browserHistory.push('/tasks-list')
							}}
						>
							<TextLink title="Registers" mdBold />
						</StyledLink>
					</MenuWrapper>
				}
			>
				<MenuElements>
					<Circle />
					<div className="text">
						{allLinks.filter((element) => element.active).length > 0
							? allLinks.filter((element) => element.active)[0].title
							: 'Home'}
					</div>
					<Image imageSrc={arrow} customRotate={180} alt="handler" />
				</MenuElements>
			</Tooltip>
		</MainWrapper>
	)
}

const HeaderMenu = withRouter(HeaderMenuWrapper)

export default connect((state: IReduxState) => ({
	roles: state.authorization.roles,
}))(HeaderMenu)
