import { createAsyncThunk } from '@reduxjs/toolkit'
import { usersService } from 'src/facade/users/usersService'
import { getUserTokenDetails } from 'src/services/getUserTokenDetails'

export const fetchProfileThunk = createAsyncThunk('profile/fetch', async () => {
	const [profile, user] = await Promise.all([usersService.fetchUserData(), getUserTokenDetails()])

	if (localStorage.getItem('contract') == null) {
		// Set contract to local storage for 3D viewer
		localStorage.setItem('contract', profile.contract?.contract)
	}

	return {
		profile,
		user,
	}
})
