import { Typography, TypographyProps } from '@mui/material'
import { red } from '@mui/material/colors'
import { FC } from 'react'

export interface MessageTextProps extends TypographyProps {
	errorMessage?: string
}

export const MessageText: FC<MessageTextProps> = ({ errorMessage, ...typographyProps }) => {
	if (!errorMessage) {
		return null
	}

	return (
		<Typography {...typographyProps} sx={{ ...typographyProps.sx, color: red[500] }}>
			{errorMessage}
		</Typography>
	)
}
