import AddCommentIcon from '@mui/icons-material/AddComment'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, Tooltip } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { styled as materialStyled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useEffect, useRef, useState } from 'react'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { openAddingCommentPanel, TidpComment } from 'src/features/comments/commentsSlice'
import { colors } from 'src/theme/colors'
import { useAppDispatch, useAppSelector } from 'src/utils/reduxUtils'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { ICommentTypes, IReply } from '../../../models/disciplineOverview/disciplineOverview'
import { AddComment, SaveCommentForm } from './AddComment'
import { CommentCard } from './CommentCard'
import { CommentFilters, Filters } from './Filters'
import { useLocalStorageState } from 'src/utils/useLocalStorageState'

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}

const AppBar = materialStyled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
	borderRadius: '4px',
	height: '55px',
	boxShadow: '0px 0px 0px',
	backgroundColor: `#E6EBF5`,
	color: 'black',
	justifyContent: 'center',
}))

export const DrawerWrapper = styled.div`
	min-width: 500px;
	max-width: 500px;
`

const CommentWrapper = styled.div<{ isClose: boolean }>`
	background: ${colors.white};
	padding-top: 0px;
	box-sizing: border-box;
	position: sticky;
	min-width: 500px;
	max-width: 500px;
	top: 0;
	border-radius: ${({ isClose }) => (isClose ? '50px' : '10px')};
`

const CommentContentWrapper = styled.div`
	max-height: 75vh;
	margin: 12px 0;
	overflow-y: auto;
	&::-webkit-scrollbar-thumb {
		display: none;
	}
`

const EmptyCommentsWrapper = styled.div`
	padding-top: 8px;
`

const IconsWrapper = styled.div`
	display: flex;
	align-items: center;
`

interface CommentsDrawerProps {
	comments: Array<TidpComment & { replies: IReply[]; code: string }>
	commentTypes: Array<ICommentTypes>
	reviewers: Array<string>
	owners: Array<string>
	tagCode: string
	onStatusChange: (data: { status: string; value: string; tagCode: string }) => void
	saveNewComment: (form: SaveCommentForm) => Promise<void>
	saveReply: (text: string, commentId: string, tidpId: string, commentCode: string) => void
}

export function CommentsDrawer(props: CommentsDrawerProps) {
	const dispatch = useAppDispatch()
	const [displayFilters, setDisplayFilters] = useState(false)
	const [commentFilters, setCommentFilters] = useState<CommentFilters | null>(null)
	const ref = useRef<HTMLDivElement>(null)
	const [scrollPosition, setScrollPosition] = useLocalStorageState('scroll-position', 0)
	const { isAddingComment } = useAppSelector((state) => state.comments)
	const { refreshing, tidp } = useAppSelector((state) => state.tidpOverview)
	const username = useAppSelector((state) => state.profile.user!.name)

	const isUserReviewer: boolean =
		username !== undefined && username !== null ? props.reviewers.includes(username) : false
	const canAddComment = tidp?.attribute?.['TIDP Status'] === 'Issued for Review' && isUserReviewer

	let filteredComments = props.comments
	filteredComments.map((comment) => {
		if (comment.selections ? 'tidp_tag_code' in comment.selections : true) {
			comment.commentLabel = '#TIDP_Comment'
		}
		if (comment.selections && 'didp_tag_code' in comment.selections) {
			comment.commentLabel = '#Discipline_Comment'
		}
	})
	if (commentFilters?.status) {
		filteredComments = filteredComments.filter((comment) => comment.Status === commentFilters.status)
	}
	if (commentFilters?.users != null && commentFilters.users.length > 0) {
		filteredComments = filteredComments.filter((comment) => commentFilters.users!.includes(comment['Recorded By']))
	}
	if (commentFilters?.discipline) {
		if (commentFilters.discipline === 'General') {
			filteredComments = filteredComments.filter((comment) =>
				comment.selections ? 'tidp_tag_code' in comment.selections : true,
			)
		} else {
			filteredComments = filteredComments.filter((comment) => {
				if (comment.selections && 'didp_tag_code' in comment.selections) {
					const didpTagCode = comment.selections.didp_tag_code
					const didpAsset = tidp.relatedTo?.find((relatedTo: any) => relatedTo.tag_code === didpTagCode)
					if ('discipline_tag_code' in comment.selections) {
						const disciplineTagCode = comment.selections.discipline_tag_code
						const disciplineAsset = didpAsset.relatedTo?.find(
							(relatedTo: any) => relatedTo.tag_code === disciplineTagCode,
						)
						return disciplineAsset.Discipline === commentFilters.discipline
					}
					return didpAsset['DIDP Name'] === commentFilters.discipline
				}
				return false
			})
		}
	}

	const handleApplyFilters = (filters: CommentFilters) => {
		setCommentFilters(filters)
	}

	const handleCancelAddComment = () => {
		dispatch(openAddingCommentPanel({ isAddingComment: false }))
	}

	const handleAddComment = () => {
		dispatch(openAddingCommentPanel({ isAddingComment: true }))
	}

	const debouncedHandleScroll = useDebouncedCallback(() => {
		if (!isAddingComment && ref.current?.scrollTop) {
			setScrollPosition(ref.current?.scrollTop!)
		}
	}, 300)

	useEffect(() => {
		const element = ref.current
		if (!element) {
			return
		}
		element.addEventListener('scroll', debouncedHandleScroll)
		return () => {
			element.removeEventListener('scroll', debouncedHandleScroll)
		}
	}, [])

	useEffect(() => {
		if (!ref.current) {
			return
		}
		if (!isAddingComment) {
			ref.current.scrollTo({ top: scrollPosition })
		}
	}, [isAddingComment])

	return (
		<CommentWrapper isClose={false}>
			<Box sx={{ display: 'flex' }}>
				<AppBar position="absolute" padding-top="100px" padding-bot="0px" border-radius="4px">
					<Toolbar sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }} disableGutters>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
							<Typography variant="h6" noWrap component="div">
								Comments
							</Typography>
							<Tooltip
								title={
									<div style={{ whiteSpace: 'pre-line' }}>
										{`• When upon the TIDP Overview click ‘+’ to add a comment against the TIDP. 

										• When within a Discipline click ‘+’ to add a comment against the Discipline level. 
										
										• When within a Discipline rightclick a deliverable and select ‘Add Comment’ to assign a comment against the specific deliverable.`}
									</div>
								}
							>
								<InfoOutlinedIcon />
							</Tooltip>
						</div>
						<IconsWrapper>
							{canAddComment && (
								<Tooltip disableInteractive title="Add new comment">
									<IconButton sx={{ height: '45px', width: '45px' }} onClick={() => handleAddComment()}>
										<AddCommentIcon />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip disableInteractive title="Filter comments">
								<IconButton
									sx={{ height: '45px', width: '45px' }}
									onClick={() => {
										setDisplayFilters(!displayFilters)
									}}
								>
									<FilterAltIcon />
								</IconButton>
							</Tooltip>
						</IconsWrapper>
					</Toolbar>
				</AppBar>
				<Box component="main" sx={{ flexGrow: 1, p: 2, paddingRight: '0px', paddingLeft: '0px', paddingTop: '50px' }}>
					{displayFilters && (
						<Filters
							initialFilters={commentFilters}
							onChange={handleApplyFilters}
							commentTypes={props.commentTypes}
							comments={props.comments}
						/>
					)}
					{refreshing ? (
						<Box my={2} sx={{ display: 'flex', justifyContent: 'center', height: '90%', alignItems: 'center' }}>
							<SpinnerComponent size={64} />
						</Box>
					) : (
						<CommentContentWrapper ref={ref}>
							{!isAddingComment && (
								<>
									{filteredComments.length !== 0 ? (
										filteredComments
											.slice()
											.sort((c1, c2) => +new Date(c2['Recorded At']) - +new Date(c1['Recorded At']))
											.map((comment) => (
												<CommentCard
													key={comment.id}
													comment={comment}
													saveReply={props.saveReply}
													tagCode={props.tagCode}
													owners={props.owners}
													reviewers={props.reviewers}
												/>
											))
									) : (
										<EmptyCommentsWrapper>No comments to display</EmptyCommentsWrapper>
									)}
								</>
							)}
							{isAddingComment && (
								<AddComment
									onCancel={handleCancelAddComment}
									commentTypes={props.commentTypes}
									saveNewComment={props.saveNewComment}
								/>
							)}
						</CommentContentWrapper>
					)}
				</Box>
			</Box>
		</CommentWrapper>
	)
}
