/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

const StyledSpan = styled.div`
	background-color: ${({ theme }) => theme.colors.main100};
	border-radius: 4px;
	padding: 3px;
	min-width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const StyledTextInside = styled.div`
	font-size: 12px;
	color: ${colors.white};
`

function Counter({ text, className }: { text: string; className?: string }) {
	return (
		<StyledSpan className={className}>
			<StyledTextInside>{text}</StyledTextInside>
		</StyledSpan>
	)
}

export default Counter
