import { action } from 'typesafe-actions'
import {
	GET_DISCIPLINE_OVERVIEW,
	HANDLE_DISCIPLINE_TABS,
	HANDLE_CREATE_NEW_DELIVERABLE,
	ASSIGN_VALUE_TO_DELIVERABLES_TABS,
	SAVE_NEW_DELIVERABLES,
	HANDLE_NEW_COMMENT_TEXT,
	START_REPLAY_ON_COMMENT,
	SAVE_NEW_COMMENT,
	SAVE_REPLY,
	HANDLE_REPLY_COMMENT,
	IS_SAVING_NEW_DELIVERABLE,
	HANDLE_START_NEW_COMMENT,
	SET_DISCIPLINE_DATA,
	CREATE_NEW_DELIVERABLE,
	IS_SAVING_NEW_COMMENT,
	NOMINATE_ADDITIONAL_DIDP_OWNER,
	SET_NOMINATE_SELECTED,
	SET_NOMINATE_IS_OPEN,
	HANDLE_IS_NOMINATING,
	ASSIGN_DIDP_OWNER,
	INFORM_TIDP_OWNER,
	IS_INFORMING_TIDP,
	SET_DISCIPLINE_TAG_CODE,
	HANDLE_ACCEPT_OWNERSHIP,
	ASSIGN_NOMINATED_DIDP_OWNER,
	HANDLE_DIDP_ACCEPTANCE,
	UPDATE_COMMENT,
	HANDLE_SELECTED_DELIVERABLES,
	GET_DISCIPLINE_OVERVIEW_HISTORY,
	SET_DISCIPLINE_OVERVIEW_HISTORY,
	UNLINK_DELIVERABLE,
	HANDLE_IS_LINKING,
	DELETE_DELIVERABLE_FROM_LIST,
	HANDLING_SELECTED_DELIVERABLES,
	HANDLE_ACTION_PROCESSING,
	HANDLE_FETCH_DELIVERABLE_NUMBERS,
	SET_DELIVERABLES,
	HANDLE_IS_LOADING_DELIVERABLES,
	GET_DELIVERABLES,
	SET_ONE_DELIVERABLE,
	GET_ONE_DELIVERABLE,
	HANDLE_DELIVERABLE_POPUP,
	HANDLE_IS_DELIVERABLE_CHANGING,
	HANDLE_IS_DELIVERABLE_LOADING,
	GET_DELIVERABLES_SILENT,
	HANDLE_IS_RENAMING_DELIVERABLES,
	RENAME_DELIVERABLES,
	HANDLE_DIDP_REOPENING,
	HANDLE_DIDP_REMOVING,
	SET_DISCIPLINE_TIDP_TAG_CODE,
} from './constants'

export const getDisciplineOverview = (id: number | string) => action(GET_DISCIPLINE_OVERVIEW, id)

export const handleActionIsProcessing = (isProcessing: boolean) => action(HANDLE_ACTION_PROCESSING, isProcessing)

export const nominateAdditionalDidpOwner = (item: { task_id: number; tagCode: string; action_name: string }) =>
	action(NOMINATE_ADDITIONAL_DIDP_OWNER, item)
export const setNominateSelected = (nominated: any) => action(SET_NOMINATE_SELECTED, nominated)
export const setNominateOpen = (isOpen: boolean) => action(SET_NOMINATE_IS_OPEN, isOpen)
export const setIsNominating = (isOpen: boolean) => action(HANDLE_IS_NOMINATING, isOpen)

export const informTIDPOwner = (item: { task_id: number; action_name: string; tagCode: string }) =>
	action(INFORM_TIDP_OWNER, item)
export const setIsInformingTIDP = (isInforming: boolean) => action(IS_INFORMING_TIDP, isInforming)

export const assignDidpOwner = (didpOwner: any) => action(ASSIGN_DIDP_OWNER, didpOwner)
export const assignNominatedDidpOwner = (nominatedDidpOwner: any) =>
	action(ASSIGN_NOMINATED_DIDP_OWNER, nominatedDidpOwner)

export const handleTab = (id: number) => action(HANDLE_DISCIPLINE_TABS, id)

export const handleCreatingNewDeliverables = (isCreating: boolean) => action(HANDLE_CREATE_NEW_DELIVERABLE, isCreating)
export const assignValueToDeliverablesTabs = (tabs: { element: { value: string; label: string }; id: string }) =>
	action(ASSIGN_VALUE_TO_DELIVERABLES_TABS, tabs)
export const saveCreatingNewDeliverables = () => action(SAVE_NEW_DELIVERABLES)

export const saveNewComment = (data: any, disciplineTagCode: string, isRejectComment?: boolean) =>
	action(SAVE_NEW_COMMENT, [data, disciplineTagCode, isRejectComment])

export const handleNewCommentText = (text: string) => action(HANDLE_NEW_COMMENT_TEXT, text)
export const handleStartNewComment = (isNew: boolean) => action(HANDLE_START_NEW_COMMENT, isNew)

export const handleSelectedDeliverables = (items: {
	task_id: number
	body: Array<{ documentNumber: string | number; revision: string }>
	tagCode: string
	action_name: string
}) => action(HANDLE_SELECTED_DELIVERABLES, items)
export const handleSelectedDeliverable = (isLoading: boolean) => action(HANDLING_SELECTED_DELIVERABLES, isLoading)

export const startReply = (id: string) => action(START_REPLAY_ON_COMMENT, id)
export const saveReply = (text: string, commentId: string, tidpId: string, commentCode: string) =>
	action(SAVE_REPLY, [text, commentId, tidpId, commentCode])
export const handleReplyComment = (text: string) => action(HANDLE_REPLY_COMMENT, text)
export const isSavingNewComment = (isSaving: boolean) => action(IS_SAVING_NEW_COMMENT, isSaving)

export const setDisciplineData = (data: any) => action(SET_DISCIPLINE_DATA, data)
export const setDisciplineDidpTagCode = (tag: string) => action(SET_DISCIPLINE_TAG_CODE, tag)
export const setDisciplineTidpTagCode = (tag: string) => action(SET_DISCIPLINE_TIDP_TAG_CODE, tag)

export const createNewDeliverable = (data: {
	action_name: string
	task_id: number
	deliverable: any
	tag_code: string
}) => action(CREATE_NEW_DELIVERABLE, data)

export const isSavingNewDeliverable = (isSaving: boolean) => action(IS_SAVING_NEW_DELIVERABLE, isSaving)

export const handleAcceptOwnership = (data: {
	isAccepted: boolean
	tagCode: any
	action_name: string
	task_id: any
	comment?: string
}) => action(HANDLE_ACCEPT_OWNERSHIP, data)
export const handleDIDPAcceptance = (data: {
	isAccepted: boolean
	tagCode: any
	action_name: string
	task_id: number
	comment?: string
}) => action(HANDLE_DIDP_ACCEPTANCE, data)

export const handleDIDPReopening = (data: { tagCode: any; action_name: string; task_id: number; comment?: string }) =>
	action(HANDLE_DIDP_REOPENING, data)

export const handleDIDPRemoving = (data: { tagCode: any; action_name: string; task_id: number; comment?: string }) =>
	action(HANDLE_DIDP_REMOVING, data)

export const handleFetchDeliverableNumbers = (data: {
	tagCode: any
	action_name: string
	task_id: number
	comment?: string
}) => action(HANDLE_FETCH_DELIVERABLE_NUMBERS, data)

export const updateDisciplineComment = (comment: any) => action(UPDATE_COMMENT, comment)

export const getDisciplineHistory = (tagCode: string | number) => action(GET_DISCIPLINE_OVERVIEW_HISTORY, tagCode)
export const setDiciplineHistory = (history: any[]) => action(SET_DISCIPLINE_OVERVIEW_HISTORY, history)

export const unlinkDeliverable = (discipline: {
	deliverableTagCode: string
	didpTagCode: string
	task_id: number
	action_name: string
}) => action(UNLINK_DELIVERABLE, discipline)
export const deliverableUnlinking = (isUnlinking: boolean) => action(HANDLE_IS_LINKING, isUnlinking)
export const deleteDeliverableFromLIst = (deliverableTagCode: string) =>
	action(DELETE_DELIVERABLE_FROM_LIST, deliverableTagCode)

export const setDeliverables = (deliverables: any) => action(SET_DELIVERABLES, deliverables)
export const getDeliverables = (tag: string | number) => action(GET_DELIVERABLES, tag)
export const getDeliverablesSilent = (tag: string | number) => action(GET_DELIVERABLES_SILENT, tag)
export const handleIsLoadingDeliverables = (isLoading: boolean) => action(HANDLE_IS_LOADING_DELIVERABLES, isLoading)

export const renameDeliverables = (item: {
	tidpTagCode: string
	deliverables: Array<{ tag_code: string; title: string }>
	action_name: string
	task_id: number
}) => action(RENAME_DELIVERABLES, item)
export const isRenamingDeliverables = (isRenaming: boolean) => action(HANDLE_IS_RENAMING_DELIVERABLES, isRenaming)

export const getOneDeliverable = (tag: string) => action(GET_ONE_DELIVERABLE, tag)
export const setOneDeliverable = (deliverable: any) => action(SET_ONE_DELIVERABLE, deliverable)
export const handleDeliverablePopup = (isOpen: boolean) => action(HANDLE_DELIVERABLE_POPUP, isOpen)
export const handleIsDeliverableChanging = (isChanging: boolean) => action(HANDLE_IS_DELIVERABLE_CHANGING, isChanging)
export const handleIsDeliverableLoading = (isLoading: boolean) => action(HANDLE_IS_DELIVERABLE_LOADING, isLoading)
