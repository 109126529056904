import { Attachment, DownloadImageForm, UploadAttachmentForm } from 'src/facade/form-attribute/formAttributeService.types'
import { axiosInstance } from 'src/services/axiosInstance'

export const formAttributeService = {
	uploadAttachment(form: UploadAttachmentForm): Promise<any> {
		const formdata = new FormData()
		formdata.append('contract', form.contract)
		formdata.append('tag_code', form.tagCode)
		formdata.append('attribute_name', form.attributeName)
		formdata.append('file', form.file)
		return axiosInstance.post('/assets/attribute/attachment/', formdata)
	},
	async downloadAttachment(form: DownloadImageForm): Promise<Attachment[]> {
		const { data } = await axiosInstance.get(
			`/assets/attribute/attachment/?contract=${form.contract}&tag_code=${form.tagCode}&attribute_name=${form.attributeName}`,
		)
		return data.response
	},
}
