import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import axios from 'axios'
import { FC, ReactNode, useEffect, useState } from 'react'
import { LoadingPage } from 'src/components/LoadingPage'
import { MsalErrorPage } from 'src/components/Pages/MsalErrorPage/MsalErrorPage'
import { getDomain } from 'src/utils/getDomain'
import { getAuthorityPrefix } from 'src/utils/msalConfig'
import { msalInstance } from 'src/utils/msalInstance'

let didInit = false

export const MsalWrapper: FC<{ children: ReactNode }> = ({ children }) => {
	const [isLoading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		if (!didInit) {
			;(async () => {
				let domain = getDomain()
				try {
					await axios.get(`${getAuthorityPrefix()}/v2.0/.well-known/openid-configuration\?p\=B2C_1A_${domain}`)
					await msalInstance.initialize(`B2C_1A_${domain}`)
				} catch (error) {
					await msalInstance.initialize()
				} finally {
					didInit = true
					setLoading(false)
				}
			})()
		}
	}, [])

	if (isLoading) {
		return <LoadingPage />
	}

	return (
		<MsalProvider instance={msalInstance.instance}>
			<MsalAuthenticationTemplate
				interactionType={InteractionType.Redirect}
				authenticationRequest={msalInstance.authRequest}
				errorComponent={MsalErrorPage}
				loadingComponent={LoadingPage}
			>
				{children}
			</MsalAuthenticationTemplate>
		</MsalProvider>
	)
}
