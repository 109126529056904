import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

const Label = styled.label`
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	display: inline-block;
	position: relative;
	cursor: pointer;
	font-size: 0.8rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	span {
		display: inline-block;
		vertical-align: top;
		&:nth-of-type(2) {
			margin-top: 3px;
		}
	}

	.checkmark {
		position: relative;
		display: inline-block;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: ${colors.white};
		border-radius: 5px;
		border: 1px solid ${colors.black47};
		transition: 0.3s;
	}
	&:hover input ~ .checkmark {
		background-color: ${colors.white};
		transition: 0.3s;
	}

	input:checked ~ .checkmark {
		background-color: ${colors.main100};
		transition: 0.3s;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	input:checked ~ .checkmark:after {
		display: block;
	}

	.checkmark:after {
		left: 7px;
		top: 3px;
		width: 4px;
		height: 10px;
		border: solid ${colors.white};
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`

interface ICheckBox {
	handle: () => void
	isChecked: boolean
}

export const CheckBox = (props: ICheckBox) => {
	return (
		<Label>
			<input type="checkbox" checked={props.isChecked} onChange={props.handle} />
			<span className="checkmark" />
		</Label>
	)
}
