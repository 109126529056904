/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import { FontWeightType } from '../../../typings/styledComponents'

enum FontWeight {
	light = 300,
	regular = 500,
	bold = 700,
}

const StyledHeadline = styled.h2<{
	small?: boolean
	extraSmall?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
}>`
	font-weight: ${({ fontWeight }) => (fontWeight ? FontWeight[fontWeight] : 'bold')};
	font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '96px')};
	line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '112px')};

	${({ small }) =>
		small &&
		css`
			font-size: 32px;
			line-height: 38px;
		`}

	${({ extraSmall }) =>
		extraSmall &&
		css`
			font-size: 16px;
			line-height: 19px;
		`}
`

function Headline({
	title,
	small,
	extraSmall,
	fontWeight,
	fontSize,
	lineHeight,
}: {
	title: string
	small?: boolean
	extraSmall?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
}) {
	return (
		<StyledHeadline
			small={small}
			extraSmall={extraSmall}
			fontWeight={fontWeight}
			fontSize={fontSize}
			lineHeight={lineHeight}
		>
			{title}
		</StyledHeadline>
	)
}

export default Headline
