import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { AxiosError } from 'axios'

export const axiosBaseQuery =
	(): BaseQueryFn<
		() => Promise<any>,
		// {
		// 	url: string
		// 	method: AxiosRequestConfig['method']
		// 	data?: AxiosRequestConfig['data']
		// },
		// { fetch: () => Promise<any> },
		unknown,
		unknown
	> =>
	async (/* { url, method, data } */ fetchFn) => {
		try {
			const result = await fetchFn()
			return { data: result }
		} catch (axiosError) {
			let err = axiosError as AxiosError
			return {
				error: { status: err.response?.status, data: err.response?.data },
			}
		}
	}
