/* eslint-disable no-return-assign */
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import {
	ASSIGN_DIDP_OWNER,
	ASSIGN_NOMINATED_DIDP_OWNER,
	ASSIGN_VALUE_TO_DELIVERABLES_TABS,
	DELETE_DELIVERABLE_FROM_LIST,
	HANDLE_ACTION_PROCESSING,
	HANDLE_CREATE_NEW_DELIVERABLE,
	HANDLE_DELIVERABLE_POPUP,
	HANDLE_DISCIPLINE_TABS,
	HANDLE_IS_DELIVERABLE_CHANGING,
	HANDLE_IS_DELIVERABLE_LOADING,
	HANDLE_IS_LINKING,
	HANDLE_IS_LOADING_DELIVERABLES,
	HANDLE_IS_NOMINATING,
	HANDLE_NEW_COMMENT_TEXT,
	HANDLE_REPLY_COMMENT,
	HANDLE_START_NEW_COMMENT,
	HANDLING_SELECTED_DELIVERABLES,
	IS_INFORMING_TIDP,
	IS_SAVING_NEW_DELIVERABLE,
	SAVE_NEW_DELIVERABLES,
	SET_DELIVERABLES,
	SET_DISCIPLINE_DATA,
	SET_DISCIPLINE_OVERVIEW_HISTORY,
	SET_DISCIPLINE_TAG_CODE,
	SET_NOMINATE_IS_OPEN,
	SET_NOMINATE_SELECTED,
	SET_ONE_DELIVERABLE,
	START_REPLAY_ON_COMMENT,
	UPDATE_COMMENT,
	HANDLE_IS_RENAMING_DELIVERABLES,
	SET_DISCIPLINE_TIDP_TAG_CODE,
} from './constants'
import { initialsForDisciplines } from './initialDataForDisciplines'
import { IDisciplineOverview } from '../../models/disciplineOverview/disciplineOverview'

export type DisciplineOverviewActions = ActionType<typeof actions>

const initialDisciplineOverview: IDisciplineOverview = {
	nominateSelected: initialsForDisciplines.nominateSelected,
	didpTagCode: initialsForDisciplines.didpTagCode,
	tidpTagCode: initialsForDisciplines.tidpTagCode,
	isNominatedOpen: initialsForDisciplines.isNominatedOpen,
	isInforming: initialsForDisciplines.isInforming,
	isNominating: initialsForDisciplines.isNominating,
	disciplineOwner: initialsForDisciplines.disciplineOwner,
	disciplineActions: initialsForDisciplines.disciplineActions,
	deliverables: initialsForDisciplines.deliverables,
	isCreatingNewDeliverable: initialsForDisciplines.isCreatingNewDeliverable,
	comments: initialsForDisciplines.comments,
	newCommentText: initialsForDisciplines.newCommentText,
	isNewComment: initialsForDisciplines.isNewComment,
	newReply: initialsForDisciplines.newReply,
	isSavingNewDeliverable: initialsForDisciplines.isSavingNewDeliverable,
	replies: initialsForDisciplines.replies,
	didpOwner: initialsForDisciplines.didpOwner,
	nominatedDidpOwner: initialsForDisciplines.nominatedDidpOwner,
	didpStatus: initialsForDisciplines.didpStatus,
	disciplineHistory: initialsForDisciplines.disciplineHistory,
	isLinking: initialsForDisciplines.isLinking,
	isLoadingSelectedDeliverable: initialsForDisciplines.isLoadingSelectedDeliverable,
	isActionProcessing: initialsForDisciplines.isActionProcessing,
	isLoadingDeliverables: initialsForDisciplines.isLoadingDeliverables,
	isOpenDeliverablePopup: initialsForDisciplines.isOpenDeliverablePopup,
	isChangingDeliverable: initialsForDisciplines.isChangingDeliverable,
	isLoadingDeliverable: initialsForDisciplines.isLoadingDeliverable,
	oneDeliverableTasks: initialsForDisciplines.oneDeliverableTasks,
	isRenamingDeliverables: initialsForDisciplines.isRenamingDeliverables,
}

const authorizationReducer = (
	state: IDisciplineOverview = initialDisciplineOverview,
	action: DisciplineOverviewActions,
) => {
	switch (action.type) {
		case HANDLE_DISCIPLINE_TABS: {
			return {
				...state,
				disciplineOwner: state.disciplineOwner.map((el) => ({ ...el, isOpen: el.id === action.payload })),
			}
		}
		case HANDLE_ACTION_PROCESSING: {
			return { ...state, isActionProcessing: action.payload }
		}
		case HANDLE_IS_RENAMING_DELIVERABLES: {
			return { ...state, isRenamingDeliverables: action.payload }
		}
		case HANDLE_IS_LINKING: {
			return { ...state, isLinking: action.payload }
		}
		case HANDLING_SELECTED_DELIVERABLES: {
			return { ...state, isLoadingSelectedDeliverable: action.payload }
		}
		case DELETE_DELIVERABLE_FROM_LIST: {
			return {
				...state,
				deliverables: {
					...state.deliverables,
					body: state.deliverables.body.filter((item) => item.tagCode !== action.payload),
				},
			}
		}
		case HANDLE_START_NEW_COMMENT: {
			return { ...state, isNewComment: action.payload, newCommentText: '', newReply: { id: '', comment: '' } }
		}
		case SET_DISCIPLINE_OVERVIEW_HISTORY: {
			return { ...state, disciplineHistory: action.payload }
		}
		case ASSIGN_DIDP_OWNER: {
			return { ...state, didpOwner: action.payload }
		}
		case ASSIGN_NOMINATED_DIDP_OWNER: {
			return { ...state, nominatedDidpOwner: action.payload }
		}
		case HANDLE_IS_NOMINATING: {
			return { ...state, isNominating: action.payload }
		}
		case IS_INFORMING_TIDP: {
			return { ...state, isInforming: action.payload }
		}
		case SET_NOMINATE_IS_OPEN: {
			return { ...state, isNominatedOpen: action.payload }
		}
		case IS_SAVING_NEW_DELIVERABLE: {
			return { ...state, isSavingNewDeliverable: action.payload }
		}
		case SET_NOMINATE_SELECTED: {
			return { ...state, nominateSelected: action.payload }
		}
		case SET_DISCIPLINE_TAG_CODE: {
			return { ...state, didpTagCode: action.payload }
		}
		case SET_DISCIPLINE_TIDP_TAG_CODE: {
			return { ...state, tidpTagCode: action.payload }
		}
		case START_REPLAY_ON_COMMENT: {
			return { ...state, newReply: { id: action.payload, comment: '' }, newCommentText: '' }
		}
		case HANDLE_REPLY_COMMENT: {
			return { ...state, newReply: { ...state.newReply, comment: action.payload }, isNewComment: false }
		}
		case HANDLE_NEW_COMMENT_TEXT: {
			return { ...state, newCommentText: action.payload }
		}
		case SET_ONE_DELIVERABLE: {
			return { ...state, oneDeliverableTasks: action.payload }
		}
		case SAVE_NEW_DELIVERABLES: {
			const deliverable = {}
			Object.keys(state.deliverables.newDeliverableFields).map(
				// @ts-ignore
				(item) => (deliverable[item] = state.deliverables.newDeliverableFields[item].label),
			)
			state.deliverables.body.push(deliverable)
			return {
				...state,
				deliverables: { ...state.deliverables, newDeliverableFields: {} },
				isCreatingNewDeliverable: false,
			}
		}
		case ASSIGN_VALUE_TO_DELIVERABLES_TABS: {
			const newDeliverable = { ...state.deliverables.newDeliverableFields }
			newDeliverable[action.payload.id] = action.payload.element
			return { ...state, deliverables: { ...state.deliverables, newDeliverableFields: newDeliverable } }
		}
		case HANDLE_CREATE_NEW_DELIVERABLE: {
			const element = {}
			if (action.payload) {
				// @ts-ignore
				state.deliverables.header.map((item) => (element[item.id] = ''))
			}
			return {
				...state,
				deliverables: { ...state.deliverables, newDeliverableFields: element },
				isCreatingNewDeliverable: action.payload,
			}
		}
		case SET_DISCIPLINE_DATA: {
			return {
				...state,
				disciplineOwner: action.payload[0],
				comments: action.payload[2],
				replies: action.payload[3],
				didpStatus: action.payload[4],
				newReply: { id: '', comment: '' },
				newCommentText: '',
				isNewComment: false,
			}
		}
		case SET_DELIVERABLES: {
			return { ...state, deliverables: action.payload }
		}
		case HANDLE_DELIVERABLE_POPUP: {
			return { ...state, isOpenDeliverablePopup: action.payload }
		}
		case HANDLE_IS_DELIVERABLE_CHANGING: {
			return { ...state, isChangingDeliverable: action.payload }
		}
		case HANDLE_IS_DELIVERABLE_LOADING: {
			return { ...state, isLoadingDeliverable: action.payload }
		}
		case HANDLE_IS_LOADING_DELIVERABLES: {
			return { ...state, isLoadingDeliverables: action.payload }
		}
		case UPDATE_COMMENT: {
			return {
				...state,
				comments: state.comments.map((comment) =>
					comment.tagCode === action.payload.value ? { ...comment, status: action.payload.status } : comment,
				),
			}
		}
		default:
			return state
	}
}

export default authorizationReducer
