import camelcaseKeys from 'camelcase-keys'
import { msalInstance } from 'src/utils/msalInstance'
import { parseJwt } from 'src/utils/parseJwt'

export async function getUserTokenDetails(): Promise<UserTokenDetails> {
	const token = await msalInstance.getAimsToken()
	if (!token) {
		throw new Error('No token found.')
	}
	return camelcaseKeys(parseJwt(token), { deep: true })
}

export interface UserTokenDetails {
	exp: number
	nbf: number
	ver: string
	iss: string
	sub: string
	aud: string
	nonce: string
	iat: number
	authTime: number
	oid: string
	name: string
	extensionEsId: string
	extensionTenantsData: string
	emails: string[]
	tfp: string
}
