import { assetsService } from 'src/facade/assets/assetsService'
import { AssetSearchResponse } from 'src/facade/assets/assetsService.types'
import { TidpAsset } from 'src/facade/assets/tidp/tidpAssetsService.types'
import { AimsModule } from 'src/features/authorization/constants'

export const tidpAssetsService = {
	async search(tagCode: string, contract: string): Promise<AssetSearchResponse<TidpAsset>> {
		return assetsService.search({
			contract,
			relationsLevel: 2,
			tag_code: { fieldValue: [tagCode] },
			module_name: AimsModule.TIDP,
			allowedAssetClassCode: ["TIDP", "DIDP", "Comment", "Work Package", "Document", "Technical Lifecycles"]
		})
	},
}
