/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import lodash from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'src/components/Molecules/Layout'
import { DisciplinesGrid } from 'src/components/Pages/TIDPOverview/DisciplinesGrid'
import { saveNewComment } from 'src/features/tidpOverview/actions'
import { saveTidpDocumentCommentThunk } from 'src/features/tidpOverview/saveTidpDocumentCommentThunk'
import { useAppDispatch } from 'src/utils/reduxUtils'
import styled from 'styled-components'
import collapseAllIcon from '../../../assets/icons/collapseAll.svg'
import { changeCommentsStatus } from '../../../features/comments/commentsSlice'
import { controlsActions } from '../../../features/controls'
import { tasksActionsElementActions } from '../../../features/taskActions'
import { tidpOverviewActions } from '../../../features/tidpOverview'
import { usersActions } from '../../../features/users'
import { IReduxState } from '../../../models/redux/redux'
import { colors } from '../../../theme/colors'
import { getBaseUrl } from '../../../utils/getBaseUrl'
import Cell from '../../Atoms/Cell'
import SpinnerComponent from '../../Atoms/Loader'
import { CommentsDrawer, DrawerWrapper } from '../../Molecules/Comments/CommentsDrawer'
import HistoryComponent from '../../Molecules/HistoryComponent'
import LGButton from '../../Molecules/LGButton'
import Overview from '../../Molecules/Overview'
import PageTitle from '../../Molecules/PageTitle'
import RejectPopup from '../../Molecules/RejectPopup'
import SelectList from '../../Molecules/SelectList'
import {
	BottomButtonSection,
	OverviewContent,
	OverviewContentWrapper,
	OverviewWrapper,
	PageHeader,
	SpinnerOverview,
	StyledTd,
} from './tidpOverviewCustomStyles'
import { ITidpOverviewComponent } from './tidpOverviewInterface'
import { useFetchTidpAssetsQuery } from 'src/features/tidp-assets/tidpAseetsApi'

const TIDPOverview = (props: ITidpOverviewComponent) => {
	const dispatch = useAppDispatch()
	const { data: tidps } = useFetchTidpAssetsQuery({
		tagCode: props.match.params.param1,
		contract: props.contract.contract,
	})

	const assetAndWorkPackages =
		!tidps || !tidps[0]
			? []
			: tidps[0].relatedWith
					.filter((item) => item.class_name === 'Work Package')
					.map((element) => {
						return {
							'Work Package Name': element['Work Package Name'],
							'Work Package Type': element['Work Package Type'],
							'Work Package Number': element['Work Package Number'],
						}
					})

	const assetAndWorkPackagesTab = {
		isSelected: true,
		name: 'Asset & Work Packages',
		id: 2,
		tabName: 'TIDP Asset',
		tabs:
			assetAndWorkPackages.length > 0
				? Object.keys(assetAndWorkPackages[0]).map((k) => ({
						name: k,
						value: (assetAndWorkPackages[0] as any)[k as any],
				  }))
				: [],
	}

	const [contractElement, handleContract] = useState('')
	const [allDeliverablesOpen, handleAllDeliverablesOpen] = useState(false)

	const [isNextRequest, handleIsNextRequest] = useState(false)
	const [match, handleMatch] = useState<any>('')

	const [searchUserFieldName, handleSearchUserFieldName] = useState('')
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [searchPhrase, handleSearchPhrase] = useState('')
	const disciplineOwners = props.rowCells.map((discipline) => discipline.nominatedOwners)
	const leadReviewers = props.externalStakeholersValue.map((reviewer) => reviewer.value)
	const reviewers = props.internalStakeholersValue.map((reviewer) => reviewer.value).concat(leadReviewers)

	const documentsSheetsRelation = useMemo(
		() => props.tidp?.relatedWith.find((relation: any) => relation.relation_type === 'eBCommentSheet - Document'),
		[props.tidp],
	)

	let commentTypes = props.rowCells.map((discipline: any) => {
		return {
			type: discipline.label,
			tagCode: discipline.tagCode,
		}
	})
	commentTypes.unshift({ type: 'General', tagCode: props.match.params.param1 })

	const changeNavigationTab = (id: number) => {
		props.handleOverviewTabs(id)
	}

	const getOverview = () => {
		if (isNextRequest && props.match !== match) {
			handleMatch(props.match)
			props.getTdipOverview(props.match.params.param1)
		}
		if (props.contract && contractElement !== props.contract) {
			handleContract(props.contract)
			props.getListOfUsersByRole({ role: '', text: '' })
			props.getListOfDisciplines()
			props.getTdipOverview(props.match.params.param1)
			handleIsNextRequest(true)
		}
	}

	const fetchOverview = useCallback(getOverview, [props.match, props.contract])

	const onUsersChange = (text: string, field: string) => {
		handleSearchUserFieldName(field)
		handleSearchPhrase(text)
		props.getListOfUsersByRole({ role: '', text })
	}

	useEffect(() => {
		fetchOverview()
	}, [fetchOverview])

	const getCells = () => {
		let tabsElements = props.tidpNavTabs.find((element) => element.isSelected)
		if (!tabsElements) {
			return []
		}
		if (tabsElements?.tabName === 'TIDP Asset') {
			tabsElements = assetAndWorkPackagesTab
		}
		const hasUpdateStakeholdersAction = props.tasksActions.find(
			(item: any) => item.action_name === 'action_update_internal_stakeholders',
		)
		const hasUpdateTidpOwnerAction = props.tasksActions.find(
			(item: any) => item.action_name === 'action_update_tidp_owner',
		)
		return tabsElements.tabs
			.filter((el) => !['eBClassName', 'eBInstanceId', 'eBSchemaName', 'eB Comment Sheet Revision'].includes(el.name))
			.filter((el) => (hasUpdateStakeholdersAction ? !['SCS Lead Reviewer', 'Reviewers'].includes(el.name) : true))
			.filter((el) => (hasUpdateTidpOwnerAction ? !['TIDP Owner'].includes(el.name) : true))
			.map((el) => (
				<tr key={`${el.name}_${el.value}`}>
					<StyledTd large>
						<Cell isEye={!!el.info} eyeInfo={el.info} text={el.name.replace('TIDP ', '')} white />
					</StyledTd>
					<StyledTd large>
						<Cell
							onStatusRefresh={() =>
								props.refreshTidpNumber({
									...props.tasksActions.filter((item) => item.action_name === 'action_generate_tidp_number')[0],
									tag_code: props.match.params.param1,
								})
							}
							cellStatus={el?.status}
							showRefreshStatus={
								props.tasksActions.filter((item) => item.action_name === 'action_generate_tidp_number').length > 0
							}
							text={el.value}
							endAdornment={
								el.name === 'eB Comment Sheet' && hasStatusReviewApproved && documentsSheetsRelation ? (
									<a
										target="_blank"
										href={`${getBaseUrl()}/EMTViewer/?document_id=${documentsSheetsRelation.tag_code}`}
										rel="noreferrer"
									>
										view
									</a>
								) : undefined
							}
						/>
					</StyledTd>
				</tr>
			))
	}

	const clearSearchForUsers = () => {
		props.getListOfUsersByRole({ role: '', text: '' })
	}

	const isOverview = props.tidpNavTabs.filter((item) => item.name === 'Overview' && item.isSelected).length > 0

	const [popupData, handlePopupData] = useState<{
		isPopupOpen: boolean
		task_id: number
		action_name: string
		actionField: any
	}>({
		isPopupOpen: false,
		task_id: -1,
		action_name: '',
		actionField: null,
	})
	const [isRejecting, handleIsRejecting] = useState(false)

	const confirmReject = (comment: string) => {
		handleIsRejecting(true)
		popupData.actionField(comment)
		handlePopupData({ isPopupOpen: false, task_id: -1, action_name: '', actionField: null })
	}

	const cancelReject = () => {
		handleIsRejecting(false)
		handlePopupData({ isPopupOpen: false, task_id: -1, action_name: '', actionField: null })
	}

	const hasStatusReviewApproved: boolean = props.tidp?.attribute?.['TIDP Status'] === 'Review Approved'

	return (
		<Layout goBackUrl="/tasks-list">
			{props.isLoading || !props.tidp ? (
				<SpinnerOverview>
					<SpinnerComponent size={100} />
				</SpinnerOverview>
			) : (
				<OverviewWrapper>
					<RejectPopup
						isPopupOpen={popupData.isPopupOpen}
						isLoading={isRejecting}
						onConfirm={confirmReject}
						onReject={cancelReject}
					/>
					<PageHeader>
						<div>
							<PageTitle
								margin="0"
								title="TIDP Overview"
								subTitle="This page contains all your TIDP information. Manage your disciplines and associated deliverables here."
							/>
						</div>
						<div>
							<HistoryComponent
								handleIsOpenHistory={props.handleHistorySidebar}
								isOpenHistory={!props.isHistorySidebarOpen}
								historyElements={props.tidpOverviewHistory}
							/>
						</div>
					</PageHeader>

					<div className="overviewNav">
						{props.tidpNavTabs.map((element) => (
							<div
								onClick={() => changeNavigationTab(element.id)}
								key={element.name}
								style={{ borderTop: element.isSelected ? `2px solid ${colors.main100}` : '' }}
							>
								{element.name}
							</div>
						))}
					</div>
					<OverviewContentWrapper>
						<OverviewContent>
							<Overview title={props.tidpNavTabs.filter((element) => element.isSelected)[0].tabName}>
								<table>
									<tbody>{getCells()}</tbody>
									{props.tidpNavTabs.filter((item) => item.name === 'Overview')[0].isSelected && (
										<tbody>
											{props.tasksActions.some((item) => item.action_name === 'action_update_tidp_owner') && (
												<tr>
													<StyledTd large>
														<Cell isEye eyeInfo="You may enter multiple names here." text="TIDP Owner" white />
													</StyledTd>
													<StyledTd large>
														<SelectList
															isLoading={props.isLoadingUsers && searchUserFieldName === 'reviewers'}
															onInputChange={(text) => onUsersChange(text, 'reviewers')}
															onMenuClose={clearSearchForUsers}
															border={`1px solid ${colors.lightGreyE5}`}
															isMulti
															value={props.ownersValue}
															padding="0 10px"
															options={props.users}
															onChange={props.assignOwnersValue}
															isDisabled={
																!props.tasksActions.some((item) => item.action_name === 'action_update_tidp_owner')
															}
														/>
													</StyledTd>
												</tr>
											)}
											{props.tasksActions.filter((item) => item.action_name === 'action_update_internal_stakeholders')
												.length > 0 && (
												<tr>
													<StyledTd large>
														<Cell
															isEye
															eyeInfo="You may enter multiple names here. When you press save, the reviewers will be notified."
															text="SCS Lead Reviewer"
															white
														/>
													</StyledTd>
													<StyledTd large>
														<SelectList
															isLoading={props.isLoadingUsers && searchUserFieldName === 'reviewers'}
															onInputChange={(text) => onUsersChange(text, 'reviewers')}
															onMenuClose={clearSearchForUsers}
															border={`1px solid ${colors.lightGreyE5}`}
															isMulti
															value={props.internalStakeholersValue}
															padding="0 10px"
															options={props.users}
															onChange={props.assignInternalStakeholersValue}
															isDisabled={
																!(
																	props.tasksActions.filter(
																		(item) => item.action_name === 'action_update_internal_stakeholders',
																	).length > 0
																)
															}
														/>
													</StyledTd>
												</tr>
											)}
											{props.tasksActions.filter((item) => item.action_name === 'action_update_external_stakeholders')
												.length > 0 && (
												<tr>
													<StyledTd large>
														<Cell
															isEye
															eyeInfo="When you press save for the Reviewers, you will then need to press 'issue' for them to be notified."
															text="Reviewers"
															white
														/>
													</StyledTd>
													<StyledTd large>
														<SelectList
															isLoading={props.isLoadingUsers && searchUserFieldName === 'leadReviewers'}
															onInputChange={(text) => onUsersChange(text, 'leadReviewers')}
															onMenuClose={clearSearchForUsers}
															border={`1px solid ${colors.lightGreyE5}`}
															padding="0 10px"
															isMulti
															value={props.externalStakeholersValue}
															options={props.users}
															onChange={props.assignExternalStakeholersValue}
															isDisabled={
																!(
																	props.tasksActions.filter(
																		(item) => item.action_name === 'action_update_external_stakeholders',
																	).length > 0
																)
															}
														/>
													</StyledTd>
												</tr>
											)}
										</tbody>
									)}
								</table>
								{props.tidpNavTabs.filter((item) => item.name === 'Overview')[0].isSelected && (
									<BottomButtonSection>
										{props.tasksActions.filter((el) => el.action_name === 'action_issue_for_approval').length > 0 && (
											<LGButton
												padding="0 20px"
												text="Issue for Review"
												height={48}
												disabled={props.isActionInProgress}
												isWhiteText
												onClick={() =>
													props.updateTIDPStatus({
														task_id: props.tasksActions.filter(
															(el) => el.action_name === 'action_issue_for_approval',
														)[0].task_id,
														action_name: props.tasksActions.filter(
															(el) => el.action_name === 'action_issue_for_approval',
														)[0].action_name,
													})
												}
											/>
										)}
										{props.tasksActions.filter((el) => el.action_name === 'action_update_external_stakeholders')
											.length > 0 && (
											<LGButton
												disabled={
													lodash.isEqual(props.originalExternalStakeholersValue, props.externalStakeholersValue) ||
													props.isActionInProgress
												}
												padding="0 20px"
												text="Save Reviewers"
												height={48}
												isWhiteText
												onClick={() =>
													props.updateExternalStakeholdersValue({
														externalStakeholders: props.externalStakeholersValue.map((k: any) => {
															return k.value
														}),
														tag_code: props.match.params.param1,
														task_id: props.tasksActions.filter(
															(el) => el.action_name === 'action_update_external_stakeholders',
														)[0].task_id,
														action_name: props.tasksActions.filter(
															(el) => el.action_name === 'action_update_external_stakeholders',
														)[0].action_name,
													})
												}
											/>
										)}
										{props.tasksActions.filter((el) => el.action_name === 'action_update_internal_stakeholders')
											.length > 0 && (
											<LGButton
												disabled={
													lodash.isEqual(props.originalInternalStakeholersValue, props.internalStakeholersValue) ||
													props.isActionInProgress
												}
												padding="0 20px"
												text="Save SCS Lead Reviewer"
												height={48}
												isWhiteText
												onClick={() =>
													props.updateInternalStakeholdersValue({
														internalStakeholders: props.internalStakeholersValue.map((k: any) => {
															return k.value
														}),
														tag_code: props.match.params.param1,
														action_name: props.tasksActions.filter(
															(el) => el.action_name === 'action_update_internal_stakeholders',
														)[0].action_name,
														task_id: props.tasksActions.filter(
															(el) => el.action_name === 'action_update_internal_stakeholders',
														)[0].task_id,
													})
												}
											/>
										)}

										{props.tasksActions.filter((el) => el.action_name === 'action_accept_tidp').length > 0 && (
											<LGButton
												padding="0 20px"
												text="Approve TIDP"
												height={48}
												disabled={props.isActionInProgress}
												isWhiteText
												onClick={() =>
													props.updateTIDPStatus({
														action_name: props.tasksActions.filter((el) => el.action_name === 'action_accept_tidp')[0]
															.action_name,
														task_id: props.tasksActions.filter((el) => el.action_name === 'action_accept_tidp')[0]
															.task_id,
													})
												}
											/>
										)}
										{props.tasksActions.filter((el) => el.action_name === 'action_reject_tidp').length > 0 && (
											<LGButton
												padding="0 20px"
												text="Reject TIDP"
												disabled={props.isActionInProgress}
												height={48}
												isWhiteText
												onClick={() =>
													handlePopupData({
														isPopupOpen: true,
														task_id: props.tasksActions.filter((item) => item.action_name === 'action_reject_tidp')[0]
															.task_id,
														action_name: props.tasksActions.filter(
															(item) => item.action_name === 'action_reject_tidp',
														)[0].action_name,
														actionField: (comment: string) =>
															props.updateTIDPStatus({
																comment,
																action_name: props.tasksActions.filter(
																	(item) => item.action_name === 'action_reject_tidp',
																)[0].action_name,
																task_id: props.tasksActions.filter(
																	(item) => item.action_name === 'action_reject_tidp',
																)[0].task_id,
															}),
													})
												}
											/>
										)}
										{props.tasksActions.filter((el) => el.action_name === 'action_create_another_revision').length >
											0 && (
											<LGButton
												padding="0 20px"
												text="Create Another Revision"
												height={48}
												disabled={props.isActionInProgress}
												isWhiteText
												onClick={() =>
													props.duplicateTidp({
														action_name: props.tasksActions.filter(
															(el) => el.action_name === 'action_create_another_revision',
														)[0].action_name,
														task_id: props.tasksActions.filter(
															(el) => el.action_name === 'action_create_another_revision',
														)[0].task_id,
														tag_code: props.match.params.param1,
													})
												}
											/>
										)}
										{props.tasksActions.filter((el) => el.action_name === 'action_trigger_adding_another_revision')
											.length > 0 && (
											<LGButton
												padding="0 20px"
												text="Trigger Another Revision"
												height={48}
												disabled={props.isActionInProgress}
												isWhiteText
												onClick={() =>
													props.addAnotherRevision({
														action_name: props.tasksActions.filter(
															(el) => el.action_name === 'action_trigger_adding_another_revision',
														)[0].action_name,
														task_id: props.tasksActions.filter(
															(el) => el.action_name === 'action_trigger_adding_another_revision',
														)[0].task_id,
														tag_code: props.match.params.param1,
													})
												}
											/>
										)}

										{props.tasksActions.some((el) => el.action_name === 'action_update_tidp_owner') && (
											<LGButton
												disabled={
													lodash.isEqual(props.originalOwnersValue, props.ownersValue) || props.isActionInProgress
												}
												padding="0 20px"
												text="Save TIDP Owner"
												height={48}
												isWhiteText
												onClick={() =>
													props.updateOwnersValue({
														owners: props.ownersValue.map((k: any) => {
															return k.value
														}),
														tag_code: props.match.params.param1,
														action_name: props.tasksActions.filter(
															(el) => el.action_name === 'action_update_tidp_owner',
														)[0].action_name,
														task_id: props.tasksActions.filter((el) => el.action_name === 'action_update_tidp_owner')[0]
															.task_id,
													})
												}
											/>
										)}
									</BottomButtonSection>
								)}
							</Overview>
							{isOverview && (
								<Overview
									onClickIcon={() => handleAllDeliverablesOpen(!allDeliverablesOpen)}
									leftIcon={collapseAllIcon}
									title="Disciplines"
								>
									<DisciplinesGrid allDeliverablesOpen={allDeliverablesOpen} />
								</Overview>
							)}
						</OverviewContent>
						<DrawerWrapper>
							<CommentsDrawer
								onStatusChange={(value) =>
									props.changeCommentStatus({
										tagCode: value.tagCode,
										status: value.status,
										contract: props.contract.contract,
										action: () => tidpOverviewActions.updateTidpComment({ value: value.tagCode, status: value.value }),
									})
								}
								reviewers={reviewers}
								owners={props.tidp.attribute['TIDP Owner'].concat(...disciplineOwners)}
								comments={props.tidpComments}
								commentTypes={commentTypes}
								tagCode={props.tidp.tag_code}
								saveNewComment={async (form) =>
									await dispatch(
										saveTidpDocumentCommentThunk({
											...form,
											selections: {
												tidp_tag_code: props.tidp.tag_code,
											},
										}),
									).unwrap()
								}
								saveReply={props.saveReply}
							/>
						</DrawerWrapper>
					</OverviewContentWrapper>
				</OverviewWrapper>
			)}
		</Layout>
	)
}

export default connect(
	(state: IReduxState) => ({
		tidpNavTabs: state.tidpOverview.tidpNavTabs,
		disciplinedHeaderCells: state.tidpOverview.disciplinedHeaderCells,
		rowCells: state.tidpOverview.rowCells,
		ownersList: state.tidpOverview.ownersList,
		newCommentText: state.tidpOverview.newCommentText,
		newReply: state.tidpOverview.newReply,
		isNewComment: state.tidpOverview.isNewComment,
		listOfDisciplines: state.tidpOverview.listOfDisciplines,
		tidpComments: state.tidpOverview.tidpComments,
		technicalLifecycleCode: state.tidpOverview.technicalLifecycleCode,
		isSavingNewComment: state.tidpOverview.isSavingNewComment,
		externalStakeholersText: state.tidpOverview.externalStakeholersText,
		userText: state.users.userText,
		users: state.users.users,
		externalStakeholersValue: state.tidpOverview.externalStakeholersValue,
		internalStakeholersValue: state.tidpOverview.internalStakeholersValue,
		ownersValue: state.tidpOverview.ownersValue,
		originalInternalStakeholersValue: state.tidpOverview.originalInternalStakeholersValue,
		originalExternalStakeholersValue: state.tidpOverview.originalExternalStakeholersValue,
		originalOwnersValue: state.tidpOverview.originalOwnersValue,
		tidpOverviewHistory: state.tidpOverview.tidpOverviewHistory,
		isSavingOverview: state.tidpOverview.isSavingOverview,
		isActionInProgress: state.tidpOverview.isActionInProgress,
		isLoading: state.controls.isLoading,
		roles: state.authorization.roles,
		contract: state.users.contract,
		isHistorySidebarOpen: state.controls.isHistorySidebarOpen,
		tasksActions: state.tasksActions.tasksActions,
		isLoadingUsers: state.users.isLoadingUsers,
		tidp: state.tidpOverview.tidp,
	}),
	{
		handleOverviewTabs: tidpOverviewActions.handleOverviewTabs,
		overviewDisciplinesHandleIsRequire: tidpOverviewActions.overviewDisciplinesHandleIsRequire,
		overviewHandleDisciplineOwner: tidpOverviewActions.overviewHandleDisciplineOwner,
		overviewHandleDisciplineStatus: tidpOverviewActions.overviewHandleDisciplineStatus,
		changeOverviewCommentContent: tidpOverviewActions.changeOverviewCommentContent,
		saveTIDPOverview: tidpOverviewActions.saveTidpOverview,
		getTdipOverview: tidpOverviewActions.getTidpOverview,
		getListOfUsersByRole: usersActions.getListOfUsersByRole,
		startTIDPReply: tidpOverviewActions.startTIDPReply,
		saveReply: tidpOverviewActions.saveReply,
		handleTIDPReplyComment: tidpOverviewActions.handleTIDPReplyComment,
		handleStartNewTIDPComment: tidpOverviewActions.handleStartNewTIDPComment,
		handleNewTIDPCommentText: tidpOverviewActions.handleNewTIDPCommentText,
		assignInternalStakeholersValue: tidpOverviewActions.assignInternalStakeholdersValue,
		assignExternalStakeholersValue: tidpOverviewActions.assignExternalStakeholdersValue,
		assignOwnersValue: tidpOverviewActions.assignOwnersValue,
		updateExternalStakeholdersValue: tidpOverviewActions.updateExternalStakeholdersValue,
		updateInternalStakeholdersValue: tidpOverviewActions.updateInternalStakeholdersValue,
		updateOwnersValue: tidpOverviewActions.updateOwnersValue,
		duplicateTidp: tidpOverviewActions.duplicateTidp,
		addAnotherRevision: tidpOverviewActions.addAnotherRevision,
		getListOfDisciplines: tidpOverviewActions.getListOfDisciplines,
		updateTIDPStatus: tidpOverviewActions.updateTIDPStatus,
		refreshTidpNumber: tidpOverviewActions.refreshTidpNumber,
		getTidpHistory: tidpOverviewActions.getTidpHistory,
		changeCommentStatus: changeCommentsStatus,
		handleHistorySidebar: controlsActions.handleHistorySidebar,
		getTaskActions: tasksActionsElementActions.getTaskActions,
	},
)(TIDPOverview)
