import { action } from 'typesafe-actions'
import {
	TIDP_OVERVIEW_TABS,
	HANDLE_OVERVIEW_DISCIPLINE_IS_REQUIRED,
	HANDLE_OVERVIEW_DISCIPLINE_OWNER,
	HANDLE_OVERVIEW_DISCIPLINE_STATUS,
	CHANGE_OVERVIEW_COMMENT_CONTENT,
	GET_TIDP_OVERVIEW,
	SAVE_TIDP_OVERVIEW,
	SET_TIDP_OVERVIEW_DATA,
	SET_TIDP_OVERVIEW_DISCIPLINES,
	UPDATE_TIDP_OVERVIEW,
	IS_SAVING_NEW_COMMENT_TIDP_OVERVIEW,
	SAVE_NEW_TIDP_COMMENT,
	START_NEW_TIDP_REPLY,
	SAVE_TIDP_REPLY,
	HANDLE_TIDP_REPLY_COMMENT,
	HANDLE_START_NEW_TIDP_COMMENT,
	HANDLE_NEW_TIDP_COMMENT_TEXT,
	CLEAR_TIDP_COMMENT,
	ASSIGN_TIDP_COMMENTS,
	ASSIGN_INTERNAL_STAKEHOLDERS_VALUE,
	ASSIGN_EXTERNAL_STAKEHOLDERS_VALUE,
	ASSIGN_OWNERS_VALUE,
	UPDATE_TIDP_STATUS,
	UPDATE_TIDP_COMMENT,
	GET_TIDP_OVERVIEW_HISTORY,
	SET_TIDP_OVERVIEW_HISTORY,
	UPDATE_INTERNAL_STAKEHOLDERS_VALUE,
	UPDATE_EXTERNAL_STAKEHOLDERS_VALUE,
	UPDATE_OWNERS_VALUE,
	HANDLE_IS_SAVING_OVERVIEW,
	GET_LIST_OF_DISCIPLINES,
	SET_LIST_OF_DISCIPLINES,
	SET_NEW_DISCIPLINE,
	REFRESH_TIDP_NUMBER,
	SET_TECHNICAL_LIFECYCLE_CODE,
	HANDLE_NAV_NUMBER_STATUS,
	GET_TIDP_STATUS,
	GET_TIDP_STATUS_SILENT,
	DUPLICATE_TIDP,
	ADD_ANOTHER_REVISION,
	SET_ANOTHER_REVISION,
	SET_ACTION_IN_PROGRESS,
} from './constants'

export const handleOverviewTabs = (id: string | number) => action(TIDP_OVERVIEW_TABS, id)

export const overviewDisciplinesHandleIsRequire = (isRequired: { isRequired: string; id: string | number }) =>
	action(HANDLE_OVERVIEW_DISCIPLINE_IS_REQUIRED, isRequired)
export const overviewHandleDisciplineOwner = (owner: { ownerName: any[]; id: string | number }) =>
	action(HANDLE_OVERVIEW_DISCIPLINE_OWNER, owner)
export const overviewHandleDisciplineStatus = (status: { status: string; id: string | number }) =>
	action(HANDLE_OVERVIEW_DISCIPLINE_STATUS, status)
export const changeOverviewCommentContent = (comment: { comment: string | number; id: string | number }) =>
	action(CHANGE_OVERVIEW_COMMENT_CONTENT, comment)

export const saveTidpOverview = (item: { tidpId: string; task_id: number; task_name: string; newDisciplines: any }) =>
	action(SAVE_TIDP_OVERVIEW, item)
export const isSavingOverview = (isSaving: boolean) => action(HANDLE_IS_SAVING_OVERVIEW, isSaving)

export const getTidpOverview = (tidpId: string, silent: boolean = false) =>
	action(GET_TIDP_OVERVIEW, { tidpId, silent })
export const setTidpOverviewData = (data: any, stakeholders: any, tidp: any) =>
	action(SET_TIDP_OVERVIEW_DATA, [data, stakeholders, tidp])
export const setTidpOverviewDisciplines = (data: any) => action(SET_TIDP_OVERVIEW_DISCIPLINES, data)
export const setNewDiscipline = (disciplines: any) => action(SET_NEW_DISCIPLINE, disciplines)

export const updateTidpOverview = (data: { data: any; isLast?: { isLast: boolean; action?: any } }) =>
	action(UPDATE_TIDP_OVERVIEW, data)

export const saveNewComment = (
	data: any,
	tidpTagCode: string,
	documentTagCode?: string,
	commentCode?: string,
	status?: string,
) => action(SAVE_NEW_TIDP_COMMENT, [data, tidpTagCode, documentTagCode, commentCode, status])
export const saveReply = (text: string, commentId: string, tidpId: string, commentCode: string) =>
	action(SAVE_TIDP_REPLY, [text, commentId, tidpId, commentCode])
export const handleTIDPReplyComment = (text: string) => action(HANDLE_TIDP_REPLY_COMMENT, text)
export const handleStartNewTIDPComment = (isNew: boolean) => action(HANDLE_START_NEW_TIDP_COMMENT, isNew)
export const isSavingNewCommentTidpOverview = (isSaving: boolean) =>
	action(IS_SAVING_NEW_COMMENT_TIDP_OVERVIEW, isSaving)
export const handleNewTIDPCommentText = (text: string) => action(HANDLE_NEW_TIDP_COMMENT_TEXT, text)
export const clearCommentData = () => action(CLEAR_TIDP_COMMENT)
export const assignTidpComments = (comments: any) => action(ASSIGN_TIDP_COMMENTS, comments)
export const startTIDPReply = (tagCode: string) => action(START_NEW_TIDP_REPLY, tagCode)

export const assignInternalStakeholdersValue = (value: any) => action(ASSIGN_INTERNAL_STAKEHOLDERS_VALUE, value)
export const assignExternalStakeholdersValue = (value: any) => action(ASSIGN_EXTERNAL_STAKEHOLDERS_VALUE, value)
export const assignOwnersValue = (value: any) => action(ASSIGN_OWNERS_VALUE, value)

export const setTechnicalLifecycleCode = (technicalCode: string) => action(SET_TECHNICAL_LIFECYCLE_CODE, technicalCode)

export const getListOfDisciplines = () => action(GET_LIST_OF_DISCIPLINES)
export const setListOfDisciplines = (disciplines: any) => action(SET_LIST_OF_DISCIPLINES, disciplines)

export const updateExternalStakeholdersValue = (items: {
	externalStakeholders: any
	task_id: number
	action_name: string
	tag_code: string
}) => action(UPDATE_EXTERNAL_STAKEHOLDERS_VALUE, items)
export const updateInternalStakeholdersValue = (items: {
	internalStakeholders: any
	task_id: number
	action_name: string
	tag_code: string
}) => action(UPDATE_INTERNAL_STAKEHOLDERS_VALUE, items)
export const updateOwnersValue = (items: { owners: any; task_id: number; action_name: string; tag_code: string }) =>
	action(UPDATE_OWNERS_VALUE, items)

export const duplicateTidp = (items: { task_id: number; action_name: string; tag_code: string }) =>
	action(DUPLICATE_TIDP, items)
export const addAnotherRevision = (items: { task_id: number; action_name: string; tag_code: string }) =>
	action(ADD_ANOTHER_REVISION, items)
export const setAnotherRevision = (rev: { revision: any; tidpNumber: any }) => action(SET_ANOTHER_REVISION, rev)

export const setActionInProgress = (isInProgress: boolean) => action(SET_ACTION_IN_PROGRESS, isInProgress)

export const updateTIDPStatus = (items: { task_id: number; action_name: string; comment?: string }) =>
	action(UPDATE_TIDP_STATUS, items)

export const updateTidpComment = (comment: any) => action(UPDATE_TIDP_COMMENT, comment)

export const refreshTidpNumber = (numberAction: any) => action(REFRESH_TIDP_NUMBER, numberAction)
export const setNavNumberStatus = (navigation: any) => action(HANDLE_NAV_NUMBER_STATUS, navigation)
export const getTidpStatus = (tagCode: string) => action(GET_TIDP_STATUS, tagCode)
export const getTidpStatusSilent = (tagCode: string) => action(GET_TIDP_STATUS_SILENT, tagCode)

export const getTidpHistory = (tagCode: string) => action(GET_TIDP_OVERVIEW_HISTORY, tagCode)
export const setTidpHistory = (history: any[]) => action(SET_TIDP_OVERVIEW_HISTORY, history)
