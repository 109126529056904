/* eslint-disable no-nested-ternary */
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'
import moment from 'moment'
import { dateFormat } from '../../../assets/dateFormats'
import LGButton from '../LGButton'

const HistoryElement = styled.div`
	margin-top: 12px;
	display: flex;
	align-items: center;
`
const HistoryElementWrapper = styled.div<{ width?: string }>`
	width: ${({ width }) => width || '100%'};
`
const PersonData = styled.div`
	font-size: 12px;
	font-weight: 700;
	margin-left: 12px;
`

const HistoryContent = styled.div`
	font-size: 14px;
	margin-left: 40px;
	margin-bottom: 8px;
	word-break: break-word;
`
const CommentWithButton = styled.div`
	display: flex;
	flex-flow: column;
	align-items: flex-end;
`

interface IHeaderMenuWrapper {
	isOpenHistory: boolean
	historyElements: { name: string; date: Date; history: string }[]
	handleIsOpenHistory: (isOpen: boolean) => void
}

const Circle = styled.div`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.greyBD};
	color: ${colors.white};
	font-weight: 700;
	border-radius: 50%;
`

const oneHistoryElement = (item: { name: string; date: Date; history: string }, index?: number, width?: string) => (
	<HistoryElementWrapper width={width} key={`${item.name}_${index}`}>
		<HistoryElement>
			<Circle>{item.name.substring(0, 2)}</Circle>
			<PersonData>{item.name}</PersonData>
			<PersonData>{moment(new Date(item.date)).format(dateFormat.engHours)}</PersonData>
		</HistoryElement>
		<HistoryContent>{item.history}</HistoryContent>
	</HistoryElementWrapper>
)

const HistoryComponent = (props: IHeaderMenuWrapper) => {
	const { historyElements, handleIsOpenHistory, isOpenHistory } = props
	return (
		<div>
			{isOpenHistory ? (
				historyElements.length > 1 ? (
					<CommentWithButton>
						<LGButton
							width={100}
							onClick={() => handleIsOpenHistory(true)}
							isWhiteText
							padding="5px"
							text="Open history"
						/>
						{oneHistoryElement(historyElements[0], 1, '15vw')}
					</CommentWithButton>
				) : historyElements.length === 1 ? (
					oneHistoryElement(historyElements[0], 1, '15vw')
				) : null
			) : null}
		</div>
	)
}

export default HistoryComponent
