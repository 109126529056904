import browserHistory from 'src/services/history'
import { storageService } from 'src/utils/storageService'

export function authRedirectUser(): void {
	const lastVisitedUrl = storageService.getLastVisitedPathname()

	if (!lastVisitedUrl || lastVisitedUrl === '') {
		return
	}

	storageService.removeLastVisitedPathname()
	// localStorage.setItem(LOCAL_STORAGE_IS_CALL, JSON.stringify(true))

	if (!lastVisitedUrl.startsWith('/')) {
		window.location.replace(`${window.location.hostname === 'localhost' ? 'http://' : 'https://'}${lastVisitedUrl}`)
	} else {
		browserHistory.replace(lastVisitedUrl)
	}
}
