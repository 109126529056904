import { Typography } from '@mui/material'
import { FC, memo, useMemo } from 'react'
import { FieldPath } from 'react-hook-form'
import { AttributeType } from 'src/facade/forms/formsService.types'
import { AttributeDateInput } from 'src/forms/_shared/attribute/AttributeDateInput'
import { AttributeMediaInput } from 'src/forms/_shared/attribute/AttributeMediaInput'
import { AttributeTextInput } from 'src/forms/_shared/attribute/AttributeTextInput'
import { AttributeUserInput } from 'src/forms/_shared/attribute/AttributeUserInput'
import { AppAttribute, AppChildForm, MainFormForm } from 'src/forms/_shared/main-form/MainForm'

export interface AttributeInputProps {
	name: FieldPath<MainFormForm>
	attribute: AppAttribute
	form: MainFormForm | AppChildForm
	disabled: boolean
	hide?: boolean
}

export const AttributeInput: FC<AttributeInputProps> = memo((props) => {
	const ATTRIBUTE_INPUT_BY_TYPE_MAP: Record<AttributeType, JSX.Element> = useMemo(
		() => ({
			User: <AttributeUserInput {...props} />,
			Text: <AttributeTextInput {...props} />,
			Numeric: <AttributeTextInput {...props} />,
			Date: <AttributeDateInput {...props} />,
			Media: <AttributeMediaInput {...props} />,
			Attachment: <AttributeMediaInput {...props} />,
			Signature: <AttributeMediaInput {...props} />,
			'Non Editable': <AttributeTextInput {...props} disabled />,
			URI: <Typography my={1}>{props.attribute.attribute_type} - missing implementation</Typography>,
			Weather: <Typography my={1}>{props.attribute.attribute_type} - missing implementation</Typography>,
			Location: <Typography my={1}>{props.attribute.attribute_type} - missing implementation</Typography>,
			Geo_location: <Typography my={1}>{props.attribute.attribute_type} - missing implementation</Typography>,
			'Markup Image': <Typography my={1}>{props.attribute.attribute_type} - missing implementation</Typography>,
			'Read Only Image': <Typography my={1}>{props.attribute.attribute_type} - missing implementation</Typography>,
		}),
		[props],
	)

	if (props.hide) {
		return null
	}

	return ATTRIBUTE_INPUT_BY_TYPE_MAP[props.attribute.attribute_type] || null
})

AttributeInput.displayName = 'AttributeInput'
