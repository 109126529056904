import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { changeCommentStatus, getCommentAttributes } from 'src/services/comments'
import { AppEpic } from '../../utils/reduxUtils'
import { getTidpOverview } from '../tidpOverview/actions'
import {
	changeCommentsStatus,
	fetchCommentsAttributes,
	fetchCommentsAttributesSuccess,
	setIsChangingComment,
} from './commentsSlice'
import { toast } from 'react-toastify'

export const changeCommentStatusEpic: AppEpic<ReturnType<typeof changeCommentsStatus>> = (action$, state$) =>
	action$.pipe(
		filter(changeCommentsStatus.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(setIsChangingComment({ loading: true, tagCode: action.payload.tagCode })),
				fromPromise(changeCommentStatus(action.payload)).pipe(
					switchMap(() => {
						return concat(
							of(setIsChangingComment({ loading: false, tagCode: action.payload.tagCode })),
							of(getTidpOverview(state.tidpOverview.tidp.tag_code, true)),
						)
					}),
					catchError((err) => {
						toast.error(err?.response?.data?.error || 'Failed to add comment.')
						return of(setIsChangingComment({ loading: false, tagCode: action.payload.tagCode }))
					}),
				),
			)
		}),
	)

export const getCommentsAttributesForUserEpic: AppEpic<ReturnType<typeof fetchCommentsAttributes>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(fetchCommentsAttributes.match),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			return concat(
				fromPromise(getCommentAttributes((state.users as any).contract.contract)).pipe(
					switchMap((response) => {
						return of(fetchCommentsAttributesSuccess(response.class))
					}),
				),
			)
		}),
	)

export const commentsEpics = combineEpics(changeCommentStatusEpic, getCommentsAttributesForUserEpic)
