import { ICellRendererParams } from 'ag-grid-community'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import LGButton from '../LGButton'
import { StatusBarConfig } from './DataGrid'

export interface StatusBarLeftParams extends ICellRendererParams, StatusBarConfig {}

const Wrapper = styled.div`
	display: flex;
	align-items: middle;
	margin-top: 4px;
`

// eslint-disable-next-line react/display-name
export const StatusBarLeft = forwardRef<any, StatusBarLeftParams>(({ onUndo, onRedo }, ref) => {
	const [config, setConfig] = useState<StatusBarConfig>({})

	const undo = () => {
		onUndo?.()
	}

	const redo = () => {
		onRedo?.()
	}

	useImperativeHandle(ref, () => {
		return {
			setConfig: (c: Partial<StatusBarConfig>) => {
				setConfig(c)
			},
		}
	})

	return (
		<Wrapper>
			<LGButton
				disabled={!config.canUndo}
				margin="0 0 16px 10px"
				padding="0 10px"
				onClick={undo}
				text="Undo"
				height={24}
				isWhiteText
			/>
			<LGButton
				disabled={!config.canRedo}
				margin="0 0 16px 10px"
				padding="0 10px"
				onClick={redo}
				text="Redo"
				height={24}
				isWhiteText
			/>
		</Wrapper>
	)
})
