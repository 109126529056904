import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/utils/reduxUtils'

export const selectDidpAssets = createSelector(
	(state: RootState) => state.tidpOverview,
	(state) => state.tidp?.relatedTo?.filter((relatedTo: any) => relatedTo.class_code === 'DIDP'),
)

export const selectIsUserAnyDidpOwner = createSelector(
	(state: RootState) => [selectDidpAssets(state), state.profile.user!.name],
	([didpAssets, username]) => {
		return didpAssets?.some((didpAsset: any) => didpAsset?.['DIDP Owner']?.includes(username))
	},
)
