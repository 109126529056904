/* eslint-disable jsx-a11y/no-autofocus */
import { Button } from '@mui/material'
import { uniqBy } from 'lodash'
import { countBy, isEmpty } from 'ramda'
import { FC, useState } from 'react'
import SelectOverview from 'src/components/Molecules/SelectOverview'
import { DependsOnConditionalDialog } from 'src/components/Organisms/Forms/DependsOnConditionalDialog'
import { DEPENDS_ON_OPTIONS } from 'src/components/Organisms/Forms/dependsOnOptions'
import { DisplayGroupDialog } from 'src/components/Organisms/Forms/DisplayGroupDialog'
import { AttributeDependsOn, DisplayStyleForm } from 'src/facade/forms/formsService.types'
import { handleAutoFocus, handleComponentsHeaderValues, removeComponent } from 'src/features/formsHandler/actions'
import { additionalDependsOn } from 'src/features/formsHandler/utils'
import {
	DisplayGroupDefault,
	DisplayGroupValue,
	IAttributes,
	IFormComponentsAttributes,
	ISequences,
} from 'src/models/formsHandler/formsHandler'
import { useAppDispatch } from 'src/utils/reduxUtils'
import { resolveValueFunction } from 'src/utils/resolveValueFunction'
import styled from 'styled-components'
import deleteIcon from '../../../assets/icons/delete.svg'
import Image from '../../Atoms/Image'
import Input from '../../Atoms/Input'

const RemoveAttribute = styled.div`
	margin: 15px 0;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
`
const TopElement = styled.div<{ display?: string }>`
	margin-bottom: 15px;

	.value_input {
		width: 300px;
		height: 40px;
	}

	.sequence {
		display: ${({ display }) => display || 'block'};
		align-items: center;

		img {
			width: 12px;
			height: 12px;
			margin: 0 5px;
			cursor: pointer;
		}
	}

	.value {
		margin-right: 10px;
	}

	.name {
		margin-bottom: 10px;
		font-weight: bold;
	}
`

export interface ComponentDetailsProps {
	attribute: IFormComponentsAttributes
	focusName: string
	index: number
	isDisabled: boolean
	sequences: ISequences
	dependsOnOptions: Array<{
		value: string | number
		label: string
		id: string | number
	}>
	uniqComponents: IFormComponentsAttributes[]
	components: IFormComponentsAttributes[]
	mainForm: IAttributes
}

export const ComponentDetails: FC<ComponentDetailsProps> = ({
	attribute,
	isDisabled,
	focusName,
	index,
	dependsOnOptions,
	sequences,
	uniqComponents,
	components,
	mainForm,
}) => {
	const dispatch = useAppDispatch()

	const [isOpenDependsOnConditionalDialog, setOpenDependsOnConditionalDialog] = useState(false)
	const [isOpenDisplayGroupDialog, setOpenDisplayGroupDialog] = useState(false)

	const dependsOnLabel = attribute.depends_on_conditional?.condition
		? attribute.depends_on_conditional?.condition === AttributeDependsOn.PreRequisite
			? 'Prerequisite'
			: `${attribute.depends_on_conditional?.argument ? ` ${attribute.depends_on_conditional.argument}` : ''} ${
					attribute.depends_on_conditional?.argumentComponentId
						? `(${attribute.depends_on_conditional?.argumentComponentId})`
						: ''
			  } ${Object.keys(DEPENDS_ON_OPTIONS)
					.find((key) => DEPENDS_ON_OPTIONS[key] === attribute.depends_on_conditional?.condition)
					?.toLowerCase()}${
					attribute.depends_on_conditional?.conditionValue ? ` ${attribute.depends_on_conditional.conditionValue}` : ''
			  }`
		: 'add'

	const displayGroupLabel =
		attribute.display_group == null
			? 'add'
			: attribute.display_group.type === 'default'
			? attribute.display_group.value.formula
			: resolveValueFunction(attribute.display_group)

	return (
		<div className="component_element" key={`${attribute.id}_${index}_${attribute.is_new}`}>
			{!isDisabled && (
				<RemoveAttribute>
					<Image
						imageSrc={deleteIcon}
						width="15px"
						height="15px"
						alt="remove_att"
						onClick={() => dispatch(removeComponent(attribute.id))}
					/>
				</RemoveAttribute>
			)}
			<TopElement display="flex">
				<div className="name">Display group:</div>
				<Button
					disabled={isDisabled}
					onClick={() => setOpenDisplayGroupDialog(true)}
					sx={{ textAlign: 'left', fontSize: 12, lineHeight: 1.2, width: '100%' }}
				>
					{displayGroupLabel}
				</Button>
				<DisplayGroupDialog
					open={isOpenDisplayGroupDialog}
					onClose={() => setOpenDisplayGroupDialog(false)}
					components={components}
					dependsOnOptions={[
						...mainForm.attributes.map((attr) => ({
							value: attr.attribute_name.value,
							label: attr.attribute_name.value,
							category: 'Form fields',
						})),
						...uniqComponents
							.slice()
							.sort((a, b) => a.sequence - b.sequence)
							.filter((component) => component.id !== attribute.id)
							.map((component) =>
								component.dependsOnOptions.map((option) => ({
									...option,
									category: component.displayName,
								})),
							)
							.flat(),
					]}
					defaultValue={
						isEmpty(attribute.display_group)
							? null
							: (attribute.display_group as DisplayGroupValue | DisplayGroupDefault | null | undefined)
					}
					onChange={(displayGroup) => {
						dispatch(
							handleComponentsHeaderValues({
								id: attribute.id,
								keyName: 'display_group',
								value: displayGroup,
							}),
						)
						setOpenDependsOnConditionalDialog(false)
					}}
				/>
			</TopElement>
			<TopElement display="flex">
				<div className="name">Sequence:</div>
				<Input
					min={1}
					isDisabled={isDisabled}
					border={
						(!isDisabled && (attribute as any).sequence === '') ||
						sequences.filter((el) => el.sequence === attribute.sequence && (el as any).sequence !== '').length > 1
							? '1px solid red'
							: ''
					}
					autoFocus={focusName === `select_sequence_input_${index}_${attribute.id}`}
					className="value_input"
					height="40px"
					placeholder="Sequence"
					value={attribute.sequence}
					type="number"
					onChange={(value) => {
						dispatch(handleComponentsHeaderValues({ id: attribute.id, keyName: 'sequence', value: Number(value) }))
						dispatch(handleAutoFocus(`select_sequence_input_${index}_${attribute.id}`))
					}}
				/>
			</TopElement>
			<TopElement>
				<div className="name">Display Name:</div>
				<Input
					isDisabled={isDisabled}
					border={!isDisabled && attribute.displayName === '' ? '1px solid red' : ''}
					className="value_input"
					height="40px"
					placeholder="Display Name"
					value={attribute.displayName}
					onChange={(value) =>
						dispatch(
							handleComponentsHeaderValues({
								id: attribute.id,
								keyName: 'displayName',
								value,
							}),
						)
					}
				/>
			</TopElement>
			<TopElement>
				<div className="name">Contexts:</div>
				<Input
					isDisabled={isDisabled}
					className="value_input"
					height="40px"
					placeholder="Contexts"
					value={attribute.contexts}
					onChange={(value) =>
						dispatch(
							handleComponentsHeaderValues({
								id: attribute.id,
								keyName: 'contexts',
								value,
							}),
						)
					}
				/>
			</TopElement>
			<TopElement>
				<div className="name">Depends On:</div>
				<SelectOverview
					isDisabled={isDisabled}
					maxMenuHeight={100}
					maxWidth="300px"
					height="40px"
					autoFocus={focusName === `select_depends_on_input_${index}_${attribute.id}`}
					placeholder="Depends On"
					options={[...dependsOnOptions, additionalDependsOn].filter((depend) => depend.id !== attribute.id)}
					value={attribute.dependsOn}
					onChange={(value: any) => {
						dispatch(handleAutoFocus(`select_depends_on_input_${index}_${attribute.id}`))
						dispatch(handleComponentsHeaderValues({ id: attribute.id, keyName: 'dependsOn', value }))
					}}
				/>
			</TopElement>
			<TopElement>
				<div className="name">Mandatory:</div>
				<SelectOverview
					isDisabled={isDisabled}
					maxMenuHeight={100}
					maxWidth="300px"
					height="40px"
					autoFocus={focusName === `select_mandatory_input_${index}_${attribute.id}`}
					placeholder="Mandatory"
					options={[true, false].map((el) => ({ value: el, label: el ? 'Yes' : 'No' }))}
					value={{ value: attribute.mandatory, label: attribute.mandatory ? 'Yes' : 'No' }}
					onChange={(value: any) => {
						dispatch(handleAutoFocus(`select_mandatory_input_${index}_${attribute.id}`))
						dispatch(handleComponentsHeaderValues({ id: attribute.id, keyName: 'mandatory', value }))
					}}
				/>
			</TopElement>
			<TopElement>
				<div className="name">Display style:</div>
				<SelectOverview
					isDisabled={isDisabled}
					maxMenuHeight={100}
					maxWidth="300px"
					height="40px"
					// autoFocus={focusName === `select_depends_on_input_${index}_${att.id}`}
					placeholder=""
					options={(['normal', 'grid'] as DisplayStyleForm[]).map((value) => ({
						value,
						label: value,
					}))}
					value={{ value: attribute.display_style, label: attribute.display_style }}
					onChange={({ value }: any) => {
						// dispatch(handleAutoFocus(`select_depends_on_input_${index}_${att.id}`))
						dispatch(handleComponentsHeaderValues({ id: attribute.id, keyName: 'display_style', value }))
					}}
				/>
			</TopElement>
			<TopElement>
				<div className="name">Name:</div>
				<Input
					className="value_input"
					isDisabled
					border={!isDisabled && attribute.componentName === '' ? '1px solid red' : ''}
					height="40px"
					placeholder="Name"
					value={attribute.componentName}
					autoFocus={focusName === `select_name_input_${index}_${attribute.id}`}
					onChange={(value) => {
						dispatch(handleAutoFocus(`select_name_input_${index}_${attribute.id}`))
						dispatch(
							handleComponentsHeaderValues({
								id: attribute.id,
								keyName: 'componentName',
								value,
							}),
						)
					}}
				/>
			</TopElement>
			<TopElement>
				<div className="name">Depends on conditional:</div>
				<Button
					disabled={isDisabled}
					onClick={() => setOpenDependsOnConditionalDialog(true)}
					sx={{ textAlign: 'left', fontSize: 12, lineHeight: 1.2, width: '100%' }}
				>
					{dependsOnLabel}
				</Button>
				<DependsOnConditionalDialog
					open={isOpenDependsOnConditionalDialog}
					onClose={() => setOpenDependsOnConditionalDialog(false)}
					components={components}
					dependsOnOptions={[
						...mainForm.attributes.map((attr) => ({
							value: attr.attribute_name.value,
							label: attr.attribute_name.value,
							category: 'Form fields',
							componentId: attr.id as any,
						})),
						...uniqBy(components, 'originalId')
							.slice()
							.sort((a, b) => a.sequence - b.sequence)
							.map((component) =>
								component.attributes.map((attribute) => ({
									value: attribute.attribute_name.value,
									label: attribute.attribute_name.value,
									category:
										countBy((component) => component.originalId, components)[component.originalId] > 1
											? (component.componentName as string)
											: component.displayName,
									componentId: component.id as string,
								})),
							)
							.flat(),
					]}
					defaultValue={attribute.depends_on_conditional}
					onChange={(dependsOnValue) => {
						dispatch(
							handleComponentsHeaderValues({
								id: attribute.id,
								keyName: 'depends_on_conditional',
								value: dependsOnValue,
							}),
						)
						setOpenDependsOnConditionalDialog(false)
					}}
				/>
			</TopElement>
		</div>
	)
}
