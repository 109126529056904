import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { PostQuery } from 'src/services/tasksActions'
import { isOfType } from 'typesafe-actions'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import { controlsActions } from '../controls'
import { getTaskActions } from './actions'
import { GET_TASK_ACTION } from './constants'
import { tasksActionsElementActions } from './index'

export const getTasksListEpic: AppEpicDeprecated<ReturnType<typeof getTaskActions>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(GET_TASK_ACTION)),
		withLatestFrom(state$),
		switchMap(([action]) => {
			return concat(
				of(tasksActionsElementActions.setTaskActions([])),
				of(tasksActionsElementActions.isTaskActionsLoading(true)),
				fromPromise(PostQuery(action.payload.tagCode)).pipe(
					switchMap((response) => {
						return concat(
							of(tasksActionsElementActions.setTaskActions(response.actions)),
							of(tasksActionsElementActions.isTaskActionsLoading(false)),
							of(controlsActions.handleIsLoading(false)),
						)
					}),
					catchError((err) => {
						return concat(
							of(controlsActions.handleIsLoading(false)),
							of(tasksActionsElementActions.isTaskActionsLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: tasksActionsElementActions.getTaskActions(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export default combineEpics(getTasksListEpic)
