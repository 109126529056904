export const GET_LIST_OF_FORMS = 'formsList/GET_LIST_OF_FORMS'
export const SET_LIST_OF_FORMS = 'formsList/SET_LIST_OF_FORMS'
export const HANDLE_LIST_IS_LOADING = 'formsList/HANDLE_LIST_IS_LOADING'

export const GET_COMPONENTS_OF_FORMS = 'formsList/GET_COMPONENTS_OF_FORMS'
export const SET_COMPONENT_OF_FORMS = 'formsList/SET_COMPONENT_OF_FORMS'
export const HANDLE_COMPONENTS_LIST_IS_LOADING = 'formsList/HANDLE_COMPONENTS_LIST_IS_LOADING'

export const REMOVE_FORM = 'formsList/REMOVE_FORM'
export const HANDLE_IS_REMOVING_FORM = 'formsList/HANDLE_IS_REMOVING_FORM'
