/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Layout } from 'src/components/Molecules/Layout'
import styled from 'styled-components'
import cancel from '../../../assets/icons/errorCancel.svg'
import save from '../../../assets/icons/save.svg'
import {
	checkFormNumber,
	clearForm,
	getComponentAttributeTypes,
	handleFormDetails,
	saveExistingComponentAsNew,
	saveNewComponent,
	saveNewForm,
} from '../../../features/formsHandler/actions'
import browserHistory from '../../../services/history'
import { colors } from '../../../theme/colors'
import { useAppDispatch, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Input from '../../Atoms/Input'
import Button from '../../Molecules/Button'
import PageTitle from '../../Molecules/PageTitle'
import AttributesComponent from '../../Organisms/Forms/Attributes'
import Components from '../../Organisms/Forms/Components'
import FormDetailsComponent from '../../Organisms/Forms/formDetails'
import Popup from '../../Organisms/Popup'

const PopupContent = styled.div`
	.header {
		display: flex;
		justify-content: center;
		padding: 15px;
		font-size: 16px;
		font-weight: bold;
	}

	.formName {
		margin: 30px 15px;

		input {
			max-width: 300px;
		}
	}

	.buttons {
		display: flex;

		button {
			margin-left: 15px;
			padding: 10px 12px;
		}
	}
`
const MainWrapper = styled.div``
const PageTitleWrapper = styled.div`
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;

	.buttons {
		display: flex;

		button {
			margin-left: 15px;
			padding: 10px 12px;
		}
	}
`

const FormCreate = () => {
	const [saveComponentPopup, handleSaveComponentPopup] = useState<{
		isOpen: boolean
		componentId: null | number | string
		component: string
		name: string | number
	}>({
		isOpen: false,
		componentId: null,
		component: '',
		name: '',
	})
	const [checkNumber, setCheckNumber] = useState<any>(null)

	const dispatch = useAppDispatch()
	const {
		FormDetails,
		FormAttributes,
		FormComponents,
		sequences,
		isSavingNewForm,
		isSavingExistingComponent,
		isFormNumberOriginal,
	} = useAppSelectorDeprecated((state) => ({
		FormDetails: state.formsHandler.FormDetails,
		FormAttributes: state.formsHandler.FormAttributes,
		FormComponents: state.formsHandler.FormComponents,
		sequences: state.formsHandler.sequences,
		isSavingNewForm: state.formsHandler.isSavingNewForm,
		isSavingExistingComponent: state.formsHandler.isSavingExistingComponent,
		isFormNumberOriginal: state.formsHandler.isFormNumberOriginal,
	}))

	const getOverview = () => {
		dispatch(clearForm())
		dispatch(getComponentAttributeTypes())
	}
	const fetchOverview = useCallback(getOverview, [])

	useEffect(() => {
		fetchOverview()
	}, [fetchOverview])

	const cancelForm = () => {
		browserHistory.push('/forms/templates')
	}

	const attributesCondition =
		FormAttributes.attributes.filter((element) => {
			return (
				element.sequence.value !== '' &&
				element.attribute_name.value !== '' &&
				element.display_name.value !== '' &&
				element.attribute_type.value !== ''
			)
		}).length === FormAttributes.attributes.length

	const sequenceCondition =
		lodash.uniqBy(sequences, 'sequence').filter((seq) => seq.sequence !== '').length === sequences.length

	const detailsCondition =
		Object.keys(FormDetails)
			// @ts-ignore
			.map((value) => FormDetails[value].value)
			.map((value) => (value.length > 0 ? value.replace(/\s/g, '') : value))
			.filter((el) => el === '').length === 0

	const componentsCondition =
		FormComponents.attributes.filter(
			(att) =>
				// @ts-ignore
				!att.isEditing && !att.is_new && att.componentName !== '' && att.displayName !== '' && att.sequence !== '',
		).length === FormComponents.attributes.length

	const handleOpenPopup = ({ componentId, component }: { componentId: string | number; component: string }) => {
		handleSaveComponentPopup({ componentId, component, isOpen: true, name: '' })
	}

	return (
		<Layout>
			<MainWrapper>
				{saveComponentPopup.isOpen && (
					<Popup
						onClick={() => handleSaveComponentPopup({ isOpen: false, componentId: null, component: '', name: '' })}
						isCloseButton
						children={
							<PopupContent>
								<div className="header">
									By selecting save, you are creating a new Component. Please name the new Component.
								</div>
								<div className="formName">
									<Input
										// eslint-disable-next-line jsx-a11y/no-autofocus
										autoFocus
										height="40px"
										placeholder="Component name"
										value={saveComponentPopup.name}
										onChange={(value) => handleSaveComponentPopup({ ...saveComponentPopup, name: value })}
									/>
								</div>
								<div className="buttons">
									<Button
										imageSrc={cancel}
										text="Cancel"
										isError
										onClick={() =>
											handleSaveComponentPopup({ isOpen: false, componentId: null, component: '', name: '' })
										}
									/>
									<Button
										color={colors.main100}
										textColor={colors.white}
										isDisabled={saveComponentPopup.name === ''}
										imageSrc={save}
										text="Save Form"
										isError={false}
										onClick={() => {
											if (saveComponentPopup.component === 'empty') {
												dispatch(
													saveNewComponent({
														componentId: saveComponentPopup.componentId,
														name: saveComponentPopup.name,
													}),
												)
											}
											if (saveComponentPopup.component === 'new_component') {
												dispatch(
													saveExistingComponentAsNew({
														componentId: saveComponentPopup.componentId,
														name: saveComponentPopup.name,
													}),
												)
											}
											handleSaveComponentPopup({ isOpen: false, componentId: null, component: '', name: '' })
										}}
									/>
								</div>
							</PopupContent>
						}
					/>
				)}
				<PageTitleWrapper>
					<div>
						<PageTitle margin="5px 0 30px 0" title="Create New Form" subTitle="" />
					</div>
					<div className="buttons">
						<div>
							<Button imageSrc={cancel} text="Cancel" isError onClick={cancelForm} />
						</div>
						<div>
							<Button
								color={colors.main100}
								textColor={colors.white}
								loaderColor={colors.white}
								imageSrc={save}
								text="Save Form"
								isError={false}
								isLoading={isSavingNewForm}
								isDisabled={
									!isFormNumberOriginal ||
									!attributesCondition ||
									!sequenceCondition ||
									!componentsCondition ||
									isSavingNewForm ||
									!detailsCondition ||
									(FormAttributes.attributes.length === 0 && FormComponents.attributes.length === 0)
								}
								onClick={() => dispatch(saveNewForm())}
							/>
						</div>
					</div>
				</PageTitleWrapper>
				<FormDetailsComponent
					isGuide={false}
					IsEditForm={false}
					formDetails={FormDetails}
					isCreate
					isDisabled={isSavingExistingComponent || isSavingNewForm}
					handleFormDetails={(value) => {
						if (value.key === 'formNumber') {
							if (checkNumber) {
								clearTimeout(checkNumber)
							}
							setCheckNumber(
								setTimeout(() => {
									dispatch(checkFormNumber({ value: value.value }))
								}, 300),
							)
						}
						dispatch(handleFormDetails(value))
					}}
				/>
				<AttributesComponent isDisabled={isSavingExistingComponent || isSavingNewForm} />
				<Components handleSavePopup={handleOpenPopup} isDisabled={isSavingExistingComponent || isSavingNewForm} />
			</MainWrapper>
		</Layout>
	)
}
export default FormCreate
