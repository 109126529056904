import { colors } from 'src/theme/colors'
import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { dateFormat } from 'src/assets/dateFormats'
import { IReply } from 'src/models/disciplineOverview/disciplineOverview'
import { FormattedComment } from './FormattedComment'

const Circle = styled.div`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.greyBD};
	color: ${colors.white};
	font-weight: 700;
	border-radius: 50%;
`

const CommentHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 8px;
	gap: 5px;
`

const UserData = styled.div`
	font-size: 13px;
	font-weight: 500;
`

const CommentContent = styled.div`
	width: 100%;
	padding-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
	padding-bottom: 12px;
	white-space: pre-line;
	line-height: 1.1;
`

const CommentWrapper = styled.div`
	margin-left: 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin-top: 8px;
	backgroundcolor: ${colors.lightGreyF10};
	margin-right: 0px;
`

interface ReplyProps {
	reply: IReply
}

export function Reply(props: ReplyProps) {
	return (
		<CommentWrapper>
			<Paper
				elevation={0}
				sx={{
					margin: '0px',
					backgroundColor: `${colors.lightGreyE5}`,
					padding: '4px',
					paddingTop: 0,
					paddingBottom: 0,
				}}
			>
				<CommentHeader>
					<Circle>{props.reply.recordBy.substring(0, 2)}</Circle>
					<UserData>{props.reply.recordBy}</UserData>
					<UserData>{moment(new Date(props.reply.recordAt)).format(dateFormat.engHours)}</UserData>
				</CommentHeader>
				<CommentContent>
					<FormattedComment comment={props.reply.comment} />
				</CommentContent>
			</Paper>
		</CommentWrapper>
	)
}
