import styled from 'styled-components'
import logo from '../../../assets/logo.svg'
import { colors } from '../../../theme/colors'
import { Link } from 'react-router-dom'
import { msalInstance } from 'src/utils/msalInstance'
import { Button } from '@mui/material'

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${colors.black};
	img {
		max-height: 170px;
		max-width: 150px;
	}
	.header {
		margin: 20px 0;
		font-size: 50px;
	}
	.comeBack {
		font-size: 20px;
		a {
			color: ${colors.blue1};
		}
	}
`

export function AccessDenied() {
	return (
		<Wrapper>
			<img src={logo} alt="emt_rails_logo" />
			<div className="header">[401] - Access denied</div>
			<div className="comeBack">
				Click <Button onClick={() => msalInstance.logout()}>here</Button> to return to sign in page
			</div>
		</Wrapper>
	)
}
