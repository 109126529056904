/* eslint-disable consistent-return */

import { IDisciplineOverviewComponent } from './disciplineOverviewInterface'

export interface IHandlePopup {
	isPopupOpen: boolean
	task_id: number
	action_name: string
	actionField: any
}

export const tooltipTextByActions = (action: string) => {
	if (action === 'action_fetch_deliverable_numbers') {
		return 'This button creates placeholders in Assetwise and fetches Document Numbers for all your New Deliverables added below. If there are any errors, press again to re-sync with Assetwise. Once all Document Numbers are generated, you can ‘Send For Acceptance’.'
	}
	if (action === 'action_nominate_didp_owner') {
		return 'Add an additional owner to this Discipline. Begin typing or select the user from the dropdown list.'
	}
	if (action === 'action_add_new_deliverables') {
		return 'Enter the metadata for a new deliverable placeholder to be created in Assetwise.'
	}
	if (action === 'action_add_existing_deliverables') {
		return 'Chooses an exisiting document / revision from Asset Wise.'
	}
}

export const namesByActions = (action: string) => {
	if (action === 'action_accept_didp_ownership') {
		return 'Accept Ownership'
	}
	if (action === 'action_fetch_deliverable_numbers') {
		return 'Fetch Deliverable Numbers'
	}
	if (action === 'action_reject_didp_ownership') {
		return 'Reject Ownership'
	}
	if (action === 'action_accept_didp') {
		return 'Accept Discipline'
	}
	if (action === 'action_reject_didp') {
		return 'Reject Discipline'
	}
	if (action === 'action_nominate_didp_owner') {
		return 'Nominate Additional Owner'
	}
	if (action === 'action_send_didp_for_acceptance') {
		return 'Send For Acceptance'
	}
	if (action === 'action_reopen_didp') {
		return 'Reopen'
	}
	if (action === 'action_remove_didp') {
		return 'Remove Discipline'
	}
	return ''
}

export const clickOnAction = (
	action: { task_id: number; action_name: string },
	props: IDisciplineOverviewComponent,
	handlePopupData: React.Dispatch<React.SetStateAction<IHandlePopup>>,
	additionalItems?: any,
) => {
	if (action.action_name === 'action_nominate_didp_owner' && !props.isNominatedOpen && !props.isNominating) {
		additionalItems.onInputChange('')
		props.setNominateOpen(true)
	}

	if (action.action_name === 'action_nominate_didp_owner' && props.nominateSelected.length > 0) {
		props.nominateAdditionalDidpOwner({
			task_id: action.task_id,
			action_name: action.action_name,
			tagCode: props.match.params.param1,
		})
	}

	if (action.action_name === 'action_accept_didp_ownership') {
		props.handleAcceptOwnership({
			isAccepted: true,
			tagCode: props.match.params.param1,
			action_name: action.action_name,
			task_id: action.task_id,
		})
	}
	if (action.action_name === 'action_fetch_deliverable_numbers') {
		additionalItems.handleFetchExistingDeliverablesPopup({
			isOpen: true,
			tagCode: props.match.params.param1,
			action_name: action.action_name,
			task_id: action.task_id,
		})
	}

	if (action.action_name === 'action_reject_didp_ownership') {
		handlePopupData({
			isPopupOpen: true,
			action_name: action.action_name,
			task_id: action.task_id,
			actionField: (comment: string) =>
				props.handleAcceptOwnership({
					isAccepted: false,
					tagCode: props.match.params.param1,
					action_name: action.action_name,
					task_id: action.task_id,
					comment,
				}),
		})
	}

	if (action.action_name === 'action_send_didp_for_acceptance') {
		if (props.deliverables.body.filter((item: any) => !item.row1).length === 0) {
			props.informTIDPOwner({
				task_id: action.task_id,
				action_name: action.action_name,
				tagCode: props.match.params.param1,
			})
		}
	}

	if (action.action_name === 'action_accept_didp') {
		props.handleDIDPAcceptance({
			isAccepted: true,
			tagCode: props.match.params.param1,
			task_id: action.task_id,
			action_name: action.action_name,
		})
	}

	if (action.action_name === 'action_reopen_didp') {
		props.handleDIDPReopening({
			tagCode: props.match.params.param1,
			task_id: action.task_id,
			action_name: action.action_name,
		})
	}

	if (action.action_name === 'action_remove_didp') {
		props.handleDIDPRemoving({
			tagCode: props.match.params.param1,
			task_id: action.task_id,
			action_name: action.action_name,
		})
	}

	if (action.action_name === 'action_reject_didp') {
		handlePopupData({
			isPopupOpen: true,
			task_id: action.task_id,
			action_name: action.action_name,
			actionField: (comment: string) =>
				props.handleDIDPAcceptance({
					isAccepted: false,
					tagCode: props.match.params.param1,
					task_id: action.task_id,
					action_name: action.action_name,
					comment,
				}),
		})
	}
}
