export const GET_DISCIPLINE_OVERVIEW = 'disciplineOverview/GET_DISCIPLINE_OVERVIEW'

export const HANDLE_DISCIPLINE_TABS = 'disciplineOverview/HANDLE_DISCIPLINE_TABS'

export const HANDLE_CREATE_NEW_DELIVERABLE = 'disciplineOverview/HANDLE_CREATE_NEW_DELIVERABLE'
export const ASSIGN_VALUE_TO_DELIVERABLES_TABS = 'disciplineOverview/ASSIGN_VALUE_TO_DELIVERABLES_TABS'
export const SAVE_NEW_DELIVERABLES = 'disciplineOverview/SAVE_NEW_DELIVERABLES'
export const NOMINATE_ADDITIONAL_DIDP_OWNER = 'disciplineOverview/NOMINATE_ADDITIONAL_DIDP_OWNER'
export const SET_NOMINATE_SELECTED = 'disciplineOverview/SET_NOMINATE_SELECTED'
export const SET_NOMINATE_IS_OPEN = 'disciplineOverview/SET_NOMINATE_IS_OPEN'
export const HANDLE_IS_NOMINATING = 'disciplineOverview/HANDLE_IS_NOMINATING'
export const INFORM_TIDP_OWNER = 'disciplineOverview/INFORM_TIDP_OWNER'
export const IS_INFORMING_TIDP = 'disciplineOverview/IS_INFORMING_TIDP'
export const SET_DISCIPLINE_TAG_CODE = 'disciplineOverview/SET_DISCIPLINE_TAG_CODE'
export const SET_DISCIPLINE_TIDP_TAG_CODE = 'disciplineOverview/SET_DISCIPLINE_TIDP_TAG_CODE'
export const ASSIGN_DIDP_OWNER = 'disciplineOverview/ASSIGN_DIDP_OWNER'
export const ASSIGN_NOMINATED_DIDP_OWNER = 'disciplineOverview/ASSIGN_NOMINATED_DIDP_OWNER'
export const HANDLING_SELECTED_DELIVERABLES = 'disciplineOverview/HANDLING_SELECTED_DELIVERABLES'
export const HANDLE_ACTION_PROCESSING = 'disciplineOverview/HANDLE_ACTION_PROCESSING'
export const HANDLE_FETCH_DELIVERABLE_NUMBERS = 'disciplineOverview/HANDLE_FETCH_DELIVERABLE_NUMBERS'

export const HANDLE_NEW_COMMENT_TEXT = 'disciplineOverview/HANDLE_NEW_COMMENT_TEXT'
export const SAVE_NEW_COMMENT = 'disciplineOverview/SAVE_NEW_COMMENT'
export const START_REPLAY_ON_COMMENT = 'disciplineOverview/START_REPLAY_ON_COMMENT'
export const SAVE_REPLY = 'disciplineOverview/SAVE_REPLY'
export const HANDLE_REPLY_COMMENT = 'disciplineOverview/HANDLE_REPLY_COMMENT'
export const HANDLE_START_NEW_COMMENT = 'disciplineOverview/HANDLE_START_NEW_COMMENT'
export const SET_DISCIPLINE_DATA = 'disciplineOverview/SET_DISCIPLINE_DATA'
export const CREATE_NEW_DELIVERABLE = 'disciplineOverview/CREATE_NEW_DELIVERABLE'
export const IS_SAVING_NEW_DELIVERABLE = 'disciplineOverview/IS_SAVING_NEW_DELIVERABLE'
export const IS_SAVING_NEW_COMMENT = 'disciplineOverview/IS_SAVING_NEW_COMMENT'

export const HANDLE_ACCEPT_OWNERSHIP = 'disciplineOverview/HANDLE_ACCEPT_OWNERSHIP'
export const HANDLE_DIDP_ACCEPTANCE = 'disciplineOverview/HANDLE_DIDP_ACCEPTANCE'
export const HANDLE_DIDP_REOPENING = 'disciplineOverview/HANDLE_DIDP_REOPENING'
export const HANDLE_DIDP_REMOVING = 'disciplineOverview/HANDLE_DIDP_REMOVING'

export const UPDATE_COMMENT = 'disciplineOverview/UPDATE_COMMENT'

export const HANDLE_SELECTED_DELIVERABLES = 'disciplineOverview/HANDLE_SELECTED_DELIVERABLES'

export const UNLINK_DELIVERABLE = 'disciplineOverview/UNLINK_DELIVERABLE'
export const HANDLE_IS_LINKING = 'disciplineOverview/HANDLE_IS_LINKING'
export const DELETE_DELIVERABLE_FROM_LIST = 'disciplineOverview/DELETE_DELIVERABLE_FROM_LIST'

export const GET_DISCIPLINE_OVERVIEW_HISTORY = 'disciplineOverview/GET_DISCIPLINE_OVERVIEW_HISTORY'
export const SET_DISCIPLINE_OVERVIEW_HISTORY = 'disciplineOverview/SET_DISCIPLINE_OVERVIEW_HISTORY'

export const SET_DELIVERABLES = 'disciplineOverview/SET_DELIVERABLES'
export const HANDLE_IS_LOADING_DELIVERABLES = 'disciplineOverview/HANDLE_IS_LOADING_DELIVERABLES'
export const GET_DELIVERABLES = 'disciplineOverview/GET_DELIVERABLES'
export const GET_DELIVERABLES_SILENT = 'disciplineOverview/GET_DELIVERABLES_SILENT'

export const GET_ONE_DELIVERABLE = 'disciplineOverview/GET_ONE_DELIVERABLE'
export const SET_ONE_DELIVERABLE = 'disciplineOverview/SET_ONE_DELIVERABLE'
export const HANDLE_DELIVERABLE_POPUP = 'disciplineOverview/HANDLE_DELIVERABLE_POPUP'
export const HANDLE_IS_DELIVERABLE_CHANGING = 'disciplineOverview/HANDLE_IS_DELIVERABLE_CHANGING'
export const HANDLE_IS_DELIVERABLE_LOADING = 'disciplineOverview/HANDLE_IS_DELIVERABLE_LOADING'

export const HANDLE_IS_RENAMING_DELIVERABLES = 'disciplineOverview/HANDLE_IS_RENAMING_DELIVERABLES'
export const RENAME_DELIVERABLES = 'disciplineOverview/RENAME_DELIVERABLES'
