import lodash from 'lodash'
import { combineEpics, Epic } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { getDocumentAttributes, getTechnicalLIfecyclettributes } from 'src/services/attributes'
import * as Types from 'Types'
import { isOfType } from 'typesafe-actions'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import { getAttributesForDocument } from './actions'
import { attributesActions, attributesConstants } from './index'

export const getDocumentAttributesEpic: AppEpicDeprecated<ReturnType<typeof getAttributesForDocument>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(isOfType(attributesConstants.GET_DOCUMENT_ATTRIBUTES)),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			return fromPromise(getDocumentAttributes(state.users.contract.contract)).pipe(
				switchMap((response) => {
					const listOfAttributes = lodash.uniqBy(
						[...response.class[0].attributes, ...response.global_attributes],
						'attribute_name',
					)
					return concat(of(attributesActions.setAttributesForDocument(listOfAttributes)))
				}),
				catchError((err) => {
					return of(
						authorizationActions.recallApi({
							errorCode: err.status,
							callback: attributesActions.getAttributesForDocument(),
						}),
					)
				}),
			)
		}),
	)

export const getTechnicalLifecycleEpic: Epic<any, any, any, Types.Services> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(attributesConstants.GET_TECHNICAL_LIFECYCLES_ATTRIBUTES)),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			return fromPromise(getTechnicalLIfecyclettributes(state.users.contract.contract)).pipe(
				switchMap((response) => {
					return concat(of(attributesActions.setAttributesForTechnicalLifecycle(response.class[0].attributes)))
				}),
				catchError((err) => {
					return of(
						authorizationActions.recallApi({
							errorCode: err.status,
							callback: attributesActions.getAttributesForTechnicalLifecycle(),
						}),
					)
				}),
			)
		}),
	)

export default combineEpics(getDocumentAttributesEpic, getTechnicalLifecycleEpic)
