import { axiosInstance } from 'src/services/axiosInstance'
import { GET_ATTRIBUTES } from '../constants'

export const getDocumentAttributes = async (contract: string) => {
	const { data } = await axiosInstance.get(`${GET_ATTRIBUTES}?contract=${contract}&class_code=Document`)
	return data
}

export const getTechnicalLIfecyclettributes = async (contract: string) => {
	const { data } = await axiosInstance.get(`${GET_ATTRIBUTES}?contract=${contract}&class_code=Technical Lifecycles`)
	return data
}
