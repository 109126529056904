import { AimsModule } from 'src/features/authorization/constants'
import { getBaseUrlOrLocalhost } from 'src/utils/getBaseUrl'
import { getDocumentsUrl } from './getDocumentsUrl'

const moduleBaseUrl: string = `${getBaseUrlOrLocalhost()}?module=`
const documentsUrl = getDocumentsUrl()

export const MODULE_URLS: Record<AimsModule, string> = {
	[AimsModule.Assets]: `${moduleBaseUrl}Assets`,
	[AimsModule.TIDP]: `${moduleBaseUrl}TIDP`,
	[AimsModule.DIDP]: `${moduleBaseUrl}DIDP`,
	[AimsModule.Documents]: documentsUrl,
	[AimsModule.ThreeD]: `${getBaseUrlOrLocalhost()}app/emt-3d`,
	[AimsModule.Atlas]: `${getBaseUrlOrLocalhost()}atlas`,
}

export const ROOT_APP_URL = `${getBaseUrlOrLocalhost()}app`
