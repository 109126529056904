export const GET_PRIMARY_ASSETS = 'flowFroManager/GET_PRIMARY_ASSETS'
export const SET_PRIMARY_ASSETS = 'flowFroManager/SET_PRIMARY_ASSETS'
export const SET_PRIMARY_ASSET_SELECTED = 'flowFroManager/SET_PRIMARY_ASSET_SELECTED'

export const GET_WORK_PACKAGE = 'flowFroManager/GET_WORK_PACKAGE'
export const SET_WORK_PACKAGE = 'flowFroManager/SET_WORK_PACKAGE'
export const SET_WORK_PACKAGE_SELECTED = 'flowFroManager/SET_WORK_PACKAGE_SELECTED'

export const GET_CONTRACT_DATA = 'flowFroManager/GET_CONTRACT_DATA'
export const SET_CONTRACT_DATA = 'flowFroManager/SET_CONTRACT_DATA'

export const GET_OWNERS = 'flowFroManager/GET_OWNERS'
export const SET_OWNERS = 'flowFroManager/SET_OWNERS'
export const SET_OWNER_SELECTED = 'flowFroManager/SET_OWNER_SELECTED'

export const SET_CERTIFICATION_DATE = 'flowFroManager/SET_CERTIFICATION_DATE'
export const SET_FINAL_SUBMISSION_DATE = 'flowFroManager/SET_FINAL_SUBMISSION_DATE'
export const SET_COMMENCEMENT_DATE = 'flowFroManager/SET_COMMENCEMENT_DATE'
export const SET_IDR_SUBMISSION_DATE = 'flowFroManager/SET_IDR_SUBMISSION_DATE'

export const SET_TECHNICAL_LIFECYCLE = 'flowFroManager/SET_TECHNICAL_LIFECYCLE'
export const SET_TECHNICAL_LIFECYCLE_OPTIONS = 'flowFroManager/SET_TECHNICAL_LIFECYCLE_OPTIONS'

export const CONFIRM_FLOW_FOR_MANAGER = 'flowFroManager/CONFIRM_FLOW_FOR_MANAGER'
export const CLEAR_FORM = 'flowFroManager/CLEAR_FORM'

export const MANAGE_TAB = 'flowFroManager/MANAGE_TAB'
