/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import lodash from 'lodash'
import { isEmpty } from 'ramda'
import { toast } from 'react-toastify'
import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise, isNumeric } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { assetServiceService } from 'src/facade/asset_service'
import { formsService } from 'src/facade/forms/formsService'
import { AttributeType, ChildForm, Form } from 'src/facade/forms/formsService.types'
import * as formsHandlerService from 'src/services/formsHandler'
import browserHistory from '../../services/history'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import {
	addExistingComponent,
	assignGuideInfo,
	checkFormNumber,
	clearForm,
	downloadGuide,
	getComponentAttributeTypes,
	getForm,
	handleIsGettingForm,
	handleIsSavingNewComponent,
	handleIsSavingNewForm,
	handleIsUpdatingExistingComponent,
	handleIsUpdatingMainForm,
	handleSelectedFile,
	isCheckingCode,
	isDownloadingGuide,
	isRemovingGuide,
	isUploadingGuide,
	removeGuide,
	saveExistingComponentAsNew,
	saveNewComponent,
	saveNewForm,
	setAllSequences,
	setAttributes,
	setComponentAttributeTypes,
	setComponents,
	setFormDetails,
	setIsFormNumberOriginal,
	updateExistingComponent,
	updateMainForm,
	uploadGuide
} from './actions'
import { initialFormState } from './slice'
import { AssignFormObjects, componentToSave, getUpdatedField } from './utils'

const trimSpaces = (str: any) => str.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ')

export const getFormEpic: AppEpicDeprecated<ReturnType<typeof getForm>> = (action$, state$) =>
	action$.pipe(
		filter(getForm.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(clearForm()),
				of(handleIsGettingForm(true)),
				fromPromise(
					formsHandlerService.getForm({
						id: action.payload.id,
						contract: state.users.contract.contract,
						counter: action.payload.counter,
					}),
				).pipe(
					switchMap((responseInitial) => {
						const response = responseInitial[action.payload.id]
						if (response.total > 100) {
							return of(getForm({ id: action.payload.id, counter: response.total }))
						}
						const mainForm = response.forms.filter((item: any) => item.is_main_form)[0]
						const attributes = AssignFormObjects(lodash.sortBy(mainForm.attributes, ['sequence']))
						const attributesSequences = attributes.map((item: any) => ({
							id: item.id,
							sequence: item.sequence.value,
						}))

						const components = response.forms.filter((item: any) => !item.is_main_form)
						const formDetails = {
							formName: {
								value: mainForm.form_name,
							},
							formNumber: {
								value: mainForm.form_number,
							},
							formRevision: {
								value: mainForm.form_revision,
							},
						}
						const internalDependsOn = [
							...attributes.map((item: any) => ({
								value: item.attribute_name.value,
								label: item.attribute_name.value,
								id: item.id,
							})),
							...components.map((component: any) => ({
								value: component.form_name,
								label: component.form_name,
								id: component.id,
							})),
						]
						const formAttributes = {
							...initialFormState.FormAttributes,
							dependsOnOptions: internalDependsOn,
							attributes,
						}
						const componentsSequence = components.map((elements: any) => ({
							id: elements.linked_attribute_id,
							sequence: elements.sequence,
						}))
						const Components = components.map((elements: any) => {
							const dependElement = internalDependsOn.filter((depend) => depend.value === elements.depends_on)
							return {
								is_new: false,
								isEditing: false,
								id: elements.linked_attribute_id,
								originalId: elements.id,
								dependsOn: {
									value: elements.depends_on,
									label: elements.depends_on,
									id: dependElement.length > 0 ? dependElement[0].id : '',
								},
								displayName: elements.display_name,
								display_style: elements.display_style, 
								display_group: isEmpty(elements.display_group) ? null :  elements.display_group,   
								linked_attribute_id: elements.linked_attribute_id,
								sequence: elements.sequence,
								componentName: elements.form_name,
								contexts: elements.contexts,
								mandatory: elements.mandatory ? elements.mandatory : false,
								attributes: AssignFormObjects(lodash.sortBy(elements.attributes, ['sequence'])),
								oldAttributes: [],
								isOpen: true,
								isChecked: true,
								dependsOnOptions: elements.attributes.map((item: any) => ({
									value: item.attribute_name,
									label: item.attribute_name,
									id: item.id,
								})),
								sequences: elements.attributes.map((item: any) => ({ id: item.id, sequence: item.sequence })),
								depends_on_conditional: elements.depends_on_conditional,
							}
						})
						const guideInfo = {
							id: mainForm.linked_guidance_file_id ? mainForm.linked_guidance_file_id : '',
							name: mainForm.linked_guidance_file_name ? mainForm.linked_guidance_file_name : '',
							main_form_id: mainForm.id,
						}
						return concat(
							of(handleIsGettingForm(false)),
							of(setFormDetails(formDetails)),
							of(setAttributes(formAttributes)),
							of(handleSelectedFile('')),
							of(assignGuideInfo(guideInfo)),
							of(setAllSequences([...attributesSequences, ...componentsSequence])),
							of(
								setComponents({
									...initialFormState.FormComponents,
									dependsOnOptions: internalDependsOn,
									attributes: Components,
								}),
							),
						)
					}),
					catchError((err: any) => {
						return concat(
							of(handleIsGettingForm(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: getForm(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)
export const getComponentAttributeTypesEpic: AppEpicDeprecated<ReturnType<typeof getComponentAttributeTypes>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(getComponentAttributeTypes.match),
		withLatestFrom(state$),
		switchMap(() => {
			return fromPromise(formsHandlerService.getComponentAttributeTypes()).pipe(
				switchMap((response) => {
					return of(setComponentAttributeTypes(response.result.map((item: string) => ({ value: item, label: item }))))
				}),
				catchError((err: any) => {
					return of(
						authorizationActions.recallApi({
							errorCode: err.status,
							callback: getComponentAttributeTypes(),
						}),
					)
				}),
			)
		}),
	)

export const checkFormNumberEpic: AppEpicDeprecated<ReturnType<typeof checkFormNumber>> = (action$, state$) =>
	action$.pipe(
		filter(checkFormNumber.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(isCheckingCode(true)),
				of(setIsFormNumberOriginal(false)),
				fromPromise(formsHandlerService.getClassMetadata(action.payload.value, state.users.contract.contract)).pipe(
					switchMap((response) => {
						return concat(of(setIsFormNumberOriginal(response.length === 0)), of(isCheckingCode(false)))
					}),
					catchError((err: any) => {
						return concat(
							of(isCheckingCode(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: checkFormNumber(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const addExistingComponentEpic: AppEpicDeprecated<ReturnType<typeof addExistingComponent>> = (action$, state$) =>
	action$.pipe(
		filter(addExistingComponent.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return fromPromise(
				formsHandlerService.getForm({ id: action.payload.id, contract: state.users.contract.contract }),
			).pipe(
				switchMap((responseInitial) => {
					const response = responseInitial[action.payload.id]
					const components = response.forms
					const seq = state.formsHandler.sequences.filter((item) => isNumeric(item.sequence))
					// @ts-ignore
					const newSequence = seq.length === 0 ? 1 : Math.max(...seq.map((item) => item.sequence)) + 1
					const Components = components.map((elements: any) => {
						return {
							is_new: false,
							isEditing: false,
							id: elements.linked_attribute_id
								? elements.linked_attribute_id
								: `${elements.id}_${new Date().getTime()}`,
							originalId: elements.id,
							dependsOn: {
								value: elements.depends_on,
								label: elements.depends_on,
								id: new Date().getTime(),
							},
							displayName: elements.display_name === undefined ? '' : trimSpaces(elements.display_name),
							sequence: newSequence,
							componentName: elements.form_name,
							contexts: elements.contexts === undefined ? '' : trimSpaces(elements.contexts),
							mandatory: elements.mandatory === undefined ? false : elements.mandatory,
							attributes: AssignFormObjects(lodash.sortBy(elements.attributes, ['sequence'])),
							oldAttributes: [],
							isOpen: true,
							isChecked: true,
							dependsOnOptions: elements.attributes.map((item: any) => ({
								value: item.attribute_name,
								label: item.attribute_name,
								id: item.id,
							})),
							sequences: elements.attributes.map((item: any) => ({ id: item.id, sequence: item.sequence })),
							depends_on_conditional: elements.depends_on_conditional,
						}
					})
					const internalDependsOn = Components.map((component: any) => ({
						value: component.componentName,
						label: component.componentName,
						id: component.id,
					}))
					const dependsOn = [...state.formsHandler.FormComponents.dependsOnOptions, ...internalDependsOn]
					return concat(
						of(
							setComponents({
								...state.formsHandler.FormComponents,
								dependsOnOptions: dependsOn,
								attributes: [...state.formsHandler.FormComponents.attributes, ...Components],
							}),
						),
						// @ts-ignore
						of(setAllSequences([...state.formsHandler.sequences, { id: Components[0].id, sequence: newSequence }])),
						of(setAttributes({ ...state.formsHandler.FormAttributes, dependsOnOptions: dependsOn })),
					)
				}),

				catchError((err: any) => {
					return of(
						authorizationActions.recallApi({
							errorCode: err.status,
							callback: addExistingComponent(action.payload),
						}),
					)
				}),
			)
		}),
	)

export const saveNewComponentEpic: AppEpicDeprecated<ReturnType<typeof saveNewComponent>> = (action$, state$) =>
	action$.pipe(
		filter(saveNewComponent.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const componentToEdit = state.formsHandler.FormComponents.attributes.filter(
				(at) => at.id === action.payload.componentId,
			)
			const component = {
				name: trimSpaces(action.payload.name),
				is_main_form: false,
				contract: state.users.contract.contract,
				attributes: componentToSave(componentToEdit[0].attributes),
				components: [],
			}
			return concat(
				fromPromise(formsHandlerService.createNewForm(component)).pipe(
					switchMap((response) => {
						const seq = state.formsHandler.sequences.filter((item) => isNumeric(item.sequence))
						// @ts-ignore
						const newSequence = seq.length === 0 ? 1 : Math.max(...seq.map((item) => item.sequence)) + 1

						const originalId = `${response.id}_${new Date().getTime()}`
						const attributes = state.formsHandler.FormComponents.attributes.map((att) =>
							att.id === action.payload.componentId
								? {
									...att,
									attributes: AssignFormObjects(lodash.sortBy(response.attributes, ['sequence'])),
									is_new: false,
									isEditing: false,
									componentName: response.class_name,
									id: originalId,
									originalId: response.id,
									sequence: newSequence,
								}
								: att,
						)
						const dependsOn: any = [
							...state.formsHandler.FormComponents.dependsOnOptions,
							{ value: response.class_name, label: response.class_name, id: originalId },
						].filter((att) => att.value !== '')
						return concat(
							of(
								setComponents({
									...state.formsHandler.FormComponents,
									dependsOnOptions: dependsOn,
									attributes,
								}),
							),
							// @ts-ignore
							of(setAllSequences([...state.formsHandler.sequences, { id: response.id, sequence: newSequence }])),
							of(setAttributes({ ...state.formsHandler.FormAttributes, dependsOnOptions: dependsOn })),
						)
					}),
					catchError((err) => {
						return concat(
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: saveNewComponent(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const saveExistingComponentAsNewEpic: AppEpicDeprecated<ReturnType<typeof saveExistingComponentAsNew>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(saveExistingComponentAsNew.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const componentToEdit = state.formsHandler.FormComponents.attributes.filter(
				(at) => at.id === action.payload.componentId,
			)
			const component = {
				name: trimSpaces(action.payload.name),
				is_main_form: false,
				contract: state.users.contract.contract,
				attributes: componentToSave(componentToEdit[0].attributes),
				components: [],
			}
			return concat(
				of(handleIsSavingNewComponent(true)),
				fromPromise(formsHandlerService.createNewForm(component)).pipe(
					switchMap((response) => {
						const seq = state.formsHandler.sequences.filter((item) => isNumeric(item.sequence))
						// @ts-ignore
						const newSequence = seq.length === 0 ? 1 : Math.max(...seq.map((item) => item.sequence)) + 1

						const dependsOnAttributes = response.attributes.map((att: any) => ({
							value: att.attribute_name,
							label: att.attribute_name,
							id: att.id,
						}))
						const originalId = `${response.id}_${new Date().getTime()}`
						const newAttribute = state.formsHandler.FormComponents.attributes
							.filter((att) => att.id === action.payload.componentId)
							.map((att) => ({
								...att,
								attributes: AssignFormObjects(lodash.sortBy(response.attributes, ['sequence'])),
								dependsOn: dependsOnAttributes,
								is_new: false,
								isEditing: false,
								componentName: response.class_name,
								id: originalId,
								originalId: response.id,
								sequence: newSequence,
							}))
						const dependsOn: any = [
							...state.formsHandler.FormComponents.dependsOnOptions,
							{ value: response.class_name, label: response.class_name, id: originalId },
						]
						return concat(
							of(
								setComponents({
									...state.formsHandler.FormComponents,
									dependsOnOptions: dependsOn,
									attributes: [
										...state.formsHandler.FormComponents.attributes.map((item) => ({
											...item,
											isEditing: false,
										})),
										...newAttribute,
									],
									oldAttributes: [...state.formsHandler.FormComponents.attributes],
								}),
							),
							// @ts-ignore
							of(setAllSequences([...state.formsHandler.sequences, { id: response.id, sequence: newSequence }])),
							of(setAttributes({ ...state.formsHandler.FormAttributes, dependsOnOptions: dependsOn })),
							of(handleIsSavingNewComponent(false)),
						)
					}),
					catchError((err) => {
						return concat(
							of(handleIsSavingNewComponent(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: saveExistingComponentAsNew(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const createNewFormEpic: AppEpicDeprecated<ReturnType<typeof saveNewForm>> = (action$, state$) =>
	action$.pipe(
		filter(saveNewForm.match),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			const components = state.formsHandler.FormComponents.attributes.map((el) => {
				return {
					id: el.originalId,
					name: el.componentName,
					mandatory:
						el.mandatory === undefined
							? false
							: typeof el.mandatory === 'boolean'
								? el.mandatory
								: // @ts-ignore
								el.mandatory.value === undefined
									? false
									: // @ts-ignore
									el.mandatory.value,
					sequence: el.sequence,
					display_name: trimSpaces(el.displayName),
					contexts: el.contexts === undefined ? '' : trimSpaces(el.contexts),
					depends_on: el.dependsOn.value ? el.dependsOn.value : '',
					depends_on_conditional: el.depends_on_conditional ? el.depends_on_conditional : null,
				}
			})
			const component = {
				name: trimSpaces(state.formsHandler.FormDetails.formName.value),
				number: trimSpaces(state.formsHandler.FormDetails.formNumber.value),
				revision: trimSpaces(state.formsHandler.FormDetails.formRevision.value),
				is_main_form: true,
				contract: state.users.contract.contract,
				attributes: componentToSave(state.formsHandler.FormAttributes.attributes),
				components,
			}
			return concat(
				of(handleIsSavingNewForm(true)),
				fromPromise(formsHandlerService.createNewForm(component)).pipe(
					switchMap((response) => {
						const templateValuesAttributes = response.attributes
							.map((attr: any, index: number) => ({
								...state.formsHandler.FormAttributes.attributes[index],
								id: attr.id,
							}))
							.filter(
								(attr: any) =>
									attr.attribute_type.value?.value === AttributeType.ReadonlyImage ||
									attr.attribute_type.value?.value === AttributeType.MarkupImage,
							)
							.filter((attr: any) => attr.template_value.value instanceof File)

						return fromPromise(
							Promise.all(
								templateValuesAttributes.map((attr: any) => {
									return assetServiceService.uploadAttachment({
										attribute_id: attr.id,
										contract_id: state.users.contract.contract,
										file: attr.template_value.value,
									})
								}),
							),
						)
					}),
					switchMap(() => {
						browserHistory.push('/forms/templates')
						return concat(of(clearForm()), of(handleIsSavingNewForm(false)))
					}),
					catchError((err) => {
						return concat(
							of(handleIsSavingNewForm(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: saveNewForm(),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const updateExistingComponentEpic: AppEpicDeprecated<ReturnType<typeof updateExistingComponent>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(updateExistingComponent.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const componentToEdit = state.formsHandler.FormComponents.attributes.filter(
				(at) => at.id === action.payload.componentId,
			)
			const { originalId } = componentToEdit[0]
			const deletions = componentToEdit[0].oldAttributes
				.filter((item) => componentToEdit[0].attributes.filter((el) => el.id === item.id).length === 0)
				.map((item) => item.id)

			const newAttributes = componentToEdit[0].attributes.filter(
				(item) => componentToEdit[0].oldAttributes.filter((el) => el.id === item.id).length === 0,
			)

			const updatedAttributes = componentToEdit[0].attributes.filter(
				(item) =>
					componentToEdit[0].oldAttributes.filter((el) => (el.id === item.id ? !lodash.isEqual(item, el) : false))
						.length > 0,
			)

			const attributes = [
				...componentToSave(newAttributes),
				...getUpdatedField(updatedAttributes, componentToEdit[0].oldAttributes),
			]
			const formToUpdate = {
				contract: state.users.contract.contract,
				id: originalId,
				attributes,
				deletions,
			}

			return concat(
				of(handleIsUpdatingExistingComponent(true)),
				fromPromise(formsHandlerService.updateComponent(formToUpdate)).pipe(
					switchMap((saveResponse) => {
						return fromPromise(
							formsService.fetch(action.payload.formId, { contract: state.users.contract.contract }),
						).pipe(
							switchMap((response) => {
								const childForm = response[action.payload.formId].forms.find(
									(form) => form.id === originalId,
								) as ChildForm

								const allAttributes = [...newAttributes, ...updatedAttributes]

								const templateValuesAttributes = allAttributes
									.map((attr) => ({
										...attr,
										id: childForm.attributes.find((att) => att.sequence === attr.sequence.value)?.id,
									}))
									.filter(
										(attr) =>
											attr.attribute_type.value?.value === AttributeType.ReadonlyImage ||
											attr.attribute_type.value?.value === AttributeType.MarkupImage,
									)
									.filter((attr) => attr.template_value.value instanceof File)

								return fromPromise(
									Promise.all(
										templateValuesAttributes.map((attr) => {
											return assetServiceService.uploadAttachment({
												attribute_id: attr.id as any,
												contract_id: state.users.contract.contract,
												file: attr.template_value.value,
											})
										}),
									),
								)
							}),
							switchMap(() => {
								const attributesUpdate = componentToEdit[0].attributes
									.filter((att) => deletions.filter((del) => del === att.id).length === 0)
									.filter((att) => newAttributes.filter((newEl) => newEl.id === att.id).length === 0)
								const attributesElements = state.formsHandler.FormComponents.attributes.map((att) =>
									originalId === att.originalId
										? {
											...att,
											attributes: [
												...attributesUpdate,
												...AssignFormObjects(
													lodash.sortBy(
														saveResponse.attributes.map((attr: any) => ({
															...attr,
															template_value: newAttributes.find((newAtt) => newAtt.sequence.value === attr.sequence)
																?.template_value?.value,
														})),
														['sequence'],
													),
												),
											],
											oldAttributes: [],
											isEditing: false,
										}
										: att,
								)
								return concat(
									of(
										setComponents({
											...state.formsHandler.FormComponents,
											attributes: attributesElements,
											oldAttributes: attributesElements,
										}),
									),
								)
							}),
						)
					}),
					catchError((error) => {
						console.error(error)
						toast.error('Cannot update component.')
						return of(handleIsUpdatingExistingComponent(false))
					}),
				),
			)
		}),
	)

const isComponentNameToUpdate = (el: string) => {
	return (
		el === 'sequence' ||
		el === 'dependsOn' ||
		el === 'contexts' ||
		el === 'displayName' ||
		el === 'display_style' ||
		el === 'mandatory' ||
		el === 'componentName' || 
		el === 'display_group' ||
		el === 'depends_on_conditional'
	)
}
const getValueOfKey = (item: any, key: string) => {
	if (key === 'dependsOn') {
		return item[key].value === undefined ? '' : item[key].value
	}
	if (key === 'mandatory') {
		return item[key].value === undefined ? false : item[key].value
	}
	return item[key]
}
const changeKey = (el: string) => {
	if (el === 'dependsOn') {
		return 'depends_on'
	}
	if (el === 'displayName') {
		return 'display_name'
	}
	if (el === 'componentName') {
		return 'name'
	}
	return el
}

export const updateMainFormEpic: AppEpicDeprecated<ReturnType<typeof updateMainForm>> = (action$, state$) =>
	action$.pipe(
		filter(updateMainForm.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const attributesDeletions = state.formsHandler.FormAttributes.oldAttributes
				.filter((item) => state.formsHandler.FormAttributes.attributes.filter((el) => el.id === item.id).length === 0)
				.map((item) => item.id)

			const componentsDeletions = state.formsHandler.FormComponents.oldAttributes
				.filter((item) => state.formsHandler.FormComponents.attributes.filter((el) => el.id === item.id).length === 0)
				.map((item) => item.id)

			const newAttributes = state.formsHandler.FormAttributes.attributes.filter(
				(item) => state.formsHandler.FormAttributes.oldAttributes.filter((el) => el.id === item.id).length === 0,
			)

			const updatedComponents: any = state.formsHandler.FormComponents.attributes
				.filter((item) => {
					const oldAttr = state.formsHandler.FormComponents.oldAttributes.filter((el) => el.id === item.id)
					if (oldAttr.length > 0) {
						return (
							oldAttr[0].sequence !== item.sequence ||
							oldAttr[0].dependsOn !== item.dependsOn ||
							oldAttr[0].contexts !== item.contexts ||
							oldAttr[0].displayName !== item.displayName ||
							oldAttr[0].display_style !== item.display_style ||
							oldAttr[0].display_group !== item.display_group ||
							oldAttr[0].mandatory !== item.mandatory ||
							oldAttr[0].componentName !== item.componentName ||
							oldAttr[0].depends_on_conditional !== item.depends_on_conditional
						)
					}
					return true
				})
				.map((item) => {
					const oldAttr = state.formsHandler.FormComponents.oldAttributes.filter((el) => el.id === item.id)
					const newElement: any = {
						id: item.originalId,
					}
					if (item.linked_attribute_id) {
						newElement.linked_attribute_id = item.linked_attribute_id
					}
					if (oldAttr.length > 0) {
						for (const itemKey in item) {
							// @ts-ignore
							if (isComponentNameToUpdate(itemKey) && !lodash.isEqual(oldAttr[0][itemKey], item[itemKey])) {
								// @ts-ignore
								newElement[changeKey(itemKey)] = getValueOfKey(item, itemKey)
							}
						}
					} else {
						// contexts
						for (const itemKey in item) {
							// @ts-ignore
							if (isComponentNameToUpdate(itemKey)) {
								// @ts-ignore
								newElement[changeKey(itemKey)] = getValueOfKey(item, itemKey)
							}
						}
					}
					return newElement
				})

			const updatedAttributes = state.formsHandler.FormAttributes.attributes.filter(
				(item) =>
					state.formsHandler.FormAttributes.oldAttributes.filter((el) =>
						el.id === item.id ? !lodash.isEqual(item, el) : false,
					).length > 0,
			)

			const formToUpdate = {
				contract: state.users.contract.contract,
				name: trimSpaces(state.formsHandler.FormDetails.formName.value),
				attributes: [
					...componentToSave(newAttributes),
					...getUpdatedField(updatedAttributes, state.formsHandler.FormAttributes.oldAttributes),
				],
				components: [...updatedComponents],
				deletions: [...attributesDeletions, ...componentsDeletions],
			}

			return concat(
				of(handleIsUpdatingMainForm(true)),
				fromPromise(formsHandlerService.updateForm(formToUpdate)).pipe(
					switchMap(() => {
						return fromPromise(formsService.fetch(action.payload.id, { contract: state.users.contract.contract }))
					}),
					switchMap((response) => {
						const mainForm = response[action.payload.id].forms.find((form) => form.is_main_form) as Form

						const allAttributes = [
							...state.formsHandler.FormComponents.attributes.map((attr) => attr.attributes).flat(),
							...state.formsHandler.FormAttributes.attributes,
						]

						const templateValuesAttributes = allAttributes
							.map((attr) => ({
								...attr,
								id: mainForm.attributes.find((att) => att.sequence === attr.sequence.value)?.id,
							}))
							.filter(
								(attr) =>
									attr.attribute_type.value?.value === AttributeType.ReadonlyImage ||
									attr.attribute_type.value?.value === AttributeType.MarkupImage,
							)
							.filter((attr) => attr.template_value.value instanceof File)

						return fromPromise(
							Promise.all(
								templateValuesAttributes.map((attr) => {
									return assetServiceService.uploadAttachment({
										attribute_id: attr.id as any,
										contract_id: state.users.contract.contract,
										file: attr.template_value.value,
									})
								}),
							),
						).pipe(
							switchMap(() => {
								return concat(
									of(handleIsUpdatingMainForm(false)),
									of(clearForm()),
									of(
										getForm({
											id: action.payload.id,
											counter: 100,
										}),
									),
								)
							}),
						)
					}),
					catchError(() => {
						toast.error('Error while updating form.')
						return concat(of(handleIsUpdatingMainForm(false)))
					}),
				),
			)
		}),
	)

export const downloadGuideEpic: AppEpicDeprecated<ReturnType<typeof downloadGuide>> = (action$, state$) =>
	action$.pipe(
		filter(downloadGuide.match),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			return concat(
				of(isDownloadingGuide(true)),
				fromPromise(
					formsHandlerService.downloadGuide(state.formsHandler.guideInfo.id, state.users.contract.contract),
				).pipe(
					switchMap((response) => {
						const blob = new Blob([response], { type: 'application/pdf' })
						const link = document.createElement('a')
						link.style.display = 'none'
						const objectURL = URL.createObjectURL(blob)
						link.href = objectURL
						link.href = URL.createObjectURL(blob)
						link.download = `${state.formsHandler.guideInfo.name}.pdf`
						link.click()
						return concat(of(isDownloadingGuide(false)))
					}),
					catchError((err) => {
						return concat(
							of(isDownloadingGuide(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: downloadGuide(),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const uploadGuideEpic: AppEpicDeprecated<ReturnType<typeof uploadGuide>> = (action$, state$) =>
	action$.pipe(
		filter(uploadGuide.match),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const blob = new Blob(action.payload, { type: 'application/pdf' })
			const formData = new FormData()
			formData.append('contract', state.users.contract.contract)
			formData.append('parent_class_id', state.formsHandler.guideInfo.main_form_id)
			formData.append('file', blob)
			formData.append('file_name', action.payload[0].name)
			return concat(
				of(isUploadingGuide(true)),
				fromPromise(formsHandlerService.uploadGuide(formData)).pipe(
					switchMap((response) => {
						return concat(
							of(assignGuideInfo({ ...state.formsHandler.guideInfo, id: response.id, name: response.name })),
							of(isUploadingGuide(false)),
							of(handleSelectedFile('')),
						)
					}),
					catchError((err) => {
						toast.error('Error while uploading file.')
						return concat(
							of(isUploadingGuide(false)),
							of(handleSelectedFile('')),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: uploadGuide(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const removeGuideEpic: AppEpicDeprecated<ReturnType<typeof removeGuide>> = (action$, state$) =>
	action$.pipe(
		filter(removeGuide.match),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			return concat(
				of(isRemovingGuide(true)),
				fromPromise(
					formsHandlerService.removeGuide(state.users.contract.contract, state.formsHandler.guideInfo.main_form_id),
				).pipe(
					switchMap(() => {
						return concat(
							of(assignGuideInfo({ ...state.formsHandler.guideInfo, id: '', name: '' })),
							of(isRemovingGuide(false)),
							of(handleSelectedFile('')),
						)
					}),
					catchError((err) => {
						toast.error('Error while removing file.')
						return concat(
							of(isRemovingGuide(false)),
							of(handleSelectedFile('')),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: removeGuide(),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const formsHandlerEpics = combineEpics(
	getFormEpic,
	getComponentAttributeTypesEpic,
	addExistingComponentEpic,
	saveNewComponentEpic,
	saveExistingComponentAsNewEpic,
	updateExistingComponentEpic,
	updateMainFormEpic,
	createNewFormEpic,
	downloadGuideEpic,
	uploadGuideEpic,
	removeGuideEpic,
	checkFormNumberEpic,
)
