import { AppMainForm } from 'src/facade/forms/appFormConverter'
import { Attribute, ChildForm } from 'src/facade/forms/formsService.types'

export function sortForm(mainForm: AppMainForm): AppMainForm {
	const sortAttrs = (attrs: Attribute[]) => attrs.slice().sort((a, b) => a.sequence - b.sequence)
	const sortForms = (forms: ChildForm[]) => forms.slice().sort((a, b) => a.sequence - b.sequence)

	return {
		...mainForm,
		attributes: sortAttrs(mainForm.attributes),
		children: sortForms(
			mainForm.children.map((childForm) => ({ ...childForm, attributes: sortAttrs(childForm.attributes) })),
		),
	}
}
