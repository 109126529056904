/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { createAction, createSelector, createSlice, nanoid } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { omit, propSatisfies, range } from 'ramda'
import undoable, { includeAction } from 'redux-undo'
import { DataGridStatus } from '../../components/Molecules/DataGrid/DataGrid'
import { AsyncStatus } from '../../models/AsyncStatus'
import { RootState } from '../../utils/reduxUtils'

export type DeliverableStatus = 'Empty' | 'Error' | 'Done' | 'In queue' | 'In progress'

export interface Deliverable {
	id: string
	title: string
	documentNumber: string
	documentType: string
	revision: string
	hs2Deliverable: string
	GDPR: string
	stage: string
	suitabilityStatus: string
	discipline: string
	tagCode: string
	author: string[]
	deliverableStatus: DeliverableStatus
	type?: DeliverableType

	isValid: boolean
	status: DataGridStatus
	isUpdating: boolean
	modifiedFields: (keyof Deliverable)[]
}

export type DeliverableType = 'NEW' | 'EXISTING'

export interface DeliverablesState {
	loading: AsyncStatus
	initialDeliverables: Record<string, Deliverable>
	deliverables: Deliverable[]
	options: Record<string, any>
	isSaving: boolean
}

const initialState: DeliverablesState = {
	loading: 'idle',
	initialDeliverables: {},
	deliverables: [],
	options: {},
	isSaving: false,
}

export const fetchDeliverables = createAction<string /* tagCode */>('deliverables/fetch')
export const refetchDeliverables = createAction<string /* tagCode */>('deliverables/refetch')
export const fetchDeliverablesSuccess = createAction<any>('deliverables/fetch/success')
export const fetchDeliverablesFail = createAction('deliverables/fetch/fail')

export const checkPendingDeliverables = createAction<string /* tagCode */>('deliverables/check')
export const checkPendingDeliverablesSucess = createAction<any>('deliverables/check/success')
export const checkPendingDeliverablesFail = createAction('deliverables/check/fail')

export const addDeliverable = createAction<number>('deliverables/add')
export const addExistingDeliverable = createAction<number>('deliverables/addExisting')
export const removeDeliverable = createAction<Deliverable>('deliverables/remove')
export const removeDeliverables = createAction<Deliverable[]>('deliverables/removeMany')
export const syncDeliverables = createAction<Deliverable[]>('deliverables/sync')

export const saveDeliverables = createAction<{ tidpTagCode: string; deliverables: Deliverable[] }>('deliverables/save')
export const saveDeliverablesPending = createAction<Deliverable[]>('deliverables/save/pending')
export const saveDeliverablesSuccess = createAction<Deliverable[]>('deliverables/save/success')
export const saveDeliverablesFail = createAction('deliverables/save/fail')

export const clearDeliverablesHistory = createAction('deliverables/history/clear')

const EMPTY_DELIVERABLE: Partial<Deliverable> = {
	deliverableStatus: 'Empty',
	documentType: '',
	documentNumber: '',
	revision: '',
	title: '',
	hs2Deliverable: '',
	GDPR: '',
	stage: '',
	suitabilityStatus: '',
	discipline: '',
}

export interface ActionTransaction {
	status: DataGridStatus
	entities: Deliverable[]
}

export const deliverablesSlice = createSlice({
	name: 'deliverables',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(fetchDeliverables.match, (state) => {
				state.loading = 'pending'
			})
			.addMatcher(refetchDeliverables.match, (state) => {
				state.loading = 'pending'
			})
			.addMatcher(fetchDeliverablesSuccess.match, (state, { payload }) => {
				state.loading = 'success'
				state.options = payload.options
				state.deliverables = payload.body
					.map((deliverable: any) => ({
						id: nanoid(),
						status: 'ORIGINAL',
						title: deliverable.name,
						documentNumber: deliverable.row1,
						documentType: deliverable.row2,
						revision: deliverable.row5,
						hs2Deliverable: deliverable.row3,
						GDPR: deliverable.row6,
						stage: deliverable.row7,
						suitabilityStatus: deliverable.row8,
						discipline: deliverable.row9,
						deliverableStatus: deliverable.row10,
						tagCode: deliverable.tagCode,
						author: deliverable.author,
					}))
					.sort((a: Deliverable, b: Deliverable) => a.tagCode.localeCompare(b.tagCode))
				state.initialDeliverables = state.deliverables.reduce((pV, cV) => ({ ...pV, [cV.id]: cV }), {})
			})
			.addMatcher(fetchDeliverablesFail.match, (state) => {
				state.loading = 'fail'
				state.options = initialState.options
				state.deliverables = initialState.deliverables
				state.initialDeliverables = initialState.initialDeliverables
			})
			.addMatcher(addDeliverable.match, (state, { payload: count }) => {
				state.deliverables = state.deliverables.concat(
					range(0, count).map(() => ({
						id: nanoid(),
						status: 'CREATED',
						type: 'NEW',
						...(EMPTY_DELIVERABLE as any),
					})),
				)
			})
			.addMatcher(removeDeliverable.match, (state, { payload: deliverable }) => {
				if (deliverable.status === 'CREATED') {
					state.deliverables = state.deliverables.filter(({ id }) => id !== deliverable.id)
				} else {
					const foundDeliverable = state.deliverables.find(({ id }) => id === deliverable.id)
					if (foundDeliverable) {
						foundDeliverable.status = 'REMOVED'
					}
				}
			})
			.addMatcher(removeDeliverables.match, (state, { payload: deliverables }) => {
				state.deliverables = state.deliverables.filter((deliverable) => {
					const shouldBeRemoved: boolean = deliverables.map((d) => d.id).includes(deliverable.id)
					if (deliverable.status === 'CREATED') {
						return !shouldBeRemoved
					}
					return true
				})

				state.deliverables.forEach((deliverable) => {
					const shouldBeRemoved: boolean = deliverables.map((d) => d.id).includes(deliverable.id)
					if (shouldBeRemoved) {
						deliverable.status = 'REMOVED'
					}
				})
			})
			.addMatcher(syncDeliverables.match, (state, { payload }) => {
				state.deliverables = payload
			})
			.addMatcher(addExistingDeliverable.match, (state, { payload: count }) => {
				state.deliverables = state.deliverables.concat(
					range(0, count).map(() => ({
						id: nanoid(),
						status: 'CREATED' as DataGridStatus,
						type: 'EXISTING',
						...(EMPTY_DELIVERABLE as any),
					})),
				)
			})
			.addMatcher(saveDeliverablesPending.match, (state, { payload }) => {
				state.isSaving = true
				payload.forEach((deliverable) => {
					const stateDeliverable = state.deliverables.find(({ id }) => id === deliverable.id)
					if (stateDeliverable) {
						stateDeliverable.isUpdating = true
					}
				})
			})
			.addMatcher(saveDeliverablesSuccess.match, (state, { payload }) => {
				state.isSaving = false
				payload.forEach((deliverable) => {
					const stateDeliverable = state.deliverables.find(({ id }) => id === deliverable.id) as Deliverable
					state.initialDeliverables[deliverable.id] = deliverable
					if (stateDeliverable.status === 'CREATED') {
						stateDeliverable.status = 'ORIGINAL'
					} else if (stateDeliverable.status === 'REMOVED') {
						state.deliverables = state.deliverables.filter(({ id }) => id !== deliverable.id)
					}
					stateDeliverable.isUpdating = false
				})
			})
			.addMatcher(saveDeliverablesFail.match, (state) => {
				state.isSaving = false
			})
			.addMatcher(checkPendingDeliverablesSucess.match, (state, { payload }) => {
				const pendingDeliverables = state.deliverables.filter(
					(deliverable) => deliverable.deliverableStatus === 'In progress',
				)
				pendingDeliverables.forEach((deliverable) => {
					const newDeliverable = payload.body.find(({ tagCode }: any) => tagCode === deliverable.tagCode)
					if (newDeliverable) {
						deliverable.deliverableStatus = newDeliverable.row10
						state.initialDeliverables[deliverable.id].deliverableStatus = newDeliverable.row10
					}
				})
			})
	},
})

export const deliverablesSelector = createSelector(
	(state: RootState) => state.deliverables,
	({ present: { deliverables, initialDeliverables } }) => {
		return getCRUDDeliverables(deliverables, initialDeliverables)
	},
)

export const getCRUDDeliverables = (deliverables: Deliverable[], initialDeliverables: Record<string, Deliverable>) => {
	return deliverables.map((deliverable) => {
		const status = ['CREATED', 'REMOVED'].includes(deliverable.status)
			? deliverable.status
			: isEqual(
					omit(['status', 'modifiedFields', 'isValid', 'isUpdating'], deliverable),
					omit(['status', 'modifiedFields', 'isValid', 'isUpdating'], initialDeliverables[deliverable.id]),
			  )
			? 'ORIGINAL'
			: 'MODIFIED'

		let isValid = true
		if (status === 'CREATED') {
			if (deliverable.type === 'EXISTING') {
				isValid = !!deliverable.documentNumber && !!deliverable.revision
			} else {
				isValid = (
					[
						'documentType',
						'title',
						'hs2Deliverable',
						'GDPR',
						'stage',
						'suitabilityStatus',
						'discipline',
					] as (keyof Deliverable)[]
				).every((key) => propSatisfies((x) => !!x, key, deliverable))
			}
		}
		if (status === 'MODIFIED') {
			isValid = !!deliverable.title
		}

		return {
			...deliverable,
			status,
			isValid,
			modifiedFields:
				status === 'MODIFIED'
					? [
							initialDeliverables[deliverable.id].title !== deliverable.title && 'title',
							initialDeliverables[deliverable.id].author !== deliverable.author && 'author',
					  ].filter(Boolean)
					: [],
		} as Deliverable
	})
}

export const deliverablesReducer = undoable(deliverablesSlice.reducer, {
	neverSkipReducer: true,
	ignoreInitialState: true,
	syncFilter: true,
	initTypes: [fetchDeliverables.type],
	clearHistoryType: [fetchDeliverablesSuccess.type, refetchDeliverables.type, clearDeliverablesHistory.type],
	filter: includeAction([
		fetchDeliverablesSuccess.type,
		syncDeliverables.type,
		addDeliverable.type,
		addExistingDeliverable.type,
		removeDeliverable.type,
	]),
})
