import { createSlice } from '@reduxjs/toolkit'
import { UserData } from 'src/facade/users/usersService.types'
import { fetchProfileThunk } from 'src/features/profile/fetchProfileThunk'
import { AsyncStatus } from 'src/models/AsyncStatus'
import { UserTokenDetails } from 'src/services/getUserTokenDetails'

export type ProfileState = {
	fetching: AsyncStatus
	profile: UserData | null
	user: UserTokenDetails | null
}

const initialState: ProfileState = {
	fetching: 'idle',
	profile: null,
	user: null,
}

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(fetchProfileThunk.pending.match, (state) => {
				state.fetching = 'pending'
			})
			.addMatcher(fetchProfileThunk.fulfilled.match, (state, { payload }) => {
				state.profile = payload.profile
				state.user = payload.user
				state.fetching = 'success'
			})
			.addMatcher(fetchProfileThunk.rejected.match, (state) => {
				state.profile = null
				state.user = null
				state.fetching = 'fail'
			})
	},
})
