/* eslint-disable react/display-name */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */

import { connect } from 'react-redux'
import { IReduxState } from '../../../models/redux/redux'
import styled from 'styled-components'
import SpinnerComponent from '../../Atoms/Loader'
import Headline from '../../Atoms/Headline'
import { MyColumn, MyTable } from '../../Molecules/MyTable'
import { CellProps, Renderer } from 'react-table'
import SelectOverview from '../../Molecules/SelectOverview'
import { colors } from '../../../theme/colors'
import Button from '../../Molecules/Button'
import { useCallback, useEffect, useState } from 'react'
import Popup from '../../Organisms/Popup'
import { Field, Form, Formik } from 'formik'
import LGButton from '../../Molecules/LGButton'
import * as Yup from 'yup'
import { usersManagementActions } from '../../../features/usersManagement'
import Input from '../../Atoms/Input'
import { dateFormat } from '../../../assets/dateFormats'
import moment from 'moment'
import lodash from 'lodash'
import Pagination from '../../Molecules/Pagination'
import React from 'react'
import { Layout } from 'src/components/Molecules/Layout'

interface IUserManagement {
	isLoadingUserDetails: boolean
	isLoadingUsersList: boolean
	isResettingPassword: { id: string; isResetting: boolean }
	filtering: boolean
	isEditing: { isEditing: boolean; id: string }
	isDeleting: { isDeleting: boolean; id: string }
	isAddingNewUser: boolean
	oneUserDetails: any
	availableRolesList: any
	totalPageCounter: number
	pageNumber: number
	filters: any
	popupData: any
	usersList: any
	getListOfUsers: typeof usersManagementActions.getListOfUsers
	getOneUser: typeof usersManagementActions.getOneUser
	resetPassword: typeof usersManagementActions.resetPassword
	clearUserDetails: typeof usersManagementActions.clearUserDetails
	handleFilterItems: typeof usersManagementActions.handleFilterItems
	changePage: typeof usersManagementActions.changePage
	editUser: typeof usersManagementActions.editUser
	addNewUser: typeof usersManagementActions.addNewUser
	handlePopupData: typeof usersManagementActions.handlePopupData
	deleteUser: typeof usersManagementActions.deleteUser
}

interface IUsersList {
	id: string
	email: string
	createdTime: string
	isSSO: boolean
	isActive: boolean
	groups: IGroup[]
}

interface IGroup {
	name: string
}

export const SpinnerOverview = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const PageTitleWrapper = styled.div`
	.header {
		margin-bottom: 30px;
	}
`

const InformMessage = styled.div`
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	color: ${colors.main100};
	.link {
		margin: 10px 0;
		font-size: 15px;
		a {
			margin-left: 5px;
		}
		a:link,
		a:visited,
		a:active {
			color: ${colors.greyB0};
		}
		a:hover {
			color: ${colors.black};
		}
	}
`

function getGroupNames(groups: IGroup[]): string[] {
	const groupNames = groups.map((g) => ' ' + g.name)
	return groupNames
}

const usersData = (usersList: IUsersList[]) => {
	const u = usersList.map((user) => ({
		email: user.email,
		createdTime: user.createdTime ? moment(user.createdTime).format(dateFormat.engHours) : '',
		isSSO: user.isSSO ? 'yes' : 'no',
		isActive: user.isActive ? 'yes' : 'no',
		id: user.id,
		groups: getGroupNames(user.groups),
	}))
	return u
}

export const TooltipEmail = styled.div`
	position: absolute;
	background: ${colors.white};
	right: 0;
	border: 1px solid ${colors.main100};
	border-radius: 10px;
	padding: 5px;
	top: -10px;
`

export const StyledEditButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	button {
		&:nth-child(2n) {
			margin-left: 5px;
		}
		span {
			color: ${colors.white};
			font-weight: 300;
		}
	}
`

const PopupContainer = styled.div`
	padding: 15px;
	max-width: 400px;
	.registerInputHeader {
		margin: 7px 0;
		color: ${colors.black50};
		font-size: 15px;
	}
	form {
		color: ${colors.black50};
	}
	.errorMessage {
		padding: 15px 0;
		font-size: 12px;
		color: ${colors.danger100};
	}
	.registerInputContainer {
		display: flex;
		border-radius: 5px;
		padding: 0 10px;
		justify-content: center;
		width: 100%;
		min-width: 300px;
		background: ${colors.lightGreyE0};
		border: none;
		height: 30px;
		div {
			display: flex;
			flex-direction: column;
		}
	}
`

const AddUserWrapper = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: flex-end;
	margin-right: 10px;
	span {
		color: ${colors.white};
		font-weight: 300;
	}
`
const TimeAlignment = styled.div`
	input {
		text-align: center;
	}
`
const SelectWrapper = styled.div`
	display: flex;
	height: 100%;
	margin: 0 15px;
	align-items: center;
	justify-content: flex-end;
`

const promptFormErrors = (formErrors: any) => {
	const errorInfo = (
		<div>
			<div>{`${formErrors.email ? `Email ${formErrors.email}` : ''}`}</div>
			<div>{`${formErrors.defaultModule ? `Default Module ${formErrors.defaultModule}` : ''}`}</div>
		</div>
	)
	return errorInfo
}

const RegisterSchema = Yup.object().shape({
	email: Yup.string().email('must be valid email').min(2, 'too short!').max(79, 'too long!').required('required'),
	defaultModule: Yup.object().nullable().required(),
})

const UserManagement = (props: IUserManagement) => {
	const [inputFocus, setInputFocus] = useState('')
	const openEditPopup = (id: string) => {
		props.getOneUser(id)
		props.handlePopupData({ isNew: false, isOpen: true })
	}

	const getUsers = () => {
		if (props.usersList.length === 0 && !props.filtering) {
			props.getListOfUsers()
		}
	}

	const fetchUsersList = useCallback(getUsers, [])

	useEffect(() => {
		fetchUsersList()
	}, [fetchUsersList])

	const HeaderInputFilter = (item: {
		placeholder: string
		fieldName: string
		isDisabled: boolean
		isSelect?: boolean
	}) => {
		return item.isSelect ? (
			<SelectWrapper>
				<SelectOverview
					fullWidth
					height="48px"
					isDisabled={item.isDisabled}
					placeholder={item.placeholder}
					value={props.filters[item.fieldName]}
					backgroundColor={colors.white}
					onChange={(value: any) => {
						props.handleFilterItems({ value: value.value !== null ? value : null, objectName: item.fieldName })
					}}
					options={[
						{ value: null, label: '' },
						{ value: true, label: 'Yes' },
						{ value: false, label: 'No' },
					]}
				/>
			</SelectWrapper>
		) : (
			<>
				<Input
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus={item.fieldName === inputFocus}
					placeholder={item.placeholder}
					// @ts-ignore
					value={props.filters[item.fieldName]}
					backgroundColor={colors.white}
					onChange={(value: any) => {
						props.handleFilterItems({ value: value.value !== null ? value : null, objectName: item.fieldName })
						setInputFocus(item.fieldName)
					}}
				/>

				{props.filters && props.filters[item.fieldName].length > 0 && props.filters[item.fieldName].length < 3 && (
					<TooltipEmail>At least 3 characters</TooltipEmail>
				)}
			</>
		)
	}

	const usersColumns: any = () =>
		[
			{
				Header: () => HeaderInputFilter({ placeholder: 'Email', isDisabled: false, fieldName: 'email' }),
				accessor: 'email',
				Cell: cellRenderer('email'),
			},
			{
				Header: () => HeaderInputFilter({ placeholder: 'Group Name', isDisabled: false, fieldName: 'groups' }),
				accessor: 'groups',
				Cell: cellRenderer('groups'),
			},
			{
				Header: () =>
					HeaderInputFilter({ placeholder: 'External Sign On', isSelect: true, isDisabled: true, fieldName: 'isSSO' }),
				accessor: 'isSSO',
				options: {
					noHeaderTdContainer: true,
				},
				Cell: cellRenderer('isSSO'),
			},
			{
				Header: 'Created Time',
				accessor: 'createdTime',
				options: {
					noHeaderTdContainer: false,
				},
				Cell: ({ column, value, row }: any) => (
					<TimeAlignment>
						<Input value={value} />
					</TimeAlignment>
				),
			},
			{
				Header: () => (
					<AddUserWrapper>
						<Button
							isLoading={props.isAddingNewUser}
							isDisabled={props.isAddingNewUser}
							onClick={() => {
								props.handlePopupData({ isNew: true, isOpen: true })
							}}
							color={colors.main100}
							text="Add new"
						/>
					</AddUserWrapper>
				),
				options: {
					noHeaderTdContainer: true,
				},
				maxWidth: 50,

				accessor: 'button',
				Cell: cellRenderer('button'),
			},
		].map((column) => ({ ...column, noTdContainer: true, disableSortBy: true }) as MyColumn<IUsersList>)
	const cellRenderer =
		(headerId: string): Renderer<CellProps<IUsersList>> =>
		({ column, value, row }) => {
			return headerId === 'button' ? (
				<StyledEditButton>
					<Button
						color={colors.danger100}
						isLoading={!!(props.isDeleting.id === row.original.id && props.isDeleting.id)}
						isDisabled={props.isDeleting.isDeleting}
						text="Delete"
						onClick={() => props.deleteUser(row.original.id)}
					/>
					<Button
						color={colors.main100}
						text="Edit"
						onClick={() => openEditPopup(row.original.id)}
						isLoading={props.isEditing.id === row.original.id && props.isEditing.isEditing}
						isDisabled={props.isEditing.id === row.original.id && props.isEditing.isEditing}
					/>
					{/* <Button */}
					{/*	color={colors.main100} */}
					{/*	isLoading={!!(props.isResettingPassword.id === row.original.id && props.isResettingPassword.id)} */}
					{/*	isDisabled={!!(props.isResettingPassword.id === row.original.id && props.isResettingPassword.id)} */}
					{/*	text="Reset Password" */}
					{/*	onClick={() => props.resetPassword({ id: row.original.id, email: row.original.email })} */}
					{/* /> */}
				</StyledEditButton>
			) : (
				<SelectOverview isDisabled value={[{ value, label: value }]} backgroundColor={colors.main5} fullWidth />
			)
		}

	const userFields = (
		handleChange: any,
		values: any,
		setFieldValue: any,
		isNew: boolean,
		setFieldTouched: any,
		isDisabled?: boolean,
	) => (
		<>
			{[{ id: 'email', placeholder: 'Email', isDisabled: !isNew }].map((user) => (
				<React.Fragment key={user.id}>
					<div className="registerInputHeader">{user.placeholder}</div>
					<Field
						autoComplete="off"
						className="registerInputContainer"
						name={user.id}
						id={user.id}
						placeholder={user.placeholder}
						type="text"
						disabled={user.isDisabled || isDisabled}
						// @ts-ignore
						value={values[user.id]}
						onChange={handleChange}
					/>
				</React.Fragment>
			))}
			<>
				<div className="registerInputHeader">External Sign On</div>
				{values.isSSO}
				<SelectOverview
					backgroundColor={colors.lightGreyE0}
					isNotCentered
					valuePadding="0 10px"
					isDisabled={isDisabled || (values.isInFewDomains && !!values.isInFewDomains)}
					options={[
						{ value: true, label: 'Yes' },
						{ value: false, label: 'No' },
					]}
					value={{ value: values.isSSO, label: values.isSSO ? 'Yes' : 'No' }}
					onChange={(value: any) => {
						setFieldValue('isSSO', value.value)
						setFieldTouched('isSSO', value.value)
					}}
				/>
			</>

			{/* <div className="registerInputHeader">Is Active</div> */}
			{/* <SelectOverview */}
			{/*	backgroundColor={colors.lightGreyE0} */}
			{/*	isNotCentered={true} */}
			{/*	valuePadding={'0 10px'} */}
			{/*	options={[ */}
			{/*		{ value: true, label: 'Yes' }, */}
			{/*		{ value: false, label: 'No' }, */}
			{/*	]} */}
			{/*	value={{ value: values.isActive, label: values.isActive ? 'Yes' : 'No' }} */}
			{/*	onChange={(value: any) => { */}
			{/*		setFieldValue('isActive', value.isActive); */}
			{/*		setFieldTouched('isActive', value.isActive); */}
			{/*	}} */}
			{/* /> */}
			<div className="registerInputHeader">Permissions</div>
			<SelectOverview
				isMulti
				maxWidth="280px"
				backgroundColor={colors.lightGreyE0}
				isNotCentered
				isDisabled={isDisabled}
				valuePadding="0 10px"
				options={props.availableRolesList}
				value={values.permissions}
				onChange={(value: any) => {
					if (!value || value.length === 0) {
						setFieldValue('defaultModule', [])
					}
					setFieldValue('permissions', value)
					setFieldTouched('permissions', value)
				}}
			/>

			<div className="registerInputHeader">Default Module</div>
			<SelectOverview
				backgroundColor={colors.lightGreyE0}
				isNotCentered
				placeholder="Default Module"
				valuePadding="0 10px"
				isDisabled={isDisabled}
				options={
					values.defaultModule && values.defaultModule.length > 0
						? lodash
								.uniq([
									'',
									...values?.permissions.map((item: { value: string }) =>
										item.value.substring(0, item.value.indexOf(':')),
									),
								])
								.filter((item: any) => values.defaultModule[0].label !== item)
								.map((item: any) => ({ value: item, label: item }))
						: values.permissions
						? lodash
								.uniq([
									...values?.permissions.map((item: { value: string }) =>
										item.value.substring(0, item.value.indexOf(':')),
									),
									'',
								])
								.map((item: any) => ({ value: item, label: item }))
						: []
				}
				value={values.defaultModule}
				onChange={(option: any) => {
					setFieldTouched('defaultModule', option)
					setFieldValue('defaultModule', option)
				}}
			/>
		</>
	)

	return (
		<Layout>
			{' '}
			{props.isLoadingUsersList ? (
				<SpinnerOverview>
					<SpinnerComponent size={100} />
				</SpinnerOverview>
			) : (
				<>
					{props.popupData.isOpen && (
						<Popup
							onClick={() => {
								props.clearUserDetails()
								props.handlePopupData({ isNew: false, isOpen: false })
							}}
							isCloseButton
							children={
								<PopupContainer>
									{props.popupData.isNew ? (
										<Formik
											initialValues={{
												email: '',
												isSSO: false,
												isActive: true,
												defaultModule: null,
												permissions: [],
											}}
											validationSchema={RegisterSchema}
											onSubmit={(values) => {
												props.clearUserDetails()
												props.handlePopupData({ isNew: false, isOpen: false })
												props.addNewUser(values)
											}}
										>
											{({ values, setFieldValue, setFieldTouched, errors, touched, handleChange }) => (
												<Form>
													{userFields(handleChange, values, setFieldValue, true, setFieldTouched)}
													<div className="errorMessage">{errors ? promptFormErrors(errors) : ''}</div>
													<LGButton
														isLoading={false}
														disabled={Object.keys(touched).length === 0 || Object.keys(errors).length > 0}
														minWidth="100%"
														type="submit"
														text="Save new user"
														padding="10px 25px"
														isWhiteText
													/>
												</Form>
											)}
										</Formik>
									) : (
										<React.Fragment>
											{props.isLoadingUserDetails ? (
												<SpinnerOverview>
													<SpinnerComponent size={40} />
												</SpinnerOverview>
											) : (
												<Formik
													initialValues={{
														email: props.oneUserDetails?.email ? props.oneUserDetails?.email : '',
														isSSO: props.oneUserDetails?.isSSO,
														isActive: props.oneUserDetails?.isActive ? props.oneUserDetails?.isActive : '',
														defaultModule: props.oneUserDetails?.defaultModule
															? props.oneUserDetails?.defaultModule
															: '',
														isInFewDomains: props.oneUserDetails?.isInFewDomains,
														permissions: props.oneUserDetails?.permissions ? props.oneUserDetails?.permissions : '',
													}}
													enableReinitialize
													validationSchema={RegisterSchema}
													onSubmit={(form) => {
														props.clearUserDetails()
														props.handlePopupData({ isNew: false, isOpen: false })
														props.editUser({ form, id: props.oneUserDetails.id })
													}}
												>
													{({
														values,
														setFieldTouched,
														setFieldValue,
														initialValues,
														errors,
														touched,
														handleChange,
													}) => (
														<Form>
															{props.oneUserDetails.isFoundInAd ? (
																<>
																	{userFields(handleChange, values, setFieldValue, false, setFieldTouched)}
																	<div className="errorMessage">{errors ? promptFormErrors(errors) : ''}</div>
																	<LGButton
																		isLoading={false}
																		disabled={
																			initialValues === values ||
																			Object.keys(touched).length === 0 ||
																			Object.keys(errors).length > 0
																		}
																		minWidth="100%"
																		type="submit"
																		text="Update User"
																		padding="10px 25px"
																		isWhiteText
																	/>
																</>
															) : (
																<>
																	<InformMessage>
																		Profile configuration for this user is not complete in Enable My Team
																		<div className="link">
																			Please contact
																			<a href="mailto:help@enablemyteam.com e-mail">help@enablemyteam.com</a>
																		</div>
																	</InformMessage>
																	{userFields(handleChange, values, setFieldValue, false, setFieldTouched, true)}
																</>
															)}
														</Form>
													)}
												</Formik>
											)}
										</React.Fragment>
									)}
								</PopupContainer>
							}
						/>
					)}
					<PageTitleWrapper>
						<div className="header">
							<Headline title="Users Management" small />
						</div>
						<MyTable isLoading={props.filtering} fullWidth columns={usersColumns()} data={usersData(props.usersList)} />
					</PageTitleWrapper>
					{props.usersList.length > 0 && Math.floor(props.totalPageCounter / 15) > 1 && (
						<Pagination
							page={props.pageNumber}
							onPageChange={(page) => props.changePage({ pageSize: 15, pageNumber: page })}
							pageCount={Math.ceil(props.totalPageCounter / 15)}
							initialPage={0}
						/>
					)}
				</>
			)}
		</Layout>
	)
}

export default connect(
	(state: IReduxState) => ({
		isLoadingUsersList: state.usersManagement.isLoadingUsersList,
		isLoadingUserDetails: state.usersManagement.isLoadingUserDetails,
		oneUserDetails: state.usersManagement.oneUserDetails,
		availableRolesList: state.usersManagement.availableRolesList,
		usersList: state.usersManagement.usersList,
		isResettingPassword: state.usersManagement.isResettingPassword,
		filters: state.usersManagement.filters,
		filtering: state.usersManagement.filtering,
		isEditing: state.usersManagement.isEditing,
		isAddingNewUser: state.usersManagement.isAddingNewUser,
		popupData: state.usersManagement.popupData,
		pageNumber: state.usersManagement.pagination.pageNumber,
		totalPageCounter: state.usersManagement.totalPageCounter,
		isDeleting: state.usersManagement.isDeleting,
	}),
	{
		getListOfUsers: usersManagementActions.getListOfUsers,
		getOneUser: usersManagementActions.getOneUser,
		resetPassword: usersManagementActions.resetPassword,
		handleFilterItems: usersManagementActions.handleFilterItems,
		changePage: usersManagementActions.changePage,
		clearUserDetails: usersManagementActions.clearUserDetails,
		editUser: usersManagementActions.editUser,
		addNewUser: usersManagementActions.addNewUser,
		handlePopupData: usersManagementActions.handlePopupData,
		deleteUser: usersManagementActions.deleteUser,
	},
)(UserManagement)
