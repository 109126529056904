import { groupBy, head } from 'ramda'
import { Action } from 'src/facade/action/actionService.types'
import { FormTemplate } from 'src/facade/forms/formsService'
import { Task, TaskName } from 'src/facade/tasks/tasksService.types'
import { AppTask } from 'src/features/tasks/tasksApi'
import { getDocumentNumber } from 'src/forms/_shared/getDocumentNumber'

export type AppTaskName = TaskName | 'CONTRIBUTOR_AND_REVIEWER'

export type UiAppTask = AppTask & {
	task_name: AppTaskName
}

export interface TaskRow {
	id: number
	path: [mainAssetTagCode: string] | [mainAssetTagCode: string, taskId: string] | string[]
	form: string
	documentNumber: string
	sentDate: string
	sender: string
	recipients: string[]
	role: string
	status: string
	task: UiAppTask
	actions: UiAction[]
}

export type UiAction = { taskId: number; action: Action }

export function createFormRows(
	tasks: AppTask[] | undefined,
	templates: FormTemplate[] | undefined,
	profileEmail: string,
): TaskRow[] {
	if (!templates || !tasks) {
		return []
	}

	const nonContributorTasks = (tasks || []).filter(
		(task) => task.task_name !== TaskName.FORM_CONTRIBUTOR || task.created_by === profileEmail,
	)

	const tasksGroupedByTagCode = groupBy((task) => task.main_asset.tag_code, nonContributorTasks)

	const rowsWithoutParent = filterRowsWithoutParent(tasks)

	const getTemplate = (task: Task) => templates?.find((template) => template.id === task.metadata.form_template_id)

	return Object.entries(tasksGroupedByTagCode)
		.map(([, taskElements]) => {
			const owner = taskElements.find((task) => task.task_name === TaskName.FORM_OWNER)

			const tasksGroupedByRole = groupBy(
				(task) => (task.task_name === TaskName.FORM_OWNER ? '' : head(task.metadata.attribute_name!.split('_')) || ''),
				taskElements,
			)

			const transformedTasks: (UiAppTask & { actions: UiAction[] })[] = Object.values(tasksGroupedByRole).map(
				([firstTask, secondTask]) => {
					if (!secondTask) {
						return {
							...firstTask,
							actions: firstTask.possible_actions.map((action) => ({ action, taskId: firstTask.task_id })),
						}
					} else {
						const ownerTask = firstTask.task_name === TaskName.FORM_OWNER ? firstTask : secondTask
						return {
							...ownerTask,
							created_at:
								+firstTask.created_at - +secondTask.created_at > 0 ? firstTask.created_at : secondTask.created_at,
							possible_actions: [...firstTask.possible_actions, ...secondTask.possible_actions],
							task_name: 'CONTRIBUTOR_AND_REVIEWER' as any,
							actions: [
								...firstTask.possible_actions.map((action) => ({ action, taskId: firstTask.task_id })),
								...secondTask.possible_actions.map((action) => ({ action, taskId: secondTask.task_id })),
							],
						}
					}
				},
			)

			const createTaskRow = (task: UiAppTask & { actions: UiAction[] }) => {
				const template = getTemplate(task)
				const rootPath = owner ? `${task.main_asset.tag_code}_OWNER` : `${task.main_asset.tag_code}_CONTRIBUTOR`
				const path = task.task_name === TaskName.FORM_OWNER ? [rootPath] : [rootPath, task.task_id.toString()]
				const role = task.task_name === TaskName.FORM_OWNER ? '' : head(task.metadata.attribute_name!.split('_')) || ''
				const isRootTask: boolean = task.task_name === TaskName.FORM_OWNER

				return {
					id: task.task_id,
					form: isRootTask && template ? `${template.class_name ?? ''} (${template.class_code})` : '',
					documentNumber: isRootTask ? getDocumentNumber(task) ?? '' : '',
					sentDate: task.created_at,
					sender: isRootTask ? task.created_by : '',
					recipients: (task.metadata!.assigned_users || '').split(',') || [],
					role,
					status: task.status,
					path,
					task,
					actions: task.actions,
				}
			}

			return transformedTasks.map((task) => createTaskRow(task)) as TaskRow[]
		})
		.flat()
		.filter((t) => !!t.task)
		.filter(rowsWithoutParent)

}

function filterRowsWithoutParent(tasks: AppTask[]) {
	return (row: TaskRow) =>
		!(
			row.path.length === 2 &&
			!tasks
				?.filter((task) => task.task_name === TaskName.FORM_OWNER)
				.map((task) => task.main_asset.tag_code)
				.includes(row.task.main_asset.tag_code)
		)
}
