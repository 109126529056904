import { last } from 'ramda'
import { toast } from 'react-toastify'
import { combineEpics } from 'redux-observable'
import { concat, EMPTY, of, zip } from 'rxjs'
import { catchError, debounceTime, delay, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import { isOfType } from 'typesafe-actions'
import { deliverablesService, DeliverableAction } from '../../services/deliverablesService'
import * as disciplineOverviewService from '../../services/disciplineOverview'
import { AppEpic, AppEpicDeprecated, RootState } from '../../utils/reduxUtils'
import { getTaskActions } from '../taskActions/actions'
import {
	checkPendingDeliverables,
	checkPendingDeliverablesFail,
	checkPendingDeliverablesSucess,
	clearDeliverablesHistory,
	Deliverable,
	deliverablesSelector,
	fetchDeliverables,
	fetchDeliverablesFail,
	fetchDeliverablesSuccess,
	getCRUDDeliverables,
	refetchDeliverables,
	saveDeliverables,
	saveDeliverablesFail,
	saveDeliverablesPending,
	saveDeliverablesSuccess,
} from './deliverablesSlice'
import { fetchTaskActions } from '../taskActions/taskActionsSlice'
import { fromPromise } from 'rxjs/internal-compatibility'

export const fetchDeliverablesEpic: AppEpicDeprecated<ReturnType<typeof fetchDeliverables>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType([fetchDeliverables.type, refetchDeliverables.type])),
		withLatestFrom(state$),
		switchMap(([{ payload: tag }, state]) => {
			const { contract } = state.users.contract
			return fromPromise(disciplineOverviewService.getDeliverables({ tag, contract })).pipe(
				switchMap((response) => {
					const documentsData = dataForDeliverables(response, state)
					return concat(of(fetchDeliverablesSuccess(documentsData)), of(checkPendingDeliverables(tag)))
				}),
				catchError(() => {
					toast.error('Could not get deliverables.')
					return of(fetchDeliverablesFail())
				}),
			)
		}),
	)

export const checkPendingDeliverablesEpic: AppEpicDeprecated<ReturnType<typeof checkPendingDeliverables>> = (
	action$,
	state$,
) =>
	action$.pipe(
		filter(checkPendingDeliverables.match),
		delay(5000),
		withLatestFrom(state$),
		switchMap(([{ payload: tag }, state]) => {
			const isAnyPending = (state as RootState).deliverables.present.deliverables.some(
				(item) => item.deliverableStatus === 'In progress',
			)

			if (!isAnyPending) {
				return EMPTY
			}

			const { contract } = state.users.contract
			return fromPromise(disciplineOverviewService.getDeliverables({ tag, contract })).pipe(
				switchMap((response) => {
					const documentsData = dataForDeliverables(response, state)
					return concat(of(checkPendingDeliverablesSucess(documentsData)), of(checkPendingDeliverables(tag)))
				}),
				catchError(() => {
					toast.error('Error while cheking deliverables status.')
					return of(checkPendingDeliverablesFail())
				}),
			)
		}),
	)

export const saveDeliverablesEpic: AppEpic<ReturnType<typeof saveDeliverables>> = (action$, state$) =>
	action$.pipe(
		filter(saveDeliverables.match),
		debounceTime(1000),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { tidpTagCode, deliverables: deliverablesToUpdate },
				},
				state,
			]) => {
				let currentDeliverables: Deliverable[] = deliverablesSelector(state)
				if (state.deliverables.future.length > 0) {
					const lastTransationDeliverables = last(state.deliverables.future)
					if (lastTransationDeliverables) {
						currentDeliverables = getCRUDDeliverables(
							deliverablesToUpdate,
							lastTransationDeliverables.deliverables.reduce((pV, cV) => ({ ...pV, [cV.id]: cV }), {}),
						).filter(({ status }) => status === 'MODIFIED')
					}
				}

				const deliverables = currentDeliverables.filter(({ isValid, isUpdating }) => isValid && !isUpdating)

				const createdDeliverables = deliverables.filter((deliverable) => deliverable.status === 'CREATED')
				const modifiedDeliverables = deliverables.filter((deliverable) => deliverable.status === 'MODIFIED')
				const removedDeliverables = deliverables.filter((deliverable) => deliverable.status === 'REMOVED')
				const createdNewDeliverables = createdDeliverables.filter((deliverable) => deliverable.type === 'NEW')
				const createdExistingDeliverables = createdDeliverables.filter((deliverable) => deliverable.type === 'EXISTING')

				const allModifiedDeliverables = [...createdDeliverables, ...modifiedDeliverables, ...removedDeliverables]

				if (allModifiedDeliverables.length === 0) {
					return EMPTY
				}

				const createAction = state.tasksActions2.actions[DeliverableAction.CREATE]
				const createExistingAction = state.tasksActions2.actions[DeliverableAction.CREATE_EXISTING]
				const updateAction = state.tasksActions2.actions[DeliverableAction.UPDATE]
				const removeAction = state.tasksActions2.actions[DeliverableAction.REMOVE]

				const { contract } = (state.users as any).contract

				const asset =
					state.disciplineOverview.disciplineOwner
						.find((item: any) => item.name === 'TIDP Details')
						?.tabs.filter((item: any) => item.name === 'Asset Name')[0].value ?? ''

				const createDeliverableObservables =
					createdNewDeliverables.length > 0
						? [
								fromPromise(
									deliverablesService.create(
										createAction,
										{ tidpTagCode, contract, deliverables: createdNewDeliverables },
										asset,
									),
								),
						  ]
						: []

				const createExistingDeliverableObservables =
					createdExistingDeliverables.length > 0
						? [
								fromPromise(
									deliverablesService.createExisting(createExistingAction, {
										tidpTagCode,
										contract,
										deliverables: createdExistingDeliverables,
									}),
								),
						  ]
						: []

				const updateObservables =
					modifiedDeliverables.length > 0
						? [
								fromPromise(
									deliverablesService.update(updateAction, {
										tidpTagCode,
										contract,
										deliverables: modifiedDeliverables,
									}),
								),
						  ]
						: []

				const removeDeliverableObservables =
					removedDeliverables.length > 0
						? [
								fromPromise(
									deliverablesService.remove(removeAction, {
										tidpTagCode,
										contract,
										deliverables: removedDeliverables,
									}),
								),
						  ]
						: []

				const allActionsObservables = zip(
					...createDeliverableObservables,
					...createExistingDeliverableObservables,
					...updateObservables,
					...removeDeliverableObservables,
				)

				return concat(
					of(saveDeliverablesPending(allModifiedDeliverables)),
					allActionsObservables.pipe(
						mergeMap(() => {
							const wasRemovalOrAddition = allModifiedDeliverables.some(
								({ status }) => status === 'CREATED' || status === 'REMOVED',
							)
							return concat(
								of(fetchTaskActions(tidpTagCode)),
								of(getTaskActions({ tagCode: tidpTagCode })),
								wasRemovalOrAddition ? of(clearDeliverablesHistory()) : EMPTY,
								of(saveDeliverablesSuccess(allModifiedDeliverables)),
							)
						}),
						catchError((err) => {
							toast.error(err?.response?.data?.Error ?? 'Save has failed.')
							return of(saveDeliverablesFail())
						}),
					),
				)
			},
		),
	)

const dataForDeliverables = (response: any, state: any) => {
	let listOfDeliverables: {
		[key: string]: {
			predetermined_list: Array<{ value: string; label: string }>
		}
	} = {}
	state.attributes.documentAttributes.forEach(
		(item: { attribute_name: string; predetermined_list?: Array<string> }) => {
			if (item.predetermined_list) {
				listOfDeliverables = {
					...listOfDeliverables,
					[item.attribute_name]: {
						predetermined_list: item.predetermined_list.map((attribute: string) => ({
							value: attribute,
							label: attribute,
						})),
					},
				}
			}
		},
	)

	const HS2Deliverable = listOfDeliverables['HS2 Deliverable']?.predetermined_list
	const GDPR = listOfDeliverables.GDPR?.predetermined_list
	const Stage = listOfDeliverables.lifecycleStage?.predetermined_list
	const SuitabilityStatus = listOfDeliverables['Suitability Status']?.predetermined_list
	const Discipline = listOfDeliverables.Discipline?.predetermined_list

	const documentsData = {
		options: {
			documentType: getDocumnentCodes(state).documentTypeWithCodes,
			hs2Deliverable: HS2Deliverable,
			GDPR,
			stage: Stage,
			suitabilityStatus: SuitabilityStatus,
			discipline: Discipline,
		},
		header: [
			{
				id: 'name',
				name: 'Deliverable title',
				type: 'text',
			},
			{
				id: 'row1',
				name: 'Document Number',
				type: 'text',
			},
			{
				id: 'row2',
				name: 'Document Type',
				options: getDocumnentCodes(state).documentTypeWithCodes,
				type: 'select',
			},
			{
				id: 'row3',
				name: 'HS2 Deliverable',
				options: HS2Deliverable,
				type: 'select',
			},
			{
				id: 'row5',
				name: 'Revision',
				type: 'text',
			},
			{
				id: 'row6',
				name: 'GDPR',
				options: GDPR,
				type: 'select',
			},
			{
				id: 'row7',
				name: 'Stage',
				options: Stage,
				type: 'select',
			},
			{
				id: 'row8',
				name: 'Suitability Status',
				options: SuitabilityStatus,
				type: 'select',
			},
			{
				id: 'row9',
				name: 'Discipline',
				options: Discipline,
				type: 'select',
			},
		],
		body: response.result.map((document: any) => {
			const indexOfDocumentType = getDocumnentCodes(state).documentType.findIndex(
				(item: string) => item === document['Document Type'],
			)
			return {
				name: document['Document Name'],
				row1: document['Document Number'],
				row2:
					indexOfDocumentType > -1
						? `${getDocumnentCodes(state).documentType[indexOfDocumentType]} (${
								getDocumnentCodes(state).documentTypeCodes[indexOfDocumentType]
						  })`
						: document['Document Type'],
				row3: document['HS2 Deliverable'],
				row5: document.Revision,
				row6: document.GDPR,
				row7: document.Stage,
				row8: document['Suitability Status'],
				row9: document.Discipline,
				row10: document.status,
				tagCode: document.tag_code,
				author: document['Created By'],
			}
		}),
		newDeliverableFields: {},
	}

	return documentsData
}

const getDocumnentCodes = (state: any) => {
	const documentType = state.attributes.documentAttributes.filter(
		(attribute: { attribute_name: string }) => attribute.attribute_name === 'Document Type',
	)[0].predetermined_list
	const documentTypeCodes = state.attributes.documentAttributes.filter(
		(attribute: { attribute_name: string }) => attribute.attribute_name === 'Document Type Code',
	)[0].predetermined_list

	const documentTypeWithCodes = documentTypeCodes
		.map((item: string, index: number) => `${documentType[index]} (${item})`)
		.map((item: string, index: number) => ({ value: documentType[index], label: item }))
		.sort((a: any, b: any) => (a.label < b.label ? -1 : 1))
	return {
		documentTypeWithCodes,
		documentTypeCodes,
		documentType,
	}
}

export const deliverablesEpics = combineEpics(fetchDeliverablesEpic, saveDeliverablesEpic, checkPendingDeliverablesEpic)
