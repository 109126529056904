import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { IFlowForManager } from '../../models/florForManager/flowForManager'
import {
	CLEAR_FORM,
	SET_CERTIFICATION_DATE,
	SET_COMMENCEMENT_DATE,
	SET_FINAL_SUBMISSION_DATE,
	SET_IDR_SUBMISSION_DATE,
	SET_OWNER_SELECTED,
	SET_OWNERS,
	SET_PRIMARY_ASSET_SELECTED,
	SET_PRIMARY_ASSETS,
	SET_CONTRACT_DATA,
	SET_TECHNICAL_LIFECYCLE,
	SET_WORK_PACKAGE,
	SET_WORK_PACKAGE_SELECTED,
	MANAGE_TAB,
	SET_TECHNICAL_LIFECYCLE_OPTIONS,
} from './constants'

export type FlowForManagerActions = ActionType<typeof actions>

const initialFlowForManagerReducer: IFlowForManager = {
	primaryAssets: [],
	primaryAssetsSelected: {},
	workPackage: [],
	workPackageSelected: {},
	contractData: {},
	technicalLifeCycle: {},
	technicalLifeCycleElements: [],
	owners: [
		{
			value: 'lholbrook@enablemyteam.com',
			label: 'lholbrook@enablemyteam.com',
		},
		{
			value: 'none',
			label: 'none',
		},
	],
	selectedOwner: {},
	l3CertificationDate: null,
	finalSubmissionDate: null,
	idrSubmissionDate: null,
	stageCommencementDate: null,
	tabsForManagement: [
		{ id: 1, isVisible: true, isOpen: true, isChecked: false },
		{ id: 2, isVisible: false, isOpen: false, isChecked: false },
	],
}

const authorizationReducer = (state: IFlowForManager = initialFlowForManagerReducer, action: FlowForManagerActions) => {
	switch (action.type) {
		case CLEAR_FORM: {
			return { ...state, ...initialFlowForManagerReducer }
		}
		case MANAGE_TAB: {
			return { ...state, tabsForManagement: action.payload }
		}
		case SET_TECHNICAL_LIFECYCLE_OPTIONS: {
			return { ...state, technicalLifeCycleElements: action.payload }
		}
		case SET_PRIMARY_ASSETS: {
			return { ...state, primaryAssets: action.payload }
		}
		case SET_PRIMARY_ASSET_SELECTED: {
			return { ...state, primaryAssetsSelected: action.payload }
		}
		case SET_WORK_PACKAGE: {
			return { ...state, workPackage: action.payload }
		}
		case SET_WORK_PACKAGE_SELECTED: {
			return { ...state, workPackageSelected: action.payload }
		}
		case SET_CONTRACT_DATA: {
			return { ...state, contractData: action.payload }
		}
		case SET_IDR_SUBMISSION_DATE: {
			return { ...state, idrSubmissionDate: action.payload }
		}
		case SET_COMMENCEMENT_DATE: {
			return { ...state, stageCommencementDate: action.payload }
		}
		case SET_FINAL_SUBMISSION_DATE: {
			return { ...state, finalSubmissionDate: action.payload }
		}
		case SET_CERTIFICATION_DATE: {
			return { ...state, l3CertificationDate: action.payload }
		}
		case SET_TECHNICAL_LIFECYCLE: {
			return { ...state, technicalLifeCycle: action.payload }
		}
		case SET_OWNERS: {
			return { ...state, owners: action.payload }
		}
		case SET_OWNER_SELECTED: {
			return { ...state, selectedOwner: action.payload }
		}
		default:
			return state
	}
}

export default authorizationReducer
