import React, { memo } from 'react'
import styled from 'styled-components'
import {
	addNewAttribute,
	handleAttributeByKey,
	handleIsOpenAttributes,
	removeAttribute,
} from '../../../features/formsHandler/actions'
import { useAppDispatch, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Accordion from '../../Molecules/Accordion'
import FormTable from './Form'
import { additionalDependsOn } from '../../../features/formsHandler/utils'

export const AccordionWrapper = styled.div`
	input {
		max-width: 400px;
		height: 60px;
	}

	margin: 30px;

	.addComponent {
		img {
			max-width: 15px;
			max-height: 15px;
			margin-right: 8px;
		}
	}

	.inputWrapper {
		margin: 20px 0;
	}

	.header {
		margin-bottom: 15px;
		font-weight: bold;
		font-size: 18px;
	}
	input[type='file'] {
		display: none;
	}

	.upload {
		display: flex;
		button {
			margin-left: 10px;
		}
	}

	.custom-file-upload {
		font-family: 'Roboto';
		height: 60px;
		background-color: rgba(1, 60, 166, 0.05);
		border-radius: 8px;
		min-height: 32px;
		padding: 0 12px;
		display: flex;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		width: 100%;
		align-items: center;
		max-width: 400px;
		cursor: pointer;
	}
`

interface IAttributesComponent {
	isDisabled: boolean
}

const focusStart = 'attributes_'

const AttributesComponent = ({ isDisabled }: IAttributesComponent) => {
	const dispatch = useAppDispatch()

	const { sequences, isOpenAttributes, focusName, isCheckedAttributes, attributes, IsFormsLoading, dependsOnOptions } =
		useAppSelectorDeprecated((state) => ({
			isOpenAttributes: state.formsHandler.FormAttributes.isOpen,
			isCheckedAttributes: state.formsHandler.FormAttributes.isChecked,
			attributes: state.formsHandler.FormAttributes.attributes,
			dependsOnOptions: state.formsHandler.FormAttributes.dependsOnOptions,
			IsFormsLoading: state.formsHandler.IsFormsLoading,
			focusName: state.formsHandler.focusName,
			sequences: state.formsHandler.sequences,
		}))

	return (
		<Accordion
			isArrow
			isOpen={isOpenAttributes}
			handleOpen={() => dispatch(handleIsOpenAttributes(!isOpenAttributes))}
			title="Form Attributes"
			isChecked={isCheckedAttributes}
		>
			<AccordionWrapper>
				<FormTable
					attributesSequence={sequences}
					showErrors={!isDisabled}
					focusStart={focusStart}
					focusName={focusName}
					onClickDelete={(el) => dispatch(removeAttribute(el))}
					isDeleteColumn={!isDisabled}
					isAddRow={!isDisabled}
					addNewRow={() => dispatch(addNewAttribute())} // to delete
					idAddRowDisabled={isDisabled}
					isDisabled={isDisabled}
					isLoading={IsFormsLoading}
					dataForForm={attributes}
					dependsOnOptions={[...dependsOnOptions, additionalDependsOn]}
					onEditRow={(item) => {
						dispatch(handleAttributeByKey({ ...item, focusStart }))
					}}
				/>
			</AccordionWrapper>
		</Accordion>
	)
}

export default memo(AttributesComponent)
