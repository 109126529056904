import { axiosInstance } from 'src/services/axiosInstance'
import { Deliverable } from '../features/deliverables/deliverablesSlice'
import { REACT_ACTIONS } from './constants'
import { TaskAction } from './tasksActionsService'

export enum DeliverableAction {
	CREATE = 'action_add_new_deliverables',
	CREATE_EXISTING = 'action_add_existing_deliverables',
	UPDATE = 'action_rename_deliverables',
	REMOVE = 'action_remove_deliverables',
	DELIVERABLE_NUMBER_FETCHED = 'action_fetch_deliverable_numbers',
}

export interface Action {
	action_name: string
	task_id: string
}

export interface ActionDeliverableParams {
	tidpTagCode: string
	contract: string
	deliverables: Deliverable[]
}

export const deliverablesService = {
	async update(action: TaskAction, params: ActionDeliverableParams) {
		const body = {
			action_name: action.actionName,
			task_id: action.taskId,
			action_input_parameters: {
				contract: params.contract,
				tag_code: params.tidpTagCode,
				DELIVERABLES: params.deliverables.map((deliverable) => ({
					tag_code: deliverable.tagCode,
					title: deliverable.title,
					'Created By': deliverable.author,
					documentType: deliverable.documentType,
					hs2Deliverable: deliverable.hs2Deliverable,
					GDPR: deliverable.GDPR,
					stage: deliverable.stage,
					suitabilityStatus: deliverable.suitabilityStatus,
					discipline: deliverable.discipline,
				})),
			},
		}
		const { data } = await axiosInstance.post(REACT_ACTIONS, body)
		return data
	},
	async remove(action: TaskAction, params: ActionDeliverableParams) {
		const body = {
			action_name: action.actionName,
			task_id: action.taskId,
			action_input_parameters: {
				contract: params.contract,
				tag_code: params.tidpTagCode,
				DELIVERABLES: params.deliverables.map((deliverable) => ({
					tag_code: deliverable.tagCode,
				})),
			},
		}
		const { data } = await axiosInstance.post(REACT_ACTIONS, body)
		return data
	},
	async create(action: TaskAction, params: ActionDeliverableParams, asset: any) {
		const body = {
			action_name: action.actionName,
			task_id: action.taskId,
			action_input_parameters: {
				contract: params.contract,
				tag_code: params.tidpTagCode,
				DELIVERABLES: params.deliverables.map((deliverable) => {
					return {
						'Document Name': `${deliverable.documentType}${asset.length > 0 ? ` - ${asset} - ` : ' - '}${deliverable.title
							}`,
						'Document Type': deliverable.documentType,
						'HS2 Deliverable': deliverable.hs2Deliverable,
						GDPR: deliverable.GDPR,
						Stage: deliverable.stage,
						'Suitability Status': deliverable.suitabilityStatus,
						Discipline: deliverable.discipline,
						'Created By': deliverable.author,
					}
				}),
			},
		}
		const { data } = await axiosInstance.post(REACT_ACTIONS, body)
		return data
	},
	async createExisting(action: TaskAction, params: ActionDeliverableParams) {
		const body = {
			action_name: action.actionName,
			task_id: action.taskId,
			action_input_parameters: {
				contract: params.contract,
				tag_code: params.tidpTagCode,
				DELIVERABLES: params.deliverables.map((deliverable) => {
					const element: any = { 'Document Number': deliverable.documentNumber }
					if (deliverable.revision !== '') {
						element.Revision = deliverable.revision
					}
					return element
				}),
			},
		}
		const { data } = await axiosInstance.post(REACT_ACTIONS, body)
		return data
	},
}
