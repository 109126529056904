import { axiosInstance } from '../../services/axiosInstance'
import { Form } from './formsService.types'

export const formsService = {
	async fetch(id: string, params: Omit<FetchAttributesParams, 'ids'>): Promise<FetchFormsResponse> {
		const { data } = await axiosInstance.get('/asset_service/attributes/detail_attributes/', {
			params: { ...params, ids: id },
		})
		return data
	},
	async fetchTemplates(contract: string): Promise<FormTemplate[]> {
		const { data } = await axiosInstance.get('assets/class/form_template', { params: { contract } })
		return data
	},
	async fetchAll(contract: string): Promise<FetchFormsResponse> {
		const templates = await this.fetchTemplates(contract)
		const formsArray = await Promise.all(templates.map((template) => this.fetch(template.id, { contract })))
		const forms: FetchFormsResponse = Object.assign({}, ...formsArray)
		return forms
	},
}

export interface FetchAttributesParams {
	contract: string
	size?: number
	ids?: string | string[]
}

export interface FetchFormsResponse {
	[formId: string]: {
		forms: Form[]
	}
}

export interface FormTemplate {
	id: string
	contract: string
	class_code: string
	class_name: string
	customer: string
}
