import { createApi } from '@reduxjs/toolkit/query/react'
import { assetsService } from 'src/facade/assets/assetsService'
import { Asset, AssetSearchResponse } from 'src/facade/assets/assetsService.types'
import { axiosBaseQuery } from 'src/utils/axiosBaseQuery'

export const assetsApi = createApi({
	reducerPath: 'assetsApi',
	baseQuery: axiosBaseQuery(),
	tagTypes: ['Assets'],
	endpoints: (builder) => ({
		fetchAssets: builder.query<Asset[], { tagCodes: string[]; contract: string }>({
			query:
				({ contract, tagCodes }) =>
					() =>
						assetsService.search({
							contract,
							tag_code: {
								fieldValue: tagCodes,
							},
						}),
			transformResponse: (response: AssetSearchResponse) => {
				return response.result
			},
			providesTags: ['Assets'],
		}),
	}),
})

export const { useFetchAssetsQuery } = assetsApi
