import styled from 'styled-components'
import React, { FC } from 'react'
import { CellProps, Renderer } from 'react-table'

const CenterDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`

interface CenteredCellProps {
	value: any
}

export const CenteredCell: FC<CenteredCellProps> = ({ value }) => {
	return (
		<CenterDiv>
			<div>{value}</div>
		</CenterDiv>
	)
}

export const cellCenterRenderer: Renderer<CellProps<{}>> = ({ value }) => <CenteredCell value={value} />
