import { LoadingButton } from '@mui/lab'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import moment from 'moment'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { dateFormat } from 'src/assets/dateFormats'
import { ICommentTypes } from 'src/models/disciplineOverview/disciplineOverview'
import { colors } from 'src/theme/colors'
import { useAppSelector, useAppSelectorDeprecated } from 'src/utils/reduxUtils'
import styled from 'styled-components'

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	padding-top: 8px;
	padding-bot: 5px;
	align-items: baseline;
	width: 500px;
	height: 370px;
`

const CommentMetadataWrapper = styled.div`
	width: 500px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const DropdownWrapper = styled.div`
	padding-top: 8px;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
`

const ButtonsWrapper = styled.div`
	width: 500px;
	position: relative;
	display: flex;
	flex-direction: row;
	padding-top: 15px;
	width: 100%;
	justify-content: flex-end;
`

interface CommentInputs {
	code: string
	status: string
	comment: string
}

export interface SaveCommentForm {
	message: string
	tagCode: string
	documentTagCode: string
	commentCode: string
	status: string
	deliverableTagCode?: string
}

interface AddCommentProps {
	commentTypes: Array<ICommentTypes>
	saveNewComment: (form: SaveCommentForm) => Promise<void>
	onCancel: () => void
}

export function AddComment(props: AddCommentProps) {
	const { saveNewComment } = props
	const username = useAppSelector((state) => state.profile.user!.name)
	const today = new Date()
	const { tidp } = useAppSelectorDeprecated((state) => state.tidpOverview)
	const { highlightedDeliverableTagCode: deliverableTagCode } = useAppSelector((state) => state.comments)

	const documentTagCode = tidp.relatedTo.find(
		(relatedTo: any) => relatedTo.class_code === 'Document' && relatedTo.class_name === 'Document',
	)?.tag_code

	const {
		register,
		handleSubmit,
		control,
		formState: { isSubmitting },
	} = useForm<CommentInputs>({
		defaultValues: {
			code: '',
			status: 'Open',
			comment: '',
		},
	})

	const onSubmit: SubmitHandler<CommentInputs> = async (inputs) => {
		await saveNewComment({
			message: inputs.comment,
			tagCode: tidp.tag_code,
			documentTagCode,
			commentCode: inputs.code,
			status: inputs.status,
			deliverableTagCode,
		})
	}

	return (
		<StyledForm onSubmit={handleSubmit(onSubmit)}>
			<CommentMetadataWrapper>
				<FormControl sx={{ marginBot: '8px', marginRight: '10px' }}>
					<TextField
						inputProps={{
							style: { width: '245px', backgroundColor: `${colors.lightGreyF10}` },
						}}
						id="username-text-field"
						label="Recorded by"
						defaultValue={username}
						InputProps={{
							readOnly: true,
						}}
						variant="filled"
					/>
				</FormControl>
				<TextField
					inputProps={{
						style: { width: '245px', backgroundColor: `${colors.lightGreyF10}` },
					}}
					id="comment-date-text-field"
					label="Recorded at"
					defaultValue={moment(today).format(dateFormat.engHours)}
					InputProps={{
						readOnly: true,
					}}
					variant="filled"
				/>
			</CommentMetadataWrapper>
			<DropdownWrapper>
				<Controller
					control={control}
					name="code"
					render={(field) => (
						<FormControl
							required
							variant="filled"
							sx={{ m: 1, minWidth: 150.6, marginLeft: '0px', marginRight: '11px' }}
						>
							<InputLabel id="comment-code-select-label">Code</InputLabel>
							<Select
								{...field}
								{...register('code', { required: true })}
								style={{ width: '244px', backgroundColor: `${colors.lightGreyF10}` }}
								labelId="comment-code-select-label"
								id="discipline-code-select"
							>
								<MenuItem value="A - Accepted">A - Accepted</MenuItem>
								<MenuItem value="B - Accepted with comments">B - Accepted with comments</MenuItem>
								<MenuItem value="C - Rejected">C - Rejected</MenuItem>
							</Select>
						</FormControl>
					)}
				/>
				<Controller
					control={control}
					name="status"
					defaultValue="Open"
					render={(field) => (
						<FormControl variant="filled" sx={{ m: 1, minWidth: 150.6, marginLeft: '0px', marginRight: '0px' }}>
							<InputLabel id="comment-status-select-label">Status</InputLabel>
							<Select
								{...field}
								{...register('status', { required: false })}
								disabled
								sx={{ width: '244px', backgroundColor: `${colors.lightGreyF10}` }}
								labelId="comment-status-select-label"
								id="comment-status-select"
								defaultValue="Open"
							>
								<MenuItem value="Open">Open</MenuItem>
								<MenuItem value="Closed">Closed</MenuItem>
							</Select>
						</FormControl>
					)}
				/>
			</DropdownWrapper>
			<Controller
				control={control}
				name="comment"
				defaultValue=""
				render={(field) => (
					<TextField
						{...field}
						{...register('comment', { required: true })}
						sx={{ width: '500px', backgroundColor: `${colors.lightGreyF10}` }}
						id="comment-text-field"
						label="Type comment *"
						multiline
						rows={5}
						variant="filled"
					/>
				)}
			/>
			<ButtonsWrapper>
				<LoadingButton variant="outlined" sx={{ marginRight: '8px' }} type="submit" loading={isSubmitting}>
					Submit
				</LoadingButton>
				<Button variant="outlined" onClick={props.onCancel}>
					Cancel
				</Button>
			</ButtonsWrapper>
		</StyledForm>
	)
}
