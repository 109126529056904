import { createAction } from '@reduxjs/toolkit'
import { action } from 'typesafe-actions'
import { CHANGE_USER_TEXT, GET_USER_BY_ROLE, HANDLE_IS_LOADING_USERS, SET_CONTRACT, SET_USERS } from './constants'
import { Contract } from './reducer'

export const getListOfUsersByRole = (users: { text: string; role: string }) => action(GET_USER_BY_ROLE, users)
export const assignListOfUsers = (users: Array<{ value: string; label: string }>) => action(SET_USERS, users)
export const changeTextOfSearch = (text: string) => action(CHANGE_USER_TEXT, text)

export const setContract = (contract: Contract) => action(SET_CONTRACT, contract)

export const handleLoadingUsers = (isLoading: boolean) => action(HANDLE_IS_LOADING_USERS, isLoading)
