/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */

import { connect } from 'react-redux'
import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'
import { MyColumn, MyTable, StyledTdContainer } from '../../Molecules/MyTable'
import { CellProps, Renderer } from 'react-table'
import SelectOverview from '../../Molecules/SelectOverview'
import Headline from '../../Atoms/Headline'
import { IReduxState } from '../../../models/redux/redux'
import { colors } from '../../../theme/colors'
import Button from '../../Molecules/Button'
import { groupsManagementActions } from '../../../features/groupsManagement'
import { StyledEditButton, TooltipEmail } from '../UserManagement'
import NewGroupPopup from './newGroupPopup'
import Input from '../../Atoms/Input'
import { IEditPopup, IGroupsList, IUsersPaginationData } from '../../../models/groupsManagement/groupsManagement'
import EditGroupPopup from './editGroupPopup'
import { usersActions } from '../../../features/users'
import Pagination from '../../Molecules/Pagination'
import { Roles } from '../../../features/authorization/reducer'
import { Layout } from 'src/components/Molecules/Layout'
import { useAppSelector, useAppSelectorDeprecated } from 'src/utils/reduxUtils'

interface IGroupsManagementComponent {
	getListOfUsersByRole: typeof usersActions.getListOfUsersByRole

	isLoadingUsers: boolean
	users: any

	getListOfGroups: typeof groupsManagementActions.getListOfGroups
	deleteGroup: typeof groupsManagementActions.deleteGroup
	handleNewGroupPopup: typeof groupsManagementActions.handleNewGroupPopup
	createNewGroup: typeof groupsManagementActions.createNewGroup
	handleEditGroup: typeof groupsManagementActions.handleEditGroup
	getUsersForGroup: typeof groupsManagementActions.getUsersForGroup
	removeUsersForGroup: typeof groupsManagementActions.removeUsersForGroup
	addNewUserToGroup: typeof groupsManagementActions.addNewUserToGroup
	searchForGroup: typeof groupsManagementActions.searchForGroup
	searchForUsersInGroup: typeof groupsManagementActions.searchForUsersInGroup
	handleCheckedUser: typeof groupsManagementActions.handleCheckedUser
	checkUsersDuplicates: typeof groupsManagementActions.checkUsersDuplicates
	clearPrivileges: typeof groupsManagementActions.clearPrivileges

	getPrivilegesTypes: typeof groupsManagementActions.getPrivilegesTypes
	getPrivilegesSource: typeof groupsManagementActions.getPrivilegesSource
	savePrivilege: typeof groupsManagementActions.savePrivilege
	assignChild: typeof groupsManagementActions.assignChild
	isPrivilegesTypesLoading: boolean

	roles: string[]
	isLoadingGroups: boolean
	isAddingNewUsersToGroup: boolean
	isDeletingGroup: boolean
	newGroupPopup: boolean
	isRemovingUsersFromGroup: boolean
	newGroupIsCreating: boolean
	isSavingPrivileges: boolean
	isLoadingUsersForGroup: boolean
	usersPaginationData: IUsersPaginationData
	usersWithoutDuplicates: Array<{
		value: string
		label: string
	}>
	listOfUsersInGroup: Array<{ user: string; isSelected: boolean }>
	groupsPagination: { page: number; pageCount: number }
	editPopup: IEditPopup
	groupsList: Array<IGroupsList>
}

export const SpinnerOverview = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const PageTitleWrapper = styled.div`
	.header {
		margin-bottom: 30px;
	}
`

const AddGroupWrapper = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: flex-end;
	margin-right: 10px;
	span {
		color: ${colors.white};
		font-weight: 300;
	}
`

interface IGroupList {
	tag: string
	title: string
	quantity: number
	project_org: boolean
}

const usersData = (usersList: IGroupList[]) =>
	usersList.map((group) => ({
		title: group.title,
		quantity: group.quantity,
		tag: group.tag,
		project_org: group.project_org,
	}))

const GroupsManagement = (props: IGroupsManagementComponent) => {
	const [groupFilter, handleGroupFilter] = useState({ value: '' })
	const { contract } = useAppSelector((state) => state.users)

	const getGroups = () => {
		props.handleNewGroupPopup(false)
		closePopup()
		props.assignChild({})
		props.getListOfGroups({ page: 0, contract: localStorage.getItem('contract')! })
	}

	const fetchGroupsList = useCallback(getGroups, [])

	useEffect(() => {
		fetchGroupsList()
	}, [fetchGroupsList, contract])

	const groupsColumns: any = () =>
		[
			{
				Header: '',
				accessor: 'index',
				options: {
					noHeaderTdContainer: true,
				},
				maxWidth: 15,
				Cell: cellRenderer('index'),
			},
			{
				Header: () => {
					return (
						<>
							<Input
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus={!props.editPopup.isOpen}
								placeholder="Group Name"
								value={groupFilter.value}
								backgroundColor={colors.white}
								onChange={(value: any) => {
									handleGroupFilter({ value })
									if (value.length >= 3) {
										props.searchForGroup({ page: 0, query: value })
									}
									if (value.length === 0) {
										props.getListOfGroups({ page: 0, contract: localStorage.getItem('contract')! })
									}
								}}
							/>
							{groupFilter.value.length > 0 && groupFilter.value.length < 3 && (
								<TooltipEmail>At least 3 characters</TooltipEmail>
							)}
						</>
					)
				},
				accessor: 'title',
				Cell: cellRenderer('title'),
			},
			{
				Header: 'Total group members',
				accessor: 'quantity',
				maxWidth: 15,
				Cell: cellRenderer('quantity'),
			},
			{
				Header: 'Project Organisation',
				accessor: 'project_org',
				maxWidth: 15,
				Cell: cellRenderer('project_org'),
			},

			{
				Header: () => (
					<AddGroupWrapper>
						<Button
							isLoading={props.newGroupIsCreating}
							isDisabled={props.newGroupIsCreating}
							onClick={() => {
								props.handleNewGroupPopup(true)
							}}
							color={colors.main100}
							text="Add New"
						/>
					</AddGroupWrapper>
				),
				options: {
					noHeaderTdContainer: true,
				},
				maxWidth: 20,
				accessor: 'button',
				Cell: cellRenderer('button'),
			},
		].map((column) => ({ ...column, noTdContainer: true, disableSortBy: true }) as MyColumn<IGroupList>)

	const closePopup = () => {
		props.handleEditGroup({ ...props.editPopup, isOpen: false })
		props.clearPrivileges()
	}

	const removeUsers = (tag: string | number) => {
		props.removeUsersForGroup({ groupTag: tag })
	}
	const cellRenderer =
		(headerId: string): Renderer<CellProps<IGroupList>> =>
		({ value, row }) => {
			if (headerId === 'index') {
				return <StyledTdContainer center>{props.groupsPagination.page * 15 + row.index + 1}</StyledTdContainer>
			}
			if (headerId === 'project_org') {
				return (
					<StyledTdContainer center className="project_org">
						{row?.values.project_org ? 'Yes' : 'No'}
					</StyledTdContainer>
				)
			}
			if (headerId === 'button') {
				return (
					<StyledEditButton>
						{props.roles.some((role) => role === Roles['AIMS:roles.Sysadmin']) && (
							<Button
								color={colors.danger100}
								text="Delete"
								onClick={() => {
									props.deleteGroup(row.original.tag)
								}}
								isLoading={props.isDeletingGroup}
								isDisabled={props.isDeletingGroup}
							/>
						)}
						<Button
							color={colors.main100}
							text="Edit"
							onClick={() =>
								props.handleEditGroup({
									...props.editPopup,
									isOpen: true,
									tag: row.original.tag,
									name: row.original.title,
									project_org: row.original.project_org,
								})
							}
							isLoading={false}
							isDisabled={false}
						/>
					</StyledEditButton>
				)
			}
			return <SelectOverview isDisabled value={[{ value, label: value }]} backgroundColor={colors.main5} fullWidth />
		}
	return (
		<Layout>
			{props.newGroupPopup && (
				<NewGroupPopup handleNewGroupPopup={props.handleNewGroupPopup} createNewGroup={props.createNewGroup} />
			)}
			{props.editPopup.isOpen && (
				<EditGroupPopup
					getListOfUsersByRole={props.getListOfUsersByRole}
					users={props.users}
					isLoadingUsers={props.isLoadingUsers}
					addNewUserToGroup={props.addNewUserToGroup}
					isAddingNewUsersToGroup={props.isAddingNewUsersToGroup}
					onRemoveUsers={removeUsers}
					isRemovingUsersFromGroup={props.isRemovingUsersFromGroup}
					closePopup={closePopup}
					isLoadingUsersForGroup={props.isLoadingUsersForGroup}
					handleEditGroup={props.handleEditGroup}
					editPopup={props.editPopup}
					getUsersForGroup={props.getUsersForGroup}
					listOfUsersInGroup={props.listOfUsersInGroup}
					usersPaginationData={props.usersPaginationData}
					searchForUsersInGroup={props.searchForUsersInGroup}
					handleCheckedUser={props.handleCheckedUser}
					checkUsersDuplicates={props.checkUsersDuplicates}
					usersWithoutDuplicates={props.usersWithoutDuplicates}
					getPrivilegesTypes={props.getPrivilegesTypes}
					isPrivilegesTypesLoading={props.isPrivilegesTypesLoading}
					getPrivilegesSource={props.getPrivilegesSource}
					savePrivilege={props.savePrivilege}
					isSavingPrivileges={props.isSavingPrivileges}
				/>
			)}
			<PageTitleWrapper>
				<div className="header">
					<Headline title="Groups Management" small />
				</div>
				<MyTable
					isLoading={props.isLoadingGroups}
					fullWidth
					columns={groupsColumns()}
					data={usersData(props.groupsList)}
				/>
				{!props.isLoadingGroups && props.groupsPagination.pageCount > 1 && (
					<Pagination
						page={props.groupsPagination.page}
						onPageChange={(page) => {
							if (groupFilter.value.length >= 3) {
								props.searchForGroup({ page, query: groupFilter.value })
							} else {
								props.getListOfGroups({ page, contract: localStorage.getItem('contract')! })
							}
						}}
						pageCount={props.groupsPagination.pageCount}
						initialPage={0}
					/>
				)}
			</PageTitleWrapper>
		</Layout>
	)
}

export default connect(
	(state: IReduxState) => ({
		groupsList: state.groupsManagement.groupsList,
		isLoadingGroups: state.groupsManagement.isLoadingGroups,
		isDeletingGroup: state.groupsManagement.isDeletingGroup,
		newGroupPopup: state.groupsManagement.newGroupPopup,
		newGroupIsCreating: state.groupsManagement.newGroupIsCreating,
		editPopup: state.groupsManagement.editPopup,
		isLoadingUsersForGroup: state.groupsManagement.isLoadingUsersForGroup,
		listOfUsersInGroup: state.groupsManagement.listOfUsersInGroup,
		isRemovingUsersFromGroup: state.groupsManagement.isRemovingUsersFromGroup,
		isAddingNewUsersToGroup: state.groupsManagement.isAddingNewUsersToGroup,
		groupsPagination: state.groupsManagement.groupsPagination,
		usersPaginationData: state.groupsManagement.usersPaginationData,
		usersWithoutDuplicates: state.groupsManagement.usersWithoutDuplicates,

		isPrivilegesTypesLoading: state.groupsManagement.isPrivilegesTypesLoading,
		isSavingPrivileges: state.groupsManagement.isSavingPrivileges,

		roles: state.authorization.roles,

		isLoadingUsers: state.users.isLoadingUsers,
		users: state.users.users,
	}),
	{
		getListOfUsersByRole: usersActions.getListOfUsersByRole,

		getListOfGroups: groupsManagementActions.getListOfGroups,
		deleteGroup: groupsManagementActions.deleteGroup,
		handleNewGroupPopup: groupsManagementActions.handleNewGroupPopup,
		createNewGroup: groupsManagementActions.createNewGroup,
		handleEditGroup: groupsManagementActions.handleEditGroup,
		getUsersForGroup: groupsManagementActions.getUsersForGroup,
		removeUsersForGroup: groupsManagementActions.removeUsersForGroup,
		addNewUserToGroup: groupsManagementActions.addNewUserToGroup,
		searchForGroup: groupsManagementActions.searchForGroup,
		searchForUsersInGroup: groupsManagementActions.searchForUsersInGroup,
		handleCheckedUser: groupsManagementActions.handleCheckedUser,
		checkUsersDuplicates: groupsManagementActions.checkUsersDuplicates,
		clearPrivileges: groupsManagementActions.clearPrivileges,

		getPrivilegesTypes: groupsManagementActions.getPrivilegesTypes,
		getPrivilegesSource: groupsManagementActions.getPrivilegesSource,
		savePrivilege: groupsManagementActions.savePrivilege,
		assignChild: groupsManagementActions.assignChild,
	},
)(GroupsManagement)
