import { Link } from 'react-router-dom'
import logo from '../../../assets/logo.svg'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

const PageNotFoundWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${colors.black};
	img {
		max-height: 170px;
		max-width: 150px;
	}
	.header {
		margin: 10px 0;
		font-size: 50px;
	}
	.comeBack {
		font-size: 20px;
		a {
			color: ${colors.blue1};
		}
	}
`

const PageNotFound = () => {
	return (
		<PageNotFoundWrapper>
			<img src={logo} alt="emt_rails_logo" />
			<div className="header">[404] - PAGE NOT FOUND</div>
			<div className="comeBack">
				Click <Link to="/">here</Link> to return to Homepage{' '}
			</div>
		</PageNotFoundWrapper>
	)
}

export default PageNotFound
