import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { assetsApi } from 'src/features/assets/assetsApi'
import { didpApi } from 'src/features/didp/didpApi'
import { formTemplatesApi } from 'src/features/form-templates/formTemplatesApi'
import { formsApi } from 'src/features/forms/formsApi'
import { tasksApi } from 'src/features/tasks/tasksApi'
import { tidpAssetsApi } from 'src/features/tidp-assets/tidpAseetsApi'
import { usersApi } from 'src/features/users/usersApi'
import { attributesReducer } from '../../features/attributes'
import { authReducer } from '../../features/authorization/reducer'
import { commentsReducer } from '../../features/comments/commentsSlice'
import { controlsReducer } from '../../features/controls'
import { deliverablesReducer } from '../../features/deliverables/deliverablesSlice'
import { disciplineOverviewReducer } from '../../features/disciplineOverview'
import { flowForManagerReducer } from '../../features/flowForManager'
import { formsHandlerReducer } from '../../features/formsHandler/slice'
import { formsListReducer } from '../../features/formsList'
import { groupsManagementReducer } from '../../features/groupsManagement'
import { profileSlice } from '../../features/profile/profileSlice'
import { searchAssetsReducer } from '../../features/search'
import { tasksActionsElementReducer } from '../../features/taskActions'
import { taskActionsReducer } from '../../features/taskActions/taskActionsSlice'
import { tasksListReducer } from '../../features/tasksList'
import { tidpOverviewReducer } from '../../features/tidpOverview'
import { usersReducer } from '../../features/users'
import { usersManagementReducer } from '../../features/usersManagement'
import browserHistory from '../../services/history'

const rootReducer = combineReducers({
	authorization: authReducer,
	router: connectRouter(browserHistory),
	tidpOverview: tidpOverviewReducer,
	disciplineOverview: disciplineOverviewReducer,
	flowForManager: flowForManagerReducer,
	users: usersReducer,
	controls: controlsReducer,
	comments: commentsReducer,
	attributes: attributesReducer,
	search: searchAssetsReducer,
	tasksList: tasksListReducer,
	tasksActions: tasksActionsElementReducer,
	tasksActions2: taskActionsReducer,
	usersManagement: usersManagementReducer,
	groupsManagement: groupsManagementReducer,
	formsList: formsListReducer,
	formsHandler: formsHandlerReducer,
	deliverables: deliverablesReducer,
	profile: profileSlice.reducer,
	[tasksApi.reducerPath]: tasksApi.reducer,
	[formsApi.reducerPath]: formsApi.reducer,
	[formTemplatesApi.reducerPath]: formTemplatesApi.reducer,
	[usersApi.reducerPath]: usersApi.reducer,
	[assetsApi.reducerPath]: assetsApi.reducer,
	[tidpAssetsApi.reducerPath]: tidpAssetsApi.reducer,
	[didpApi.reducerPath]: didpApi.reducer,
})

export default rootReducer
