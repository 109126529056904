import { FC } from 'react'
import { Link } from 'react-router-dom'
import LGButton from 'src/components/Molecules/LGButton'
import Header, { headerHeight } from 'src/components/Organisms/Header'
import RightSidebar from 'src/components/Organisms/RightSidebar'
import Sidebar from 'src/components/Organisms/Sidebar'
import browserHistory from 'src/services/history'
import { useAppSelectorDeprecated } from 'src/utils/reduxUtils'
import styled from 'styled-components'
import backArrow from '../../assets/icons/historyBackArrow.svg'

const ContentWrapper = styled.div`
	display: flex;
	height: 100%;
`

const DashboardWrapper = styled.div<{}>`
	flex: 1;
	height: 100%;
	max-height: calc(100vh - ${headerHeight});
	overflow-y: auto;
	padding: 45px;
`
const GoBackButtonWrapper = styled.div`
	margin-top: -20px;
	margin-bottom: 10px;

	img {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
`

export const Layout: FC<{ goBackUrl?: string }> = ({ children, goBackUrl }) => {
	return (
		<>
			<Header />
			<ContentWrapper>
				<Sidebar />
				<DashboardWrapper>
					{(browserHistory.length > 0 || goBackUrl) && browserHistory.location.pathname !== '/tasks-list' && (
						<GoBackButtonWrapper>
							{goBackUrl ? (
								<Link
									to={goBackUrl}
									component={({ ...props }) => (
										<LGButton
											text="Back"
											height={31}
											isWhiteText={false}
											padding="6px 12px"
											icon={backArrow}
											{...props}
											onClick={() => browserHistory.push(goBackUrl)}
										/>
									)}
								/>
							) : (
								<LGButton
									text="Back"
									height={31}
									isWhiteText={false}
									padding="6px 12px"
									icon={backArrow}
									onClick={() => browserHistory.goBack()}
								/>
							)}
						</GoBackButtonWrapper>
					)}
					{children}
				</DashboardWrapper>
				<RightSidebar />
			</ContentWrapper>
		</>
	)
}
