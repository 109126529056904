import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { TextField } from '@mui/material'
import { get } from 'lodash'
import { FC, memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AttributeInputProps } from 'src/forms/_shared/attribute/AttributeInput'
import { MainFormForm } from 'src/forms/_shared/main-form/MainForm'

export const AttributeDateInput: FC<AttributeInputProps> = memo((props) => {
	const { attribute, name } = props
	const { control, formState } = useFormContext<MainFormForm>()

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Controller
				name={name}
				control={control}
				defaultValue={null}
				rules={{ required: { value: attribute.mandatory, message: 'Field is required.' } }}
				render={({ field }) => (
					<DesktopDatePicker
						{...field}
						inputFormat="yyyy/MM/dd"
						mask="____/__/__"
						disabled={props.disabled}
						renderInput={(params: any) => (
							<TextField
								{...params}
								fullWidth
								required={attribute.mandatory}
								id={attribute.id}
								label={attribute.display_name}
								InputLabelProps={{ shrink: true }}
								variant="standard"
								size="medium"
								margin="normal"
								error={!!get(formState.errors, name)}
								helperText={get(formState.errors, name)?.message}
							/>
						)}
					/>
				)}
			/>
		</LocalizationProvider>
	)
})

AttributeDateInput.displayName = 'AttributeDateInput'
