import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IReduxState } from '../../../models/redux/redux'
import { colors } from '../../../theme/colors'
import SidebarLink from '../../Molecules/SidebarLink'
import { Roles } from '../../../features/authorization/reducer'

const MainWrapper = styled.div<{ width: number }>`
	height: 100%;
	width: ${({ width }) => `${width}px`};
	padding: 36px 20px;
	background-color: ${({ theme }) => theme.colors.main2};
	border-right: ${({ theme }) => `1px solid ${theme.colors.main10}`};
	a {
		color: ${colors.black}
	  text-decoration: none;
	}
`

interface ISidebarWrapper extends RouteComponentProps {
	roles: string[]
}

function Sidebar({ roles, location }: ISidebarWrapper) {
	const pathRoles = [
		{
			path: '/tasks-list',
			name: 'TIDPs',
			roles: [],
		},
		{
			path: '/forms',
			name: 'Forms',
			roles: [
				Roles['Registers:roles.Registers.WorkflowAdmin'],
				Roles['Registers:roles.Registers.WorkflowManager'],
				Roles['Assets:assets.Roles.Viewer'],
				Roles['Documents:assets.Roles.Creator'],
				Roles['Assets:assets.Roles.Creator'],
			],
		},
	].map((item, index) => ({ ...item, id: index + 1 }))

	const [isHovered, handleHovered] = useState(false)
	const canSee = (el: string[]) => new Set([...roles, ...el]).size !== [...roles, ...el].length

	return (
		<MainWrapper
			width={isHovered ? 240 : 65}
			onMouseEnter={() => handleHovered(true)}
			onMouseLeave={() => handleHovered(false)}
		>
			{pathRoles.length > 0
				? pathRoles.map((item) =>
						item.roles.length === 0 || canSee(item.roles) ? (
							<Link key={`${item.path}_${item.name}`} to={item.path}>
								<SidebarLink
									isCollapsed={isHovered}
									key={item.id}
									active={location.pathname === item.path}
									title={item.name}
								/>
							</Link>
						) : null,
				  )
				: null}
		</MainWrapper>
	)
}

export default connect((state: IReduxState) => ({
	roles: state.authorization.roles,
}))(withRouter(Sidebar))
