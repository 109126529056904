/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import { FontWeightType } from '../../../typings/styledComponents'
import Tooltip from '../../Molecules/Tooltip'

enum FontWeight {
	light = 300,
	regular = 500,
	bold = 700,
}

interface IStyledLi {
	selected?: boolean
	small?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
	color?: string
	centered?: boolean
}

const StyledLi = styled.span<IStyledLi>`
	font-weight: ${({ fontWeight }) => (fontWeight ? FontWeight[fontWeight] : 500)};
	font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
	line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '19px')};
	color: ${({ color, selected, theme }) => color || (selected && theme.colors.main50)};

	&:empty {
		padding: 9px;
		width: auto;
		height: auto;
	}

	${({ small }) =>
		small &&
		css`
			font-size: 12px;
			line-height: 14px;
		`};

	${({ centered }) =>
		centered &&
		css`
			margin: auto;
		`};
`
const StyledItem = styled.span``
const TextItem = styled.div`
	margin: 5px 0;
`

function ListItem({
	text,
	small,
	selected,
	fontWeight,
	fontSize,
	lineHeight,
	color,
	centered,
}: {
	text: string | string[]
	small?: boolean
	selected?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
	color?: string
	centered?: boolean
}) {
	return (
		<>
			<StyledLi
				small={small}
				selected={selected}
				fontWeight={fontWeight}
				fontSize={fontSize}
				lineHeight={lineHeight}
				centered={centered}
			>
				{Array.isArray(text) ? (
					text.length > 0 ? (
						<Tooltip
							placement="left"
							trigger="hover"
							tooltip={
								<>
									{text.map((item, index) => (
										<TextItem key={`${item}_${index}`}>{item}</TextItem>
									))}
								</>
							}
						>
							{text.map(
								(item, index) =>
									index < 3 && (
										<StyledItem key={`${item}_${index}`}>{`${item}${
											index < 2 && text.length - 1 > index ? ',' : ''
										} `}</StyledItem>
									),
							)}
							{text.length > 2 && '...'}
						</Tooltip>
					) : (
						text.map((item) => <StyledItem key={item}>{item}</StyledItem>)
					)
				) : (
					text
				)}
			</StyledLi>
		</>
	)
}

export default ListItem
