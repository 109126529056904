/* eslint-disable no-underscore-dangle */
import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import * as searchWorkflowApi from 'src/facade/workflow/workflowService'
import { AppTask } from 'src/features/tasks/tasksApi'
import * as searchApi from 'src/services/search'
import { isOfType } from 'typesafe-actions'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import { searchAssets } from './actions'
import { SEARCH_ASSETS } from './constants'
import { searchAssetsActions } from './index'

export const searchAssetsEpic: AppEpicDeprecated<ReturnType<typeof searchAssets>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(SEARCH_ASSETS)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			if (action.payload === '') {
				return concat(of(searchAssetsActions.setIsSearching(false)), of(searchAssetsActions.setAssetsResults([])))
			}

			const onFormsPage = window.location.pathname === '/app/forms'

			const handleAssetSearchResults = (item: {
				_source: { attribute: any; class_code: string; tag_code: string }
			}) => ({
				displayName: `
					${item._source.attribute[`${item._source.class_code} ${item._source.class_code === 'TIDP' ? 'Title' : 'Name'}`]} 
					${`[ ${item._source.attribute[`${item._source.class_code} Number`]}]`}
					${item._source.class_code === 'TIDP' && item._source.attribute.Revision ? `(${item._source.attribute.Revision})` : ''}
				`,
				link:
					item._source.class_code === 'TIDP'
						? `/overview/${item._source.tag_code}`
						: `/discipline-overview/${item._source.tag_code}`,
			})

			const handleTaskSearchResult = (task: AppTask) => ({
				displayName: `${task.task_name} (${task.metadata.class_code})`,
				link: `/forms/${task.task_id}`,
			})

			return concat(
				of(searchAssetsActions.setIsSearching(true)),
				fromPromise(
					onFormsPage
						? searchWorkflowApi.searchTasks(action.payload, state.users.contract.contract)
						: searchApi.searchAssets(action.payload, state.users.contract.contract),
				).pipe(
					switchMap((response) => {
						const results = onFormsPage
							? response.results.map(handleTaskSearchResult)
							: response.result.map(handleAssetSearchResults)

						return concat(
							of(searchAssetsActions.setIsSearching(false)),
							of(searchAssetsActions.setAssetsResults(results)),
						)
					}),
					catchError((err: { status: number }) => {
						return concat(
							of(searchAssetsActions.setIsSearching(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: searchAssetsActions.searchAssets(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export default combineEpics(searchAssetsEpic)
