import { getBaseUrl } from 'src/utils/getBaseUrl'

export function getDomain(): string | null {
  const regex = /https:\/\/(staging-)?(\w+)\.enablemyteam\.com/
  const baseUrl = getBaseUrl()
  const match = baseUrl.match(regex)

  if (match) {
    return match[2]
  }

  return null
}
