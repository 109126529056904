import { toast } from 'react-toastify'
import { combineEpics } from 'redux-observable'
import { concat, EMPTY, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import * as userManagementService from 'src/services/usersManagement'
import { isOfType } from 'typesafe-actions'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import {
	addNewUser,
	changePage,
	deleteUser,
	editUser,
	getListOfUsers,
	getOneUser,
	handleFilterItems,
	resetPassword,
} from './actions'
import { usersManagementActions, usersManagementConstans } from './index'

export const getUserDetailsEpic: AppEpicDeprecated<ReturnType<typeof getOneUser>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersManagementConstans.GET_ONE_USER)),
		withLatestFrom(state$),
		switchMap(([action]) => {
			return concat(
				of(usersManagementActions.isOneUserDetailsLoading(true)),
				fromPromise(userManagementService.getUserByUserId(action.payload)).pipe(
					switchMap((response) => {
						const editedUser = {
							email: response.userName,
							isSSO: response.isSSO,
							id: response.userId,
							isFoundInAd: response.isFoundInAd,
							isInFewDomains: response.isInFewDomains,
							permissions: response.roles.map((item: string) => ({ value: item, label: item })),
							defaultModule: { value: response.defaultModule, label: response.defaultModule },
						}
						return concat(
							of(usersManagementActions.setOneUserDetails(editedUser)),
							of(usersManagementActions.isOneUserDetailsLoading(false)),
						)
					}),
					catchError((err) => {
						toast.error('Could not get user details')
						return concat(
							of(usersManagementActions.handlePopupData({ isOpen: false, isNew: false })),
							of(usersManagementActions.isOneUserDetailsLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: usersManagementActions.getOneUser(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const resetPasswordEpic: AppEpicDeprecated<ReturnType<typeof resetPassword>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersManagementConstans.RESET_PASSWORD)),
		withLatestFrom(state$),
		switchMap(([action]) => {
			return concat(
				of(usersManagementActions.handleResettingPassword({ id: action.payload.id, isResetting: true })),
				fromPromise(userManagementService.resetPassword(action.payload.id)).pipe(
					switchMap(() => {
						toast.success(`Password for user ${action.payload.email} reset correctly`)
						return concat(of(usersManagementActions.handleResettingPassword({ id: '', isResetting: false })))
					}),
					catchError((err) => {
						toast.success('Error while resetting')
						return of(
							authorizationActions.recallApi({
								errorCode: err.status,
								callback: usersManagementActions.resetPassword(action.payload),
							}),
						)
					}),
				),
			)
		}),
	)

export const getUsersListEpic: AppEpicDeprecated<ReturnType<typeof getListOfUsers>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersManagementConstans.GET_LIST_OF_USERS)),
		withLatestFrom(state$),
		switchMap(([, state]) => {
			return concat(
				of(usersManagementActions.isLoadingListOfUsers(true)),
				fromPromise(
					userManagementService.getUsersForUserManagement(
						state.usersManagement.pagination.pageNumber,
						state.usersManagement.pagination.pageSize,
					),
				).pipe(
					switchMap((response) => {
						const users = response.users.map((res: any) => ({
							email: res.userName,
							isSSO: res.isSSO,
							createdTime: res.createdTime,
							id: res.userId,
							groups: res.groups,
						}))
						return concat(
							of(usersManagementActions.setListOfUsers(users)),
							of(usersManagementActions.setTotalCounter(response.totalMembers)),
							of(
								usersManagementActions.setAvailableRoles(
									response.availableRoles.map((item: any) => ({ value: item, label: item })),
								),
							),
							of(usersManagementActions.isLoadingListOfUsers(false)),
						)
					}),
					catchError((err) => {
						return concat(
							of(usersManagementActions.isLoadingListOfUsers(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: usersManagementActions.getListOfUsers(),
								}),
							),
						)
					}),
				),
			)
		}),
	)
export const getFilteredDataEpic: AppEpicDeprecated<ReturnType<typeof handleFilterItems>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersManagementConstans.HANDLE_FILTERS_ITEMS)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			if (
				(state.usersManagement.filters.email && state.usersManagement.filters.email.length < 3) ||
				(state.usersManagement.filters.groups && state.usersManagement.filters.groups.length < 3)
			) {
				return EMPTY
			}
			return concat(
				of(usersManagementActions.setListOfUsers([])),
				of(usersManagementActions.handleIsFiltering(true)),
				of(usersManagementActions.setListOfUsers([])),
				fromPromise(
					userManagementService.getUsersForUserManagement(
						state.usersManagement.pagination.pageNumber,
						state.usersManagement.pagination.pageSize,
						state.usersManagement.filters.email,
						// @ts-ignore
						state.usersManagement.filters.isActive?.value,
						state.usersManagement.filters.groups,
					),
				).pipe(
					switchMap((response) => {
						const users = response.users.map((res: any) => ({
							email: res.userName,
							isSSO: res.isSSO,
							isActive: res.isActive,
							createdTime: res.createdTime,
							id: res.userId,
							groups: res.groups,
						}))
						return concat(
							of(usersManagementActions.setTotalCounter(response.totalMembers)),
							of(usersManagementActions.handleIsFiltering(false)),
							of(usersManagementActions.setListOfUsers(users)),
						)
					}),
					catchError((err) => {
						return of(
							authorizationActions.recallApi({
								errorCode: err.status,
								callback: usersManagementActions.handleFilterItems(action.payload),
							}),
						)
					}),
				),
			)
		}),
	)

export const editUserEpic: AppEpicDeprecated<ReturnType<typeof editUser>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersManagementConstans.EDIT_USER)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const body = {
				userId: action.payload.id,
				isSSO: action.payload.form.isSSO,
				roles: action.payload.form.permissions.map((item: any) => item.value),
				defaultModule: action.payload.form.defaultModule?.value,
			}
			return concat(
				of(usersManagementActions.isEditingUser({ id: action.payload.id, isEditing: true })),
				fromPromise(userManagementService.updateUser(body)).pipe(
					switchMap((response) => {
						toast.success('User updated')
						return concat(
							of(
								usersManagementActions.setListOfUsers(
									state.usersManagement.usersList.map((item: any) =>
										item.email === response.response.email
											? {
													...item,
													isSSO: action.payload.form.isSSO,
											  }
											: item,
									),
								),
							),
							of(usersManagementActions.isEditingUser({ id: '', isEditing: false })),
						)
					}),
					catchError((err) => {
						toast.error('Error while updating user')
						return concat(
							of(usersManagementActions.isEditingUser({ id: '', isEditing: false })),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: usersManagementActions.editUser(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const deleteUserEpic: AppEpicDeprecated<ReturnType<typeof deleteUser>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersManagementConstans.DELETE_USER)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(usersManagementActions.isDeletingUser({ id: action.payload, isDeleting: true })),
				fromPromise(userManagementService.deleteUser(action.payload)).pipe(
					switchMap(() => {
						toast.success('User deleted')
						return concat(
							of(
								usersManagementActions.setListOfUsers(
									state.usersManagement.usersList.filter((item: any) => item.id !== action.payload),
								),
							),
							of(usersManagementActions.isDeletingUser({ id: '', isDeleting: false })),
						)
					}),
					catchError((err) => {
						toast.error('Error while deleting user')
						return concat(
							of(usersManagementActions.isDeletingUser({ id: '', isDeleting: false })),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: usersManagementActions.deleteUser(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const addUserEpic: AppEpicDeprecated<ReturnType<typeof addNewUser>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersManagementConstans.ADD_NEW_USER)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			const body = {
				email: action.payload.email.toLowerCase(),
				isSSO: action.payload.isSSO,
				roles: action.payload.permissions.map((item: any) => item.value),
				defaultModule: action.payload.defaultModule?.value,
			}
			return concat(
				of(usersManagementActions.isAddingNewUser(true)),
				fromPromise(userManagementService.addUser(body)).pipe(
					switchMap((response) => {
						const userBody = {
							email: response.user.email,
							isSSO: response.user.isSSO,
							createdTime: response.user.createdTime,
							id: response.user.userId,
							groups: [],
						}
						toast.success('User created')
						return concat(
							of(usersManagementActions.setListOfUsers([userBody, ...state.usersManagement.usersList])),
							of(usersManagementActions.isAddingNewUser(false)),
						)
					}),
					catchError((err) => {
						toast.error(err.response?.data?.Error || 'Error while creating user')
						return concat(
							of(usersManagementActions.isAddingNewUser(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: usersManagementActions.addNewUser(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const changePageEpic: AppEpicDeprecated<ReturnType<typeof changePage>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(usersManagementConstans.CHANGE_PAGE)),
		withLatestFrom(state$),
		switchMap(() => {
			return of(usersManagementActions.handleFilterItems({ value: '', objectName: '' }))
		}),
	)

export default combineEpics(
	resetPasswordEpic,
	getUserDetailsEpic,
	getUsersListEpic,
	getFilteredDataEpic,
	editUserEpic,
	addUserEpic,
	changePageEpic,
	deleteUserEpic,
)
