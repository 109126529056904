/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import Image from '../../Atoms/Image'
import TextButton from '../../Atoms/TextButton'
import starIconRed14 from '../../../assets/icons/starIconRed14.svg'
import SpinnerComponent from '../../Atoms/Loader'

interface IStyledButton {
	text?: string
	width?: string | number
	height?: number
	color?: string
	imageSrc?: string
	isError?: boolean
	disabled?: boolean
}

const SpinnerWrapper = styled.div`
	margin: 0 5px;
`

const StyledButton = styled.button<IStyledButton>`
	width: ${({ width }) => (width && typeof width === 'string' ? width : `${width}px`)};
	height: ${({ height }) => height && `${height}px`};
	display: flex;
	outline: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 250ms ease-in;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	border: none;
	outline: none;
	background-color: ${({ color, theme, disabled }) =>
		disabled ? theme.colors.lightGreyE0 : color || theme.colors.lightGreyE0};
	border-radius: 50%;

	${({ text }) =>
		text &&
		css`
			flex-direction: row;
			border-radius: 4px;
			padding: 6px 12px;
		`}

	${({ imageSrc, text }) =>
		imageSrc &&
		css`
			img {
				width: 15px;
				height: 15px;
				margin-right: ${text ? '10px' : 0};
			}
		`}

	${({ isError }) =>
		isError &&
		css`
			flex-direction: row;
			background: #fce9e7;
			border-radius: 4px;
			padding: 10px 12px;
			color: ${({ theme }) => theme.colors.danger100};
		`}
`

const StarWrapper = styled.div`
	margin-right: 10px;
`

function Button({
	text,
	imageSrc,
	textColor,
	width,
	height,
	color,
	isError,
	onClick,
	isDisabled,
	isLoading,
	loaderColor,
	loaderHeight,
}: {
	text?: string
	imageSrc?: string
	width?: string | number
	height?: number
	isError?: boolean
	color?: string
	loaderColor?: string
	textColor?: string
	onClick?: () => void
	isDisabled?: boolean
	isLoading?: boolean
	loaderHeight?: number
}) {
	return (
		<StyledButton
			onClick={onClick}
			text={text}
			imageSrc={imageSrc}
			isError={isError}
			width={width}
			height={height}
			color={color}
			disabled={isDisabled}
		>
			{isError && !imageSrc && (
				<StarWrapper>
					<Image imageSrc={starIconRed14} alt="star-icon" />
				</StarWrapper>
			)}
			{imageSrc && <Image imageSrc={imageSrc} alt="menu-icon" />}
			{text && <TextButton text={text} xl textColor={textColor} mainColor={!isError} />}
			{isLoading && (
				<SpinnerWrapper>
					<SpinnerComponent color={loaderColor} size={loaderHeight || (height ? height * 0.7 : 15)} />
				</SpinnerWrapper>
			)}
		</StyledButton>
	)
}

export default Button
