import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { LoadingButton } from '@mui/lab'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	ButtonGroup,
	DialogContentText,
	Divider,
	Typography,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { groupBy, omit, pipe, prop, sort } from 'ramda'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { MessagePanel } from 'src/components/message-panel/MessagePanel'
import { MessageText } from 'src/components/message-text/MessageText'
import { actionTaskService } from 'src/facade/action/task/actionTaskService'
import { useFetchFormQuery } from 'src/features/forms/formsApi'
import { AppTask, tasksApi } from 'src/features/tasks/tasksApi'
import { SelectOption } from 'src/typings/SelectOption'
import { useAppDispatch, useAppSelector } from 'src/utils/reduxUtils'

export interface SendTaskDialogProps {
	open: boolean
	task: AppTask
	onClose?(): void
}

export enum FormPrivilageType {
	Hidden = 'Hidden',
	Editable = 'Editable',
	Readonly = 'Readonly',
}

const OPTIONS: SelectOption<FormPrivilageType>[] = [
	{ label: 'Hidden', value: FormPrivilageType.Hidden },
	{ label: 'Editable', value: FormPrivilageType.Editable },
	{ label: 'Readonly', value: FormPrivilageType.Readonly },
]

export type TaskSenderAttribute = {
	id: any
	contexts: string
	displayName: string
	formName: string
	sequence: number
	attributeName: string
	formPrivilageType: FormPrivilageType
}

export interface TaskSenderForm {
	[contextName: string]: TaskSenderAttribute[]
}

const NO_CONTEXTS: string = 'NO_CONTEXTS'

export const SendTaskDialog: FC<SendTaskDialogProps> = ({ open, onClose, task }) => {
	const dispatch = useAppDispatch()
	const {
		contract: { contract },
	} = useAppSelector((state) => state.users)

	const [message, setMessage] = useState<string | undefined>()

	const {
		data: mainForm,
		error: formError,
		isLoading: isLoadingForm,
	} = useFetchFormQuery({ id: task.metadata.form_template_id, contract })

	const allAttributes: TaskSenderAttribute[] = useMemo(
		() =>
			mainForm
				? [...mainForm.children, ...(mainForm.attributes || [])].slice().map((attr) => ({
						id: attr.id,
						contexts: (attr.contexts as string) || NO_CONTEXTS,
						displayName: attr.display_name ?? '',
						formName: 'form_name' in attr ? attr.form_name : '',
						sequence: attr.sequence ?? 0,
						attributeName: attr.attribute_name ?? '',
						formPrivilageType: FormPrivilageType.Hidden,
				  }))
				: [],
		[mainForm],
	)

	const defaultValues = useMemo(
		() =>
			pipe(
				sort((attr1: TaskSenderAttribute, attr2: TaskSenderAttribute) => attr1.sequence - attr2.sequence),
				groupBy((attribute: TaskSenderAttribute) => attribute.contexts),
			)(allAttributes),
		[allAttributes],
	)

	const methods = useForm<TaskSenderForm>({ defaultValues })
	const { watch, reset, setValue, handleSubmit, formState } = methods

	useEffect(() => reset(defaultValues), [defaultValues, reset])

	const groupedFormsByContexts = watch()
	const formsWithContexts = omit([NO_CONTEXTS], groupedFormsByContexts)
	const formsWithoutContexts = prop(NO_CONTEXTS, groupedFormsByContexts)

	const setAllContextAttribute = (contextName: string) => (type: FormPrivilageType) => {
		groupedFormsByContexts[contextName].forEach((attribute, index) => {
			setValue(`${contextName}.${index}.formPrivilageType`, type)
		})
	}

	const areSelectedAllAttributes = (contextName: string): FormPrivilageType | null => {
		return (
			(Object.keys(FormPrivilageType) as FormPrivilageType[]).find((type) =>
				groupedFormsByContexts[contextName]
					.map((attribute) => attribute.formPrivilageType)
					.every((val) => val === type),
			) || null
		)
	}

	const onSubmit = async (form: TaskSenderForm) => {
		try {
			const attributes = Object.values(form).flat()
			const privileges = {
				read_only: attributes
					.filter((attr) => attr.formPrivilageType === FormPrivilageType.Readonly)
					.map((attr) => attr.attributeName),
				write: attributes
					.filter((attr) => attr.formPrivilageType === FormPrivilageType.Editable)
					.map((attr) => attr.attributeName),
			}

			const users = task.metadata!.assigned_users!.split(',')
			const contributors = users.map((user: string) => {
				return [user, privileges]
			})

			await actionTaskService.send(task.task_id, {
				contract,
				contributors,
			})
			dispatch(tasksApi.util.invalidateTags(['Tasks']))
			toast.success('Task has been sent successfully.')
		} catch (e) {
			setMessage('Failed to send task.')
		}
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" scroll="body">
			<form
				noValidate
				onSubmit={(event) => {
					setMessage(undefined)
					handleSubmit(async (submittedForm) => {
						await onSubmit(submittedForm)
					})(event)
				}}
			>
				<DialogTitle>{task.metadata?.attribute_name?.split('_')?.[0]}</DialogTitle>
				<DialogContent sx={{ pb: 1 }}>
					<DialogContentText sx={{ mb: 2 }}>
						Configure permission for {task.metadata?.assigned_users?.split(',').join(', ')}
					</DialogContentText>

					{formError ? (
						<MessagePanel my={3} errorMessage="Failed to load form." />
					) : isLoadingForm ? (
						<Box my={2} sx={{ display: 'flex', justifyContent: 'center', height: '90%', alignItems: 'center' }}>
							<SpinnerComponent size={42} />
						</Box>
					) : (
						<FormProvider {...methods}>
							{formsWithoutContexts?.map((attribute, index) => (
								<Box key={`attribute/component_${attribute.id}_${attribute.attributeName}`} mb={1}>
									<SendTaskAttribute
										contextName={NO_CONTEXTS}
										attribute={attribute}
										index={index}
										disabled={formState.isSubmitting}
									/>
									{index !== formsWithoutContexts.length - 1 && <Divider sx={{ my: 1 }} />}
								</Box>
							))}
							{Object.entries(formsWithContexts).map(([contextName, contextAttributes]) => (
								<Fragment key={contextName}>
									<Divider />
									<Accordion
										square
										elevation={0}
										disableGutters
										sx={() => ({
											'&:before': {
												display: 'none',
											},
										})}
									>
										<AccordionSummary
											sx={(theme) => ({
												background: 'transparent',
												padding: 0,
												border: 0,
												flexDirection: 'row-reverse',
												'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
													transform: 'rotate(90deg)',
												},
												'& .MuiAccordionSummary-content': {
													marginLeft: theme.spacing(1),
													justifyContent: 'space-between!important',
													alignItems: 'center!important',
													my: 1,
												},
											})}
											expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
											aria-controls={`accordion-${contextName}`}
											id={`accordion-${contextName}`}
										>
											<Typography sx={{ fontWeight: 500 }}>{contextName}</Typography>
											<ButtonGroup
												disabled={formState.isSubmitting}
												variant="outlined"
												aria-label="form-priviligies"
												size="small"
												sx={{ ml: 1 }}
											>
												{OPTIONS.map((option) => (
													<Button
														key={option.value}
														variant={areSelectedAllAttributes(contextName) === option.value ? 'contained' : 'outlined'}
														onClick={(e) => {
															e.stopPropagation()
															setAllContextAttribute(contextName)(option.value)
														}}
													>
														{option.label}
													</Button>
												))}
											</ButtonGroup>
										</AccordionSummary>
										<AccordionDetails sx={{ p: 0 }}>
											{contextAttributes.map((attribute, innerIndex) => (
												<Box key={`attribute/component_${attribute.id}_${attribute.attributeName}`} ml={1} mb={1}>
													<SendTaskAttribute
														contextName={contextName}
														attribute={attribute}
														index={innerIndex}
														disabled={formState.isSubmitting}
													/>
													{innerIndex !== contextAttributes.length - 1 && <Divider sx={{ my: 1 }} />}
												</Box>
											))}
										</AccordionDetails>
									</Accordion>
								</Fragment>
							))}
						</FormProvider>
					)}
				</DialogContent>
				<DialogActions sx={{ display: 'flex', justifyContent: message ? 'space-between' : 'flex-end' }}>
					<MessageText mt={2} errorMessage={message} />
					<Box>
						<Button variant="outlined" onClick={onClose} sx={{ ml: 1 }}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							type="submit"
							loading={formState.isSubmitting}
							loadingIndicator={'Sending...'}
							sx={{ ml: 1 }}
						>
							Send
						</LoadingButton>
					</Box>
				</DialogActions>
			</form>
		</Dialog>
	)
}

const SendTaskAttribute: FC<{
	contextName: string
	attribute: TaskSenderAttribute
	index: number
	disabled: boolean
}> = ({ attribute, contextName, index, disabled }) => {
	const { setValue } = useFormContext<TaskSenderForm>()

	return (
		<Box
			key={`attribute/component_${attribute.id}_${attribute.attributeName}`}
			sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
		>
			<Typography>{`${attribute.displayName} ${attribute.formName ? `(${attribute.formName})` : ''}`}</Typography>
			<ButtonGroup disabled={disabled} variant="outlined" aria-label="form-priviligies" size="small" sx={{ ml: 1 }}>
				{OPTIONS.map((option) => (
					<Button
						key={option.value}
						variant={attribute.formPrivilageType === option.value ? 'contained' : 'outlined'}
						onClick={() => setValue(`${contextName}.${index}.formPrivilageType`, option.value)}
					>
						{option.label}
					</Button>
				))}
			</ButtonGroup>
		</Box>
	)
}
