import React from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

const StyledContentOfTooltip = styled.div<{ additionalStyles: any }>`
	${({ additionalStyles }) => additionalStyles};
	padding: 10px;
	color: ${colors.black};
	border-radius: 10px;
`

const ToolTipText = styled.span<{ width: any }>`
	max-width: ${({ width }) => width || ''};
`

const Tooltip = ({ styleForTooltip, trigger, children, tooltip, hideArrow, maxTextWidth, ...props }: any) => (
	<TooltipTrigger
		{...props}
		trigger={trigger}
		tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
			<StyledContentOfTooltip
				additionalStyles={styleForTooltip}
				{...getTooltipProps({
					ref: tooltipRef,
					className: 'tooltip-container',
				})}
			>
				{!hideArrow && (
					<div
						{...getArrowProps({
							ref: arrowRef,
							className: 'tooltip-arrow',
							'data-placement': placement,
						})}
					/>
				)}
				<ToolTipText width={maxTextWidth}>{tooltip}</ToolTipText>
			</StyledContentOfTooltip>
		)}
	>
		{({ getTriggerProps, triggerRef }) => (
			<span
				{...getTriggerProps({
					ref: triggerRef,
					className: 'trigger',
				})}
			>
				{children}
			</span>
		)}
	</TooltipTrigger>
)

export default Tooltip
