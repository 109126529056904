import { Autocomplete, TextField } from '@mui/material'
import { get } from 'lodash'
import { FC, memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useFetchUsersQuery } from 'src/features/users/usersApi'
import { AttributeInputProps } from 'src/forms/_shared/attribute/AttributeInput'
import { MainFormForm } from 'src/forms/_shared/main-form/MainForm'

export const AttributeUserInput: FC<AttributeInputProps> = memo(({ attribute, name, disabled }) => {
	const { formState, control } = useFormContext<MainFormForm>()

	const { data: users, isLoading } = useFetchUsersQuery()

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={[]}
			rules={{ required: { value: attribute.mandatory, message: 'Field is required.' } }}
			render={({ field }) => (
				<Autocomplete
					{...field}
					fullWidth
					multiple
					disablePortal
					onChange={(e, data) => field.onChange(data)}
					loading={isLoading}
					options={users?.map((u) => u.username) || []}
					disabled={disabled}
					renderInput={(params) => (
						<TextField
							{...params}
							required={attribute.mandatory}
							id={attribute.id}
							label={attribute.display_name}
							InputLabelProps={{ shrink: true }}
							variant="standard"
							size="medium"
							margin="normal"
							error={!!get(formState.errors, name)}
							helperText={get(formState.errors, name)?.message}
						/>
					)}
				/>
			)}
		/>
	)
})

AttributeUserInput.displayName = 'AttributeUserInput'
