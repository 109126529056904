/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import { ActionType } from 'typesafe-actions'
import lodash from 'lodash'

import * as actions from './actions'
import {
	ASSIGN_EXTERNAL_STAKEHOLDERS_VALUE,
	ASSIGN_INTERNAL_STAKEHOLDERS_VALUE,
	ASSIGN_OWNERS_VALUE,
	ASSIGN_TIDP_COMMENTS,
	CHANGE_OVERVIEW_COMMENT_CONTENT,
	CLEAR_TIDP_COMMENT,
	GET_TIDP_OVERVIEW,
	HANDLE_IS_SAVING_OVERVIEW,
	HANDLE_NAV_NUMBER_STATUS,
	HANDLE_NEW_TIDP_COMMENT_TEXT,
	HANDLE_OVERVIEW_DISCIPLINE_IS_REQUIRED,
	HANDLE_OVERVIEW_DISCIPLINE_OWNER,
	HANDLE_OVERVIEW_DISCIPLINE_STATUS,
	HANDLE_START_NEW_TIDP_COMMENT,
	HANDLE_TIDP_REPLY_COMMENT,
	IS_SAVING_NEW_COMMENT_TIDP_OVERVIEW,
	SET_ACTION_IN_PROGRESS,
	SET_ANOTHER_REVISION,
	SET_LIST_OF_DISCIPLINES,
	SET_NEW_DISCIPLINE,
	SET_TECHNICAL_LIFECYCLE_CODE,
	SET_TIDP_OVERVIEW_DATA,
	SET_TIDP_OVERVIEW_DISCIPLINES,
	SET_TIDP_OVERVIEW_HISTORY,
	START_NEW_TIDP_REPLY,
	TIDP_OVERVIEW_TABS,
	UPDATE_TIDP_COMMENT,
} from './constants'
import { ITidpOverview } from '../../models/tidpOverview/tidpOverview'
import { colors } from '../../theme/colors'
import { IS_SAVING_NEW_COMMENT } from 'src/features/disciplineOverview/constants'

export type TidpOverviewAction = ActionType<typeof actions>

const initialTidpOverviewReducer: ITidpOverview = {
	tidp: null,
	tidpNavTabs: [
		{
			isSelected: true,
			name: 'Overview',
			id: 1,
			tabName: 'TIDP Overview Details',
			tabs: [],
		},
		{
			isSelected: false,
			name: 'Asset & Work Packages',
			id: 2,
			tabName: 'TIDP Asset',
			tabs: [],
		},
		{
			isSelected: false,
			name: 'Contract Data',
			id: 3,
			tabName: 'TIDP Contract',
			tabs: [],
		},
		{
			isSelected: false,
			name: 'Technical Lifecycle',
			id: 4,
			tabName: 'TIDP Lifecycle',
			tabs: [],
		},
	],
	disciplinedHeaderCells: [
		{ id: 1, label: '' },
		{ id: 2, label: 'Required' },
		{ id: 3, label: 'Discipline Owner' },
		{ id: 4, label: 'Comment' },
		{ id: 5, label: 'Status' },
		{ id: 6, label: 'iconComments' },
	],
	ownersList: [
		{
			name: 'mkasznia@enablemyteam.com',
		},
	],
	rowCells: [],
	tidpComments: [],
	listOfDisciplines: [],
	isSavingNewComment: false,
	newCommentText: '',
	isNewComment: false,
	newReply: { id: '', comment: '' },
	externalStakeholersText: '',
	internalStakeholersValue: [],
	externalStakeholersValue: [],
	ownersValue: [],
	originalInternalStakeholersValue: [],
	originalExternalStakeholersValue: [],
	originalOwnersValue: [],
	tidpOverviewHistory: [],
	isSavingOverview: false,
	isActionInProgress: false,
	technicalLifecycleCode: '',
	refreshing: false,
}

const tidpOverviewReducer = (state: ITidpOverview = initialTidpOverviewReducer, action: TidpOverviewAction | any) => {
	switch (action.type) {
		case SET_ACTION_IN_PROGRESS: {
			return {
				...state,
				isActionInProgress: action.payload,
			}
		}
		case ASSIGN_INTERNAL_STAKEHOLDERS_VALUE: {
			return {
				...state,
				internalStakeholersValue: action.payload,
			}
		}
		case ASSIGN_EXTERNAL_STAKEHOLDERS_VALUE: {
			return {
				...state,
				externalStakeholersValue: action.payload,
			}
		}
		case ASSIGN_OWNERS_VALUE: {
			return {
				...state,
				ownersValue: action.payload,
			}
		}
		case SET_TIDP_OVERVIEW_HISTORY: {
			return {
				...state,
				tidpOverviewHistory: action.payload,
			}
		}
		case HANDLE_IS_SAVING_OVERVIEW: {
			return { ...state, isSavingOverview: action.payload }
		}
		case SET_TECHNICAL_LIFECYCLE_CODE: {
			return { ...state, technicalLifecycleCode: action.payload }
		}
		case SET_LIST_OF_DISCIPLINES: {
			return { ...state, listOfDisciplines: action.payload }
		}
		case START_NEW_TIDP_REPLY: {
			return { ...state, newReply: { id: action.payload, comment: '' }, newCommentText: '' }
		}
		case HANDLE_START_NEW_TIDP_COMMENT: {
			return { ...state, isNewComment: action.payload, newCommentText: '', newReply: { id: '', comment: '' } }
		}
		case HANDLE_TIDP_REPLY_COMMENT: {
			return { ...state, newReply: { ...state.newReply, comment: action.payload }, isNewComment: false }
		}
		case TIDP_OVERVIEW_TABS:
			return {
				...state,
				tidpNavTabs: state.tidpNavTabs.map((element) => ({ ...element, isSelected: element.id === action.payload })),
			}
		case GET_TIDP_OVERVIEW:
			return {
				...state,
				refreshing: true,
			}
		case SET_ANOTHER_REVISION:
			return {
				...state,
				tidpNavTabs: state.tidpNavTabs.map((element) =>
					element.name === 'Overview'
						? {
								...element,
								tabs: element.tabs.map((item) =>
									item.name === 'Revision'
										? { ...item, value: action.payload.revision }
										: item.name === 'TIDP Number'
										? { ...item, value: action.payload.tidpNumber }
										: item,
								),
						  }
						: element,
				),
			}
		case HANDLE_NAV_NUMBER_STATUS:
			return { ...state, tidpNavTabs: action.payload }
		case HANDLE_OVERVIEW_DISCIPLINE_IS_REQUIRED:
			return {
				...state,
				rowCells: state.rowCells.map((el) =>
					el.id === action.payload.id
						? {
								...el,
								isRequired: action.payload.isRequired === 'yes',
								isOkToSent: action.payload.isRequired === 'yes' ? el.selectedOwner.length !== 0 : true,
						  }
						: el,
				),
			}
		case HANDLE_OVERVIEW_DISCIPLINE_OWNER:
			return {
				...state,
				rowCells: state.rowCells.map((el) => {
					const owner =
						action.payload && action.payload.ownerName
							? action.payload.ownerName.filter((item: { isClearDisable: boolean }) => !item.isClearDisable)
							: []
					const selectedOwner = [
						...el.originalSelectedOwner.map((item: any) => ({
							...item,
							backgroundColor: colors.success25,
							isClearDisable: true,
						})),
						...owner,
					]
					return el.id === action.payload.id
						? {
								...el,
								selectedOwner,
								isOkToSent: el.isRequired
									? !lodash.isEqual(selectedOwner, el.disciplineSelectValues) && selectedOwner.length > 0
									: true,
						  }
						: el
				}),
			}
		case ASSIGN_TIDP_COMMENTS:
			return {
				...state,
				tidpComments: action.payload,
			}
		case UPDATE_TIDP_COMMENT:
			return {
				...state,
				tidpComments: state.tidpComments.map((comment) =>
					comment.tagCode === action.payload.value ? { ...comment, status: action.payload.status } : comment,
				),
			}
		case SET_TIDP_OVERVIEW_DISCIPLINES:
			return {
				...state,
				rowCells: action.payload.map((element: any) => ({
					...element,
					selectedOwner: [
						...element.nominatedOwners.map((item: any) => ({ value: item, label: item, isClearDisable: false })),
						...element.selectedOwner.map((item: any) => ({
							...item,
							backgroundColor: colors.success25,
							isClearDisable: true,
						})),
					],
				})),
			}

		case SET_NEW_DISCIPLINE:
			return {
				...state,
				rowCells: action.payload,
			}

		case IS_SAVING_NEW_COMMENT:
			return {
				...state,
				isSavingNewComment: action.payload,
			}
		case IS_SAVING_NEW_COMMENT_TIDP_OVERVIEW:
			return {
				...state,
				isSavingNewComment: action.payload,
			}
		case HANDLE_NEW_TIDP_COMMENT_TEXT:
			return {
				...state,
				newCommentText: action.payload,
			}
		case HANDLE_OVERVIEW_DISCIPLINE_STATUS:
			return {
				...state,
				rowCells: state.rowCells.map((el) =>
					el.id === action.payload.id
						? {
								...el,
								status: action.payload.status,
						  }
						: el,
				),
			}
		case CHANGE_OVERVIEW_COMMENT_CONTENT:
			return {
				...state,
				rowCells: state.rowCells.map((el) =>
					el.id === action.payload.id
						? {
								...el,
								commentContent: action.payload.comment,
						  }
						: el,
				),
			}
		case SET_TIDP_OVERVIEW_DATA:
			const externalStakeholersValue =
				action.payload[1] && action.payload[1].external && action.payload[1].external[0]
					? action.payload[1].external[0].map((el: any) => {
							return { value: el, label: el }
					  })
					: []
			const internalStakeholersValue =
				action.payload[1] && action.payload[1].internal && action.payload[1].internal[0]
					? action.payload[1].internal[0].map((el: any) => {
							return { value: el, label: el }
					  })
					: []

			const ownersValue =
				action.payload[1] && action.payload[1].owners && action.payload[1].owners[0]
					? action.payload[1].owners[0].map((el: any) => {
							return { value: el, label: el }
					  })
					: []

			return {
				...state,
				refreshing: false,
				tidp: action.payload[2],
				tidpNavTabs: action.payload[0],
				externalStakeholersValue,
				internalStakeholersValue,
				ownersValue,
				originalExternalStakeholersValue: externalStakeholersValue,
				originalInternalStakeholersValue: internalStakeholersValue,
				originalOwnersValue: ownersValue,
			}
		case CLEAR_TIDP_COMMENT:
			return {
				...state,
				isSavingNewComment: false,
				newCommentText: '',
				isNewComment: false,
				newReply: { id: '', comment: '' },
			}
		default:
			return state
	}
}

export default tidpOverviewReducer
