/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { isString } from 'formik'
import lodash, { isEmpty } from 'lodash'
import moment from 'moment'
import { isNumeric } from 'rxjs/internal-compatibility'
import { dateFormat } from '../../assets/dateFormats'
import { IFormDataObject } from '../../models/formsHandler/formsHandler'

const isExistingFormElement = (item: any) => item === null || item === undefined

export const additionalDependsOn = {
	id: Math.random() * (10 - 1) + 1,
	value: 'Pre-requisite',
	label: 'Pre-requisite',
}

export const AssignFormObjects = (sortedResults: any) =>
	sortedResults.map((item: any) => {
		return {
			id: item.id,
			related_id: item.related_id,
			attribute_name: {
				value: isExistingFormElement(item.attribute_name) ? '' : item.attribute_name,
				required: true,
			},
			sequence: {
				value: isExistingFormElement(item.sequence) ? '' : item.sequence,
				required: true,
			},
			depends_on: {
				value: !isExistingFormElement(item.depends_on) ? { value: item.depends_on, label: item.depends_on } : '',
				required: false,
			},
			display_name: {
				value: isExistingFormElement(item.display_name) ? '' : item.display_name,
				required: false,
			},
			attribute_type: {
				value:
					!isExistingFormElement(item.attribute_type) && item.attribute_type !== ''
						? {
								value: item.attribute_type,
								label: item.attribute_type,
						  }
						: '',
				required: true,
			},
			mandatory: {
				value: isExistingFormElement(item.mandatory)
					? null
					: { value: item.mandatory, label: item.mandatory ? 'Yes' : 'No' },
				required: true,
			},
			predetermined_list: {
				value: isExistingFormElement(item.predetermined_list) ? '' : item.predetermined_list,
				required: false,
			},
			contexts: {
				value: isExistingFormElement(item.contexts) ? '' : item.contexts,
				required: false,
			},
			attribute_description: {
				value: isExistingFormElement(item.attribute_description) ? '' : item.attribute_description,
				required: false,
			},
			depends_on_conditional: {
				value: !isExistingFormElement(item.depends_on_conditional) ? item.depends_on_conditional : null,
				required: false,
			},
			number_mask: {
				value: !isExistingFormElement(item.number_mask) ? item.number_mask : null,
				required: false,
			},
			attribute_unit: {
				value: isExistingFormElement(item.attribute_unit) ? '' : item.attribute_unit,
				required: false,
			},
			owner_name: {
				value: isExistingFormElement(item.owner_name) ? '' : item.owner_name,
				required: false,
			},
			create_datetime: {
				value: !isExistingFormElement(item.create_datetime)
					? moment(item.create_datetime).format(dateFormat.engHours)
					: '',
				required: false,
			},
			template_value: {
				value: isExistingFormElement(item.template_value) ? '' : item.template_value,
				required: false,
			},
		}
	})

export const FormObject: any = (id: number, sequence: number) => ({
	id,
	attribute_name: {
		value: '',
		required: true,
	},
	sequence: {
		value: sequence,
		required: true,
	},
	depends_on: {
		value: '',
		required: false,
	},
	display_name: {
		value: '',
		required: false,
	},
	attribute_type: {
		value: '',
		required: true,
	},
	mandatory: {
		value: { value: false, label: 'No' },
		required: true,
	},
	predetermined_list: {
		value: '',
		required: false,
	},
	contexts: {
		value: '',
		required: false,
	},
	attribute_description: {
		value: '',
		required: false,
	},
	number_mask: {
		value: '',
		required: false,
	},
	attribute_unit: {
		value: '',
		required: false,
	},
	owner_name: {
		value: '',
		required: false,
	},
	create_datetime: {
		value: '',
		required: false,
	},
})

export const newComponentElement: any = ({
	is_new,
	sequence,
	id,
}: {
	is_new: boolean
	sequence: number
	id: number | string
}) => ({
	is_new,
	isEditing: false,
	id,
	dependsOn: [],
	displayName: '',
	sequence,
	componentName: '',
	contexts: '',
	mandatory: { value: false, label: 'No' },
	attributes: [],
	oldAttributes: [],
	isOpen: true,
	isChecked: false,
	dependsOnOptions: [],
	sequences: [],
})

export const componentToSave: any = (attributes: Array<IFormDataObject>) =>
	attributes.map((att) => {
		return {
			attribute_name: getValue(att.attribute_name),
			sequence: getValue(att.sequence),
			display_name: getValue(att.display_name),
			attribute_type: getValue(att.attribute_type),
			mandatory: att.mandatory.value.value,
			predetermined_list: getValue(att.predetermined_list),
			contexts: getValue(att.contexts),
			attribute_description: getValue(att.attribute_description),
			attribute_unit: getValue(att.attribute_unit),
			depends_on: getValue(att.depends_on),
			number_mask: att.number_mask?.value || null,
			depends_on_conditional: att.depends_on_conditional || null,
		}
	})

const arrayValue = (attribute: any) =>
	Array.isArray(attribute) ? (attribute && attribute.length > 0 ? attribute.toString() : '') : attribute || ''
const getValue = (attribute: any) => {
	return isNumeric(attribute.value) || isString(attribute.value) || Array.isArray(attribute.value)
		? arrayValue(attribute.value)
		: attribute.value.value
		? arrayValue(attribute.value.value)
		: isEmpty(attribute.value)
		? ''
		: attribute.value
}

export const getUpdatedField = (attributes: any, oldAttributes: any) => {
	return attributes.map((attribute: any) => {
		const old = oldAttributes.filter((oldElement: any) => oldElement.id === attribute.id)[0]
		const element = {}
		for (const attributeKey in attribute) {
			if (!lodash.isEqual(attribute[attributeKey], old[attributeKey])) {
				if (attributeKey === 'mandatory') {
					// @ts-ignore
					element[attributeKey] = attribute[attributeKey].value.value
				} else {
					// @ts-ignore
					element[attributeKey] = getValue(attribute[attributeKey])
				}
			}
		}
		return {
			id: attribute.id,
			...element,
		}
	})
}
