import { axiosInstance } from 'src/services/axiosInstance'
import {
	GET_DISCIPLINES,
	GET_HISTORY,
	REACT_ACTIONS,
	REACT_CREATE_ASSET,
	REACT_GET_TIDP_DATA,
	REACT_SEARCH,
} from '../constants'

export const PostQuery = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_ACTIONS, body)
	return data
}

export const GetHistory = async (contract: string, tagCode: string) => {
	const { data } = await axiosInstance.get(`${GET_HISTORY}?contract=${contract}&tag_code=${tagCode}`)
	return data
}

export const GetDisciplines = async (contract: string) => {
	const { data } = await axiosInstance.get(`${GET_DISCIPLINES}?contract=${contract}&class_code=DIDP`)
	return data
}

export const getTIDPData = async (tag: string) => {
	const { data } = await axiosInstance.get(`${REACT_GET_TIDP_DATA}${tag}`)
	return data
}

export const getAssetByTagCode = async (tagCode: string, contract: string, moduleName: string) => {
	const { data } = await axiosInstance.post(REACT_SEARCH, {
		contract: contract,
		tag_code: { fieldValue: [tagCode] },
		relationsLevel: 3,
		module_name: moduleName,
		allowedAssetClassCode: ["TIDP", "DIDP", "Comment", "Work Package", "Document", "Technical Lifecycles"]
	})
	return data
}

export const saveAsset = async (request: {}) => {
	const { data } = await axiosInstance.post(REACT_CREATE_ASSET, request)
	return data
}

export const saveNewAssetComment = async (request: {}) => {
	const { data } = await axiosInstance.post('/assets/Comment/create/', request)
	return data
}
