export const GET_LIST_OF_USERS = 'usersManagement/GET_LIST_OF_USERS'
export const SET_LIST_OF_USERS = 'usersManagement/SET_LIST_OF_USERS'
export const SET_IS_LOADING_LIST = 'usersManagement/SET_IS_LOADING_LIST'
export const SET_AVAILABLE_ROLES_LIST = 'usersManagement/SET_AVAILABLE_ROLES_LIST'

export const HANDLE_POPUP_DATA = 'usersManagement/HANDLE_POPUP_DATA'

export const GET_ONE_USER = 'usersManagement/GET_ONE_USER'
export const SET_IS_USER_DETAILS_LOADING = 'usersManagement/SET_IS_USER_DETAILS_LOADING'
export const SET_USER_DETAILS = 'usersManagement/SET_USER_DETAILS'
export const SET_TOTAL_COUNTER = 'usersManagement/SET_TOTAL_COUNTER'
export const CLEAR_USER_DETAILS = 'usersManagement/CLEAR_USER_DETAILS'

export const CHANGE_PAGE = 'usersManagement/CHANGE_PAGE'

export const RESET_PASSWORD = 'usersManagement/RESET_PASSWORD'
export const HANDLE_RESETTING_PASSWORD = 'usersManagement/HANDLE_RESETTING_PASSWORD'

export const HANDLE_FILTERS_ITEMS = 'usersManagement/HANDLE_FILTERS_ITEMS'
export const HANDLE_IS_FILTERING = 'usersManagement/HANDLE_IS_FILTERING'

export const ADD_NEW_USER = 'usersManagement/ADD_NEW_USER'
export const HANDLE_IS_ADDING_NEW_USER = 'usersManagement/HANDLE_IS_ADDING_NEW_USER'

export const HANDLE_IS_EDITING = 'usersManagement/HANDLE_IS_EDITING'
export const EDIT_USER = 'usersManagement/EDIT_USER'

export const HANDLE_IS_DELETING = 'usersManagement/HANDLE_IS_DELETING'
export const DELETE_USER = 'usersManagement/DELETE_USER'
