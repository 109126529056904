import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import axiosRetry from 'axios-retry'
import { REFRESH_TOKEN_ATTEMPTED_STORAGE_KEY, msalInstance } from 'src/utils/msalInstance'
import { apiUrl } from './constants'

export const axiosInstance = axios.create({
	baseURL: apiUrl,
})

axiosInstance.interceptors.request.use(
	async (config: any) => {
		const token = await msalInstance.getAimsToken()

		return {
			...config,
			headers: {
				...config.headers,
				...(token ? { Authorization: `Token ${token}` } : {}),
			},
		}
	},
	(error) => {
		return Promise.reject(error)
	},
)

axiosRetry(axiosInstance, {
	retries: 3,
	retryDelay: (retryCount) => {
		return retryCount * 2000
	},
})

createAuthRefreshInterceptor(
	axiosInstance,
	async () => {
		await msalInstance.refresh()
	},
	{
		shouldRefresh: (error) => {
			const responseData: any = error.response?.data
			return (
				!sessionStorage.getItem(REFRESH_TOKEN_ATTEMPTED_STORAGE_KEY) &&
				!!error.response &&
				error.response.status === 401 &&
				!responseData?.detail?.includes('There is no such user within this domain') &&
				!responseData?.detail?.includes('Missing roles for user for customer with this domain')
			)
		},
	},
)
