/* eslint-disable react/display-name */
/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import Popup from '../../Organisms/Popup'
import styled, { css } from 'styled-components'
import { colors } from '../../../theme/colors'
import { IEditPopup, IUsersPaginationData } from '../../../models/groupsManagement/groupsManagement'
import { MyColumn, MyTable, StyledTdContainer } from '../../Molecules/MyTable'
import { CellProps, Renderer } from 'react-table'
import { groupsManagementActions } from '../../../features/groupsManagement'
import SelectOverview from '../../Molecules/SelectOverview'
import Button from '../../Molecules/Button'
import { CheckBox } from '../../Atoms/Checkbox'
import SelectList from '../../Molecules/SelectList'
import { usersActions } from '../../../features/users'
import Input from '../../Atoms/Input'
import Pagination from '../../Molecules/Pagination'
import SpinnerComponent from '../../Atoms/Loader'
import { SpinnerOverview } from './index'
import PrivilegeHandler from './privilegeHandler'
import { connect } from 'react-redux'
import { IReduxState } from '../../../models/redux/redux'
import { Roles } from '../../../features/authorization/reducer'
import doneIcon from '../../../assets/icons/done.svg'

const Tabs = styled.div<{ isSelected: boolean }>`
	cursor: pointer;
	${({ isSelected }) =>
		isSelected &&
		css`
			font-weight: 500;
			color: ${colors.main100};
			border-bottom: 1px solid ${colors.main100};
		`}
`
const PaginationWrapper = styled.div`
	margin: 15px;
`

const PopupContainer = styled.div`
	padding: 15px;
	min-width: 60vw;

	.buttonsWrapper {
		margin: 20px 0;
		display: flex;
		width: 100%;
		justify-content: space-between;

		.left {
			display: flex;
		}

		input {
			max-width: 200px;
			margin-right: 20px;
		}
	}

	.privilegesContainer {
		z-index: -1;
		max-height: 70vh;
		min-height: 10vh;
		overflow: auto;

		&::-webkit-scrollbar-thumb {
			display: none;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.headerTabs {
		justify-content: space-around;
		display: flex;
		margin-bottom: 15px;
	}

	.headerTabsProjectOrg {
		justify-content: space-around;
		display: flex;
		margin-bottom: 15px;
	}

	.doneImage {
		width: 16px;
		margin-right: 80%;
	}

	.buttonGroup {
		display: flex;
		justify-content: space-between;
		margin: 12px 0;

		.left {
			display: flex;

			button:nth-child(2) {
				margin: 0 5px;
			}
		}
	}

	.groupUsers {
		table {
			tbody {
				flex-direction: column;
				display: flex;
				max-height: 300px;
				overflow-y: auto;

				&::-webkit-scrollbar-thumb {
					display: none;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}
	}

	.privilegesWrapper {
		margin: 25px 0;

		.selectHeader {
			margin: 12px 0;
			font-weight: bold;
		}

		.headerSelects {
			margin-left: 40px;
		}

		.headerMain {
			font-weight: bold;
			font-size: 18px;
		}
	}

	.selectUser {
		margin: 10px 0;
	}
`

interface IUsersList {
	name: string
	isSelected: boolean
}

const EditGroupPopup = ({
	editPopup,
	closePopup,
	handleEditGroup,
	getUsersForGroup,
	isLoadingUsersForGroup,
	listOfUsersInGroup,
	isRemovingUsersFromGroup,
	onRemoveUsers,
	isLoadingUsers,
	users,
	getListOfUsersByRole,
	addNewUserToGroup,
	isAddingNewUsersToGroup,
	usersPaginationData,
	searchForUsersInGroup,
	handleCheckedUser,
	checkUsersDuplicates,
	usersWithoutDuplicates,
	getPrivilegesTypes,
	isPrivilegesTypesLoading,
	getPrivilegesSource,
	savePrivilege,
	isSavingPrivileges,
	createPrivilegeParent,
	newPrivileges,
	roles,
}: {
	users: any
	roles: string[]
	isLoadingUsers: boolean
	getListOfUsersByRole: typeof usersActions.getListOfUsersByRole

	editPopup: IEditPopup
	isLoadingUsersForGroup: boolean
	isRemovingUsersFromGroup: boolean
	isAddingNewUsersToGroup: boolean
	newPrivileges: any
	isSavingPrivileges: boolean
	usersWithoutDuplicates: Array<{
		value: string
		label: string
	}>
	usersPaginationData: IUsersPaginationData
	listOfUsersInGroup: Array<{ isSelected: boolean; user: string }>
	closePopup: () => void
	onRemoveUsers: (groupTag: string | number) => void
	handleEditGroup: typeof groupsManagementActions.handleEditGroup
	getUsersForGroup: typeof groupsManagementActions.getUsersForGroup
	addNewUserToGroup: typeof groupsManagementActions.addNewUserToGroup
	searchForUsersInGroup: typeof groupsManagementActions.searchForUsersInGroup
	handleCheckedUser: typeof groupsManagementActions.handleCheckedUser
	checkUsersDuplicates: typeof groupsManagementActions.checkUsersDuplicates

	getPrivilegesTypes: typeof groupsManagementActions.getPrivilegesTypes
	isPrivilegesTypesLoading: boolean

	getPrivilegesSource: typeof groupsManagementActions.getPrivilegesSource

	savePrivilege: typeof groupsManagementActions.savePrivilege

	createPrivilegeParent: typeof groupsManagementActions.createPrivilegeParent
}) => {
	const [selectedNewUsers, handleSelectedNewUsers] = useState<any>([])
	const [isAddingNewUser, handleAddNewUser] = useState(false)
	const [autoFocus, handleAutofocus] = useState('')
	const [inputNewPrivilege, handleInputNewPrivilege] = useState('')

	const handleTab = (name: string) => {
		handleEditGroup({
			...editPopup,
			tabs: editPopup.tabs.map((item) => ({ ...item, isSelected: item.name === name })),
		})
	}

	if (editPopup.tag === -1) {
		closePopup()
	}

	const setUsers = () => {
		getPrivilegesSource(editPopup.tag)
		checkUsersDuplicates(users)
		if (roles.some((role) => role === Roles['AIMS:roles.Sysadmin'])) {
			getPrivilegesTypes()
		}
	}

	const checkUsers = useCallback(setUsers, [users])

	useEffect(() => {
		checkUsers()
	}, [checkUsers])

	const cellRenderer =
		(headerId: string): Renderer<CellProps<IUsersList>> =>
		({ value, row }) => {
			if (headerId === 'index') {
				return <StyledTdContainer center>{usersPaginationData.page * 20 + row.index + 1}</StyledTdContainer>
			}
			if (headerId === 'check') {
				return (
					<StyledTdContainer center background="transparent">
						<CheckBox
							handle={() => {
								handleCheckedUser(row.original.name)
							}}
							isChecked={row.original.isSelected}
						/>
					</StyledTdContainer>
				)
			}
			return <SelectOverview isDisabled value={[{ value, label: value }]} backgroundColor={colors.main5} fullWidth />
		}

	const groupsColumns: any = () =>
		[
			{
				Header: '',
				accessor: 'index',
				options: {
					noHeaderTdContainer: true,
				},
				maxWidth: 8,
				Cell: cellRenderer('index'),
			},
			{
				Header: () => {
					return (
						<Input
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus={autoFocus === 'userName'}
							placeholder="User Name"
							value={usersPaginationData.query}
							backgroundColor={colors.white}
							onChange={(value: any) => {
								handleAutofocus('userName')
								searchForUsersInGroup({ ...usersPaginationData, query: value })
							}}
						/>
					)
				},
				accessor: 'name',
				Cell: cellRenderer('name'),
				maxWidth: 30,
			},
			{
				Header: '',
				accessor: 'check',
				Cell: cellRenderer('check'),
				maxWidth: 5,
				options: {
					noHeaderTdContainer: true,
				},
			},
		].map((column) => ({ ...column, noTdContainer: true, disableSortBy: true } as MyColumn<IUsersList>))

	const getGroupUsers = () => {
		getUsersForGroup(editPopup.tag)
	}

	const fetchUsersList = useCallback(getGroupUsers, [])

	useEffect(() => {
		fetchUsersList()
	}, [fetchUsersList])

	const assignTableData = () => {
		return listOfUsersInGroup.map((i) => ({
			name: i.user,
			isSelected: i.isSelected,
		}))
	}

	const removeUsers = () => {
		onRemoveUsers(editPopup.tag)
	}

	const handleNewUser = (user: any) => {
		handleAutofocus('multiselect')
		handleSelectedNewUsers(user)
	}

	const handleUsers = () => {
		if (selectedNewUsers.length > 0) {
			addNewUserToGroup({
				groupTag: editPopup.tag,
				users: selectedNewUsers.map((item: { value: string; label: string }) => item.value),
			})
			handleSelectedNewUsers([])
			handleAddNewUser(false)
		} else {
			handleAddNewUser(true)
		}
	}

	const checkIsExist = (isAdd: boolean, priv: any) => {
		return priv[isAdd ? 'additions' : 'substractions'].map((el: any) => {
			if (isAdd && priv.additions.length > 0) {
				return [...checkIsExist(true, el), el.value.value, ...checkIsExist(false, el)].flat()
			}
			if (!isAdd && priv.substractions.length > 0) {
				return [...checkIsExist(false, el), el.value.value, ...checkIsExist(true, el)].flat()
			}
			return el
		})
	}

	const validator =
		Object.keys(newPrivileges).length > 0
			? [
					newPrivileges.privilege.value.value,
					...checkIsExist(true, newPrivileges.privilege),
					...checkIsExist(false, newPrivileges.privilege),
			  ].flat()
			: []
	const canSaveValidate = validator.length > 0 ? validator.filter((el: any) => el === undefined).length === 0 : false
	const createParent = () => {
		createPrivilegeParent({ name: inputNewPrivilege })
		handleInputNewPrivilege('')
	}

	return (
		<Popup
			onClick={closePopup}
			isCloseButton
			children={
				<PopupContainer>
					{editPopup?.project_org ? (
						<div className="headerTabsProjectOrg">
							Project Organisation
							<img className="doneImage" alt="done_icon" src={doneIcon}></img>
						</div>
					) : null}

					<div className="headerTabs">
						{editPopup.tabs.map((el) => (
							<Tabs onClick={() => handleTab(el.name)} key={`Tab_${el.name}`} isSelected={el.isSelected}>
								{el.name}
							</Tabs>
						))}
					</div>
					{editPopup.tabs.filter((item) => item.name === 'Privileges' && item.isSelected).length > 0 && (
						<div className="groupUsers">
							{isPrivilegesTypesLoading ? (
								<SpinnerOverview>
									<SpinnerComponent size={100} />
								</SpinnerOverview>
							) : (
								<>
									<div className="privilegesContainer">
										<PrivilegeHandler editPopup={editPopup} />
									</div>
								</>
							)}

							<div className="buttonsWrapper">
								{Object.keys(newPrivileges).length > 0 ? (
									<div>
										<Button
											textColor={colors.white}
											color={colors.main100}
											loaderColor={colors.white}
											isLoading={isSavingPrivileges}
											isDisabled={
												isSavingPrivileges ||
												!canSaveValidate ||
												!roles.some((role) => role === Roles['AIMS:roles.Sysadmin'])
											}
											onClick={() => savePrivilege({ tag: editPopup.tag })}
											text="Save new privilege"
										/>
									</div>
								) : (
									<div className="left">
										{roles.some((role) => role === Roles['AIMS:roles.Sysadmin']) && (
											<>
												<Input
													placeholder="New Privilege Name"
													value={inputNewPrivilege}
													onChange={(element: any) => handleInputNewPrivilege(element)}
												/>
												<Button
													isDisabled={inputNewPrivilege.length === 0 || isSavingPrivileges}
													textColor={colors.white}
													color={colors.main100}
													onClick={createParent}
													text="Add new privilege"
												/>
											</>
										)}
									</div>
								)}
							</div>
						</div>
					)}

					{editPopup.tabs.filter((item) => item.name === 'Users' && item.isSelected).length > 0 && (
						<div className="groupUsers">
							<MyTable
								isLoading={isLoadingUsersForGroup}
								fullWidth
								columns={groupsColumns()}
								data={assignTableData()}
							/>
							{!isLoadingUsersForGroup && usersPaginationData.pageCount > 1 && (
								<PaginationWrapper>
									<Pagination
										page={usersPaginationData.page}
										onPageChange={(page) => {
											searchForUsersInGroup({ ...usersPaginationData, page })
										}}
										pageCount={usersPaginationData.pageCount}
										initialPage={0}
									/>
								</PaginationWrapper>
							)}

							{isAddingNewUser && (
								<div className="selectUser">
									<SelectList
										isLoading={isLoadingUsers}
										onInputChange={(text) => {
											handleAutofocus('multiselect')
											getListOfUsersByRole({ role: '', text })
										}}
										border={`1px solid ${colors.lightGreyE5}`}
										isMulti
										value={selectedNewUsers}
										padding="0 10px"
										options={usersWithoutDuplicates}
										onChange={handleNewUser}
										isDisabled={isAddingNewUsersToGroup}
									/>
								</div>
							)}

							<div className="buttonGroup">
								<div className="left">
									{!isLoadingUsersForGroup && (
										<Button
											onClick={handleUsers}
											textColor={colors.white}
											color={colors.main100}
											text={selectedNewUsers.length > 0 ? 'Save New Users' : 'Add Users'}
											isDisabled={isAddingNewUsersToGroup}
										/>
									)}
									{!isLoadingUsersForGroup && selectedNewUsers.length > 0 && (
										<Button
											onClick={() => handleSelectedNewUsers([])}
											textColor={colors.white}
											color={colors.danger100}
											text="Cancel Adding New Users"
										/>
									)}
								</div>
								{!isLoadingUsersForGroup && usersPaginationData.selectedElements > 0 && (
									<Button
										isLoading={isRemovingUsersFromGroup}
										isDisabled={isRemovingUsersFromGroup}
										onClick={removeUsers}
										textColor={colors.white}
										color={colors.danger100}
										text="Remove Users"
									/>
								)}
							</div>
						</div>
					)}
				</PopupContainer>
			}
		/>
	)
}
export default connect(
	(state: IReduxState) => ({
		newPrivileges: state.groupsManagement.newPrivileges,
		roles: state.authorization.roles,
	}),
	{
		createPrivilegeParent: groupsManagementActions.createPrivilegeParent,
	},
)(EditGroupPopup)
