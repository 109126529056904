/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, { memo } from 'react'
import Select, { components } from 'react-select'
import { colors } from '../../../theme/colors'
import ListItem from '../../Atoms/ListItem'

const customStyles = (elements: {
	backgroundColor?: string
	height?: string
	isError?: boolean
	fullWidth?: boolean
	minWidth?: string
	maxWidth?: string
	isDisabled?: boolean
}) => {
	return {
		control: (provided: any) => {
			return {
				...provided,
				background: elements.backgroundColor ? elements.backgroundColor : colors.main5,
				height: elements.height ? elements.height : '',
				borderRadius: '8px',
				border: elements.isError ? `1px solid ${colors.danger100}` : 'none',
				boxShadow: '0',
				minHeight: '32px',
				cursor: 'pointer',
				minWidth: elements.minWidth ? elements.minWidth : '',
				maxWidth: elements.maxWidth ? elements.maxWidth : '',
			}
		},
		valueContainer: (provided: any, state: any) => ({
			...provided,
			padding: state.selectProps.valuePadding ? state.selectProps.valuePadding : 0,
			display: 'flex',
			height: '100%',
			maxWidth: elements.maxWidth ? elements.maxWidth : '',
			justifyContent: state.selectProps.isNotCentered ? 'flex-start' : 'center',
			cursor: state.isDisabled ? 'not-allowed !important' : '',
		}),

		menu: (provided: any) => ({
			...provided,
			padding: '10px',
			marginTop: '6px',
			minWidth: elements.minWidth ? elements.minWidth : '200px',
			maxWidth: elements.maxWidth ? elements.maxWidth : '',
			background: colors.white,
			boxShadow: `0px 4px 16px ${colors.black15}`,
			borderRadius: '8px',
		}),
		menuList: (provided: any) => ({
			...provided,
			'&::-webkit-scrollbar-thumb': {
				display: 'none',
			},
			'&::-webkit-scrollbar': {
				display: 'none',
			},
		}),
		placeholder: (provided: any) => ({
			...provided,
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '14px',
			color: colors.greyB0,
		}),
		multiValue: (styles: any, { data }: any) => {
			return {
				...styles,
				backgroundColor: data.backgroundColor ? data.backgroundColor : 'hsl(0,0%,90%)',
				svg: {
					display: data.isClearDisable ? 'none' : 'block',
				},
			}
		},
		singleValue: (provided: any, state: any) => ({
			...provided,
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '14px',
			span: {
				color: state.isDisabled ? colors.darkGrey : colors.black,
				fontWeight: 500,
			},
		}),
		option: (styles: any, state: any) => ({
			...styles,
			padding: '4px 8px',
			backgroundColor: state.isSelected ? colors.lightGreyF2 : null,
			color: state.isSelected && colors.black,
			cursor: 'pointer',
		}),
		container: (styles: any) => {
			delete styles.pointerEvents
			return {
				...styles,
				...(elements.fullWidth ? { width: '100%' } : {}),
			}
		},
	}
}

const SingleValue = (props: any) => {
	return (
		<components.SingleValue {...props}>
			<ListItem text={props.data.label} fontWeight="bold" selected />
		</components.SingleValue>
	)
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Option = (props: any) => {
	return (
		<components.Option {...props}>
			<ListItem text={props.label} fontWeight="bold" small />
		</components.Option>
	)
}

function SelectOverview({
	options,
	isDisabled,
	value,
	onChange,
	backgroundColor,
	isError,
	fullWidth,
	isMulti,
	onInputChange,
	inputValue,
	isSearchable,
	closeMenuOnSelect,
	minWidth,
	maxWidth,
	height,
	menuPlacement,
	placeholder,
	isNotCentered,
	isLoading,
	valuePadding,
	autoFocus,
	openMenuOnFocus,
	menuIsOpen,
	defaultInputValue,
	maxMenuHeight,
	onFocus,
}: {
	options?: any
	isDisabled?: boolean
	value: any
	onChange?: any
	backgroundColor?: string
	height?: string
	isError?: boolean
	fullWidth?: boolean
	inputValue?: string
	onInputChange?: (input: string) => void
	onFocus?: () => void
	isMulti?: boolean
	isSearchable?: boolean
	closeMenuOnSelect?: boolean
	maxWidth?: string
	minWidth?: string
	placeholder?: string
	isNotCentered?: boolean
	valuePadding?: string
	isLoading?: boolean
	autoFocus?: boolean
	defaultInputValue?: string
	openMenuOnFocus?: boolean
	maxMenuHeight?: number
	menuIsOpen?: boolean
	menuPlacement?: 'auto' | 'bottom' | 'top'
}) {
	const onChangeInputElement = (text: string, { action }: any) => {
		if (action === 'input-change' && onInputChange) {
			onInputChange(text)
		}
	}
	return (
		<Select
			placeholder={placeholder}
			isSearchable={isSearchable}
			isMulti={isMulti}
			isNotCentered={isNotCentered}
			valuePadding={valuePadding}
			menuIsOpen={menuIsOpen}
			defaultInputValue={defaultInputValue}
			openMenuOnFocus={openMenuOnFocus}
			onInputChange={onChangeInputElement}
			inputValue={inputValue}
			isLoading={isLoading}
			closeMenuOnSelect={closeMenuOnSelect}
			options={options}
			menuPlacement={menuPlacement || 'auto'}
			isDisabled={isDisabled}
			onSelectResetsInput={false}
			onBlurResetsInput={false}
			onCloseResetsInput={false}
			styles={customStyles({ backgroundColor, isError, fullWidth, minWidth, maxWidth, height, isDisabled })}
			onChange={onChange}
			value={value}
			onFocus={onFocus}
			maxMenuHeight={maxMenuHeight}
			autoFocus={autoFocus}
			components={{
				IndicatorSeparator: () => null,
				SingleValue,
				DropdownIndicator: () => null,
				Option,
			}}
		/>
	)
}

export default memo(SelectOverview)
