/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/display-name */
/* eslint-disable react/require-default-props */
import FileUploadIcon from '@mui/icons-material/FileUpload'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import { Box, Dialog, IconButton, Input as MuiInput } from '@mui/material'
import { uniqBy } from 'lodash'
import { Fragment, memo, useCallback, useMemo, useState } from 'react'
import { CellProps, Renderer } from 'react-table'
import Input from 'src/components/Atoms/Input'
import { DependsOnConditionalRenderer } from 'src/components/Organisms/Forms/DependsOnConditionalRenderer'
import { NumberMaskRenderer } from 'src/components/Organisms/Forms/NumberMaskRenderer'
import { AttributeType } from 'src/facade/forms/formsService.types'
import { handleAutoFocus } from 'src/features/formsHandler/actions'
import styled from 'styled-components'
import plus from '../../../assets/icons/plus.svg'
import { IFormDataObject, ISequences } from '../../../models/formsHandler/formsHandler'
import { colors } from '../../../theme/colors'
import { useAppDispatch, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import TextArea from '../../Atoms/TextArea'
import LGButton from '../../Molecules/LGButton'
import { MyColumn, MyTable } from '../../Molecules/MyTable'
import SelectOverview from '../../Molecules/SelectOverview'
import Tooltip from '../../Molecules/Tooltip'

const MainWrapper = styled.div``
const TooltipText = styled.div`
	padding: 5px;
	display: flex;
	max-width: 200px;
	overflow-wrap: break-word;
	word-break: break-word;
	justify-content: center;
	flex-wrap: wrap;
`
const BottomButtons = styled.div`
	margin: 15px 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
`

interface IForm {
	isLoading?: boolean
	isDisabled: boolean
	focusStart: string
	focusName: string
	isDeleteColumn: boolean
	onClickDelete: (id: any) => void
	attributesSequence: ISequences

	showErrors: boolean

	isAddRow: boolean
	idAddRowDisabled: boolean
	addNewRow: () => void

	dataForForm: Array<IFormDataObject>
	dependsOnOptions: Array<{ value: string | number; label: string }>
	onEditRow: (item: { id: string | number; keyName: string; value: any }) => void
}

const trimText = (el: any) => (el === ' ' ? '' : el)

const FormTable = ({
	showErrors,
	attributesSequence,
	focusStart,
	isLoading,
	isAddRow,
	isDisabled,
	onClickDelete,
	focusName,
	dataForForm,
	dependsOnOptions,
	onEditRow,
	addNewRow,
	idAddRowDisabled,
	isDeleteColumn,
}: IForm) => {
	const dispatch = useAppDispatch()
	const { attributesTypesForComponent, FormComponents } = useAppSelectorDeprecated((state) => ({
		attributesTypesForComponent: state.formsHandler.attributesTypesForComponent,
		FormAttributes: state.formsHandler.FormAttributes,
		FormComponents: state.formsHandler.FormComponents,
	}))
	const [isShowingDetails, handleShowingDetails] = useState(false)

	const textCellRenderer = useCallback(
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
			({ column, value, row }) => {
				const placeholder: any = column.Header
				return isDisabled && value.value.length > 0 ? (
					<Tooltip placement="right" hideArrow tooltip={<TooltipText>{value.value}</TooltipText>}>
						<TextArea
							isCentered
							isDisabled={isDisabled}
							// autoFocus={focusName === `${focusStart}${headerId}${row.original.id}`}
							// onFocus={() => dispatch(handleAutoFocus(`${focusStart}${headerId}${row.original.id}`))}
							placeholder={placeholder}
							height="50px !important"
							color={colors.greyBD}
							padding="5px"
							placeholderFontsize="12px"
							minHeight="50px"
							backgroundColor={colors.main5}
							// onChange={(el) => onEditRow({ id: row.original.id, keyName: headerId, value: trimText(el) })}
							// value={value.value}
							onBlur={(el) => onEditRow({ id: row.original.id, keyName: headerId, value: trimText(el.target.value) })}
							defaultValue={value.value}
						/>
					</Tooltip>
				) : (
					<TextArea
						isCentered
						// autoFocus={focusName === `${focusStart}${headerId}${row.original.id}`}
						isDisabled={isDisabled}
						// onFocus={() => dispatch(handleAutoFocus(`${focusStart}${headerId}${row.original.id}`))}
						placeholder={placeholder}
						color={colors.greyBD}
						placeholderFontsize="12px"
						height="50px !important"
						minHeight="50px"
						padding="5px"
						backgroundColor={colors.main5}
						border={
							showErrors && !value.value && (headerId === 'attribute_name' || headerId === 'display_name')
								? '1px solid red'
								: ''
						}
						onBlur={(el) => onEditRow({ id: row.original.id, keyName: headerId, value: trimText(el.target.value) })}
						defaultValue={value.value}
					/>
				)
			},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isDisabled],
	)

	const mandatoryRenderer = useCallback(
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
			({ value, row }) => {
				return (
					<SelectOverview
						height="50px"
						isDisabled={isDisabled}
						autoFocus={focusName === `${focusStart}${headerId}${row.original.id}`}
						options={[true, false].map((item) => ({ value: item, label: item ? 'Yes' : 'No' }))}
						value={value.value}
						placeholder="Is Mandatory"
						fullWidth
						onChange={(item: any) => onEditRow({ id: row.original.id, keyName: headerId, value: item })}
					/>
				)
			},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[focusName, isDisabled],
	)

	const attributesTypesRenderer = useCallback(
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
			({ value, row }) => {
				return (
					<SelectOverview
						height="50px"
						isError={showErrors && !value.value.value}
						isDisabled={isDisabled}
						options={attributesTypesForComponent}
						autoFocus={focusName === `${focusStart}${headerId}${row.original.id}`}
						value={value.value}
						placeholder="Attribute Type"
						fullWidth
						isMulti={false}
						onChange={(item: any) => onEditRow({ id: row.original.id, keyName: headerId, value: item })}
					/>
				)
			},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[focusName, attributesTypesForComponent, isDisabled],
	)

	const dependsOnConditionalRenderer = useCallback(
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
			({ ...rendererProps }) => {
				return (
					<DependsOnConditionalRenderer
						{...rendererProps}
						headerId={headerId}
						isDisabled={isDisabled}
						components={FormComponents.attributes}
						onEditRow={onEditRow}
					/>
				)
			},
		[FormComponents.attributes, isDisabled, onEditRow],
	)

	const dependsOnRenderer = useCallback(
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
			({ value, row }) => {
				return (
					<SelectOverview
						height="50px"
						isDisabled={isDisabled}
						options={uniqBy(
							dependsOnOptions.filter((item) => item.value !== row.original.attribute_name.value && item.value !== ''),
							'value',
						)}
						onFocus={() => dispatch(handleAutoFocus(`${focusStart}${headerId}${row.original.id}`))}
						autoFocus={focusName === `${focusStart}${headerId}${row.original.id}`}
						value={value.value}
						placeholder="Depends On"
						fullWidth
						isMulti={false}
						onChange={(item: any) => onEditRow({ id: row.original.id, keyName: headerId, value: item })}
					/>
				)
			},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dependsOnOptions, focusName, isDisabled],
	)

	const ownerAndDateRenderer = useCallback(
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
			({ value }) => {
				const name = headerId.replace('_', ' ').charAt(0).toUpperCase() + headerId.replace('_', ' ').slice(1)
				return (
					<TextArea
						placeholder={name}
						isCentered
						backgroundColor={colors.main25}
						height="50px !important"
						isDisabled
						color={colors.greyBD}
						padding="5px"
						minHeight="50px"
						placeholderFontsize="12px"
						value={value.value}
					/>
				)
			},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const sequenceRenderer = useCallback(
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
			({ value, row }) => {
				return (
					<Input
						autoFocus={focusName === `${focusStart}${headerId}${row.original.id}`}
						isDisabled={isDisabled}
						type="number"
						min={1}
						border={
							attributesSequence.filter((el) => el.sequence === row.original.sequence.value && el.sequence !== '')
								.length > 1 ||
							(!isDisabled && row.original.sequence.value === '')
								? '1px solid red'
								: ''
						}
						placeholder="Sequence"
						height="50px !important"
						onChange={(el) => onEditRow({ id: row.original.id, keyName: headerId, value: Number(el) })}
						value={value.value}
					/>
				)
			},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[attributesSequence, focusName],
	)

	const actionsCellRenderer = useCallback(
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
			({ value, row }) => {
				// eslint-disable-next-line react-hooks/rules-of-hooks
				const [isPreviewOpen, setPreviewOpen] = useState<boolean>(false)

				return row.values.attribute_type?.value?.value === AttributeType.MarkupImage ||
					row.values.attribute_type?.value?.value === AttributeType.ReadonlyImage ? (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
						{value?.value && (
							<Fragment>
								<IconButton
									onClick={() => {
										setPreviewOpen(true)
									}}
								>
									<ImageSearchIcon />
								</IconButton>
								<Dialog
									open={isPreviewOpen}
									onClose={() => setPreviewOpen(false)}
									style={{ maxWidth: '100%', maxHeight: '100%' }}
								>
									<img
										style={{ width: 'auto', height: '100%' }}
										src={value.value instanceof File ? URL.createObjectURL(value.value) : value.value}
										alt="image-preview"
									/>
								</Dialog>
							</Fragment>
						)}
						{!isDisabled && (
							<label htmlFor={`image-attribute-button-file__${row.original.id}`}>
								<MuiInput
									// accept="image/*"
									id={`image-attribute-button-file__${row.original.id}`}
									type="file"
									onChange={(event: any) => {
										onEditRow({ id: row.original.id, keyName: headerId, value: event.target.files?.[0] })
									}}
								/>
								<IconButton component="span" color="primary">
									<FileUploadIcon />
								</IconButton>
							</label>
						)}
					</Box>
				) : null
			},
		[isDisabled, onEditRow],
	)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const details = [
		{
			Header: 'Unit',
			accessor: 'attribute_unit',
			Cell: textCellRenderer('attribute_unit'),
		},
		{
			Header: 'Number Mask',
			accessor: 'number_mask',
			Cell: ({ ...rendererProps }: CellProps<IFormDataObject>) => {
				return (
					<NumberMaskRenderer {...rendererProps} headerId="number_mask" isDisabled={isDisabled} onEditRow={onEditRow} />
				)
			},
		},
		{
			Header: 'Description',
			accessor: 'attribute_description',
			Cell: textCellRenderer('attribute_description'),
		},
		{
			Header: 'Created By',
			accessor: 'owner_name',
			Cell: ownerAndDateRenderer('owner_name'),
		},
		{
			Header: 'Created On',
			accessor: 'create_datetime',
			Cell: ownerAndDateRenderer('create_datetime'),
		},
	]

	const formsColumns: any = useMemo(
		() =>
			[
				{
					Header: 'Sequence',
					accessor: 'sequence',
					maxWidth: 50,
					Cell: sequenceRenderer('sequence'),
				},
				{
					Header: 'Attribute Name',
					accessor: 'attribute_name',
					Cell: textCellRenderer('attribute_name'),
				},
				{
					Header: 'Display Name',
					accessor: 'display_name',
					Cell: textCellRenderer('display_name'),
				},
				{
					Header: 'Attribute Type',
					accessor: 'attribute_type',
					Cell: attributesTypesRenderer('attribute_type'),
				},
				{
					Header: 'Mandatory?',
					accessor: 'mandatory',
					Cell: mandatoryRenderer('mandatory'),
				},
				{
					Header: 'Depends On',
					accessor: 'depends_on',
					Cell: dependsOnRenderer('depends_on'),
				},
				{
					Header: 'Depends on conditional',
					accessor: 'depends_on_conditional',
					Cell: dependsOnConditionalRenderer('depends_on_conditional'),
				},
				{
					Header: 'Pre-determined List',
					accessor: 'predetermined_list',
					Cell: textCellRenderer('predetermined_list'),
				},
				{
					Header: 'Contexts',
					accessor: 'contexts',
					Cell: textCellRenderer('contexts'),
				},
				...(isShowingDetails ? details : []),
				{
					Header: 'Available actions',
					accessor: 'template_value',
					Cell: actionsCellRenderer('template_value'),
				},
			]
				.filter((column) => !!column)
				.map((column) => ({ ...column, noTdContainer: true, disableSortBy: true } as MyColumn<IFormDataObject>)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isDisabled, attributesSequence, isShowingDetails, focusName, actionsCellRenderer],
	)

	return (
		<MainWrapper>
			<BottomButtons>
				<LGButton
					padding="8px 5px"
					text={isShowingDetails ? 'Hide Details' : 'Show Details'}
					onClick={() => handleShowingDetails(!isShowingDetails)}
					isWhiteText={!isShowingDetails}
				/>
			</BottomButtons>
			<MyTable
				isLoading={isLoading}
				fullWidth
				columns={formsColumns}
				data={dataForForm}
				isDeleteColumn={isDeleteColumn}
				onClickDelete={(i) => onClickDelete(i.original.id)}
			/>
			<BottomButtons>
				{isAddRow && (
					<LGButton
						className="addComponent"
						disabled={idAddRowDisabled}
						text="New Row"
						isWhiteText
						padding="8px 5px"
						icon={plus}
						onClick={addNewRow}
					/>
				)}
			</BottomButtons>
		</MainWrapper>
	)
}

export default memo(FormTable)
