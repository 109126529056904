import { configureStore } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable'
import { assetsApi } from 'src/features/assets/assetsApi'
import { didpApi } from 'src/features/didp/didpApi'
import { formTemplatesApi } from 'src/features/form-templates/formTemplatesApi'
import { formsApi } from 'src/features/forms/formsApi'
import { tasksApi } from 'src/features/tasks/tasksApi'
import { tidpAssetsApi } from 'src/features/tidp-assets/tidpAseetsApi'
import { usersApi } from 'src/features/users/usersApi'
import services from '../../services'
import rootEpic from './rootEpic'
import rootReducer from './rootReducer'

const epicMiddleware = createEpicMiddleware<any, any, any, any>({
	dependencies: services,
})

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: {
				ignoredPaths: ['tidpOverview', 'formsHandler'],
			},
			serializableCheck: {
				ignoredPaths: ['tidpOverview', 'formsHandler'],
			},
		}).concat([
			epicMiddleware,
			tasksApi.middleware,
			formsApi.middleware,
			formTemplatesApi.middleware,
			usersApi.middleware,
			assetsApi.middleware,
			tidpAssetsApi.middleware,
			didpApi.middleware,
		]),
})
epicMiddleware.run(rootEpic)

export default store
