import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux'
import { IReduxState } from '../models/redux/redux'
import { Epic } from 'redux-observable'
import * as Types from 'Types'
import { Action } from 'redux'
import store from '../appConfig/store'
import rootReducer from '../appConfig/store/rootReducer'

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch
export type AppEpicDeprecated<AppAction extends Action> = Epic<AppAction, any, IReduxState, Types.Services>

export const useAppSelectorDeprecated: TypedUseSelectorHook<IReduxState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppEpic<AppAction extends Action> = Epic<AppAction, any, RootState, Types.Services>
