import { axiosInstance } from 'src/services/axiosInstance'
import { SEARCH_USER } from './constants'

export interface User {
	userId: string
	memberRoles?: { [key: string]: MemberRole[] }
	userGroupId?: string
	username: string
}

export enum MemberRole {
	AssetsRolesAdmin = 'assets.Roles.Admin',
	AssetsRolesCreator = 'assets.Roles.Creator',
	DocumentsAssetsRolesCreator = 'Documents:assets.Roles.Creator',
	InnovationRolesViewer = 'innovation.Roles.Viewer',
}

export const usersService = {
	async fetch(query: string = ''): Promise<User[]> {
		const urlSearchParams = new URLSearchParams()
		if (query) {
			urlSearchParams.append('autocomplete', query)
		}

		const { data } = await axiosInstance.get(
			`${SEARCH_USER}${urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''}`,
		)
		return data.results
	},
}
