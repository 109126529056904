/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { CellProps, Renderer } from 'react-table'
import { Layout } from 'src/components/Molecules/Layout'
import styled from 'styled-components'
import starIcon from '../../../assets/icons/starIcon14.svg'
import { Roles } from '../../../features/authorization/reducer'
import { getFormsList, removeForm } from '../../../features/formsList/actions'
import { IFormDataObject } from '../../../models/formsHandler/formsHandler'
import { IForm } from '../../../models/formsList/formsList'
import browserHistory from '../../../services/history'
import { colors } from '../../../theme/colors'
import { useAppDispatch, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import Input from '../../Atoms/Input'
import Button from '../../Molecules/Button'
import { MyColumn, MyTable, StyledTdContainer } from '../../Molecules/MyTable'
import Pagination from '../../Molecules/Pagination'
import { LikedButton } from '../TIDPOverview/tidpOverviewCustomStyles'
import { TooltipEmail } from '../UserManagement'

const PageTitleWrapper = styled.div`
	margin-bottom: 30px;
`

const SwitchButtons = styled.div`
	display: flex;
	margin: 15px 0;
	justify-content: flex-end;
`

const pageSize = 15

export default function FormsList() {
	const dispatch = useAppDispatch()
	const { roles, isFormsListLoading, formsList, isRemovingForm } = useAppSelectorDeprecated((state) => ({
		roles: state.authorization.roles,
		isFormsListLoading: state.formsList.isFormsListLoading,
		formsList: state.formsList.formsList,
		isRemovingForm: state.formsList.isRemovingForm,
	}))

	const [searchQuery, handleSearchQuery] = useState('')
	const getForms = () => {
		dispatch(getFormsList({ pageSize, page: 0, query: '' }))
	}

	const fetchFormsList = useCallback(getForms, [])

	useEffect(() => {
		fetchFormsList()
	}, [fetchFormsList])

	const HeaderInputFilter = (item: { placeholder: string }) => {
		return (
			<>
				<Input
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
					placeholder={item.placeholder}
					value={searchQuery}
					backgroundColor={colors.white}
					onChange={(value: any) => {
						handleSearchQuery(value)
						if (value.length >= 3) {
							dispatch(getFormsList({ pageSize, page: 0, query: value }))
						} else if (formsList.query !== '') {
							dispatch(getFormsList({ pageSize, page: 0, query: '' }))
						}
					}}
				/>
				{searchQuery.length > 0 && searchQuery.length < 3 && <TooltipEmail>At least 3 characters</TooltipEmail>}
			</>
		)
	}

	const FormsColumns: any = () =>
		[
			{
				Header: '',
				accessor: 'index',
				maxWidth: 10,
				options: { noHeaderTdContainer: true },
				Cell: cellRenderer('index'),
			},
			{
				Header: () => HeaderInputFilter({ placeholder: 'Form Name' }),
				accessor: 'name',
				Cell: cellRenderer('name'),
			},
			{
				Header: '',
				maxWidth: 15,
				accessor: 'id',
				options: { noHeaderTdContainer: true },
				Cell: cellRenderer('button'),
			},
		].map((column) => ({ ...column, noTdContainer: true, disableSortBy: true } as MyColumn<IForm>))
	const cellRenderer =
		(headerId: string): Renderer<CellProps<IFormDataObject>> =>
		({ value, row }) => {
			if (headerId === 'index') {
				return <StyledTdContainer center>{row.index + 1}</StyledTdContainer>
			}
			if (headerId === 'button') {
				return (
					<StyledTdContainer padding="0" background="tranparent" center={false}>
						<LikedButton>
							<Button
								isDisabled={isRemovingForm}
								text="View Form"
								onClick={() => {
									browserHistory.push(`/forms/templates/${value}`)
								}}
								color={colors.main10}
							/>
						</LikedButton>
					</StyledTdContainer>
				)
			}
			return <Input height="40px !important" value={value} />
		}
	return (
		<Layout>
			<PageTitleWrapper>
				<Typography variant="h4">Form templates</Typography>

				<SwitchButtons>
					{roles.filter((role: string) => role === Roles['Registers:roles.Registers.WorkflowAdmin']).length > 0 && (
						<div className="create">
							<Button
								text="Create new"
								imageSrc={starIcon}
								onClick={() => browserHistory.push('/forms/templates/create')}
								color={colors.main10}
							/>
						</div>
					)}
				</SwitchButtons>
			</PageTitleWrapper>
			<MyTable
				isLoading={isFormsListLoading}
				fullWidth
				columns={FormsColumns()}
				data={formsList.forms}
				isDeleteColumn={
					roles.filter((role: string) => role === Roles['Registers:roles.Registers.WorkflowAdmin']).length > 0
				}
				isDeleteAvailable={isRemovingForm}
				onClickDelete={(el: any) => dispatch(removeForm({ id: el.original.id, name: el.original.name }))}
			/>
			{!isFormsListLoading && formsList.forms.length > 0 && Math.ceil(formsList.total / pageSize) > 1 && (
				<Pagination
					onPageChange={(item) => dispatch(getFormsList({ pageSize, page: item, query: searchQuery }))}
					pageCount={Math.ceil(formsList.total / pageSize)}
					initialPage={0}
					page={formsList.page}
				/>
			)}
		</Layout>
	)
}
