import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { complement, isEmpty } from 'ramda'
import { FC, memo, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { AttributeInput } from 'src/forms/_shared/attribute/AttributeInput'
import { AppAttribute, AppChildForm, MainFormForm } from 'src/forms/_shared/main-form/MainForm'

export type MainFormAccordionProps = { disabled?: boolean; shouldOmitDocumentNumber?: boolean } & (
	| {
			form: MainFormForm
			formIndex?: undefined
	  }
	| {
			form: AppChildForm
			formIndex: number
	  }
)

export const MainFormAccordion: FC<MainFormAccordionProps> = memo((props) => {
	const { form, formIndex } = props
	const methods = useFormContext<MainFormForm>()

	const omitDocumentNumber = (attribute: AppAttribute): boolean =>
		!props.shouldOmitDocumentNumber ? false : form.is_main_form ? attribute.display_name === 'Document Number' : false

	const hasAnyError: boolean = useMemo(
		() =>
			props.form.is_main_form
				? !!methods.formState.errors?.attributes
				: !!methods.formState.errors?.children?.[props.formIndex as number]?.attributes,
		[
			methods.formState.errors?.attributes,
			methods.formState.errors?.children,
			props.form.is_main_form,
			props.formIndex,
		],
	)

	return (
		<Accordion
			square
			disableGutters
			key={form.id}
			sx={(theme) => ({
				boxShadow: 0,
				border: `1px solid ${theme.palette.divider}`,
				'&:not(:last-child)': {
					borderBottom: 0,
				},
			})}
		>
			<AccordionSummary
				sx={(theme) => ({
					background: grey[50],
					flexDirection: 'row-reverse',
					'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
						transform: 'rotate(90deg)',
					},
					'& .MuiAccordionSummary-content': {
						marginLeft: theme.spacing(1),
						justifyContent: 'space-between!important',
					},
				})}
				expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
				aria-controls={`accordion-${form.id}`}
				id={`accordion-${form.id}`}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography>{form.is_main_form === true ? 'Form fields' : form.display_name}</Typography>
					{props.disabled && (
						<Typography variant="caption" sx={{ color: grey[500], ml: 1 }}>
							(readonly)
						</Typography>
					)}
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					{hasAnyError && (
						<Tooltip title="Form has errors.">
							<ErrorOutlineIcon sx={{ mr: 1 }} color="error" />
						</Tooltip>
					)}
					<Typography>
						{
							form.attributes
								.filter(complement(omitDocumentNumber))
								.filter((attr) => (attr.value == null ? false : !isEmpty(attr.value))).length
						}
						/{form.attributes.filter(complement(omitDocumentNumber)).length}
					</Typography>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Box sx={(theme) => ({ maxWidth: theme.breakpoints.values.sm })}>
					{form.attributes.map((attribute, index) => (
						<AttributeInput
							key={attribute.id}
							attribute={attribute}
							form={props.form}
							name={
								form.is_main_form
									? `attributes.${index}.value`
									: `children.${formIndex as number}.attributes.${index}.value`
							}
							hide={omitDocumentNumber(attribute)}
							disabled={props.disabled || methods.formState.isSubmitting}
						/>
					))}
				</Box>
			</AccordionDetails>
		</Accordion>
	)
})

MainFormAccordion.displayName = 'MainFormAccordion'
