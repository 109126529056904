export const TIDP_OVERVIEW_TABS = 'tidpOverview/TIDP_OVERVIEW_TABS'

export const HANDLE_OVERVIEW_DISCIPLINE_IS_REQUIRED = 'tidpOverview/HANDLE_OVERVIEW_DISCIPLINE_IS_REQUIRED'
export const HANDLE_OVERVIEW_DISCIPLINE_OWNER = 'tidpOverview/HANDLE_OVERVIEW_DISCIPLINE_OWNER'
export const HANDLE_OVERVIEW_DISCIPLINE_STATUS = 'tidpOverview/HANDLE_OVERVIEW_DISCIPLINE_STATUS'
export const CHANGE_OVERVIEW_COMMENT_CONTENT = 'tidpOverview/CHANGE_OVERVIEW_COMMENT_CONTENT'

export const GET_TIDP_OVERVIEW = 'tidpOverview/GET_TIDP_OVERVIEW'
export const SAVE_TIDP_OVERVIEW = 'tidpOverview/SAVE_TIDP_OVERVIEW'
export const HANDLE_IS_SAVING_OVERVIEW = 'tidpOverview/HANDLE_IS_SAVING_OVERVIEW'
export const SET_TIDP_OVERVIEW_DATA = 'tidpOverview/SET_TIDP_OVERVIEW_DATA'
export const SET_TIDP_OVERVIEW_DISCIPLINES = 'tidpOverview/SET_TIDP_OVERVIEW_DISCIPLINES'
export const UPDATE_TIDP_OVERVIEW = 'tidpOverview/UPDATE_TIDP_OVERVIEW'

export const SET_NEW_DISCIPLINE = 'tidpOverview/SET_NEW_DISCIPLINE'

export const SET_TECHNICAL_LIFECYCLE_CODE = 'tidpOverview/SET_TECHNICAL_LIFECYCLE_CODE'

export const IS_SAVING_NEW_COMMENT_TIDP_OVERVIEW = 'tidpOverview/IS_SAVING_NEW_COMMENT_TIDP_OVERVIEW'
export const SAVE_NEW_TIDP_COMMENT = 'tidpOverview/SAVE_NEW_TIDP_COMMENT'
export const SAVE_TIDP_REPLY = 'tidpOverview/SAVE_TIDP_REPLY'
export const HANDLE_TIDP_REPLY_COMMENT = 'tidpOverview/HANDLE_TIDP_REPLY_COMMENT'
export const HANDLE_START_NEW_TIDP_COMMENT = 'tidpOverview/HANDLE_START_NEW_TIDP_COMMENT'
export const HANDLE_NEW_TIDP_COMMENT_TEXT = 'tidpOverview/HANDLE_NEW_TIDP_COMMENT_TEXT'
export const CLEAR_TIDP_COMMENT = 'tidpOverview/CLEAR_TIDP_COMMENT'
export const ASSIGN_TIDP_COMMENTS = 'tidpOverview/ASSIGN_TIDP_COMMENTS'
export const START_NEW_TIDP_REPLY = 'tidpOverview/START_NEW_TIDP_REPLY'

export const ASSIGN_EXTERNAL_STAKEHOLDERS_VALUE = 'tidpOverview/ASSIGN_EXTERNAL_STAKEHOLDERS_VALUE'
export const ASSIGN_INTERNAL_STAKEHOLDERS_VALUE = 'tidpOverview/ASSIGN_INTERNAL_STAKEHOLDERS_VALUE'
export const ASSIGN_OWNERS_VALUE = 'tidpOverview/ASSIGN_OWNERS_VALUE'

export const DUPLICATE_TIDP = 'tidpOverview/DUPLICATE_TIDP'
export const ADD_ANOTHER_REVISION = 'tidpOverview/ADD_ANOTHER_REVISION'
export const SET_ANOTHER_REVISION = 'tidpOverview/SET_ANOTHER_REVISION'

export const SET_ACTION_IN_PROGRESS = 'tidpOverview/SET_ACTION_IN_PROGRESS'

export const GET_LIST_OF_DISCIPLINES = 'tidpOverview/GET_LIST_OF_DISCIPLINES'
export const SET_LIST_OF_DISCIPLINES = 'tidpOverview/SET_LIST_OF_DISCIPLINES'

export const UPDATE_EXTERNAL_STAKEHOLDERS_VALUE = 'tidpOverview/UPDATE_EXTERNAL_STAKEHOLDERS_VALUE'
export const UPDATE_INTERNAL_STAKEHOLDERS_VALUE = 'tidpOverview/UPDATE_INTERNAL_STAKEHOLDERS_VALUE'
export const UPDATE_OWNERS_VALUE = 'tidpOverview/UPDATE_OWNERS_VALUE'

export const UPDATE_TIDP_STATUS = 'tidpOverview/UPDATE_TIDP_STATUS'

export const UPDATE_TIDP_COMMENT = 'tidpOverview/UPDATE_TIDP_COMMENT'

export const REFRESH_TIDP_NUMBER = 'tidpOverview/REFRESH_TIDP_NUMBER'
export const HANDLE_NAV_NUMBER_STATUS = 'tidpOverview/HANDLE_NAV_NUMBER_STATUS'
export const GET_TIDP_STATUS = 'tidpOverview/GET_TIDP_STATUS'
export const GET_TIDP_STATUS_SILENT = 'tidpOverview/GET_TIDP_STATUS_SILENT'

export const GET_TIDP_OVERVIEW_HISTORY = 'tidpOverview/GET_TIDP_OVERVIEW_HISTORY'
export const SET_TIDP_OVERVIEW_HISTORY = 'tidpOverview/SET_TIDP_OVERVIEW_HISTORY'
