/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import { FontWeightType } from '../../../typings/styledComponents'
import { colors } from '../../../theme/colors'

enum FontWeight {
	light = 300,
	regular = 500,
	bold = 700,
}

interface IStyledTextButton {
	sm?: boolean
	md?: boolean
	lg?: boolean
	xl?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
	mainColor?: boolean
	isWhiteText?: boolean
	textColor?: string
}

const StyledTextButton = styled.span<IStyledTextButton>`
	font-family: ${({ theme }) => theme.fonts.mainFont};
	font-weight: ${({ fontWeight }) => (fontWeight ? FontWeight[fontWeight] : 500)};
	font-size: ${({ fontSize }) => `${fontSize}px`};
	line-height: ${({ lineHeight }) => `${lineHeight}px`};
	display: flex;
	align-items: center;
	color: ${({ mainColor, theme, textColor }) => textColor || (mainColor && theme.colors.main100)};
	white-space: nowrap;

	${({ isWhiteText }) =>
		isWhiteText &&
		css`
			color: ${colors.white};
		`};

	${({ sm }) =>
		sm &&
		css`
			margin-top: 2px;
			font-size: 8px;
			line-height: 9px;
		`};

	${({ md }) =>
		md &&
		css`
			font-size: 14px;
			line-height: 16px;
		`};

	${({ lg }) =>
		lg &&
		css`
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
		`};

	${({ xl }) =>
		xl &&
		css`
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
		`};
`

function TextButton({
	text,
	sm,
	md,
	lg,
	xl,
	fontWeight,
	fontSize,
	lineHeight,
	mainColor,
	isWhiteText,
	textColor,
}: {
	text: string
	sm?: boolean
	md?: boolean
	lg?: boolean
	xl?: boolean
	fontWeight?: FontWeightType
	fontSize?: number
	lineHeight?: number
	mainColor?: boolean
	isWhiteText?: boolean
	textColor?: string
}) {
	return (
		<StyledTextButton
			sm={sm}
			textColor={textColor}
			md={md}
			lg={lg}
			xl={xl}
			fontWeight={fontWeight}
			fontSize={fontSize}
			lineHeight={lineHeight}
			mainColor={mainColor}
			isWhiteText={isWhiteText}
		>
			{text}
		</StyledTextButton>
	)
}

export default TextButton
