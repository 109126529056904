import { axiosInstance } from 'src/services/axiosInstance'
import { REACT_GROUP_MANAGEMENT, REACT_GROUP_PRIVILEGES } from '../constants'

export const getListOfGroups = async (page: number, contract: string) => {
	const { data } = await axiosInstance.get(`${REACT_GROUP_MANAGEMENT}list/?page=${page}&contract=${contract}`)
	return data
}

export const searchGroups = async (page: number, query: string) => {
	const { data } = await axiosInstance.get(`${REACT_GROUP_MANAGEMENT}search/?page=${page}&group_name=${query}`)
	return data
}

export const removeGroup = async (id: any) => {
	const { data } = await axiosInstance.post(`${REACT_GROUP_MANAGEMENT}remove/`, { id })
	return data
}

export const removeUsersFromGroup = async (id: string | number, users: string[]) => {
	const { data } = await axiosInstance.post(`${REACT_GROUP_MANAGEMENT}remove_users/`, {
		id,
		users,
	})
	return data
}

export const addNewUserToGroup = async (id: string | number, users: string[]) => {
	const { data } = await axiosInstance.post(`${REACT_GROUP_MANAGEMENT}add_users/`, {
		id,
		users,
	})
	return data
}

export const getUsersForGroup = async (tag: string | number) => {
	const { data } = await axiosInstance.get(`${REACT_GROUP_MANAGEMENT}users/?group_id=${tag}`)
	return data
}

export const createNewGroup = async (body: { groupName: any; contractId: any; contractName: any }) => {
	const { data } = await axiosInstance.post(`${REACT_GROUP_MANAGEMENT}create/`, body)
	return data
}

export const getPrivilegesTypes = async () => {
	const { data } = await axiosInstance.get(`${REACT_GROUP_PRIVILEGES}types/`)
	return data
}

export const getPrivilegesList = async (contract_id: string, type: string, parent?: string) => {
	const { data } = await axiosInstance.get(
		`${REACT_GROUP_PRIVILEGES}list/?contract_id=${contract_id}&type=${type}${parent ? `&parent=${parent}` : ''}`,
	)
	return data
}
export const getPrivilegesSource = async (groupId: any) => {
	const { data } = await axiosInstance.get(`${REACT_GROUP_PRIVILEGES}for_group/?group_id=${groupId}`)
	return data
}

export const saveNewPrivilege = async (body: any) => {
	const { data } = await axiosInstance.post(`${REACT_GROUP_PRIVILEGES}create/`, body)
	return data
}
export const removeNewPrivilege = async (body: any) => {
	const { data } = await axiosInstance.post(`${REACT_GROUP_PRIVILEGES}delete/`, body)
	return data
}
