import { useEffect } from 'react'
import { MODULE_URLS } from 'src/utils/moduleUrls'
import { AimsModule } from '../features/authorization/constants'
import { useAppSelector } from './reduxUtils'
import { authRedirectUser } from 'src/utils/authRedirectUser'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from '@uidotdev/usehooks'

export const HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY = 'aims/HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY'

export function useDefaultModuleRedirect() {
	const profile = useAppSelector((state) => state.profile.profile)
	const routerLocation = useLocation()
	const [hasCheckedDefaultModule] = useLocalStorage(HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY, false)

	useEffect(() => {
		if (import.meta.env.MODE === 'development') {
			localStorage.setItem(HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY, 'true')
			return
		}
		if (!profile || !!hasCheckedDefaultModule || routerLocation.pathname === '/logout') {
			return
		}
		localStorage.setItem(HAS_CHECKED_DEFAULT_MODULE_STORAGE_KEY, 'true')

		authRedirectUser()

		const shouldRedirectToDefault = !!profile.default_module

		if (shouldRedirectToDefault) {
			const redirectUrl = MODULE_URLS[profile.default_module as AimsModule]
			if (redirectUrl) {
				location.replace(redirectUrl)
			}
		}
	}, [profile])
}
