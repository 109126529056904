import styled from 'styled-components'

export const BottomButtonSection = styled.div`
	display: flex;
	margin: 10px 0;
	button {
		margin-right: 10px;
	}
`

export const StyledTable = styled.div`
	width: 100%;
	margin-bottom: 10px;

	table thead th,
	table tbody tr:not(.noResults) {
		background: transparent;
	}

	table tbody tr:not(.noResults) {
		td:first-child > div {
			background: white;
		}
	}
`

export const SelectContainer = styled.div`
	max-width: 300px;
`
export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`
