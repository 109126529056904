import { Action } from 'src/facade/action/actionService.types'
import { Asset } from '../assets/assetsService.types'

export interface FetchTasksResponse {
	current: Task[]
	previous: Task[]
}

export interface Task {
	task_id: number
	task_role: string
	created_by: string
	task_name: TaskName
	created_at: string
	parent: any[] // FIXME
	possible_actions: Action[]
	metadata: Metadata
	main_asset: Asset
	status: TaskStatus
}

export enum TaskName {
	FORM_OWNER = 'FORM_OWNER',
	FORM_REVIEWER = 'FORM_REVIEWER',
	FORM_CONTRIBUTOR = 'FORM_CONTRIBUTOR',
}

export interface Metadata {
	class_code: string
	form_template_id: string
	read_only?: string
	write?: string
	attribute_name?: string
	assigned_users?: string
}

export enum TaskStatus {
	InProgress = '0',
	Finished = '1',
	Pending = '2',
	AwaitingAcceptance = '3',
}

export interface UpdateTaskAttributesPermissions {
	action_input_parameters: {
		contract: string
		contributors: (
			| string
			| {
				read_only: string[]
				write: string[]
			}
		)[][]
	}
	action_name: string
	task_id: any
}
