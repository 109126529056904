import styled from 'styled-components'
import { colors } from '../../../theme/colors'

export const SpinnerOverview = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const OverviewWrapper = styled.div`
	.overviewNav {
		width: 100%;
		border-top: 2px solid ${colors.lightGreyF9};
		margin: 50px 0 30px 0;
		display: flex;
		div {
			margin-top: -2px;
			border-top: 2px solid transparent;
			cursor: pointer;
			padding: 5px 10px 0 10px;
			&:first-child {
				padding-left: 0;
			}
		}
	}
`
export const OverviewContentWrapper = styled.div`
	display: flex;
	flex-direction: row;	
`

export const OverviewContent = styled.div`
	box-sizing:border-box
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-right: 60px;
	border: 100px black;
`

export const StyledTh = styled.th<{ width?: string; icon?: boolean }>`
	padding-right: 8px;
	padding-bottom: 20px;
	width: ${({ width }) => width || 'auto'};
`

export const StyledTable = styled.div`
	width: 100%;

	#additional .thContainer {
		display: flex !important;
	}
	table thead th,
	table tbody tr:not(.noResults) {
		background: transparent;
	}

	table tbody tr:not(.noResults) {
		td:first-child > div {
			background: white;
		}
	}
`

export const StyledSaveButton = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	button {
		height: 32px;
	}
`

export const HeaderElement = styled.div<{ background: string; margin: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	padding: 0 5px;
	border-radius: 5px;
	cursor: pointer;
	background: ${({ background }) => background && background};
	margin: ${({ margin }) => margin && margin};
	img {
		width: 12px;
		height: 12px;
	}
`

export const ButtonsGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 8px;
`

export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`

export const DeleteIcon = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	img {
		margin-top: -2px;
		width: 15px;
		height: 15px;
	}
`

export const LikedButton = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

export const StyledTd = styled.td<{ icon?: boolean; large?: boolean; wordBreak?: boolean; width?: string }>`
	padding-right: ${({ large }) => (large ? '16px' : '8px')};
	padding-bottom: ${({ large }) => (large ? '12px' : '8px')};
	width: ${({ width }) => width || 'auto'};
	img {
		max-height: 15px;
		max-width: 18px;
	}
	span {
		word-break: ${({ wordBreak }) => (wordBreak ? 'break-all' : 'normal')};
	}
	padding-left: ${({ icon }) => (icon ? '55px' : '0')};
`

export const BottomButtonSection = styled.div`
	display: flex;
	margin: 10px 0;
	button {
		margin-right: 10px;
	}
`
