import * as flowForManagerService from './flowForManager'
import * as usersService from './users'
import * as commentsService from './comments'
import * as attributesService from './attributes'
import * as searchAssets from './search'
import * as disciplineOverview from './disciplineOverview'
import * as tasksListService from './tasksList'
import * as tidpOverview from './tidpOverview'
import * as tasksActions from './tasksActions'
import * as usersManagement from './usersManagement'
import * as groupsManagement from './groupsManagement'
import * as formsList from './formsList'
import * as formsHandler from './formsHandler'

export default {
	flowForManagerService,
	usersService,
	commentsService,
	attributesService,
	searchAssets,
	disciplineOverview,
	tasksListService,
	tidpOverview,
	tasksActions,
	usersManagement,
	groupsManagement,
	formsList,
	formsHandler,
}
