import { Layout } from 'src/components/Molecules/Layout'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

const NoRoleWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	.noRoles {
		font-weight: 500;
		font-size: 20px;
	}
	.moreDetails {
		text-align: center;
		font-size: 16px;
		margin-top: 10px;
		.link {
			margin-top: 3px;
			a:link,
			a:visited,
			a:active {
				color: ${colors.black};
			}
			a:hover {
				color: ${colors.greyB0};
			}
		}
	}
`

const NoRolesUser = () => (
	<Layout>
		<NoRoleWrapper>
			<div className="noRoles">You have no roles assigned yet.</div>
			<div className="moreDetails">
				For any issues or questions, please contact us at
				<div className="link">
					<a href="mailto:help@enablemyteam.com e-mail">help@enablemyteam.com</a>
				</div>
			</div>
		</NoRoleWrapper>
	</Layout>
)

export default NoRolesUser
