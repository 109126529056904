import { createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import { toast } from 'react-toastify'
import { dateFormat } from 'src/assets/dateFormats'
import { TidpDocumentCommentSelections } from 'src/features/comments/commentsSlice'
import { getTidpOverview } from 'src/features/tidpOverview/actions'
import { saveNewAssetComment } from 'src/services/tidpOverview'

export const saveTidpDocumentCommentThunk = createAsyncThunk(
	'tidp/saveComment',
	async (
		payload: {
			message: any
			tagCode: string
			documentTagCode: string
			commentCode: string
			status: string
			selections: TidpDocumentCommentSelections
		},
		{ getState, dispatch },
	) => {
		const { selections } = payload
		const today = new Date()
		const state = getState() as any
		const username = state.profile!.user.name

		const data = {
			contract: state.users.contract.contract,
			class_code: 'Comment',
			relations: [
				{
					tag_code: payload.documentTagCode,
				},
			],
			attribute: {
				Message: payload.message,
				Head: true,
				Code: payload.commentCode,
				'Recorded At': moment(today).zone('+00:00').format(dateFormat.apiFormat),
				'Recorded By': username,
				Status: payload.status,
				selections,
			},
		}

		try {
			await saveNewAssetComment(data)
			dispatch(getTidpOverview(payload.tagCode, true))
			toast.info('Comment added.')
		} catch (error) {
			toast.error('Failed to add new comment.')
			throw error
		}
	},
)
