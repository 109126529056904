import { createApi } from '@reduxjs/toolkit/query/react'
import { tasksService } from 'src/facade/tasks/tasksService'
import { Task } from 'src/facade/tasks/tasksService.types'
import { axiosBaseQuery } from 'src/utils/axiosBaseQuery'

export type AppTask = Task & {
	type?: 'current' | 'previous'
}

export const tasksApi = createApi({
	reducerPath: 'tasksApi',
	baseQuery: axiosBaseQuery(),
	tagTypes: ['Tasks'],
	endpoints: (builder) => ({
		fetchTasks: builder.query<AppTask[], { activeTasksOnly: boolean }>({
			query:
				({ activeTasksOnly }) =>
					() =>
						activeTasksOnly ? tasksService.getActiveTasks() : tasksService.getAllTasks(),
			providesTags: ['Tasks'],
		}),
	}),
})

export const { useFetchTasksQuery } = tasksApi
