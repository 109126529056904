import { DefaultTheme } from 'styled-components'
import { colors } from './colors'
import { fonts } from './fonts'

declare module 'styled-components' {
	export interface DefaultTheme {
		colors: {
			black: string
			black11: string
			black25: string
			black47: string
			black50: string
			black62: string
			danger10: string
			danger100: string
			danger2: string
			danger25: string
			danger5: string
			danger50: string
			darkGrey: string
			darkGrey46: string
			greyB0: string
			greyBD: string
			greyC4: string
			lightGreyE0: string
			lightGreyE5: string
			lightGreyEC: string
			lightGreyF2: string
			lightGreyF9: string
			main10: string
			main100: string
			main2: string
			main25: string
			main5: string
			main50: string
			success10: string
			success100: string
			success2: string
			success25: string
			success5: string
			success50: string
			warning10: string
			warning100: string
			warning2: string
			warning25: string
			warning5: string
			warning50: string
			white: string
		}
		fonts: {
			mainColor: string
			mainFont: string
			mainStyle: string
		}
	}
}

const mainTheme: DefaultTheme = {
	colors,
	fonts,
}

export default mainTheme
