export type IntersectedForm = {
	id: string
	form_name: string
	form_number: string
	form_revision: string
	is_main_form: boolean
	attributes: Attribute[]
}

export interface MainForm extends IntersectedForm {
	is_main_form: true
	linked_guidance_file_id: string
	linked_guidance_file_name: string
}

export interface ChildForm extends IntersectedForm {
	is_main_form: false
	attribute_name: string
	display_name: string
	display_style: DisplayStyleForm
	linked_attribute_id: string
	mandatory: boolean
	sequence: number
	contexts: FormContexts
	depends_on: AttributeDependsOn | string
}

export type Form = MainForm | ChildForm

export enum AttributeDependsOn {
	Ddd = 'ddd',
	Empty = '',
	PreRequisite = 'Pre-requisite',
	Testing = 'testing',
	Test1B0A322D2A5A0404E813D17A5B0D380E4 = 'test1_b0a322d2-a5a0-404e-813d-17a5b0d380e4',
}

export enum FormContexts {
	BBVQuestions = 'BBV Questions',
	Empty = '',
	HS2Questions = 'HS2 Questions',
	SCQuestions = 'S/C Questions',
}

export type DisplayStyleForm = 'normal' | 'grid'

export interface Attribute {
	id: string
	sequence: number
	mandatory: boolean
	attribute_name: string
	contexts: any[] | string
	owner_name: string
	display_name: string
	predetermined_list: string | string[]
	create_datetime: string
	number_mask: NumberMask
	attribute_unit: string
	attribute_description: AttributeDescription | string
	depends_on?: AttributeDependsOn | string
	attribute_type: AttributeType
	template_value: any
}

export enum AttributeType {
	Text = 'Text',
	URI = 'URI',
	Numeric = 'Numeric',
	Weather = 'Weather',
	GeoLocation = 'Geo_location',
	Location = 'Location',
	Media = 'Media',
	User = 'User',
	Attachment = 'Attachment',
	Signature = 'Signature',
	Date = 'Date',
	NonEditable = 'Non Editable',
	MarkupImage = 'Markup Image',
	ReadonlyImage = 'Read Only Image',
}

export enum AttributeDescription {
	Empty = '',
	SubcontractorInitial = 'Subcontractor Initial',
}

export type NumberMask = {
	mask: string
	rangeLow: string
	rangeHigh: string
	displayMask?: string
	enforce?: boolean
}
