/* eslint-disable @typescript-eslint/no-redeclare */
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import {
	Box,
	Button,
	IconButton,
	Menu,
	MenuItem,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
} from '@mui/material'
import { green, grey, orange } from '@mui/material/colors'
import { GridApi, ICellRendererParams, RowNode } from 'ag-grid-enterprise'
import { AgGridColumn } from 'ag-grid-react'
import moment from 'moment'
import { equals, uniq } from 'ramda'
import { FC, Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { MessagePanel } from 'src/components/message-panel/MessagePanel'
import { DataGrid } from 'src/components/Molecules/DataGrid/DataGrid'
import { Layout } from 'src/components/Molecules/Layout'
import { Action } from 'src/facade/action/actionService.types'
import { actionFormService } from 'src/facade/action/form/actionFormService'
import { actionTaskService } from 'src/facade/action/task/actionTaskService'
import { TaskName, TaskStatus } from 'src/facade/tasks/tasksService.types'
import { useFetchFormTemplatesQuery } from 'src/features/form-templates/formTemplatesApi'
import { markAsCompleteThunk } from 'src/features/tasks/markAsCompleteThunk'
import { AppTask, tasksApi, useFetchTasksQuery } from 'src/features/tasks/tasksApi'
import { getDocumentNumber } from 'src/forms/_shared/getDocumentNumber'
import { TASK_STATUS_LABELS } from 'src/forms/_shared/taskStatusLabels'
import { createFormRows, TaskRow, UiAction } from 'src/forms/createFormRows'
import { SendTaskDialog } from 'src/forms/send-task/SendTaskDialog'
import { useFormsExpandedTasks } from 'src/forms/useFormsExpandedTasks'
import { useFormsSelectedState } from 'src/forms/useFormsSelectedState'
import { openDownloadPdf } from 'src/utils/openDownloadPdf'
import { useAppDispatch, useAppSelector } from 'src/utils/reduxUtils'
import styled from 'styled-components'
import { useFormsSelectedFilters } from './useFormsSelectedFilters'
import { useLocalStorageState } from 'src/utils/useLocalStorageState'

const FormsToggleHeader = styled.div`
	display: flex;
	align-items: center;
`

export const Forms: FC = () => { 
	const [formsSelectedState, setFormsSelectedState] = useFormsSelectedState()
	const [formsSelectedFilters, setFormsSelectedFilters] = useFormsSelectedFilters()
	const [rowId, setRowId] = useLocalStorageState('selectedRow', 0)

	const username = useAppSelector((state) => state.profile.user!.name)
	const { contract } = useAppSelector((state) => state.users)
	const {
		data: tasks,
		error: tasksError,
		isFetching: isFetchingTasks,
	} = useFetchTasksQuery({ activeTasksOnly: formsSelectedState === 'USER_FORMS' })
	const {
		data: templates,
		error: templatesError,
		isLoading: isLoadingTemplates,
	} = useFetchFormTemplatesQuery((contract as any).contract)

	const { includes: includesExpandedTagCode, toggle: toggleExpandedTask } = useFormsExpandedTasks()

	const [gridApi, setGridApi] = useState<GridApi | null>(null)

	const profileEmail = username

	const rows: TaskRow[] = useMemo(
		() => createFormRows(tasks, templates, profileEmail),
		[profileEmail, tasks, templates],
	)
	const formStatusOptions = useMemo(() => uniq(rows.map((row) => TASK_STATUS_LABELS[row.status as TaskStatus])), [rows])

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const isToggleMenuOpened = Boolean(anchorEl)
	const handleToggleMenuClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleToggleMenuClose = () => {
		setAnchorEl(null)
	}

	return (
		<Layout>
			<Box my={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<FormsToggleHeader>
					<Typography variant="h4">
						{formsSelectedState === 'USER_FORMS' ? 'User Active Forms' : 'All Project Forms'}
					</Typography>
					<div>
						<IconButton onClick={handleToggleMenuClick}>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="long-menu"
							MenuListProps={{
								'aria-labelledby': 'long-button',
							}}
							anchorEl={anchorEl}
							open={isToggleMenuOpened}
							onClose={handleToggleMenuClose}
						>
							{formsSelectedState === 'ALL_FORMS' && (
								<MenuItem
									onClick={() => {
										handleToggleMenuClose()
										setFormsSelectedState('USER_FORMS')
									}}
								>
									Users Active Forms
								</MenuItem>
							)}

							{formsSelectedState === 'USER_FORMS' && (
								<MenuItem
									onClick={() => {
										handleToggleMenuClose()
										setFormsSelectedState('ALL_FORMS')
									}}
								>
									All Project Forms
								</MenuItem>
							)}
						</Menu>
					</div>
				</FormsToggleHeader>

				<Box sx={{ display: 'flex' }}>
					<Link to="/forms/templates">
						<Button variant="contained" color="primary">
							Manage form templates
						</Button>
					</Link>
					<Box ml={2}>
						<Link to="/forms/create">
							<Button variant="outlined" color="primary">
								Create new
							</Button>
						</Link>
					</Box>
				</Box>
			</Box>
			{tasksError || templatesError ? (
				<MessagePanel my={3} errorMessage="Failed to load forms." />
			) : (
				<Fragment>
					<Box sx={{ mb: 2 }}>
						<ToggleButtonGroup
							value={formsSelectedFilters}
							onChange={(event, values: (typeof TASK_STATUS_LABELS)[]) => {
								gridApi?.setFilterModel({
									status: { type: 'set', values },
								})
							}}
							aria-label="Form statuses"
						>
							{formStatusOptions.map((taskStatusLabel) => (
								<ToggleButton key={taskStatusLabel} value={taskStatusLabel} aria-label="bold" size="small">
									{taskStatusLabel}
								</ToggleButton>
							))}
						</ToggleButtonGroup>

						<ToggleButton
							aria-label="bold"
							size="small"
							value="all"
							selected={formsSelectedFilters.length === 3}
							onClick={() => {
								gridApi?.setFilterModel({
									status: { type: 'set', formStatusOptions },
								})
							}}
						>
							All Forms
						</ToggleButton>
					</Box>
					<DataGrid
						fullWidth
						treeData
						immutableData
						onGridReady={(event) => {
							setGridApi(event.api)
						}}
						onFilterChanged={(event) => {
							const filterModel = event.api.getFilterModel()
							const statusFilterModel = filterModel.status
							if (statusFilterModel) {
								setFormsSelectedFilters(statusFilterModel.values)
							} else {
								setFormsSelectedFilters(formStatusOptions)
							}
						}}
						excludeChildrenWhenTreeDataFiltering
						getRowStyle={(params) => {
							if (params.node.level === 1) {
								return { background: '#013ca605' }
							}
							return {} as any
						}}
						rowData={rows}
						loading={isFetchingTasks || isLoadingTemplates}
						getRowNodeId={(row: TaskRow) => row.id.toString()}
						getDataPath={(row: TaskRow) => row.path}
						autoGroupColumnDef={{
							cellClass: 'left-aligned',
							headerName: 'Form',
							field: 'form',
							flex: 2,
							cellRendererParams: { suppressCount: true },
						}}
						onRowGroupOpened={(event) => {
							toggleExpandedTask(event.data.task)
						}}
						isGroupOpenByDefault={(params) => {
							return params.level === 0 ? includesExpandedTagCode(params.key) : false
						}}
						onFirstDataRendered={(event) => {
							event.api.setFilterModel({
								status: {
									type: 'set',
									values: formsSelectedFilters,
								},
							})
							const rowNode = gridApi?.getRowNode(`${rowId}`)
							const rowIndex = rowNode !== null && rowNode !== undefined ? rowNode.rowIndex! : 0
							gridApi?.ensureIndexVisible(rowIndex, 'top')
						}}
					>
						<AgGridColumn flex={2} field="documentNumber" headerName="Document number" />
						<AgGridColumn flex={1} field="sender" headerName="Sender" />
						<AgGridColumn
							flex={1}
							field="sentDate"
							headerName="Sent date"
							valueFormatter={(row) => moment(row.value).format('DD/MM/yyyy')}
							comparator={(valueA, valueB) => {
								return moment(valueA).valueOf() - moment(valueB).valueOf()
							}}
							sort="desc"
						/>
						<AgGridColumn
							flex={1}
							field="recipients"
							headerName="Recipients"
							equals={(recipients1, recipients2) => {
								return equals(recipients1, recipients2)
							}}
						/>
						<AgGridColumn flex={1} field="role" headerName="Role" />
						<AgGridColumn
							flex={1}
							field="status"
							headerName="Status"
							headerCheckboxSelectionFilteredOnly
							valueGetter={(params) => {
								return TASK_STATUS_LABELS[params.data?.status as TaskStatus]
							}}
							cellRendererFramework={(props: any) => (
								<span style={{ color: TASK_STATUS_COLORS[props?.getValue()] }}>{props?.getValue()}</span>
							)}
						/>
						<AgGridColumn
							suppressMenu
							sortable={false}
							filter={false}
							flex={1}
							cellClass="no-focus right-aligned"
							field="task.possible_actions"
							headerName=""
							cellRendererFramework={(params: any) =>
								params.data?.task ? (
									<ActionCellRenderer
										{...params}
										subtasks={tasks
											?.filter((task) => task.main_asset.tag_code === params.data?.task?.main_asset?.tag_code)
											?.filter((task) => task.task_name !== TaskName.FORM_OWNER)}
									/>
								) : null
							}
						/>
					</DataGrid>
				</Fragment>
			)}
		</Layout>
	)
}

const ActionCellRenderer: FC<ICellRendererParams & { subtasks: AppTask[] }> = (props) => {
	const dispatch = useAppDispatch()
	const {
		contract: { contract },
	} = useAppSelector((state) => state.users)
	const row: TaskRow = props.data
	const { task } = row
	const isMainTask: boolean = row.path.length === 1
	const [rowId, setRowId] = useLocalStorageState('selectedRow', 0)
	const { data: tasks, error: tasksError, isFetching: isFetchingTasks } = useFetchTasksQuery({ activeTasksOnly: true })

	async function handleMarkAsComplete(templateId: string, contract: string, tagCode: string) {
		setExecutingAction(Action.MarkTaskComplete)
		try {
			await dispatch(markAsCompleteThunk({ templateId, contract, tagCode, tasks })).unwrap()
		} finally {
			setExecutingAction(null)
		}
	}

	// const readonlyChildForms: string[] = (task?.metadata?.read_only || '').split(',').filter(Boolean)
	// const writeableChildForms: string[] = (task?.metadata?.write || '').split(',').filter(Boolean)
	// const visibleChildForms: string[] = useMemo(
	// 	() => [...writeableChildForms, ...readonlyChildForms],
	// 	[writeableChildForms, readonlyChildForms],
	// )

	// const canViewForm: boolean = row.task.possible_actions.includes(Action.FillFormAttributes)
	const canCloseForm: boolean = props.subtasks.every((taskElement) => taskElement.status === TaskStatus.Finished)
	const canOpenFormPdf: boolean =
		row.task.status === TaskStatus.Finished && row.task.possible_actions.includes(Action.ExportForm)
	const [isOpenDialog, setOpenDialog] = useState<boolean>(false)
	const [isOpeningPdf, setOpeningPdf] = useState<boolean>(false)
	const [executingAction, setExecutingAction] = useState<Action | null>(null)
	const [isExpanded, setExpanded] = useState<boolean>(props.node.expanded)

	useEffect(() => {
		props.node.addEventListener(RowNode.EVENT_EXPANDED_CHANGED, () => {
			setExpanded(props.node.expanded)
		})
	}, [props.node])

	const handleAction = useCallback(
		(action: Action, taskId: number) => async (event?: any) => {
			event?.stopPropagation()
			setExecutingAction(action)
			const actions: Partial<Record<Action, () => Promise<any>>> = {
				[Action.RecallTaskForm]: () => actionTaskService.recall(taskId, contract),
				[Action.ResendTask]: () => actionTaskService.resend(taskId, contract),
				[Action.MarkTaskComplete]: () => actionTaskService.markAsComplete(taskId, contract),
				[Action.FinishForm]: () => actionTaskService.finish(taskId, contract),
				[Action.CloseForm]: () => actionTaskService.close(taskId, contract),
			}
			try {
				await actions[action]?.()
				dispatch(tasksApi.util.invalidateTags(['Tasks']))
			} catch (e) {
				toast.error('Failed to execute action.')
			} finally {
				setExecutingAction(null)
			}
		},
		[contract, dispatch],
	)

	const handleOpenPdf = async () => {
		setOpeningPdf(true)
		try {
			const pdfData = await actionFormService.downloadPdf(row.task.main_asset.tag_code, contract)
			openDownloadPdf(pdfData, getDocumentNumber(row.task) as string)
		} catch (e) {
			toast.error('Cannot download PDF file.')
		} finally {
			setOpeningPdf(false)
		}
	}

	const selectActionButton = (uiAction: UiAction) => {
		const BUTTONS_MAP: Partial<Record<Action, JSX.Element | null>> = {
			[Action.SendTaskPriviliges]: (
				<Tooltip disableInteractive title="Send task" enterDelay={300}>
					<span>
						<IconButton onClick={() => setOpenDialog(true)} color="primary" aria-label="send_task">
							<SendOutlinedIcon />
						</IconButton>
					</span>
				</Tooltip>
			),
			[Action.RecallTaskForm]: (
				<Tooltip disableInteractive title="Re-call task" enterDelay={300}>
					<span>
						<IconButton
							color="primary"
							aria-label="recall_task"
							onClick={handleAction(Action.RecallTaskForm, uiAction.taskId)}
						>
							{executingAction === Action.RecallTaskForm ? (
								<SpinnerComponent size={22} />
							) : (
								<CancelScheduleSendOutlinedIcon />
							)}
						</IconButton>
					</span>
				</Tooltip>
			),
			[Action.ResendTask]: (
				<Tooltip disableInteractive title="Re-send task" enterDelay={300}>
					<span>
						<IconButton
							color="primary"
							aria-label="resend_task"
							onClick={handleAction(Action.ResendTask, uiAction.taskId)}
						>
							{executingAction === Action.ResendTask ? <SpinnerComponent size={22} /> : <SendOutlinedIcon />}
						</IconButton>
					</span>
				</Tooltip>
			),
			[Action.FinishForm]:
				row.task.status === TaskStatus.AwaitingAcceptance ? (
					<Tooltip disableInteractive title="Finish task" enterDelay={300}>
						<span>
							<IconButton
								color="primary"
								aria-label="finish_task"
								onClick={handleAction(Action.FinishForm, uiAction.taskId)}
							>
								{executingAction === Action.FinishForm ? (
									<SpinnerComponent size={22} />
								) : (
									<AssignmentTurnedInOutlinedIcon />
								)}
							</IconButton>
						</span>
					</Tooltip>
				) : null,
		}

		return BUTTONS_MAP[uiAction.action]
	}
	return (
		<Box sx={{ display: 'flex' }}>
			{isOpenDialog && (
				<SendTaskDialog
					task={row.task}
					open={isOpenDialog}
					onClose={() => {
						setOpenDialog(false)
					}}
				/>
			)}
			{isMainTask ? (
				<Fragment>
					{task.task_name === TaskName.FORM_CONTRIBUTOR &&
						row.actions
							.filter((uiAction) => !!selectActionButton(uiAction))
							.map((uiAction) => <Fragment key={uiAction.action}>{selectActionButton(uiAction)}</Fragment>)}
					{canCloseForm && (
						<Tooltip disableInteractive title="Close" enterDelay={300}>
							<span>
								<IconButton color="primary" aria-label="close" onClick={handleAction(Action.CloseForm, task.task_id)}>
									{executingAction === Action.CloseForm ? <SpinnerComponent size={22} /> : <CheckCircleOutlineIcon />}
								</IconButton>
							</span>
						</Tooltip>
					)}
					{canOpenFormPdf && (
						<Tooltip disableInteractive title="Download PDF" enterDelay={300}>
							<IconButton color="primary" aria-label="open-pdf" onClick={handleOpenPdf}>
								{isOpeningPdf ? <SpinnerComponent size={22} /> : <PictureAsPdfOutlinedIcon />}
							</IconButton>
						</Tooltip>
					)}
					{task.status !== TaskStatus.Finished && (
						<Tooltip disableInteractive title="Mark as complete" enterDelay={300}>
							<span>
								<IconButton
									color="primary"
									aria-label="mark_as_complete"
									onClick={() =>
										handleMarkAsComplete(
											task.metadata.form_template_id,
											task.main_asset.contract,
											task.main_asset.tag_code,
										)
									}
								>
									{executingAction === Action.MarkTaskComplete ? <SpinnerComponent size={22} /> : <TaskOutlinedIcon />}
								</IconButton>
							</span>
						</Tooltip>
					)}
					<Tooltip disableInteractive title="View form" enterDelay={300}>
						<span>
							<Link to={`/forms/${row.id}`}>
								<IconButton
									onClick={() => {
										setRowId(row.id)
									}}
									color="primary"
									aria-label="preview"
								>
									<PlagiarismOutlinedIcon />
								</IconButton>
							</Link>
						</span>
					</Tooltip>
					{props.node.hasChildren() && (
						<IconButton
							color="primary"
							aria-label="expand"
							onClick={() => {
								props.node.setExpanded(!props.node.expanded)
							}}
						>
							{isExpanded ? <ExpandMoreOutlinedIcon /> : <ChevronLeftOutlinedIcon />}
						</IconButton>
					)}
				</Fragment>
			) : (
				<Fragment>
					{row.actions
						.filter((uiAction) => !!selectActionButton(uiAction))
						.map((uiAction) => (
							<Fragment key={uiAction.action}>{selectActionButton(uiAction)}</Fragment>
						))}
					<Tooltip disableInteractive title="View task" enterDelay={300}>
						<span>
							<Link to={`/forms/${row.id}`}>
								<IconButton color="primary" aria-label="preview">
									<PlagiarismOutlinedIcon />
								</IconButton>
							</Link>
						</span>
					</Tooltip>
				</Fragment>
			)}
		</Box>
	)
}

const TASK_STATUS_COLORS: Record<(typeof TASK_STATUS_LABELS)[keyof typeof TASK_STATUS_LABELS], string> = {
	'In progress': orange[500],
	Finished: green[500],
	Pending: grey[500],
	'Awaiting acceptance': grey[900],
}
