/* eslint-disable no-case-declarations */
import { ActionType } from 'typesafe-actions'

import { fetchProfileThunk } from 'src/features/profile/fetchProfileThunk'
import * as actions from './actions'
import { CHANGE_USER_TEXT, HANDLE_IS_LOADING_USERS, SET_CONTRACT, SET_USERS } from './constants'

export type UsersAction = ActionType<typeof actions>

export interface UsersState {
	users: any[]
	userText: string
	isLoadingUsers: boolean
	contract: Contract | null
	contracts: Contract[]
}

export interface Contract {
	contract: string
	contract_name: string
}

const initialUsers: UsersState = {
	users: [],
	userText: '',
	isLoadingUsers: false,
	contract: null,
	contracts: [],
}

const usersReducer = (state: UsersState = initialUsers, action: UsersAction) => {
	switch (action.type) {
		case CHANGE_USER_TEXT:
			return { ...state, userText: action.payload }
		case HANDLE_IS_LOADING_USERS:
			return { ...state, isLoadingUsers: action.payload }
		case fetchProfileThunk.fulfilled.type:
			const contractId = localStorage.getItem('contract')
			return {
				...state,
				contract: contractId
					? (action.payload as any).profile.contract_list.find((c: Contract) => c.contract === contractId)
					: (action.payload as any).profile.contract,
				contracts: (action.payload as any).profile.contract_list,
			}
		case SET_USERS:
			return { ...state, users: action.payload }
		case SET_CONTRACT:
			return { ...state, contract: action.payload }
		default:
			return state
	}
}

export default usersReducer
