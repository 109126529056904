/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit'
import {
	addComponent,
	addNewAttribute,
	addNewComponent,
	assignGuideInfo,
	cancelFormEdit,
	clearForm,
	deleteRowComponent,
	handleAttributeByKey,
	handleAutoFocus,
	handleComponentsHeaderValues,
	handleComponentValue,
	handleEditComponent,
	handleFormDetails,
	handleIsEditForm,
	handleIsGettingForm,
	handleIsOpenAttributes,
	handleIsSavingNewComponent,
	handleIsSavingNewForm,
	handleIsUpdatingMainForm,
	handleOpenComponents,
	handleSelectedFile,
	isCheckingCode,
	isDownloadingGuide,
	isRemovingGuide,
	isUploadingGuide,
	removeAttribute,
	removeComponent,
	setAllSequences,
	setAttributes,
	setComponentAttributeTypes,
	setComponents,
	setFormDetails,
	setIsFormNumberOriginal,
} from './actions'
import { IFormsHandler } from '../../models/formsHandler/formsHandler'
import { FormObject, newComponentElement } from './utils'
import { isNumeric } from 'rxjs/internal-compatibility'

export const initialFormState: IFormsHandler = {
	IsFormsLoading: false,
	IsEditForm: false,
	isSavingExistingComponent: false,
	isUpdatingMainForm: false,
	isSavingNewForm: false,
	isDownloadingGuide: false,
	isRemovingGuide: false,
	isCheckingCode: true,
	isUploadingGuide: false,
	isFormNumberOriginal: false,
	selectedFile: '',
	guideInfo: {
		id: '',
		name: '',
		main_form_id: '',
	},
	focusName: '',
	sequences: [],
	attributesTypesForComponent: [],
	// Details
	FormDetails: {
		formName: {
			value: '',
			label: 'Form Name',
		},
		formNumber: {
			value: '',
			label: 'Form Number',
		},
		formRevision: {
			value: '',
			label: 'Form Revision',
		},
		isOpen: {
			value: true,
			label: 'Is Open',
		},
	},
	// Attributes
	FormAttributes: {
		attributes: [],
		oldAttributes: [],
		dependsOnOptions: [],
		isOpen: true,
		isChecked: false,
	},
	// Components
	FormComponents: {
		isOpen: true,
		isChecked: true,
		dependsOnOptions: [],
		attributes: [],
		oldAttributes: [],
	},
}

const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key]

export const formsHandlerSlice = createSlice({
	name: 'formsHandler',
	initialState: initialFormState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(clearForm.match, (state) => {
				state.FormAttributes = initialFormState.FormAttributes
				state.FormComponents = initialFormState.FormComponents
				state.FormDetails = initialFormState.FormDetails
				state.IsEditForm = initialFormState.IsEditForm
				state.sequences = initialFormState.sequences
				state.focusName = initialFormState.focusName
				state.guideInfo = initialFormState.guideInfo
				state.isDownloadingGuide = initialFormState.isDownloadingGuide
				state.isUploadingGuide = initialFormState.isUploadingGuide
				state.isFormNumberOriginal = initialFormState.isFormNumberOriginal
				state.isCheckingCode = initialFormState.isCheckingCode
			})
			.addMatcher(handleAutoFocus.match, (state, { payload }) => {
				state.focusName = payload
			})
			.addMatcher(isDownloadingGuide.match, (state, { payload }) => {
				state.isDownloadingGuide = payload
			})
			.addMatcher(isUploadingGuide.match, (state, { payload }) => {
				state.isUploadingGuide = payload
			})
			.addMatcher(isRemovingGuide.match, (state, { payload }) => {
				state.isRemovingGuide = payload
			})
			.addMatcher(handleSelectedFile.match, (state, { payload }) => {
				state.selectedFile = payload
			})
			.addMatcher(assignGuideInfo.match, (state, { payload }) => {
				state.guideInfo = payload
			})
			.addMatcher(handleIsEditForm.match, (state, { payload }) => {
				if (payload) {
					state.FormAttributes.oldAttributes = state.FormAttributes.attributes
					state.FormComponents.oldAttributes = state.FormComponents.attributes
				}
				state.IsEditForm = payload
			})
			.addMatcher(cancelFormEdit.match, (state) => {
				state.FormAttributes.attributes = state.FormAttributes.oldAttributes
				state.FormAttributes.oldAttributes = []
				state.FormComponents.attributes = state.FormComponents.oldAttributes
				state.FormComponents.oldAttributes = []
			})
			.addMatcher(setComponentAttributeTypes.match, (state, { payload }) => {
				state.attributesTypesForComponent = payload
			})
			.addMatcher(setAllSequences.match, (state, { payload }) => {
				state.sequences = payload
			})
			// getters
			.addMatcher(handleIsGettingForm.match, (state, { payload }) => {
				state.IsFormsLoading = payload
			})
			// Form details
			.addMatcher(setFormDetails.match, (state, { payload }) => {
				const details: any = {}
				// tslint:disable-next-line:forin
				for (const stateElement in state.FormDetails) {
					details[stateElement] = payload[stateElement]
						? {
								...getKeyValue(stateElement)(state.FormDetails),
								...payload[stateElement],
						  }
						: getKeyValue(stateElement)(state.FormDetails)
				}
				state.FormDetails = details
			})
			.addMatcher(handleFormDetails.match, (state, { payload }) => {
				state.FormDetails = {
					...state.FormDetails,
					[payload.key]: {
						...getKeyValue(payload.key)(state.FormDetails),
						value: payload.value,
					},
				}
			})
			// Form Attributes
			.addMatcher(handleIsOpenAttributes.match, (state, { payload }) => {
				state.FormAttributes.isOpen = payload
			})
			.addMatcher(setAttributes.match, (state, { payload }) => {
				state.FormAttributes = payload
			})
			.addMatcher(removeAttribute.match, (state, { payload }) => {
				state.FormAttributes.attributes = state.FormAttributes.attributes
					.filter((item) => item.id !== payload)
					.map((attribute) => {
						return attribute.depends_on && attribute.depends_on.value && attribute.depends_on.value.id === payload
							? { ...attribute, depends_on: { ...attribute.depends_on, value: null } }
							: attribute
					})
				state.FormComponents.dependsOnOptions = state.FormComponents.dependsOnOptions.filter(
					(depend) => depend.id !== payload,
				)
				state.FormAttributes.dependsOnOptions = state.FormAttributes.dependsOnOptions.filter(
					(depend) => depend.id !== payload,
				)
				state.FormComponents.attributes = state.FormComponents.attributes.map((attribute) =>
					attribute.dependsOn && attribute.dependsOn.id === payload
						? { ...attribute, dependsOn: { ...attribute.dependsOn, value: '', label: '', id: '' } }
						: attribute,
				)
				state.sequences = state.sequences.filter((item) => item.id !== payload)
			})
			.addMatcher(addNewAttribute.match, (state) => {
				const seq = state.sequences.filter((item) => isNumeric(item.sequence))
				// @ts-ignore
				const newSequence = seq.length === 0 ? 1 : Math.max(...seq.map((item) => item.sequence)) + 1
				const newId = new Date().getTime()
				state.FormAttributes.attributes = [...state.FormAttributes.attributes, FormObject(newId, newSequence)]
				state.FormComponents.dependsOnOptions = [
					...state.FormComponents.dependsOnOptions,
					{
						id: newId,
						value: '',
						label: '',
					},
				]
				state.FormAttributes.dependsOnOptions = [
					...state.FormAttributes.dependsOnOptions,
					{
						id: newId,
						value: '',
						label: '',
					},
				]
				state.sequences = [...state.sequences, { id: newId, sequence: newSequence }]
			})
			.addMatcher(handleAttributeByKey.match, (state, { payload }) => {
				const newAttributes = state.FormAttributes.attributes
					.map((attribute) =>
						payload.keyName === 'attribute_name'
							? attribute.depends_on.value && attribute.depends_on.value && attribute.depends_on.value.id === payload.id
								? { ...attribute, depends_on: { ...attribute.depends_on, value: null } }
								: attribute
							: attribute,
					)
					.map((element) =>
						element.id === payload.id
							? {
									...element,
									[payload.keyName]: {
										...getKeyValue(payload.keyName)(element),
										value: payload.value,
									},
							  }
							: element,
					)

				state.FormAttributes.attributes = newAttributes
				state.focusName = `${payload.focusStart}${payload.keyName}${payload.id}`
				if (payload.keyName === 'attribute_name') {
					state.FormComponents.attributes = state.FormComponents.attributes.map((item) =>
						item.dependsOn.id === payload.id ? { ...item, dependsOn: { value: '', label: '', id: '' } } : item,
					)
					state.FormComponents.dependsOnOptions = state.FormComponents.dependsOnOptions.map((depend) =>
						depend.id === payload.id
							? {
									...depend,
									value: payload.value,
									label: payload.value,
							  }
							: depend,
					)
					state.FormAttributes.dependsOnOptions = state.FormAttributes.dependsOnOptions.map((depend) =>
						depend.id === payload.id
							? {
									...depend,
									value: payload.value,
									label: payload.value,
							  }
							: depend,
					)
				}
				if (payload.keyName === 'sequence') {
					state.sequences = state.sequences.map((item) =>
						item.id === payload.id
							? {
									...item,
									sequence: payload.value,
							  }
							: item,
					)
				}
			})

			// Components
			.addMatcher(setComponents.match, (state, { payload }) => {
				state.FormComponents = payload
			})
			.addMatcher(handleComponentsHeaderValues.match, (state, { payload }) => {
				state.FormComponents.attributes = state.FormComponents.attributes
					.map((attribute) =>
						payload.keyName === 'componentName'
							? attribute.dependsOn && attribute.dependsOn.id === payload.id
								? {
										...attribute,
										dependsOn: {
											value: '',
											label: '',
											id: '',
										},
								  }
								: attribute
							: attribute,
					)
					.map((el) =>
						el.id === payload.id
							? {
									...el,
									[payload.keyName]: payload.value,
							  }
							: el,
					)
				if (payload.keyName === 'sequence') {
					state.sequences = state.sequences.map((item) =>
						item.id === payload.id
							? {
									...item,
									sequence: payload.value,
							  }
							: item,
					)
				}
				if (payload.keyName === 'componentName') {
					state.FormComponents.dependsOnOptions = state.FormComponents.dependsOnOptions.map((depend) =>
						depend.id === payload.id
							? {
									...depend,
									value: payload.value,
									label: payload.value,
							  }
							: depend,
					)
					state.FormAttributes.dependsOnOptions = state.FormAttributes.dependsOnOptions.map((depend) =>
						depend.id === payload.id
							? {
									...depend,
									value: payload.value,
									label: payload.value,
							  }
							: depend,
					)
					state.FormAttributes.attributes = state.FormAttributes.attributes.map((attribute) =>
						attribute.depends_on && attribute.depends_on.value && attribute.depends_on.value.id === payload.id
							? { ...attribute, depends_on: { ...attribute.depends_on, value: null } }
							: attribute,
					)
				}
			})
			.addMatcher(handleOpenComponents.match, (state, { payload }) => {
				state.FormComponents.isOpen = payload
			})
			.addMatcher(handleComponentValue.match, (state, { payload }) => {
				state.FormComponents.attributes = state.FormComponents.attributes.map((el) =>
					el.id === payload.componentId
						? {
								...el,
								dependsOnOptions:
									payload.keyName === 'attribute_name'
										? el.dependsOnOptions.map((option) =>
												option.id === payload.rowId
													? {
															...option,
															value: payload.value,
															label: payload.value,
													  }
													: option,
										  )
										: el.dependsOnOptions,
								sequences:
									payload.keyName === 'sequence'
										? el.sequences.map((sequence) =>
												sequence.id === payload.rowId
													? {
															...sequence,
															sequence: payload.value,
													  }
													: sequence,
										  )
										: el.sequences,
								attributes: el.attributes
									.map((attribute) =>
										payload.keyName === 'attribute_name'
											? attribute.depends_on.value &&
											  attribute.depends_on.value &&
											  attribute.depends_on.value.id === payload.rowId
												? { ...attribute, depends_on: { ...attribute.depends_on, value: null } }
												: attribute
											: attribute,
									)
									.map((item) =>
										item.id === payload.rowId
											? {
													...item,
													[payload.keyName]: {
														...getKeyValue(payload.keyName)(item),
														value: payload.value,
													},
											  }
											: item,
									),
						  }
						: el,
				)
				state.focusName = state.focusName = `${payload.focusStart}${payload.keyName}${payload.rowId}`
			})
			.addMatcher(addComponent.match, (state, { payload }) => {
				const newId = new Date().getTime()
				state.FormComponents.attributes = state.FormComponents.attributes.map((item) => {
					const seq = item.sequences.filter((el) => isNumeric(el.sequence))
					// @ts-ignore
					const newSequence = seq.length === 0 ? 1 : Math.max(...seq.map((el) => el.sequence)) + 1
					return item.id === payload.componentId
						? {
								...item,
								attributes: [...item.attributes, FormObject(newId, newSequence)],
								dependsOnOptions: [...item.dependsOnOptions, { value: '', label: '', id: newId }],
								sequences: [...item.sequences, { id: newId, sequence: newSequence }],
						  }
						: item
				})
			})
			.addMatcher(deleteRowComponent.match, (state, { payload }) => {
				state.FormComponents.attributes = state.FormComponents.attributes.map((item) =>
					item.id === payload.componentId
						? {
								...item,
								attributes: item.attributes
									.filter((element) => element.id !== payload.id)
									.map((attribute) => {
										return attribute.depends_on &&
											attribute.depends_on.value &&
											attribute.depends_on.value.id === payload.id
											? { ...attribute, depends_on: { ...attribute.depends_on, value: null } }
											: attribute
									}),
								dependsOnOptions: item.dependsOnOptions.filter((el) => el.id !== payload.id),
								sequences: item.sequences.filter((el) => el.id !== payload.id),
						  }
						: item,
				)
				state.FormAttributes.dependsOnOptions = state.FormAttributes.dependsOnOptions.filter(
					(depend) => depend.id !== payload.id,
				)
			})
			.addMatcher(addNewComponent.match, (state) => {
				const seq = state.sequences.filter((item) => isNumeric(item.sequence))
				// @ts-ignore
				const newSequence = seq.length === 0 ? 1 : Math.max(...seq.map((item) => item.sequence)) + 1
				const newId = new Date().getTime()
				state.FormAttributes.dependsOnOptions = [
					...state.FormAttributes.dependsOnOptions,
					{
						id: newId,
						value: '',
						label: '',
					},
				]
				state.FormComponents.dependsOnOptions = [
					...state.FormComponents.dependsOnOptions,
					{
						id: newId,
						value: '',
						label: '',
					},
				]
				state.FormComponents.attributes = [
					...state.FormComponents.attributes,
					newComponentElement({ id: newId, is_new: true, sequence: newSequence }),
				]
			})
			.addMatcher(removeComponent.match, (state, { payload }) => {
				state.FormComponents.attributes = state.FormComponents.attributes.filter(
					(attribute) => attribute.id !== payload,
				)
				state.FormComponents.dependsOnOptions = state.FormComponents.dependsOnOptions.filter(
					(depend) => depend.id !== payload,
				)
				state.FormAttributes.dependsOnOptions = state.FormAttributes.dependsOnOptions.filter(
					(depend) => depend.id !== payload,
				)
				state.FormAttributes.attributes = state.FormAttributes.attributes.map((attribute) =>
					attribute.depends_on && attribute.depends_on.value && attribute.depends_on.value.id === payload
						? { ...attribute, depends_on: { ...attribute.depends_on, value: null } }
						: attribute,
				)
			})
			.addMatcher(handleEditComponent.match, (state, { payload }) => {
				state.FormComponents.attributes = state.FormComponents.attributes.map((attribute) =>
					attribute.id === payload.id
						? payload.isEditing
							? { ...attribute, isEditing: payload.isEditing, oldAttributes: attribute.attributes }
							: { ...attribute, isEditing: payload.isEditing, oldAttributes: [], attributes: attribute.oldAttributes }
						: attribute,
				)
			})
			.addMatcher(handleIsSavingNewComponent.match, (state, { payload }) => {
				state.isSavingExistingComponent = payload
			})
			.addMatcher(handleIsUpdatingMainForm.match, (state, { payload }) => {
				state.isUpdatingMainForm = payload
			})
			.addMatcher(handleIsSavingNewForm.match, (state, { payload }) => {
				state.isSavingNewForm = payload
			})
			.addMatcher(setIsFormNumberOriginal.match, (state, { payload }) => {
				state.isFormNumberOriginal = payload
			})
			.addMatcher(isCheckingCode.match, (state, { payload }) => {
				state.isCheckingCode = payload
			})
	},
})

export const formsHandlerReducer = formsHandlerSlice.reducer
