import { useEffect } from 'react'
import { msalInstance } from 'src/utils/msalInstance'

let didInit: boolean = false

export function LogoutPage() {
	useEffect(() => {
		if (!didInit) {
			didInit = true
			msalInstance.logout()
		}
	}, [])

	return null
}
