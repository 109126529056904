import lodash from 'lodash'
import moment from 'moment'
import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import * as tasksListService from 'src/services/tasksList'
import { isOfType } from 'typesafe-actions'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import { getTasksList } from './actions'
import { GET_TASKS_LIST } from './constants'
import { tasksListActions } from './index'

interface ITaskActionResponse {
	main_asset: {
		attribute: {
			'TIDP Created Date': Date
		}
		class_code: string
		tag_code: string
	}
	related: any
	task_id: string
	task_role: string
	parent: {
		tag_code: string
	}
}

const sortDates = (a: ITaskActionResponse, b: ITaskActionResponse) => {
	const classCode = a.main_asset.class_code
	return (
		// @ts-ignore
		new Date(b.main_asset.attribute[`${classCode} Created Date`]) -
		// @ts-ignore
		new Date(a.main_asset.attribute[`${classCode} Created Date`])
	)
}

const sortByParents = (currentSortedElements: ITaskActionResponse[]) => {
	const currentSorted: ITaskActionResponse[] = currentSortedElements.reduce((acc: any, item) => {
		const array = []
		const existingElement = currentSortedElements.filter(
			(element: any) =>
				element.main_asset.tag_code === item.main_asset.tag_code ||
				(element.parent && element.parent.tag_code === item.main_asset.tag_code),
		)
		const existingInAcc = acc.filter((element: any) => element.main_asset.tag_code === item.main_asset.tag_code)
		if (existingElement.length > 0 && existingInAcc.length === 0) {
			const reducedElement = existingElement.reduce((accumulator: any, el) => {
				return {
					main_asset: accumulator.main_asset
						? { ...accumulator.main_asset, related: el.related }
						: { ...el.main_asset, related: el.related },
					parent: accumulator.parent ? accumulator.parent : el.parent,
					task_id: el.task_id,
					task_role: accumulator.task_role ? [...accumulator.task_role, el.task_role] : [el.task_role],
				}
			}, {})
			array.push(reducedElement)
		}
		return [...acc, ...array]
	}, [])
	const withParent = currentSorted.filter((e) => {
		return Array.isArray(e.parent) ? e.parent.length > 0 : Object.keys(e.parent).length > 0
	})
	const withoutParent = currentSorted
		.filter((e) => (Array.isArray(e.parent) ? e.parent.length === 0 : Object.keys(e.parent).length === 0))
		.map((e) => ({
			...e.main_asset,
			children: [],
		}))

	const currentElement = lodash.uniqBy(withParent, 'parent.tag_code').map((item) => {
		const children = lodash
			.uniqBy(
				withParent.map((element) => {
					if (element.parent.tag_code === item.parent.tag_code) {
						return {
							...element.main_asset,
						}
					}
					return {}
				}),
				'tag_code',
			)
			.filter((e) => Object.keys(e).length > 0)
		const related = currentSortedElements.filter(
			(element: any) => element.main_asset.tag_code === item.parent.tag_code && !!element.related,
		)
		return {
			...item.parent,
			children,
			related: related.length > 0 ? related[0].related : {},
		}
	})
	const elements = [...currentElement, ...withoutParent].map((item: any) => {
		const childTime = item.children
			.map((el: any) => el?.attribute[`${el.class_code} Created Date`])
			.filter((el: any) => {
				return moment(item.modified_date).valueOf() < moment(el).valueOf()
			})
			.sort((a: any, b: any) => moment(b).valueOf() - moment(a).valueOf())
		return childTime.length > 0 ? { ...item, modified_date: childTime[0] } : item
	})
	const sortedElements = elements.sort(
		(a: any, b: any) => moment(b.modified_date).valueOf() - moment(a.modified_date).valueOf(),
	)
	return lodash.uniqBy(sortedElements, 'tag_code')
}

export const getTasksListEpic: AppEpicDeprecated<ReturnType<typeof getTasksList>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(GET_TASKS_LIST)),
		withLatestFrom(state$),
		switchMap(() => {
			return concat(
				of(tasksListActions.setIsTaskListLoading(true)),
				fromPromise(tasksListService.getTasksList()).pipe(
					switchMap((response) => {
						const currentSorted: ITaskActionResponse[] = response.current.sort(sortDates)
						const previousSorted: ITaskActionResponse[] = response.previous.sort(sortDates)
						const current = sortByParents(currentSorted)
						const previous = sortByParents(previousSorted)

						return concat(
							of(tasksListActions.setIsTaskListLoading(false)),
							of(
								tasksListActions.setTasksList({
									current,
									previous,
								}),
							),
						)
					}),
					catchError((err: { status: number }) => {
						return concat(
							of(tasksListActions.setIsTaskListLoading(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: tasksListActions.getTasksList(),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export default combineEpics(getTasksListEpic)
