import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${reset}

* {
 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

body {
  .Toastify {
    .Toastify__toast-container {
      z-index: 1000000000000000000000;
    }
  }
  textarea {
    &:focus {
      outline: none;
    }
  }
  input {
    &:focus {
      outline: none;
    }
  }
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-style: ${({ theme }) => theme.fonts.mainStyle};
  color: ${({ theme }) => theme.fonts.mainColor};
  letter-spacing: -0.02em;
  margin: 0;
}

#root {
  height: 100%;
}

table, tbody, tfoot, thead, tr, th, td {
  vertical-align: middle;
}

a, a:active, a:focus{
  text-decoration: none;
  outline: none; 

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

`

export default GlobalStyle
