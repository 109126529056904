export const colors = {
	black: '#000000',
	black11: 'rgba(0, 0, 0, 0.11)',
	black15: 'rgba(0, 0, 0, 0.15)',
	black25: 'rgba(0, 0, 0, 0.25)',
	black47: 'rgba(0, 0, 0, 0.47)',
	black50: 'rgba(0, 0, 0, 0.5)',
	black62: 'rgba(0, 0, 0, 0.62)',

	danger10: 'rgba(225, 26, 13, 0.1)',
	danger100: '#E11A0D',
	danger2: 'rgba(225, 26, 13, 0.02)',
	danger25: 'rgba(225, 26, 13, 0.25)',
	danger5: 'rgba(225, 26, 13, 0.05)',
	danger50: 'rgba(225, 26, 13, 0.5)',

	disable: '#E6ECF7',
	darkGrey: '#5F6368',
	darkGrey46: '#464646',
	greyB0: '#B0B0B0',
	greyBD: '#BDBDBD',
	greyS: '#BFCEE8',
	greyC4: '#C4C4C4',
	lightGreyE0: '#E0E0E0',
	lightGreyE5: '#E5E5E5',
	lightGreyEC: '#ECECEC',
	lightGreyF2: '#F2F2F2',
	lightGreyF9: '#F9F9F9',
	lightGreyF10: '#F2F5FA',

	blue1: '#809DD3',

	main10: 'rgba(1, 60, 166, 0.1)',
	main100: '#013CA6',
	main2: 'rgba(1, 60, 166, 0.02)',
	main25: 'rgba(1, 60, 166, 0.25)',
	main5: 'rgba(1, 60, 166, 0.05)',
	main50: 'rgba(1, 60, 166, 0.5)',

	success10: 'rgba(1, 166, 37, 0.1)',
	success100: '#01A625',
	success2: 'rgba(1, 166, 37, 0.02)',
	success25: 'rgba(1, 166, 37, 0.25)',
	success5: 'rgba(1, 166, 37, 0.05)',
	success50: 'rgba(1, 166, 37, 0.5)',
	parent: 'rgba(1, 60, 166, 0.2)',

	warning10: 'rgba(237, 144, 5, 0.1)',
	warning100: '#ED9005',
	warning2: 'rgba(237, 144, 5, 0.02)',
	warning25: 'rgba(237, 144, 5, 0.25)',
	warning5: 'rgba(237, 144, 5, 0.05)',
	warning50: 'rgba(237, 144, 5, 0.5)',

	white: '#FFFFFF',
}
