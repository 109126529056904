/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { SpinnerOverview } from '../TIDPOverview/tidpOverviewCustomStyles'
import SpinnerComponent from '../../Atoms/Loader'
import Headline from '../../Atoms/Headline'
import { Roles } from '../../../features/authorization/reducer'
import Button from '../../Molecules/Button'
import starIcon from '../../../assets/icons/starIcon14.svg'
import browserHistory from '../../../services/history'
import { colors } from '../../../theme/colors'
import styled from 'styled-components'
import Filtes from '../../Molecules/Filters'
import { flip } from 'ramda'
import { MyColumn, MyTable, StyledTdContainer } from '../../Molecules/MyTable'
import { CellProps, Renderer, Row } from 'react-table'
import lodash from 'lodash'
import LGButton from '../../Molecules/LGButton'
import { dateFormat } from '../../../assets/dateFormats'
import moment from 'moment'
import { useAppDispatch, useAppSelector, useAppSelectorDeprecated } from '../../../utils/reduxUtils'
import { getTasksList } from '../../../features/tasksList/actions'
import { Layout } from 'src/components/Molecules/Layout'

const PageTitleWrapper = styled.div`
	margin-bottom: 30px;
`

const SwitchButtons = styled.div`
	display: flex;
	margin: 15px 0;
	justify-content: space-between;
	.left {
		display: flex;
		button:nth-child(2) {
			margin: 0 15px;
		}
	}
`

function TasksList() {
	const dispatch = useAppDispatch()
	const username = useAppSelector((state) => state.profile.user!.name)
	const { roles, isTasksListLoading, tasksList, contract } = useAppSelectorDeprecated((state) => ({
		roles: state.authorization.roles,
		isTasksListLoading: state.tasksList.isTasksListLoading,
		tasksList: state.tasksList.tasksList,
		contract: state.users.contract,
	}))

	const [visibleElement, handleVisibleElement] = useState(
		Object.keys(tasksList).map((item, index) => ({
			item,
			isVisible: index === 0,
			showName: item === 'current' ? 'Your Active Tasks' : 'Recent Tasks',
		})),
	)
	const [selectedFilters, handleSelectedFilters] = useState('All')
	const selectedElements =
		visibleElement.length > 0 ? tasksList[visibleElement.filter((item) => item.isVisible)[0].item] : []

	const getData = () => {
		if (Object.keys(contract).length !== 0) {
			dispatch(getTasksList())
		}
	}
	const assignData = () => {
		if (Object.keys(tasksList).length > 0 && visibleElement.length === 0) {
			handleVisibleElement(
				Object.keys(tasksList).map((item, index) => ({
					item,
					isVisible: index === 0,
					showName: item === 'current' ? 'Your Active Tasks' : 'Recent Tasks',
				})),
			)
		}
	}
	const fetchData = useCallback(getData, [contract])
	const assignElement = useCallback(assignData, [tasksList])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	useEffect(() => {
		assignElement()
	}, [assignElement])

	const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

	const changeTasksState = (name: string) => {
		handleVisibleElement(visibleElement.map((item) => ({ ...item, isVisible: item.item === name })))
	}

	interface TaskRow {
		id: number
		tagCode: string
		class: string
		index: number | string
		isParent: boolean
		name: string
		number: string
		owner: string | string[]
		status: string
		modifiedDate: string
		dueDate: string
	}

	const filters: any = lodash(
		selectedElements.map((item: any) => {
			const mainClass = item.class_code
			return { item: item?.attribute[`${mainClass} Status`] }
		}),
	)
		.groupBy('item')
		.map((items, name) => ({ title: name, counter: items.length }))
		.push({ title: 'All', counter: selectedElements.length })
		.value()

	const rows: TaskRow[] = []

	selectedElements
		.filter((cell: any) => {
			const mainClass = cell.class_code
			return selectedFilters === 'All' || selectedFilters === cell?.attribute[`${mainClass} Status`]
		})
		.forEach((cell: any, i: number) => {
			const mainClass = cell.class_code

			let dueDate: any = ''
			if (cell?.related && cell?.related.attribute) {
				const deadline =
					cell?.related.attribute['Technical Lifecycle Stage Code'] === 'DD'
						? cell.related.attribute['Design Freeze']
						: cell.related.attribute['T-12']
				const date = moment(new Date(deadline))
				const nowDate = moment().zone('+00:00')
				const dueDateCount = moment(date).diff(nowDate.format('l'), 'days')
				const dueDateCountReal = moment(date).diff(nowDate, 'days', true)
				dueDate =
					dueDateCountReal > 0 && dueDateCountReal < 1
						? `${moment(deadline).format(dateFormat.engDays)} (${moment(date).fromNow()})`
						: dueDateCount > 0
						? `${moment(deadline).format(dateFormat.engDays)} (${dueDateCount.toString()} ${
								dueDateCount === 1 ? 'day' : 'days'
						  })`
						: `${moment(deadline).format(dateFormat.engDays)} (Overdue)`
			}

			if (cell.children.length > 0) {
				let parentAsset = {}
				const parentClass = cell.class_code
				parentAsset = {
					id: cell.tag_code,
					isParent: lodash.includes(cell?.attribute[`${mainClass} Owner`], username),
					class: parentClass,
					tagCode: cell.tag_code,
					index: i + 1,
					name: cell?.attribute[`${mainClass} ${mainClass === 'DIDP' ? 'Name' : 'Title'}`],
					number: cell?.attribute[`${mainClass} Number`],
					owner:
						cell?.attribute[`${mainClass} Owner`] && cell?.attribute[`${mainClass} Owner`].length > 0
							? `${cell?.attribute[`${mainClass} Owner`].map((item: string) => item.substring(0, item.indexOf('@')))} `
							: [],
					status: cell?.attribute[`${mainClass} Status`],
					modifiedDate: moment(new Date(cell?.modified_date)).format(dateFormat.engHours),
					dueDate,
				}
				// @ts-ignore
				rows.push(parentAsset)
				cell.children.forEach((itemElement: any) => {
					const itemElementClassCode = itemElement.class_code
					// @ts-ignore
					rows.push({
						id: itemElement.tag_code,
						tagCode: itemElement.tag_code,
						isParent: lodash.includes(itemElement?.attribute[`${itemElementClassCode} Owner`], username),
						class: itemElementClassCode,
						index: '',
						name: itemElement?.attribute[
							`${itemElementClassCode} ${itemElementClassCode === 'DIDP' ? 'Name' : 'Title'}`
						],
						number: itemElement?.attribute[`${itemElementClassCode} Number`],
						owner:
							itemElement?.attribute[`${itemElementClassCode} Owner`] &&
							itemElement?.attribute[`${itemElementClassCode} Owner`].length > 0
								? `${itemElement?.attribute[`${itemElementClassCode} Owner`].map((item: string) =>
										item.substring(0, item.indexOf('@')),
								  )} `
								: [],
						status: itemElement?.attribute[`${itemElementClassCode} Status`],
						modifiedDate: moment(new Date(itemElement?.attribute[`${itemElementClassCode} Created Date`])).format(
							dateFormat.engHours,
						),
						dueDate: '',
					})
				})
			} else {
				let elementWithoutChildren = {}
				const childClass = cell.class_code
				elementWithoutChildren = {
					id: cell.tag_code,
					isParent: lodash.includes(cell?.attribute[`${childClass} Owner`], username),
					class: childClass,
					tagCode: cell.tag_code,
					index: i + 1,
					name: cell?.attribute[`${childClass} ${childClass === 'DIDP' ? 'Name' : 'Title'}`],
					number: cell?.attribute[`${childClass} Number`],
					owner:
						cell?.attribute[`${childClass} Owner`] && cell?.attribute[`${childClass} Owner`].length > 0
							? `${cell?.attribute[`${childClass} Owner`].map((item: string) => item.substring(0, item.indexOf('@')))} `
							: [],
					status: cell?.attribute[`${childClass} Status`],
					modifiedDate: moment(new Date(cell?.modified_date)).format(dateFormat.engHours),
					dueDate,
				}
				// @ts-ignore
				rows.push(elementWithoutChildren)
			}
		})

	// eslint-disable-next-line react/display-name
	const cellRenderer =
		(headerId: string /* FIXME */): Renderer<CellProps<TaskRow>> =>
		// eslint-disable-next-line react/display-name
		({ value, row }) => {
			return (
				<StyledTdContainer
					background={
						value !== ''
							? row.original.isParent
								? colors.parent
								: headerId === 'index' && !value
								? 'transparent'
								: '#013ca60d'
							: 'transparent'
					}
					center
				>
					{value}
				</StyledTdContainer>
			)
		}

	const columns: MyColumn<TaskRow>[] = [
		{
			Header: '',
			accessor: 'index',
			options: {
				noHeaderTdContainer: true,
			},
			maxWidth: 30,
			Cell: cellRenderer('index'),
		},
		{ Header: 'Name', accessor: 'name', Cell: cellRenderer('name') },
		{ Header: 'Number', accessor: 'number', Cell: cellRenderer('number') },
		{ Header: 'Owner', accessor: 'owner', Cell: cellRenderer('owner') },
		{ Header: 'Status', accessor: 'status', Cell: cellRenderer('status') },
		{ Header: 'Modified Date', accessor: 'modifiedDate', Cell: cellRenderer('modifiedDate') },
		{ Header: 'Due Date', accessor: 'dueDate', Cell: cellRenderer('dueDate') },
	].map((column) => ({ ...column, noTdContainer: true }) as MyColumn<TaskRow>)

	const handleTaskSelection = ({ original }: Row<TaskRow>) => {
		handleSelectedFilters('All')
		if (original.class === 'TIDP') {
			browserHistory.push(`/overview/${original.tagCode}`)
		}
		if (original.class === 'DIDP') {
			browserHistory.push(`/discipline-overview/${original.tagCode}`)
		}
	}

	return (
		<Layout>
			{isTasksListLoading ? (
				<SpinnerOverview>
					<SpinnerComponent size={100} />
				</SpinnerOverview>
			) : (
				<>
					<PageTitleWrapper>
						<div>
							<Headline
								title={`${
									visibleElement.length > 0
										? capitalize(visibleElement.filter((item) => item.isVisible)[0].showName)
										: ''
								}`}
								small
							/>
						</div>
						<SwitchButtons>
							<div className="left">
								{visibleElement.map((item) => (
									<LGButton
										key={item.item}
										text={item.showName}
										height={31}
										isWhiteText={item.isVisible}
										padding="6px 12px"
										onClick={() => changeTasksState(item.item)}
									/>
								))}
							</div>
							{roles.filter((role: string) => role === Roles['Registers:roles.Registers.WorkflowManager']).length >
								0 && (
								<div className="create">
									<Button
										text="Create new"
										imageSrc={starIcon}
										onClick={() => browserHistory.push('/create-new-tidp')}
										color={colors.main10}
									/>
								</div>
							)}
						</SwitchButtons>
					</PageTitleWrapper>

					<Filtes items={filters} selectedFilter={selectedFilters} onClick={handleSelectedFilters} />
					<MyTable<TaskRow> data={rows} columns={columns} onClickRow={flip(handleTaskSelection)} fullWidth />
				</>
			)}
		</Layout>
	)
}

export default TasksList
