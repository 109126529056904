import React from 'react'
import styled from 'styled-components'
import Image from '../../Atoms/Image'
import notificationsIcon from '../../../assets/icons/notificationAllert.svg'
import { colors } from '../../../theme/colors'
import Tooltip from '../Tooltip'
import { dateFormat } from '../../../assets/dateFormats'
import moment from 'moment'

const Wrapper = styled.div`
	cursor: pointer;
	position: relative;
	margin: 0 15px;
	padding: 5px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.lightGreyE0};
`
const NotificationWrapper = styled.div`
	max-height: 20vh;
	overflow: auto;
	&::-webkit-scrollbar-thumb {
		display: none;
	}
	&::-webkit-scrollbar {
		display: none;
	}
	padding: 15px;
	.notification {
		.brElement {
			margin: 10px 0;
			border-bottom: 1px solid ${colors.lightGreyE0};
		}
		&:last-child {
			.brElement {
				margin: 10px 0;
				border-bottom: none;
			}
		}
	}
`

const NotificationText = styled.div`
	margin-left: 50px;
`
const NotificationsCounter = styled.div`
	position: absolute;
	background: ${colors.success100};
	border-radius: 50%;
	padding: 3px;
	width: 20px;
	height: 20px;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${colors.white};
	right: -8px;
	top: -8px;
`
const NotificationItem = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
	.circleName {
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${colors.greyBD};
		color: ${colors.white};
		font-weight: 700;
		border-radius: 50%;
		margin-right: 10px;
	}
	.name {
		padding-bottom: 5px;
		font-size: 14px;
		font-weight: 700;
	}
	.date {
		font-size: 10px;
		font-weight: 700;
	}
`

const listOfExampleNotifies = [
	{
		name: 'sjain',
		date: new Date(),
		text: 'sjain notification text',
	},
	{
		name: 'mczarkowski',
		date: new Date(),
		text: 'mczarkowski notification text',
	},
	{
		name: 'mkasznia',
		date: new Date(),
		text: 'mkasznia notification text',
	},
	{
		name: 'lhoolbrok',
		date: new Date(),
		text: 'lhoolbrok notification text',
	},
]

interface INotifier {
	notificationsCounter: number
}

function Notifier(props: INotifier) {
	return (
		<Tooltip
			styleForTooltip={{
				top: '15px !important',
				left: '120px !important',
				padding: '0px !important',
			}}
			// TODO here should be click in future
			trigger="none"
			placement="bottom"
			hideArrow
			tooltip={
				<NotificationWrapper>
					{listOfExampleNotifies.map((item) => (
						<div className="notification" key={`${item.name}_${item.text}`}>
							<NotificationItem>
								<div className="circleName">{item.name.substring(0, 2)}</div>
								<div>
									<div className="name">{item.name}</div>
									<div className="date">{moment(new Date(item.date)).format(dateFormat.engHours)}</div>
								</div>
							</NotificationItem>
							<NotificationText>{item.text}</NotificationText>
							<div className="brElement" />
						</div>
					))}
				</NotificationWrapper>
			}
		>
			<Wrapper>
				<Image imageSrc={notificationsIcon} alt="notifier-icon" />
				{props.notificationsCounter > 0 && <NotificationsCounter>{props.notificationsCounter}</NotificationsCounter>}
			</Wrapper>
		</Tooltip>
	)
}

export default Notifier
