import { axiosInstance } from 'src/services/axiosInstance'
import { GET_ATTRIBUTES, REACT_ACTIONS, REACT_CREATE_ASSET } from '../constants'

export interface Attribute {
	contractId: string
	jsonPath: string
	attributeType: string
	attributeName: string
	sequence?: null
	attributeUnit?: null
	predeterminedList?: string[]
}

export const getCommentAttributes = async (contract: string) => {
	const { data } = await axiosInstance.get(`${GET_ATTRIBUTES}?contract=${contract}&class_code=Comment`)
	return data
}
export const PostQuery = async (body: any) => {
	const { data } = await axiosInstance.post(REACT_ACTIONS, body)
	return data
}
export const changeCommentStatus = async (comment: { tagCode: string; status: string; contract: string }) => {
	const { data } = await axiosInstance.post('/assets/Comment/create/', {
		contract: comment.contract,
		class_code: 'Comment',
		tag_code: comment.tagCode,
		attribute: {
			Status: comment.status,
		},
	})
	return data
}
