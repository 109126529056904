import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { IS_TASK_LIST_LOADING, SET_TASKS_LIST } from './constants'

export type TasksListActions = ActionType<typeof actions>

const initialTasksListReducer: ITasksList = {
	isTasksListLoading: false,
	tasksList: {},
}

const tidpsOverviewReducer = (state: ITasksList = initialTasksListReducer, action: TasksListActions) => {
	switch (action.type) {
		case IS_TASK_LIST_LOADING: {
			return { ...state, isTasksListLoading: action.payload }
		}
		case SET_TASKS_LIST: {
			return { ...state, tasksList: action.payload }
		}
		default:
			return state
	}
}

export default tidpsOverviewReducer
