import { yupResolver } from '@hookform/resolvers/yup'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import { GridApi, ICellRendererParams, RowNode } from 'ag-grid-community'
import { AgGridColumn } from 'ag-grid-react'
import { equals, omit } from 'ramda'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { batch } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { MessagePanel } from 'src/components/message-panel/MessagePanel'
import { DataGrid } from 'src/components/Molecules/DataGrid/DataGrid'
import { Action } from 'src/facade/action/actionService.types'
import { actionDidpService } from 'src/facade/action/didp/actionDidpService'
import { ModifiedDisciplineParams, NewDisciplineParams } from 'src/facade/action/didp/actionDidpService.type'
import { DidpMetadata } from 'src/facade/assets/didp/didpAssetsService.types'
import { RelatedTo, RelatedWith, TidpAsset } from 'src/facade/assets/tidp/tidpAssetsService.types'
import { useFetchDidpMetadataQuery } from 'src/features/didp/didpApi'
import { getTaskActions } from 'src/features/taskActions/actions'
import { tidpAssetsApi, useFetchTidpAssetsQuery } from 'src/features/tidp-assets/tidpAseetsApi'
import { useFetchUsersQuery } from 'src/features/users/usersApi'
import { useAppDispatch, useAppSelector } from 'src/utils/reduxUtils'
import * as yup from 'yup'

export interface DisciplineRow {
	id: string
	tagCode: string
	label: string
	status: string
	deliverablesCount: number
	owners: string[]
	comments: string
	isRequired: boolean
	deliverables: DeliverableRow[]
}

export interface DeliverableRow {
	label: string
	documentNumber: string
	documentType: string
	revision: string
	hs2Deliverable: string
	gdpr: string
	stage: string
	suitabilityStatus: string
	discipline: string
}

export interface TidpParams {
	param1: string // TODO rename to tagCode
}

export interface DisciplinesGridProps {
	allDeliverablesOpen: boolean
}

export const DisciplinesGrid: FC<DisciplinesGridProps> = ({ allDeliverablesOpen }) => {
	const {
		contract: { contract },
	} = useAppSelector((state) => state.users)

	const { param1: tagCode } = useParams<TidpParams>()

	const { data: tidps, isFetching } = useFetchTidpAssetsQuery({ tagCode, contract })

	const disciplines: DisciplineRow[] = useMemo(
		() =>
			(tidps?.[0]?.relatedTo || [])
				.filter((relatedToTidp) => relatedToTidp.class_code === 'DIDP')
				.map((relatedToTidp) => {
					const activeCommentsCount =
						relatedToTidp?.relatedTo?.filter(
							(nestedRelatedToTidp: RelatedTo) =>
								nestedRelatedToTidp.Status === 'Open' && nestedRelatedToTidp.class_name === 'Comment',
						)?.length ?? 0

					const inactiveCommentsCount =
						relatedToTidp?.relatedTo?.filter(
							(nestedRelatedToTidp: RelatedTo) =>
								nestedRelatedToTidp.Status !== 'Open' && nestedRelatedToTidp.class_name === 'Comment',
						)?.length ?? 0

					const deliverables = (
						relatedToTidp.relatedWith?.filter(
							(nestedRelatedWithTidp: RelatedWith) => nestedRelatedWithTidp.class_name === 'Document',
						) || []
					).map((deliverable: any) => ({
						label: deliverable['Document Name'],
						documentNumber: deliverable['Document Number'],
						documentType: deliverable['Document Type'],
						revision: deliverable.Revision,
						hs2Deliverable: deliverable['HS2 Deliverable'],
						gdpr: deliverable.GDPR,
						stage: deliverable.Stage,
						suitabilityStatus: deliverable['Suitability Status'],
						discipline: deliverable.Discipline,
					}))

					return {
						id: relatedToTidp.tag_code,
						tagCode: relatedToTidp.tag_code,
						label: relatedToTidp['DIDP Name'],
						status: relatedToTidp['DIDP Status'],
						deliverablesCount: deliverables.length,
						owners: (relatedToTidp['Nominated Owners'] ?? []).concat(relatedToTidp['DIDP Owner']),
						comments: `${activeCommentsCount} / ${inactiveCommentsCount}`,
						isRequired: relatedToTidp['DIDP Required'] === 'yes',
						deliverables,
					}
				})
				.sort((one, two) => (one.label > two.label ? 1 : -1)),
		[tidps],
	)

	if (isFetching) {
		return (
			<Box my={2} sx={{ display: 'flex', justifyContent: 'center', height: '90%', alignItems: 'center' }}>
				<SpinnerComponent size={64} />
			</Box>
		)
	}

	if (!tidps) {
		return <MessagePanel my={3} errorMessage="Failed to load tidp data." />
	}

	return <Wrapper allDeliverablesOpen={allDeliverablesOpen} disciplines={disciplines} tidps={tidps} />
}

export interface DisciplinesForm {
	disciplines: DisciplineRow[]
}

const schema = yup
	.object<DisciplinesForm>({
		disciplines: yup
			.array(
				yup.object({
					label: yup.string().required(),
					owners: yup.array().min(1).required(),
				}),
			)
			.required(),
	})
	.required()

const Wrapper: FC<{ disciplines: DisciplineRow[]; tidps: TidpAsset[]; allDeliverablesOpen: boolean }> = ({
	disciplines,
	tidps,
	allDeliverablesOpen,
}) => {
	const dispatch = useAppDispatch()
	const {
		contract: { contract },
	} = useAppSelector((state) => state.users)
	const { data: didpMetadata } = useFetchDidpMetadataQuery(contract)

	const { tasksActions } = useAppSelector((state) => state.tasksActions)

	const { param1: tagCode } = useParams<TidpParams>()

	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	useEffect(() => {
		gridApi?.forEachNode((node) => {
			if (node.data?.deliverables?.length > 0) {
				node.setExpanded(allDeliverablesOpen)
			}
		})
	}, [allDeliverablesOpen, gridApi])

	const { setValue, control, handleSubmit, formState } = useForm<DisciplinesForm>({
		resolver: yupResolver(schema as any),
		defaultValues: { disciplines },
	})
	const {
		fields: formDisciplines,
		append,
		remove,
	} = useFieldArray({
		control,
		name: 'disciplines',
		keyName: 'key',
	})
	const { dirtyFields, isDirty, isSubmitting, errors } = formState

	const technicalLifeCycles = tidps[0].relatedWith.filter((item: any) => item.class_name === 'Technical Lifecycles')
	const technicalLifeCycleCode = technicalLifeCycles?.[0]?.['Technical Lifecycle Stage Code']
	const disciplineMap = didpMetadata
		? getDisciplineLabels(didpMetadata)
		: { disciplineType: [], supportFunctionType: [] }
	const disciplineLabels: string[] = !!technicalLifeCycleCode
		? disciplineMap && Object.keys(disciplineMap).length > 0
			? technicalLifeCycleCode === 'DD'
				? disciplineMap.disciplineType
				: disciplineMap.supportFunctionType
			: []
		: []

	const { data: users, isLoading } = useFetchUsersQuery()

	const updateDisciplinesAction = tasksActions.find(
		(action: any) => action.action_name === Action.DidpUpdateDisciplines,
	)
	const canUpdate = !!updateDisciplinesAction

	const onSubmit = async (form: DisciplinesForm) => {
		const dirtyDisciplines = form.disciplines.filter((_, index) =>
			Object.values(omit(['deliverables'], dirtyFields?.disciplines?.[index]) || {}).some((val) =>
				Array.isArray(val) ? val.some(Boolean) : !!val,
			),
		)

		const modifiedDisciplines: ModifiedDisciplineParams[] = dirtyDisciplines
			.filter((discipline) => discipline.tagCode)
			.map((discipline) => ({
				tag_code: discipline.tagCode,
				'DIDP Required': discipline.isRequired,
				'Nominated Owners': discipline.owners,
			}))

		const newDisciplines: NewDisciplineParams[] = dirtyDisciplines
			.filter((discipline) => !discipline.tagCode)
			.map((discipline) => ({
				'DIDP Name': discipline.label,
				'DIDP Required': discipline.isRequired,
				'Nominated Owners': discipline.owners,
			}))

		await actionDidpService.updateDisciplines(updateDisciplinesAction.task_id, {
			contract,
			tag_code: tagCode,
			didps: [...modifiedDisciplines, ...newDisciplines],
		})
		batch(() => {
			dispatch(getTaskActions({ tagCode }))
			dispatch(tidpAssetsApi.util.invalidateTags(['TidpAssets']))
		})
	}

	return (
		<Box sx={{ '.ag-root': { '.ag-cell-hide-arrow': { '.ag-group-contracted': { visibility: 'hidden' } } } }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{canUpdate && (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
						<Button
							color="primary"
							variant="outlined"
							onClick={() =>
								// FIXME
								append({
									id: nanoid(),
									isRequired: true,
								} as any)
							}
						>
							Create
						</Button>
						<Box sx={{ ml: 1 }}>
							<LoadingButton
								loading={isSubmitting}
								loadingIndicator="Submitting..."
								color="primary"
								variant="contained"
								type="submit"
								disabled={!isDirty}
							>
								Submit
							</LoadingButton>
						</Box>
					</Box>
				)}

				<DataGrid
					fullWidth
					immutableData
					masterDetail
					onGridReady={({ api }) => setGridApi(api)}
					rowData={formDisciplines}
					getRowNodeId={(row: DisciplineRow) => row.id}
					loading={isLoading}
					onSortChanged={(params) => {
						params.api.refreshCells({ suppressFlash: true })
					}}
					statusBar={{
						statusPanels: [
							{
								statusPanel: 'agTotalAndFilteredRowCountComponent',
								align: 'right',
							},
						],
					}}
					// gridOptions={{ rowHeight: 46 }}
					// defaultColDef={{ autoHeight: true }}
					detailCellRendererParams={{
						refreshStrategy: 'rows',
						detailGridOptions: {
							enableCellChangeFlash: true,
							immutableData: true,
							animateRows: true,
							enableRangeSelection: true,
							detailRowAutoHeight: true,
							columnDefs: [
								{ headerName: 'Document Number', field: 'documentNumber', flex: 2 },
								{ headerName: 'Revision', field: 'revision' },
								{ headerName: 'Document Type', field: 'documentType' },
								{ headerName: 'Deliverable Title', field: 'label', flex: 2 },
								{ headerName: 'HS2 Deliverable', field: 'hs2Deliverable' },
								{ headerName: 'GDPR', field: 'gdpr' },
								{ headerName: 'Stage', field: 'stage' },
								{ headerName: 'Suitability Status', field: 'suitabilityStatus' },
								{ headerName: 'Discipline', field: 'discipline' },
							],
							defaultColDef: { flex: 1, sortable: true },
						},
						getDetailRowData: (params: any) => {
							params.successCallback(params.data.deliverables)
						},
					}}
				>
					<AgGridColumn
						width={48}
						wrapText={false}
						field="label"
						valueGetter={({ node }) => (node?.rowIndex ?? 0) + 1}
						headerName=""
						suppressMovable
						sortable={false}
						filter={false}
					/>
					<AgGridColumn
						flex={1}
						field="label"
						headerName="Discipline"
						cellEditor="autocompleteEditor"
						cellClass={({ data }) => {
							const index = formDisciplines.findIndex((discipline) => discipline?.id === data?.id)
							return errors?.disciplines?.[index]?.label ? 'ag-cell-error left-aligned' : 'left-aligned'
						}}
						editable={({ data }) => {
							return canUpdate && !data.tagCode
						}}
						cellEditorPopup
						cellEditorParams={{
							options: disciplineLabels.map((label) => ({ label: label, value: label })),
						}}
						onCellValueChanged={(params) => {
							const { id } = params.data
							const index = formDisciplines.findIndex((discipline) => discipline.id === id)
							setValue(`disciplines.${index}.label`, params.newValue, { shouldDirty: true })
							params.api.resetRowHeights()
						}}
					/>
					<AgGridColumn
						flex={1}
						field="owners"
						headerName="Owners"
						cellEditor="autocompleteEditor"
						cellClass={({ data }) => {
							const index = formDisciplines.findIndex((discipline) => discipline?.id === data?.id)
							return errors?.disciplines?.[index]?.owners ? 'ag-cell-error' : ''
						}}
						equals={(valueA, valueB) => {
							return equals(valueA, valueB)
						}}
						editable={canUpdate}
						cellEditorPopup
						cellEditorParams={{
							options: users?.map((user) => ({ label: user.username, value: user.username })) || [],
							selectProps: {
								isMulti: true,
							},
						}}
						onCellValueChanged={(params) => {
							const { id } = params.data
							const index = formDisciplines.findIndex((discipline) => discipline.id === id)
							setValue(`disciplines.${index}.owners`, params.newValue, { shouldDirty: true })
						}}
					/>
					<AgGridColumn
						flex={1}
						field="deliverablesCount"
						headerName="Deliverables"
						cellRenderer="agGroupCellRenderer"
						cellClass={({ data }) => {
							return data?.deliverables?.length === 0 ? 'ag-cell-hide-arrow' : ''
						}}
						cellRendererParams={(params: any) => {
							return params.value > 0 ? {} : { suppressDoubleClickExpand: true }
						}}
					/>
					<AgGridColumn flex={1} field="status" headerName="Status" />
					<AgGridColumn flex={1} field="comments" headerName="Comments" />
					<AgGridColumn
						suppressMenu
						sortable={false}
						filter={false}
						flex={1}
						cellClass="no-focus right-aligned"
						field="tagCode"
						headerName=""
						cellRendererFramework={(params: any) => {
							return (
								<ActionCellRenderer
									{...params}
									onRemove={(row) => {
										const index = formDisciplines.findIndex((discipline) => discipline.id === row.id)
										remove(index)
									}}
								/>
							)
						}}
					/>
				</DataGrid>
			</form>
		</Box>
	)
}

const ActionCellRenderer: FC<ICellRendererParams & { onRemove(row: DisciplineRow): void }> = (params) => {
	const [isExpanded, setExpanded] = useState<boolean>(params.node.expanded)

	useEffect(() => {
		params.node.addEventListener(RowNode.EVENT_EXPANDED_CHANGED, () => {
			setExpanded(params.node.expanded)
		})
	}, [params.node])

	return (
		<Fragment>
			{!params.value && (
				<Tooltip title="Remove" enterDelay={300}>
					<span>
						<IconButton aria-label="remove" onClick={() => params.onRemove(params.data)}>
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					</span>
				</Tooltip>
			)}
			{params.value && params.data?.deliverables?.length > 0 && (
				<IconButton
					color="primary"
					aria-label="expand"
					onClick={() => {
						params.node.setExpanded(!params.node.expanded)
					}}
				>
					{isExpanded ? <ExpandMoreOutlinedIcon /> : <ChevronLeftOutlinedIcon />}
				</IconButton>
			)}
			{params.value && (
				<Link to={`/discipline-overview/${params.value}`}>
					<Button color="primary" variant="outlined" aria-label="view" size="small">
						View discipline
					</Button>
				</Link>
			)}
		</Fragment>
	)
}

function getDisciplineLabels(didpMetadata: DidpMetadata): { disciplineType: string[]; supportFunctionType: string[] } {
	const disciplineType = didpMetadata.class[0].attributes.filter(
		(item: { attribute_name: string }) => item.attribute_name === 'Discipline Type',
	)
	const supportFunctionType = didpMetadata.class[0].attributes.filter(
		(item: { attribute_name: string }) => item.attribute_name === 'Support Function Type',
	)
	return {
		disciplineType: disciplineType.length > 0 ? (disciplineType[0].predetermined_list as string[]) : [],
		supportFunctionType: supportFunctionType.length > 0 ? (supportFunctionType[0].predetermined_list as string[]) : [],
	}
}
