import { Autocomplete } from '@mui/lab'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TidpComment } from 'src/features/comments/commentsSlice'
import { ICommentTypes, IReply } from 'src/models/disciplineOverview/disciplineOverview'
import { colors } from 'src/theme/colors'
import styled from 'styled-components'

const FiltersWrapper = styled.div`
	display: flex;
`

export interface CommentFilters {
	status?: string
	discipline?: string
	users?: string[]
}

export interface FilterProps {
	initialFilters: CommentFilters | null
	commentTypes: ICommentTypes[]
	comments: (TidpComment & { replies: IReply[]; code: string })[]
	onChange(filters: CommentFilters): void
}

export function Filters(props: FilterProps) {
	const { control, watch } = useForm<CommentFilters>({
		defaultValues:
			props.initialFilters == null
				? {
						status: '',
						discipline: '',
						users: [],
				  }
				: props.initialFilters,
	})

	const commentUsers: string[] = Array.from(new Set(props.comments.map((comment) => comment['Recorded By'])))

	useEffect(() => {
		const subscription = watch((form) => {
			props.onChange(form as CommentFilters)
		})
		return () => subscription.unsubscribe()
	}, [watch])

	return (
		<Paper
			elevation={0}
			sx={{
				margin: '0px',
				marginTop: '4px',
				backgroundColor: `${colors.lightGreyF2}`,
				borderRadius: '7px',
				width: '100%',
				marginBottom: '6px',
			}}
		>
			<form>
				<FiltersWrapper>
					<FormControl sx={{ m: 1 }} size="small" fullWidth>
						<InputLabel id="comment-status-select-label">Status</InputLabel>
						<Controller
							control={control}
							name="status"
							render={({ field }) => (
								<Select
									{...field}
									sx={{ backgroundColor: `${colors.lightGreyF9}` }}
									labelId="comment-status-select-label"
									label="Status"
								>
									<MenuItem value="">None</MenuItem>
									<MenuItem value="Open">Open</MenuItem>
									<MenuItem value="Closed">Closed</MenuItem>
								</Select>
							)}
						/>
					</FormControl>
					<Controller
						control={control}
						name="users"
						render={({ field }) => (
							<FormControl sx={{ m: 1 }} size="small" fullWidth>
								<Autocomplete
									{...field}
									multiple
									limitTags={0}
									sx={{ backgroundColor: `${colors.lightGreyF9}`, whiteSpace: 'pre-wrap' }}
									componentsProps={{
										paper: {
											sx: {
												width: 300,
											},
										},
									}}
									options={commentUsers}
									renderInput={(params) => (
										<TextField {...params} margin="none" label="User" fullWidth variant="outlined" size="small" />
									)}
									onChange={(e, option) => {
										field.onChange(option)
									}}
								/>
							</FormControl>
						)}
					/>
					<FormControl sx={{ m: 1 }} size="small" fullWidth>
						<InputLabel id="discipline-tag-code-select-label">Discipline</InputLabel>
						<Controller
							control={control}
							name="discipline"
							render={({ field }) => (
								<Select
									{...field}
									sx={{ backgroundColor: `${colors.lightGreyF9}` }}
									labelId="discipline-tag-code-select-label"
									label="Discipline"
								>
									<MenuItem value="">None</MenuItem>
									{props.commentTypes.map((comment: any) => (
										<MenuItem key={comment.type} value={`${comment.type}`}>
											{comment.type}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
				</FiltersWrapper>
			</form>
		</Paper>
	)
}
