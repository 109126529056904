export const apiUrl =
	import.meta.env.VITE_API !== ''
		? import.meta.env.VITE_API
		: `${window.location.href.substring(0, window.location.href.indexOf('app'))}aimsapi`
export const serverUrl =
	import.meta.env.VITE_LINK !== ''
		? import.meta.env.VITE_LINK
		: `${window.location.href.substring(0, window.location.href.indexOf('/app'))}`

// endpoints for demo
export const HEADER_SEARCH = `${apiUrl}/asset_service/search/`
export const REACT_SEARCH = `${apiUrl}/assets/search/`
export const REACT_CREATE_ASSET = `${apiUrl}/assets/create/`

export const GET_PRIMARY = `${apiUrl}/asset_service/primary/`

export const REACT_GET_OWNERS = `${apiUrl}/ui/data/mock_get_tidp_owners/`
export const SEARCH_USER = `${apiUrl}/user/search/`

export const GET_ATTRIBUTES = `${apiUrl}/assets/attribute/metadata/`

export const GET_CLASSES = `${apiUrl}/assets/class/metadata/`

export const GET_HISTORY = `${apiUrl}/workflow/audit/`

export const GET_DISCIPLINES = `${apiUrl}/assets/attribute/metadata/`

export const GET_TASKS = `${apiUrl}/workflow/tasks/?workflow_type=TIDP_Register_Workflow`
export const REACT_ACTIONS = `${apiUrl}/workflow/actions/`

export const REACT_GET_DOMAIN_HINT = `${apiUrl}/user/user_type/`

export const REACT_GET_DELIVERABLES = `${apiUrl}/asset_service/get_deliverables_for_didp/`
export const REACT_GET_ONE_DELIVERABLE = `${apiUrl}/task_service/tasks/?tag_code=`

export const REACT_GET_TIDP_DATA = `${apiUrl}/task_service/tasks/?tag_code=`

export const REACT_GROUP_MANAGEMENT = `${apiUrl}/group/`
export const REACT_GROUP_PRIVILEGES = `${apiUrl}/privileges/`

export const REACT_FORMS_LIST = `${apiUrl}/asset_service/attributes/list_parent/`
export const REACT_FORMS_COMPONENT_LIST = `${apiUrl}/asset_service/attributes/list_components/`
export const REMOVE_FORM = `${apiUrl}/asset_service/attributes/delete/`
export const REACT_FORMS_ATTRIBUTES = `${apiUrl}/asset_service/attributes/detail_attributes/`
export const REACT_FORMS_TYPES = `${apiUrl}/asset_service/attributes/types/`
export const REACT_FORMS_CREATE = `${apiUrl}/asset_service/attributes/create/`
export const REACT_FORMS_UPDATE = `${apiUrl}/asset_service/attributes/update_form/`
export const REACT_COMPONENT_UPDATE = `${apiUrl}/asset_service/attributes/update_component/`

export const DOWNLOAD_GUIDE = `${apiUrl}/asset_service/attributes/download_guidance/`
export const UPLOAD_GUIDE = `${apiUrl}/asset_service/attributes/upload_guidance/`
export const REMOVE_GUIDE = `${apiUrl}/asset_service/attributes/delete_guidance/`

export const REACT_GET_USERS_FOR_USER_MANAGEMENT = `${apiUrl}/user/user_management/`
export const REACT_RESET_PASSWORD_FOR_USER = `${apiUrl}/user/user_management/reset_password/`
export const REACT_GET_USER_BY_USER_ID = `${apiUrl}/user/user_management/id/`
export const REACT_UPDATE_USER = `${apiUrl}/user/user_management/update/`
export const REACT_DELETE_USER = `${apiUrl}/user/user_management/delete/`
export const REACT_ADD_USER = `${apiUrl}/user/user_management/add/`
