export const ASSIGN_USERNAME = 'login/ASSIGN_USERNAME'
export const CHANGE_IS_GETTING_ROLES = 'login/CHANGE_IS_GETTING_ROLES'
export const GET_USER_INFO = 'login/GET_USER_INFO'

export enum AimsModule {
	Assets = 'Assets',
	Documents = 'Documents',
	ThreeD = '3D',
	TIDP = 'TIDP',
	DIDP = 'DIDP',
	Atlas = 'Atlas',
}
