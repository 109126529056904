import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import {
	HANDLE_COMPONENTS_LIST_IS_LOADING,
	HANDLE_IS_REMOVING_FORM,
	HANDLE_LIST_IS_LOADING,
	SET_COMPONENT_OF_FORMS,
	SET_LIST_OF_FORMS,
} from './constants'
import { IFormsList } from '../../models/formsList/formsList'

export type FormsListActions = ActionType<typeof actions>

const initialFormsListReducer: IFormsList = {
	formsList: {
		page: 0,
		query: '',
		total: 0,
		forms: [],
	},
	componentsList: {
		page: 0,
		query: '',
		total: 0,
		forms: [],
	},
	isFormsListLoading: false,
	isRemovingForm: false,
	isComponentsListLoading: false,
}

const authorizationReducer = (state: IFormsList = initialFormsListReducer, action: FormsListActions) => {
	switch (action.type) {
		case HANDLE_LIST_IS_LOADING: {
			return { ...state, isFormsListLoading: action.payload }
		}
		case SET_LIST_OF_FORMS: {
			return { ...state, formsList: action.payload }
		}
		case SET_COMPONENT_OF_FORMS: {
			return { ...state, componentsList: action.payload }
		}
		case HANDLE_COMPONENTS_LIST_IS_LOADING: {
			return { ...state, isComponentsListLoading: action.payload }
		}
		case HANDLE_IS_REMOVING_FORM: {
			return { ...state, isRemovingForm: action.payload }
		}
		default:
			return state
	}
}

export default authorizationReducer
