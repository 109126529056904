import { toast } from 'react-toastify'
import { combineEpics } from 'redux-observable'
import { concat, of } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import * as formsListService from 'src/services/formsList'
import { isOfType } from 'typesafe-actions'
import { AppEpicDeprecated } from '../../utils/reduxUtils'
import { authorizationActions } from '../authorization'
import { getComponentsList, getFormsList, removeForm } from './actions'
import { formsListActions, formsListConstants } from './index'

export const getFormsListEpic: AppEpicDeprecated<ReturnType<typeof getFormsList>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(formsListConstants.GET_LIST_OF_FORMS)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(formsListActions.handleIsLoadingListOfForms(true)),
				action.payload.query !== state.formsList.formsList.query
					? of(
						formsListActions.setFormsList({
							...state.formsList.formsList,
							query: action.payload.query,
						}),
					)
					: of(),
				fromPromise(
					formsListService.getFormsList({
						page: action.payload.page,
						pageSize: action.payload.pageSize,
						contract: state.users.contract.contract,
						query: action.payload.query,
					}),
				).pipe(
					switchMap((response) => {
						const formsElements = {
							page: action.payload.page,
							total: response.total,
							query: action.payload.query,
							forms: response.result,
						}
						return concat(
							of(formsListActions.handleIsLoadingListOfForms(false)),
							of(formsListActions.setFormsList(formsElements)),
						)
					}),
					catchError((err: any) => {
						toast.error('Could not load list of forms')
						return concat(
							of(formsListActions.handleIsLoadingListOfForms(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: formsListActions.getFormsList(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const getComponentsListEpic: AppEpicDeprecated<ReturnType<typeof getComponentsList>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(formsListConstants.GET_COMPONENTS_OF_FORMS)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(formsListActions.handleIsLoadingListOfComponents(true)),
				action.payload.query !== state.formsList.formsList.query
					? of(
						formsListActions.setFormsList({
							...state.formsList.formsList,
							query: action.payload.query,
						}),
					)
					: of(),
				fromPromise(
					formsListService.getComponentsList({
						page: action.payload.page,
						pageSize: action.payload.pageSize,
						contract: state.users.contract.contract,
						query: action.payload.query,
					}),
				).pipe(
					switchMap((response) => {
						const formsElements = {
							page: action.payload.page,
							total: response.total,
							query: action.payload.query,
							forms: response.result,
						}
						return concat(
							of(formsListActions.handleIsLoadingListOfComponents(false)),
							of(formsListActions.setComponentsList(formsElements)),
						)
					}),
					catchError((err: any) => {
						toast.error('Could not load list of forms')
						return concat(
							of(formsListActions.handleIsLoadingListOfComponents(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: formsListActions.getComponentsList(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export const removeFormsEpic: AppEpicDeprecated<ReturnType<typeof removeForm>> = (action$, state$) =>
	action$.pipe(
		filter(isOfType(formsListConstants.REMOVE_FORM)),
		withLatestFrom(state$),
		switchMap(([action, state]) => {
			return concat(
				of(formsListActions.handleIsRemovingForm(true)),
				fromPromise(
					formsListService.removeForm({
						id: action.payload.id,
						name: action.payload.name,
						contract: state.users.contract.contract,
					}),
				).pipe(
					switchMap(() => {
						toast.success('Form removed.')
						const newForms = {
							...state.formsList.formsList,
							forms: state.formsList.formsList.forms.filter((el: any) => el.id !== action.payload.id),
						}
						return concat(of(formsListActions.handleIsRemovingForm(false)), of(formsListActions.setFormsList(newForms)))
					}),
					catchError((err: any) => {
						toast.error('Could not remove form from forms list.')
						return concat(
							of(formsListActions.handleIsRemovingForm(false)),
							of(
								authorizationActions.recallApi({
									errorCode: err.status,
									callback: formsListActions.removeForm(action.payload),
								}),
							),
						)
					}),
				),
			)
		}),
	)

export default combineEpics(getFormsListEpic, removeFormsEpic, getComponentsListEpic)
