/* eslint-disable no-nested-ternary */
import lodash from 'lodash'
import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router'
import { LoadingPage } from 'src/components/LoadingPage'
import { REFRESH_TOKEN_ATTEMPTED_STORAGE_KEY } from 'src/utils/msalInstance'
import styled from 'styled-components'
import { pages } from './AppWrapper'
import Header from './components/Organisms/Header'

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`

let didInit = false

const PrivateRoute: React.FunctionComponent<any> = ({
	isGettingRoles,
	contract,
	component: Component,
	componentRole,
	userRoles,
	...rest
}) => {
	useEffect(() => {
		if (!didInit) {
			sessionStorage.removeItem(REFRESH_TOKEN_ATTEMPTED_STORAGE_KEY)
			didInit = true
		}
	}, [])

	const componentElement = (isNoRoles: boolean, isLoading: boolean, props: any) => (
		<MainWrapper>
			{!contract ? (
				<LoadingPage />
			) : isNoRoles ? (
				<>
					<Header />
					<Component {...props} />
				</>
			) : (
				<Component {...props} />
			)}
		</MainWrapper>
	)

	return (
		<Route
			{...rest}
			render={(props) => {
				pages.filter((item) => {
					if (item.path.includes(props.location.pathname)) {
						if (item.roles) {
							const presents = lodash.intersectionWith(userRoles, item.roles, lodash.isEqual)
							if (presents.length === 0) {
								return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
							}
						}
					}
					return null
				})

				if (props.location.pathname !== '/no-roles' && !!contract && !isGettingRoles && userRoles.length === 0) {
					return <Redirect to={{ pathname: '/no-roles', state: { from: props.location } }} />
				}
				if (props.location.pathname === '/no-roles' && userRoles.length !== 0 && !isGettingRoles) {
					return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
				}
				if (props.location.pathname === '/login') {
					return <Redirect to={{ pathname: '/tasks-list', state: { from: props.location } }} />
				}
				if (props.location.pathname === '/' && userRoles) {
					return <Redirect to={{ pathname: '/tasks-list', state: { from: props.location } }} />
				}
				if (
					!!componentRole &&
					!!userRoles.length &&
					componentRole.filter((n: any) => userRoles.indexOf(n) !== -1).length === 0
				) {
					return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
				}
				return componentElement(props.location.pathname === '/no-roles', props.location.pathname === '/', props)
			}}
		/>
	)
}

export default PrivateRoute
