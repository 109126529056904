/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import Select, { components } from 'react-select'
import TextLabel from '../../Atoms/TextLabel'
import ListItem from '../../Atoms/ListItem'
import Image from '../../Atoms/Image'
import dropDownIcon from '../../../assets/icons/dropDown.svg'
import { colors } from '../../../theme/colors'
import Tooltip from '../Tooltip'
import information from '../../../assets/icons/information.svg'

const MainWrapper = styled.div``
const LabelWrapper = styled.div`
	margin-bottom: 15px;
	position: relative;
	.eyeImage {
		margin-left: 10px;
	}
`

const customStyles = ({ padding, border }: { padding?: string; border?: string }) => ({
	container: (provided: any) => ({
		...provided,
		width: '415px',
	}),
	control: (provided: any, state: any) => ({
		...provided,
		cursor: 'pointer',
		background: `${colors.lightGreyF10}`,
		borderRadius: '4px',
		padding: `${padding || '15px'}`,
		border: `${border || 'none'}`,
		boxShadow: state.isFocused ? `0 0 0 2px ${colors.greyS}` : 0,
		color: state.isFocused ? `${colors.black}` : `${colors.blue1}`,
	}),
	dropdownIndicator: (provided: any, state: any) => ({
		...provided,
		transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
		transition: 'all 150ms ease-in',
	}),
	menu: (provided: any) => ({
		...provided,
		marginTop: '6px',
		background: colors.white,
		boxShadow: `0px 4px 16px ${colors.black15}`,
		borderRadius: '4px',
	}),
	placeholder: (provided: any) => ({
		...provided,
		color: 'inherit',
		fontWeight: 'bold',
		fontSize: '16px',
		lineHeight: '19px',
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: 'inherit',
		fontWeight: 'bold',
		fontSize: '16px',
		lineHeight: '19px',
	}),
	option: (styles: any, state: any) => ({
		...styles,
		cursor: 'pointer',
		padding: '6px 20px',
		backgroundColor: state.isSelected ? colors.lightGreyF2 : null,
		color: state.isSelected && colors.black,
	}),
})

const SingleValue = (props: any) => {
	return (
		<components.SingleValue {...props}>
			<ListItem fontSize={16} text={props.data.label} fontWeight="bold" selected color="inherit" />
		</components.SingleValue>
	)
}

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<Image imageSrc={dropDownIcon} alt="drop-down-arrow" />
		</components.DropdownIndicator>
	)
}

const Option = (props: any) => {
	return (
		<components.Option {...props}>
			<ListItem text={props.label} fontWeight="bold" />
		</components.Option>
	)
}

const TooltipText = styled.div`
	padding: 5px;
	display: flex;
	max-width: 200px;
	overflow-wrap: break-word;
	justify-content: center;
	flex-wrap: wrap;
`

function SelectList({
	label,
	options,
	isDisabled,
	value,
	onChange,
	onInputChange,
	inputValue,
	isMulti,
	padding,
	border,
	onMenuClose,
	isLoading,
	tooltipInfo,
	menuPlacement,
	maxMenuHeight,
}: {
	label?: string
	options?: any
	isDisabled?: boolean
	isMulti?: boolean
	isLoading?: boolean
	value: any
	onChange?: any
	inputValue?: string
	padding?: string
	border?: string
	maxMenuHeight?: number
	menuPlacement?: 'auto' | 'bottom' | 'top'
	tooltipInfo?: string | undefined
	onInputChange?: (input: string) => void
	onMenuClose?: () => void
}) {
	const onChangeInputElement = (text: string, { action }: any) => {
		if (action === 'input-change' && onInputChange) {
			onInputChange(text)
		}
	}

	const onChangeElement = (item: any) => {
		onChange(item || [])
		if (onInputChange) {
			onInputChange('')
		}
	}

	return (
		<MainWrapper>
			{label && (
				<LabelWrapper>
					<TextLabel label={label} dropdown />
					{tooltipInfo && (
						<Tooltip placement="right" hideArrow tooltip={<TooltipText>{tooltipInfo}</TooltipText>}>
							<Image width="13px" height="13px" className="eyeImage" imageSrc={information} alt="eye" />
						</Tooltip>
					)}
				</LabelWrapper>
			)}
			<Select
				onMenuClose={onMenuClose}
				onInputChange={onChangeInputElement}
				options={options}
				isLoading={isLoading}
				isDisabled={isDisabled}
				styles={customStyles({ padding, border })}
				onChange={onChangeElement}
				isMulti={isMulti}
				inputValue={inputValue}
				onSelectResetsInput={false}
				onBlurResetsInput={false}
				onCloseResetsInput={false}
				isSearchable
				placeholder={label}
				value={value}
				maxMenuHeight={maxMenuHeight}
				menuPlacement={menuPlacement}
				components={{
					IndicatorSeparator: () => null,
					SingleValue,
					DropdownIndicator,
					Option,
				}}
			/>
		</MainWrapper>
	)
}

export default SelectList
