import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'
import { Box } from '@mui/material'
import {
	GetRowNodeIdFunc,
	GridApi,
	GridReadyEvent,
	GetContextMenuItems,
	GetContextMenuItemsParams,
	MenuItemDef,
} from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReactProps, AgGridReact } from 'ag-grid-react'
import { Children, cloneElement, FC, useCallback, useEffect, useRef, useState } from 'react'
import DeleteIcon from 'src/assets/icons/delete.svg'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { ConfirmationDialog } from 'src/components/ConfirmationDialog'
import { getSelectedRows } from 'src/components/Molecules/DataGrid/getSelectedRows'
import styled from 'styled-components'
import { usePrevious } from '../../../utils/usePrevious'
import { ActionRenderer } from './ActionRenderer'
import { AsyncAutocompleteEditor } from './AsyncAutocompleteEditor'
import { AutocompleteEditor } from './AutocompleteEditor'
import { StatusBarLeft } from './StatusBarLeft'
import { StatusBarRight } from './StatusBarRight'

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_TOKEN as string)

export interface DataGridProps extends AgGridReactProps {
	getRowNodeId: GetRowNodeIdFunc
	changesCount?: number
	statusBarConfig?: StatusBarConfig
	sync?(): void
	loading?: boolean
	fullWidth?: boolean
	onRemove?(rows: any[]): void // FIXME typings
	withConfirmation?: boolean
	getCustomContextMenuItems?: (
		params: GetContextMenuItemsParams,
	) => Array<{ name: string; action: () => void; icon?: string }>
}

export interface StatusBarConfig {
	onUndo?(): void
	canUndo?: boolean
	onRedo?(): void
	canRedo?: boolean
	onSave?(): Promise<void> | void
	canSave?: boolean
	isSaving?: boolean
	autoSave?: boolean
}

const StyledDataGrid = styled.div`
	min-height: 100%;
	height: 100%;

	.ag-root {
		position: inherit;
	}

	.ag-grid-overlay {
		height: 700px;
	}

	> .ag-root-wrapper {
		min-height: 700px;
		overflow: visible;
	}

	.ag-root-wrapper-body {
		height: 100%;
	}

	.ag-header-cell-label {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.ag-text-field-input-wrapper {
		.ag-text-field-input {
			border: none !important;
			padding-bottom: 0 !important;
		}
	}

	.ag-rich-select-list {
		height: auto;
	}

	.ag-react-container {
		width: 100%;
	}

	.ag-popup-editor {
		height: fit-content;
		width: fit-content;
		box-shadow: none !important ;
	}

	.ag-cell-inline-editing {
		height: 100%;
	}

	.ag-cell-editable {
		background: #013ca60d;
	}

	.ag-cell-error {
		background: #ffebee;
	}

	.ag-status-bar {
		padding-left: 4px;
		padding-right: 4px;
	}

	.ag-cell {
		line-height: normal;
		display: flex;
		align-items: center;

		&.no-focus {
			border: none !important;

			.ag-fill-handle {
				display: none;
			}
		}

		.ag-row-group {
			display: flex;
			align-items: center;
		}

		&.right-aligned {
			text-align: right;
			justify-content: flex-end;
		}

		&:not(.left-aligned, .right-aligned) {
			text-align: center;
			justify-content: center;
		}

		&:not(.ag-cell-range-selected) {
			border-right-color: #e2e2e2 !important;
		}
	}

	.ag-pinned-left-header,
	.ag-pinned-right-header,
	.ag-cell-last-left-pinned,
	.ag-cell-first-right-pinned {
		&:not(.ag-cell-range-selected) {
			border-width: 2px !important;
		}
	}

	.ag-details-row {
		background: #013ca605;
		padding: 8px;
	}
`

export const dataGridStatus = ['ORIGINAL', 'CREATED', 'REMOVED', 'MODIFIED'] as const
export type DataGridStatus = (typeof dataGridStatus)[number]

const globalComponents = {
	autocompleteEditor: AutocompleteEditor,
	asyncAutocompleteEditor: AsyncAutocompleteEditor,
	actionRenderer: ActionRenderer,
	statusBarLeft: StatusBarLeft,
	statusBarRight: StatusBarRight,
}

export const DataGrid: FC<DataGridProps> = ({
	sync,
	onCellValueChanged,
	fullWidth,
	children,
	onGridReady,
	loading,
	modules,
	frameworkComponents,
	defaultColDef,
	rowData,
	statusBarConfig,
	changesCount,
	onRemove,
	getCustomContextMenuItems,
	withConfirmation,
	...rest
}) => {
	const ref = useRef<{ api: GridApi }>(null)
	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	const [isDeletionPopupOpen, setIsDeletionPopupOpen] = useState(false)
	const [selectedRowsForDeletionPopup, setSelectedRowsForDeletionPopup] = useState<any[]>([])
	const prevChangesCount = usePrevious(changesCount)
	useEffect(() => {
		if (prevChangesCount != null) {
			statusBarConfig?.onSave?.()
		}
		// eslint-disable-next-line
	}, [changesCount])

	useEffect(() => {
		setTimeout(() => {
			if (loading) {
				ref.current?.api.showLoadingOverlay()
			} else {
				ref.current?.api.hideOverlay()
			}
		}, 10)
	}, [gridApi, loading, rowData])

	const handleGridReady = (params: GridReadyEvent) => {
		onGridReady?.(params)
		setGridApi(params.api)
	}

	const childrenWithDefaults = Children.map(children, (child) =>
		cloneElement(child as any, {
			...(child as any).props,
			wrapText: (child as any).props?.wrapText ?? true,
			// autoHeight: (child as any).props?.autoHeight ?? true,
			...(!(child as any).props?.width ? { flex: (child as any).props?.flex ?? 1 } : {}),
		}),
	)

	const handleDialogOpen = (selectedRows: any[]) => {
		setSelectedRowsForDeletionPopup(selectedRows)
		setIsDeletionPopupOpen(true)
	}

	const handleDialogClose = () => {
		setIsDeletionPopupOpen(false)
	}

	const handleOnConfirmed = () => {
		onRemove?.(selectedRowsForDeletionPopup)
		setIsDeletionPopupOpen(false)
	}

	useEffect(() => {
		const statusBarComponent = gridApi?.getStatusPanel('statusBarLeftKey')
		;(statusBarComponent as any)?.setConfig(statusBarConfig)
	}, [gridApi, rest.suppressReactUi, statusBarConfig])

	useEffect(() => {
		const statusBarComponent = gridApi?.getStatusPanel('statusBarRightKey')
		;(statusBarComponent as any)?.setConfig({ ...statusBarConfig, canSave: !!changesCount })
	}, [gridApi, statusBarConfig, changesCount, rest.suppressReactUi])

	useEffect(() => {
		const { current } = ref
		const onCtrlZ = (event: any) => {
			if (event.code === 'KeyZ' && (event.ctrlKey || event.metaKey)) {
				if (statusBarConfig?.canUndo) {
					current?.api?.clearRangeSelection()
					statusBarConfig?.onUndo?.()
				}
			}
		}
		// const container = (current?.api as any).gridBodyCon.eGridBody
		current?.api?.addEventListener('keydown', onCtrlZ)
		return () => current?.api?.removeEventListener('keydown', onCtrlZ)
	}, [statusBarConfig])

	const getContextMenuItems: GetContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams) => {
			const selectedRows = getSelectedRows(params.api)
			return [
				...(getCustomContextMenuItems ? getCustomContextMenuItems(params) : []),
				...(params.defaultItems || []),
				onRemove && {
					name: `Remove rows (${selectedRows.length})`,
					action: () => {
						if (withConfirmation) {
							handleDialogOpen(selectedRows)
						} else {
							onRemove(selectedRows)
						}
					},
					icon: `<img alt="delete_icon" src="${DeleteIcon}" width="16px" />`,
				},
			].filter(Boolean) as (string | MenuItemDef)[]
		},
		[onRemove, withConfirmation],
	)

	return (
		<StyledDataGrid className="ag-theme-material" style={{ height: '700px', width: fullWidth ? '100%' : 'auto' }}>
			<AgGridReact
				{...rest}
				ref={ref as any}
				frameworkComponents={{ ...globalComponents, ...(frameworkComponents || {}) }}
				modules={[/* ...AllModules, */ ...((modules as any) || [])]}
				onGridReady={handleGridReady}
				loadingOverlayComponent={() => (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<SpinnerComponent size={64} />
					</Box>
				)}
				animateRows
				getContextMenuItems={getContextMenuItems}
				// immutableData
				stopEditingWhenCellsLoseFocus
				enableRangeSelection
				enableFillHandle
				enableCellChangeFlash
				multiSortKey="ctrl"
				rowData={rowData}
				onCellValueChanged={(e) => {
					onCellValueChanged?.(e)
					if (e.oldValue !== e.value) {
						sync?.()
						ref.current?.api.resetRowHeights()
					}
				}}
				sideBar={{
					toolPanels: ['columns', 'filters'],
					position: 'left',
				}}
				defaultColDef={{
					sortable: true,
					filter: true,
					resizable: true,
					...defaultColDef,
					cellClassRules: {
						'ag-cell-editable': (params: any) => {
							return typeof params.colDef?.editable === 'function'
								? params.colDef.editable(params)
								: params.colDef.editable
						},
					},
				}}
				{...(statusBarConfig
					? {
							statusBar: {
								statusPanels: [
									{
										align: 'left',
										statusPanelParams: statusBarConfig,
										statusPanel: 'statusBarLeft',
										key: 'statusBarLeftKey',
									},
									{
										align: 'right',
										statusPanelParams: statusBarConfig,
										statusPanel: 'statusBarRight',
										key: 'statusBarRightKey',
									},
								],
							},
					  }
					: {})}
			>
				{childrenWithDefaults}
			</AgGridReact>

			<ConfirmationDialog
				open={isDeletionPopupOpen}
				onClose={handleDialogClose}
				onConfirmed={handleOnConfirmed}
				title="Confirm Deletion?"
				subtitle="Are you sure you want to delete this?"
			></ConfirmationDialog>
		</StyledDataGrid>
	)
}
