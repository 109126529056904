import { UploadAttributeTemplateValueForm } from 'src/facade/asset_service'
import { axiosInstance } from 'src/services/axiosInstance'

export const assetServiceService = {
	uploadAttachment(form: UploadAttributeTemplateValueForm): Promise<any> {
		const formdata = new FormData()
		formdata.append('attribute_id', form.attribute_id.toString())
		formdata.append('contract_id', form.contract_id)
		formdata.append('file', form.file)
		return axiosInstance.post('/asset_service/attributes/upload_template_value', formdata)
	},
}
