import styled from '@emotion/styled'
import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material'
import { red } from '@mui/material/colors'
import axios from 'axios'
import { get, head } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DropzoneArea } from 'react-mui-dropzone'
import { LOCAL_STORAGE_USER_TOKEN_KEY } from 'src/appConfig/contans'
import SpinnerComponent from 'src/components/Atoms/Loader'
import { formAttributeService } from 'src/facade/form-attribute/formAttributeService'
import { AttributeInputProps } from 'src/forms/_shared/attribute/AttributeInput'
import { MainFormForm } from 'src/forms/_shared/main-form/MainForm'
import { useAppSelector } from 'src/utils/reduxUtils'

const DropzoneAreaWrapper = styled.div`
	.MuiDropzoneArea-root {
		min-height: initial;
		margin-top: 24px;
	}
	.MuiDropzoneArea-icon.MuiSvgIcon-root {
		display: none;
	}
	.MuiDropzoneArea-text {
		margin: 8px;
		margin-top: 16px;
		margin-bottom: 16px;
		font-size: 1rem;
	}
	.MuiDropzonePreviewList-removeButton {
		top: auto;
		bottom: 0;
	}
	.MuiDropzoneArea-root.error {
		border-color: ${red[300]};
	}
	.MuiDropzoneArea-root.disabled {
		opacity: 0.6;
		pointer-events: none;
	}
	.MuiGrid-item {
		flex-basis: initial;
		max-width: initial;
	}
`

export const AttributeMediaInput: FC<AttributeInputProps> = (props) => {
	const {
		contract: { contract },
	} = useAppSelector((state) => state.users)

	const { attribute, name, form, disabled } = props
	const { control, formState, resetField, setValue } = useFormContext<MainFormForm>()

	const [isLoading, setLoading] = useState<boolean>(!!form.tagCode)
	const [initialFiles, setInitialFiles] = useState<string[]>([])

	useEffect(() => {
		const downloadImage = async () => {
			setLoading(true)
			try {
				const attachments = await formAttributeService.downloadAttachment({
					contract,
					attributeName: attribute.attribute_name,
					tagCode: form!.tagCode as string,
				})
				const attachment = head(attachments)
				if (attachment) {
					const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_TOKEN_KEY) as string)
					const { data } = await axios(attachment.downloadURI, {
						responseType: 'blob',
						headers: {
							Authorization: `Token ${token}`,
						},
					})
					setInitialFiles([data])
					resetField(name, { defaultValue: [data] })
				}
			} finally {
				setLoading(false)
			}
		}

		if (form.tagCode) {
			downloadImage()
		}
	}, [attribute.attribute_name, contract, form, form.tagCode, name, resetField, setValue])

	if (isLoading) {
		return (
			<Box my={2} sx={{ display: 'flex' }}>
				<SpinnerComponent size={32} />
			</Box>
		)
	}

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={[]}
			rules={{ required: { value: attribute.mandatory, message: 'Field is required.' } }}
			render={({ field }) => (
				<FormControl margin="normal" sx={{ width: '100%' }}>
					<InputLabel
						shrink
						variant="standard"
						htmlFor={attribute.id}
						required={attribute.mandatory}
						sx={{ color: get(formState.errors, name) ? red[700] : 'rgba(0, 0, 0, 0.6)' }}
					>
						{attribute.display_name}
					</InputLabel>
					<DropzoneAreaWrapper>
						<DropzoneArea
							{...field}
							initialFiles={initialFiles}
							showAlerts={false}
							acceptedFiles={['image/*']}
							dropzoneText={'Drag and drop an image here or click'}
							inputProps={{ id: attribute.id }}
							dropzoneClass={`${!!get(formState.errors, name) ? 'error ' : ''}` + `${disabled ? 'disabled' : ''}`}
							filesLimit={1}
						/>
					</DropzoneAreaWrapper>
					{get(formState.errors, name) && (
						<FormHelperText variant="standard" id={`helper-text-${attribute.id}`} sx={{ color: red[700] }}>
							{get(formState.errors, name)!.message}
						</FormHelperText>
					)}
				</FormControl>
			)}
		/>
	)
}

export function dataUrlToFile(dataurl: string, filename: string) {
	var arr = dataurl.split(','),
		mime = arr?.[0]?.match(/:(.*?);/)?.[1],
		bstr = window.atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new File([u8arr], filename, { type: mime })
}
