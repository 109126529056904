import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { HANDLE_IS_LOADING, HANDLE_IS_HISTORY_SIDEBAR_OPEN } from './constants'

export type ControlsActions = ActionType<typeof actions>

const initialControlsReducer: IControls = {
	isLoading: false,
	isHistorySidebarOpen: false,
}

const authorizationReducer = (state: IControls = initialControlsReducer, action: ControlsActions) => {
	switch (action.type) {
		case HANDLE_IS_LOADING:
			return { ...state, isLoading: action.payload }
		case HANDLE_IS_HISTORY_SIDEBAR_OPEN:
			return { ...state, isHistorySidebarOpen: action.payload }
		default:
			return state
	}
}

export default authorizationReducer
