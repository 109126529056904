import { ICellEditorParams } from 'ag-grid-community'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

export interface AutocompleteEditorProps extends ICellEditorParams {
	value: string | string[]
	options: Option[]
	selectProps?: any
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface Option {
	label: string
	value: string
}

const Wrapper = styled.div<{ width?: number; height: number }>`
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	background: inherit;
	display: flex;
	align-items: center;
`

export const AutocompleteEditor = forwardRef<any, AutocompleteEditorProps>((props, ref) => {
	const [option, setOption] = useState<Option | Option[]>(
		props.selectProps?.isMulti
			? (props.options.filter(({ value }) => props.value?.includes(value)) as Option[])
			: (props.options.find(({ value }) => value === props.value) as Option),
	)
	const refInput = useRef<any>(null)

	useEffect(() => {
		setTimeout(() => refInput.current?.focus())
	}, [])

	/* Component Editor Lifecycle methods */
	useImperativeHandle(ref, () => {
		return {
			getValue() {
				return Array.isArray(option) ? option.map(({ value }) => value) : option?.value
			},
			// Gets called once before editing starts, to give editor a chance to
			// cancel the editing before it even starts.
			isCancelBeforeStart() {
				return false
			},
			// Gets called once when editing is finished (eg if enter is pressed).
			// If you return true, then the result of the edit will be ignored.
			isCancelAfterEnd() {
				return false
			},
			isPopup() {
				return true
			},
		}
	})

	return (
		<Wrapper width={(props.column as any).actualWidth} height={props.eGridCell.offsetHeight}>
			<Select
				ref={refInput}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus
				options={props.options}
				menuPosition="fixed"
				styles={{
					container: (prev) => ({
						...prev,
						width: (props.column as any).actualWidth,
					}),
					control: (prev) => ({
						...prev,
						borderRadius: 0,
					}),
				}}
				value={option}
				onChange={(item: any) => setOption(item)}
				fullWidth
				defaultMenuIsOpen
				{...props.selectProps}
			/>
		</Wrapper>
	)
})

AutocompleteEditor.displayName = 'AutocompleteEditor'
AutocompleteEditor.defaultProps = {
	selectProps: {},
}
