export const DEPENDS_ON_OPTIONS: Record<string, string> = {
	Populated: 'populated',
	'Not Populated': 'not_populated',
	Equals: 'eq',
	'Not equals': 'ne',
	Contains: 'contains',
	'Greater than': 'ge',
	'Less than': 'le',
	'Starts with': 'starts_with',
	'Ends with': 'ends_with',
}
