import { PureComponent } from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

interface ISpinnerComponent {
	size: number
	color?: string
}

const Loader = styled.div<{ width: number; height: number; color?: string }>`
	width: ${({ width }) => (width ? `${width}px` : '20px')};
	height: ${({ height }) => (height ? `${height}px` : '20px')};
	position: relative;
	background: transparent !important;
	&::after {
		content: ' ';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		border-radius: 50%;
		border: ${({ color }) => (color ? `3px solid ${color}` : `3px solid ${colors.main100}`)};
		border-color: ${({ color }) =>
			color
				? `${color} transparent ${color} transparent;`
				: `${colors.main100} transparent ${colors.main100} transparent;`};
		animation: lds-dual-ring 1.2s linear infinite;
	}
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

class SpinnerComponent extends PureComponent<ISpinnerComponent> {
	public render() {
		const { size, color } = this.props
		return <Loader width={size} height={size} color={color} />
	}
}

export default SpinnerComponent
