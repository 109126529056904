import { Button } from '@mui/material'
import { uniqBy } from 'lodash'
import { FC, Fragment, useState } from 'react'
import { CellProps } from 'react-table'
import { DependsOnConditionalDialog } from 'src/components/Organisms/Forms/DependsOnConditionalDialog'
import { DEPENDS_ON_OPTIONS } from 'src/components/Organisms/Forms/dependsOnOptions'
import { AttributeDependsOn } from 'src/facade/forms/formsService.types'
import { IFormComponentsAttributes, IFormDataObject } from 'src/models/formsHandler/formsHandler'

export type DependsOnCondition = {
	condition?: string // FIXME typeof DEPENDS_ON_OPTIONS[keyof typeof DEPENDS_ON_OPTIONS]
	argument?: string
	argumentComponentId?: string
	conditionValue?: string // it cannot be named as 'value'
}

export const DependsOnConditionalRenderer: FC<
	CellProps<IFormDataObject> & {
		headerId: string
		isDisabled: boolean
		components: IFormComponentsAttributes[]
		onEditRow: (item: { id: string | number; keyName: string; value: any }) => void
	}
> = ({ value, row, headerId, isDisabled, onEditRow, components }) => {
	const [open, setOpen] = useState(false)

	const dependsOnLabel = value?.value?.condition
		? value?.value?.condition === AttributeDependsOn.PreRequisite
			? 'Prerequisite'
			: `${value?.value?.argument ? `${value?.value.argument}` : ''} ${
					value?.value?.argumentComponentId ? `(${value?.value.argumentComponentId})` : ''
			  }  ${Object.keys(DEPENDS_ON_OPTIONS)
					.find((key) => DEPENDS_ON_OPTIONS[key] === value?.value?.condition)
					?.toLowerCase()}${value?.value?.conditionValue ? ` ${value?.value.conditionValue}` : ''}`
		: 'add'

	return (
		<Fragment>
			<Button
				disabled={isDisabled}
				onClick={() => setOpen(true)}
				sx={{ textAlign: 'left', fontSize: 12, lineHeight: 1.2, width: '100%' }}
			>
				{dependsOnLabel}
			</Button>
			<DependsOnConditionalDialog
				open={open}
				dependsOnOptions={uniqBy(components, 'originalId')
					.slice()
					.sort((a, b) => a.sequence - b.sequence)
					.map((component) =>
						component.attributes.map((attribute) => ({
							value: attribute.attribute_name.value,
							label: attribute.attribute_name.value,
							category: component.displayName,
							componentId: component.id as string,
						})),
					)
					.flat()}
				defaultValue={value?.value}
				components={components}
				onClose={() => setOpen(false)}
				onChange={(dependsOnValue) => {
					onEditRow({
						id: row.original.id,
						keyName: headerId,
						value: dependsOnValue,
					})
					setOpen(false)
				}}
			/>
		</Fragment>
	)
}
